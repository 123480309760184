<div class="wrapper" [@harmonica]="show">
	<mat-slide-toggle
		[color]="color"
		[formControl]="control"
		[matTooltip]="tooltip"
		matTooltipPosition="above"
		[labelPosition]="labelPosition"
		[class.read-only]="readonly || isDisabled"
	>
		{{ label }}
		<i class="fad fa-question-circle" *ngIf="tooltip"></i>
	</mat-slide-toggle>
</div>
