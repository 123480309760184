import {
	AgendaState,
	ConnectedUpdateModel,
	CreateSessionItemModel,
	CreateSessionModel,
	CreateVoteModel,
	DuplicateSessionItemModel,
	DuplicateSessionModel,
	LoadAgendaModel,
	ToggleSessionItemModel,
	UpdateAgendaModel,
	UpdateAgendaSessionsModel,
	UpdateFlashcardSetModel,
	UpdatePushoutLinkDto,
	UpdateSelfieModel,
	UpdateSessionItemModel,
	UpdateSessionPostModel,
	UpdateVoteModel,
	CreateWordCloudModel,
	CreateBrainstormModel,
} from './agenda.model';
import {
	Agenda,
	Connected,
	Selfie,
	Session,
	SessionItem,
	SessionItemActiveChange,
	SessionItemPushouts,
	Vote,
} from '@consensus/connect/shared/agenda/domain';
import { ConnectFlashcardSet } from '@consensus/connect/shared/flashcards/domain';
import { ConnectLinkPushout } from '@consensus/connect/shared/pushout/domain';
import { Survey, SurveySection } from '@consensus/connect/shared/survey/domain';
import { CmsSurveyEditorSectionFileTypePatch } from '@consensus/connect/cms/survey/domain';
import { MoveModel } from '@shared/models';
import { createAction } from '@ngrx/store';
import { ApiAction } from '@lib/redux';
import { WordCloudModel } from '@consensus/connect/cms/word-cloud/domain';

export const loadAgendas = new ApiAction<AgendaState, void, LoadAgendaModel>(
	'Agendas',
	'Load',
	'Agenda Manager',
	{
		showErrors: true,
		parseDates: true,
	}
);

export const createAgenda = new ApiAction<AgendaState, string, Agenda>(
	'Agenda',
	'Create',
	'Agenda Manager',
	{
		successMessage: 'Agenda successfully created',
		showErrors: true,
	}
);

export const updateAgenda = new ApiAction<
	AgendaState,
	UpdateAgendaModel,
	Agenda
>('Agenda', 'Update', 'Agenda Manager', {
	successMessage: 'Agenda successfully updated',
	showErrors: true,
});

export const deleteAgenda = new ApiAction<AgendaState, string>(
	'Agenda',
	'Delete',
	'Agenda Manager',
	{
		successMessage: 'Agenda successfully removed',
		showErrors: true,
		eager: true,
	}
);

export const sessionItemActiveChanged = createAction(
	'[Socket] Session Item Active Changed',
	(payload: SessionItemActiveChange) => ({ payload })
);

export const moveAgenda = new ApiAction<AgendaState, MoveModel, MoveModel>(
	'Agenda',
	'Move',
	'Agenda Manager',
	{
		showErrors: true,
		eager: true,
	}
);

export const createSession = new ApiAction<
	AgendaState,
	CreateSessionModel,
	Session
>('Session', 'Create', 'Agenda Manager', {
	successMessage: 'Session successfully created',
	showErrors: true,
});

export const updateSession = new ApiAction<
	AgendaState,
	UpdateSessionPostModel,
	Session
>('Session', 'Update', 'Agenda Manager', {
	successMessage: 'Session successfully updated',
	showErrors: true,
});

export const updateAgendaSessionsQaEnabled = new ApiAction<
	AgendaState,
	UpdateAgendaSessionsModel,
	Session[]
>('Sessions', 'Update', 'Agenda Manager', {
	successMessage: 'Sessions for agenda successfully updated',
	showErrors: true,
});

export const duplicateSession = new ApiAction<
	AgendaState,
	DuplicateSessionModel,
	Session
>('Session', 'Duplicate', 'Agenda Manager', {
	successMessage: 'Session successfully duplicated',
	showErrors: true,
});

export const moveSession = new ApiAction<AgendaState, MoveModel, MoveModel>(
	'Session',
	'Move',
	'Agenda Manager',
	{
		showErrors: true,
		eager: true,
	}
);

export const deleteSession = new ApiAction<AgendaState, string>(
	'Session',
	'Delete',
	'Agenda Manager',
	{
		successMessage: 'Session successfully removed',
		showErrors: true,
		eager: true,
	}
);

export const createSessionItem = new ApiAction<
	AgendaState,
	CreateSessionItemModel,
	SessionItem
>('Session Item', 'Create', 'Agenda Manager', {
	successMessage: 'Session Item successfully created',
	showErrors: true,
});

export const updateSessionItem = new ApiAction<
	AgendaState,
	UpdateSessionItemModel,
	SessionItem
>('Session Item', 'Update', 'Agenda Manager', {
	successMessage: 'Session Item successfully updated',
	showErrors: true,
});

export const toggleSessionItem = new ApiAction<
	AgendaState,
	ToggleSessionItemModel,
	SessionItem
>('Session Item', 'Toggle', 'Agenda Manager', {
	showErrors: true,
});

export const activatePushout = new ApiAction<AgendaState, string, SessionItem>(
	'Session Item Pushout',
	'Activate',
	'Agenda Manager',
	{
		showErrors: true,
	}
);

export const deactivatePushout = new ApiAction<
	AgendaState,
	string,
	SessionItem
>('Session Item Pushout', 'Deactivate', 'Agenda Manager', {
	showErrors: true,
});

export const activatePushoutResults = new ApiAction<
	AgendaState,
	string,
	SessionItem
>('Session Item Pushout Results', 'Activate', 'Agenda Manager', {
	showErrors: true,
});

export const deactivatePushoutResults = new ApiAction<
	AgendaState,
	string,
	SessionItem
>('Session Item Pushout Results', 'Deactivate', 'Agenda Manager', {
	showErrors: true,
});

export const duplicateSessionItem = new ApiAction<
	AgendaState,
	DuplicateSessionItemModel,
	SessionItem
>('Session Item', 'Duplicate', 'Agenda Manager', {
	successMessage: 'Session Item successfully duplicated',
	showErrors: true,
});

export const moveSessionItem = new ApiAction<AgendaState, MoveModel, MoveModel>(
	'Session Item',
	'Move',
	'Agenda Manager',
	{
		showErrors: true,
		eager: true,
	}
);

export const deleteSessionItem = new ApiAction<AgendaState, string>(
	'Session Item',
	'Delete',
	'Agenda Manager',
	{
		successMessage: 'Session Item successfully removed',
		showErrors: true,
		eager: true,
	}
);

export const loadSessionItemPushouts = new ApiAction<
	AgendaState,
	string,
	SessionItemPushouts
>('Session Item Pushout', 'Load', 'Agenda Manager', {
	showErrors: true,
});

export const updateSessionItemPushouts = new ApiAction<
	AgendaState,
	UpdateSessionItemModel,
	SessionItemPushouts
>('Session Item Pushout', 'Update', 'Agenda Manager', {
	successMessage: 'Session Item Content successfully updated',
	showErrors: true,
});

export const updateVote = new ApiAction<AgendaState, UpdateVoteModel, Vote>(
	'Vote',
	'Update',
	'Agenda Manager',
	{
		successMessage: 'Vote successfully updated',
		showErrors: true,
	}
);

export const createVote = new ApiAction<AgendaState, CreateVoteModel, Vote>(
	'Vote',
	'Create',
	'Agenda Manager',
	{
		successMessage: 'Vote successfully updated',
		showErrors: true,
	}
);

export const createWordCloud = new ApiAction<
	AgendaState,
	CreateWordCloudModel,
	WordCloudModel
>('WordCloud', 'Create', 'Agenda Manager', {
	successMessage: 'WordCloud successfully created',
	showErrors: true,
});

export const createBrainstorm = createAction(
	'[Brainstorm] Create Brainstorm',
	(payload: CreateBrainstormModel) => ({ payload })
);

export const upsertSelfie = new ApiAction<
	AgendaState,
	UpdateSelfieModel,
	Selfie
>('Selfie', 'Update', 'Agenda Manager', {
	successMessage: 'Selfie successfully updated',
	showErrors: true,
});

export const updateSurvey = new ApiAction<AgendaState, Survey, Survey>(
	'Survey',
	'Update',
	'Agenda Manager',
	{
		successMessage: 'Survey successfully updated',
		showErrors: true,
	}
);

export const updateFlashcardSet = new ApiAction<
	AgendaState,
	UpdateFlashcardSetModel,
	ConnectFlashcardSet
>('Flashcard Set', 'Update', 'Agenda Manager', {
	successMessage: 'Flashcards successfully updated',
	showErrors: true,
});

export const updateConnected = new ApiAction<
	AgendaState,
	ConnectedUpdateModel,
	Connected
>('Connected', 'Update', 'Agenda Manager', {
	successMessage: 'Connected successfully updated',
	showErrors: true,
});

export const updatePushoutLink = new ApiAction<
	AgendaState,
	UpdatePushoutLinkDto,
	ConnectLinkPushout
>('Pushout Link', 'Update', 'Agenda Manager', {
	successMessage: 'Pushout Link successfully updated',
	showErrors: true,
});

export const patchSurveySectionFile = new ApiAction<
	AgendaState,
	CmsSurveyEditorSectionFileTypePatch,
	SurveySection
>('Survey section', 'Patch Type', 'Agenda Manager', {
	showErrors: true,
});
