<div #canvasContainer class="tw-flex tw-h-full tw-w-full tw-flex-col">
	<ng-container *ngIf="riveFile; else loadingTpl">
		<ng-container
			*ngrxLet="{
				height: containerHeight$,
				width: containerWidth$
			} as dimensions"
		>
			<canvas
				coRive
				class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center"
				[height]="dimensions.height"
				[width]="dimensions.width"
				[fit]="fit"
				[alignment]="alignment"
				[riv]="riveFile"
				(fileContentRead)="setFileContent($event)"
				(reportedEventsChange)="onReportedEventsChange($event)"
				(artboardChange)="onArtboardChange($event)"
			>
				<ng-container
					*ngrxLet="currentArtboardContent$ as currentArtboardContent"
				>
					<co-rive-animation
						*ngFor="
							let animation of currentArtboardContent.animations;
							trackBy: trackByIdentity;
							let i = index
						"
						[name]="animation"
						[play]="autoplayAnimation"
					/>
					<co-rive-state-machine
						*ngFor="
							let stateMachine of currentArtboardContent.stateMachines;
							trackBy: trackByIdentity;
							let i = index
						"
						[name]="stateMachine.name"
						[play]="true"
					>
						<ng-container
							*ngFor="
								let input of stateMachine.inputs;
								trackBy: trackByIdentity
							"
						>
							<co-rive-input
								*ngIf="!scrollInputs.includes(input.name)"
								[name]="input.name"
								[value]="inputValues[input.name]"
							/>
							<co-rive-input
								*ngIf="!scrollInputs.includes(input.name)"
								[name]="input.name"
							/>
							<co-rive-input
								*ngIf="scrollInputs.includes(input.name)"
								[name]="input.name"
								[value]="scrollPercentage$ | ngrxPush"
							/>
						</ng-container>
					</co-rive-state-machine>
				</ng-container>
			</canvas>
		</ng-container>
	</ng-container>
</div>
<ng-template #loadingTpl>
	<div class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center">
		<co-spinner />
	</div>
</ng-template>
