import { ConnectLinkPushout } from '@consensus/connect/shared/pushout/domain';
import { Survey } from '@consensus/connect/shared/survey/domain';
import { ConnectFlashcardSet } from '@consensus/connect/shared/flashcards/domain';

export interface Agenda {
	id: string;
	sortingKey: number;
	name: string;
	date: Date;
	eventId: string;
	qaEnabled: boolean;
	hideDate: boolean;
	location: string;
	visible: boolean;
	visibleToAll: boolean;
	groups: string[];
	enableAddToCalendar: boolean;

	sessions?: Session[];
	live?: number;
}

export interface Session {
	id: string;
	sortingKey: number;
	name: string;
	description: string;
	start: Date;
	end: Date;
	qaOpen: boolean;
	enableNotes: boolean;
	signatureEnabled: boolean;
	visible: boolean;
	agendaId: string;
	groups: string[];
	speakers: string[];
	visibleToAll: boolean;
	sessionItems?: SessionItem[];
	live?: number;
}

export interface SessionItem {
	id: string;
	sortingKey: number;
	name: string;
	active: boolean;
	resultsActive: boolean;
	sessionId: string;
	groups: string[];
	visibleToAll: boolean;
	sendToSession: boolean;
	includeInReport: boolean;
	pushoutsLoaded: boolean;
	displayTitle?: string;
	activityInstruction?: string;
	type?: SessionItemTypes;
	optional?: boolean;
	launchCode?: string;
	closeableAfterSubmitting?: boolean;
	enableAnimation?: boolean;
	hideFromSidepanel?: boolean;
	activatedAt: string | null;

	vote?: Vote;
	survey?: Survey;
	selfie?: Selfie;
	flashcardSet?: ConnectFlashcardSet;
	connected?: Connected;
	brainstorm?: Brainstorm;
	wordCloud?: WordCloud;
	link?: ConnectLinkPushout;
}

export type SessionItemActiveChange = Pick<
	SessionItem,
	'id' | 'active' | 'resultsActive' | 'activatedAt'
>;

export interface SessionItemOption {
	id: string;
	name: string;
	displayTitle: string;
	type?: SessionItemTypes;
	agenda: string;
	session: string;
}

export interface SessionItemPushouts {
	id: string;

	optional: boolean;
	launchCode: string;
	closeableAfterSubmitting: boolean;
	hideFromSidepanel: boolean;
	displayTitle: string;
	activityInstruction: string;
	sessionItemType: SessionItemTypes;

	vote: Vote;
	flashcardSet: ConnectFlashcardSet;
}

export interface Vote {
	id: string;
	sessionItemId: string;
	questionText: string;
	maxAnswers: number;
	points: number;
	partialPoints: boolean;
	successThreshold: number;
	allowIncorrect: boolean;
	changeSelectionText: string;
	userCanChangeSelection: boolean;
	options: VoteOption[];
}

export interface VoteOption {
	id: string;
	text: string;
	sortingKey: number;
	color: string;
	isCorrect: boolean;
}

export interface Connected {
	id: string;
	sessionItemId: string;
	color: string;
	textColor: string;
	text: string;
}

export interface Brainstorm {
	id: string;
}

export interface WordCloud {
	id: string;
}

export enum SessionItemTypes {
	Survey = 'Survey',
	ColorVote = 'ColorVote',
	Flashcard = 'Flashcard',
	Selfies = 'Selfies',
	Connected = 'Connected',
	WordCloud = 'WordCloud',
	Brainstorm = 'Brainstorm',
	Refresh = 'Refresh',
	Link = 'Link',
}

export interface Selfie {
	id: string;
	sessionItemId: string;
	enableColearnRewards: boolean;
	autoDisplayUserSelfies: boolean;
	allowMultipleSubmissions: boolean;

	sendEmailToSelfieOwner: boolean;
	tags: string[];
}
