<ng-container [ngSwitch]="type">
	<co-form-bool-input
		*ngSwitchCase="types.Bool"
		[control]="control"
		[readonly]="readonly"
	/>
	<co-form-color-input
		*ngSwitchCase="types.Color"
		[control]="control"
		[readonly]="readonly"
	/>
	<co-form-date-input
		*ngSwitchCase="types.Date"
		[control]="control"
		[readonly]="readonly"
	/>
	<co-form-datetime-input
		*ngSwitchCase="types.DateTime"
		[control]="control"
		[readonly]="readonly"
	/>
	<co-form-time-input
		*ngSwitchCase="types.Time"
		[control]="control"
		[readonly]="readonly"
	/>
	<co-form-html-input
		*ngSwitchCase="types.HTML"
		[control]="control"
		[readonly]="readonly"
	/>
	<co-form-longtext-input
		*ngSwitchCase="types.LongText"
		[control]="control"
		[readonly]="readonly"
	/>
	<co-form-password-input
		*ngSwitchCase="types.Password"
		[control]="control"
		[readonly]="readonly"
	/>
	<co-form-phone-input
		*ngSwitchCase="types.Phone"
		[control]="control"
		[readonly]="readonly"
	/>
	<co-form-search-input
		*ngSwitchCase="types.Search"
		[control]="control"
		[readonly]="readonly"
	/>
	<co-form-select
		*ngSwitchCase="types.Select"
		[control]="control"
		[readonly]="readonly"
	/>
	<co-form-select
		*ngSwitchCase="types.SelectMany"
		[control]="control"
		[readonly]="readonly"
	/>

	<co-form-plain-input
		*ngSwitchDefault
		[control]="control"
		[readonly]="readonly"
	/>
</ng-container>
