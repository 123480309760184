export function parseJsonFile(file: File) {
	return new Promise((resolve, reject) => {
		if (!file) {
			return reject(null);
		}

		const reader = new FileReader();

		reader.onload = () => {
			const result = JSON.parse(reader.result as string);
			resolve(result);
		};

		reader.readAsText(file);
	});
}
