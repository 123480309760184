import { inject, Injectable } from '@angular/core';
import { filter, map, pairwise, tap } from 'rxjs';
import {
	NavigationEnd,
	NavigationExtras,
	Router,
	RoutesRecognized,
	Scroll,
	UrlSerializer,
} from '@angular/router';
import { SessionService } from '@store/scope';
import { DOCUMENT, Location, ViewportScroller } from '@angular/common';
import { isString } from 'lodash-es';

const EVENT_URL_REGEX = /^\/event\/[^/]+\/?/;

interface NavigationHistoryEntry {
	route: string;
	scrollPosition: [number, number];
	id: number;
	trigger: 'imperative' | 'popstate' | 'hashchange';
	state: { [k: string]: unknown } | null;
}

export enum BackButtonStrategy {
	/** Use Location.back to navigate to the previous location */
	BrowserHistoryBack = 'BrowserHistoryBack',
	/** N */
	AngularRouterImperative = 'AngularRouterImperative',
	Dynamic = 'Dynamic',
}

const persistHistory = (history: NavigationHistoryEntry[]) => {
	// The reason for the try-catch is that sessionStorage isn't available
	// in Safari Private Browsers
	try {
		sessionStorage.setItem(
			'__locationService_history',
			JSON.stringify(history)
		);
	} catch {
		// eslint-disable-next-line: no-empty
	}
};

const getPresistedHistory = (): NavigationHistoryEntry[] => {
	// The reason for the try-catch is that sessionStorage isn't available
	// in Safari Private Browsers
	try {
		const persistedHistory = sessionStorage.getItem(
			'__locationService_history'
		);
		if (!persistedHistory) {
			return [];
		}

		const parsedHistory: NavigationHistoryEntry[] =
			JSON.parse(persistedHistory);
		if (parsedHistory.length > 0 && isString(parsedHistory[0])) {
			// Catch and throw away any histories from before scrollPosition, id and trigger was included
			return [];
		}
	} catch {
		return [];
	}
	return [];
};

@Injectable({ providedIn: 'root' })
export class LocationService {
	readonly #document = inject(DOCUMENT);
	readonly #urlSerializer = inject(UrlSerializer);
	readonly #viewportScroller = inject(ViewportScroller);
	readonly #location = inject(Location);
	readonly #sessionService = inject(SessionService);
	router = inject(Router);
	readonly #routerRoutesRecognizedEvents$ = this.router.events.pipe(
		filter(
			(event: unknown): event is RoutesRecognized =>
				event instanceof RoutesRecognized
		)
	);
	readonly #routerNavigationEndEvents$ = this.router.events.pipe(
		filter(
			(event: unknown): event is NavigationEnd => event instanceof NavigationEnd
		)
	);
	readonly #routerScrollEvents$ = this.router.events.pipe(
		filter((event: unknown): event is Scroll => event instanceof Scroll)
	);
	#backButtonStrategy: BackButtonStrategy = BackButtonStrategy.Dynamic;
	history: NavigationHistoryEntry[] = getPresistedHistory() ?? [];

	/** ISSUE #4965: Remove disallowUfaBackButtonDoubleClicks once a proper solution is in place for the timing issue in point and click dashboards [MAB] */
	#disallowUfaBackButtonDoubleClicks = false;
	set disallowUfaBackButtonDoubleClicks(value: boolean) {
		console.debug('SET disallowBackButtonDoubleClicks ', value);
		this.#disallowUfaBackButtonDoubleClicks = value;
	}
	get disallowUfaBackButtonDoubleClicks() {
		return this.#disallowUfaBackButtonDoubleClicks;
	}

	static cleanUrl(url: string) {
		if (!url) {
			return [];
		}
		return url
			.toLowerCase()
			.replace(/(?:^\/+|\/+$)/, '')
			.split('/')
			.filter(x => !!x);
	}

	constructor() {
		// Setup router event handers
		this.setupNavigationHistoryHandler();
		this.setupUfaImperativeNavigationScrollHandler();
		this.setupUfaPopStateNavigationScrollHandler();
	}

	async navigate(url: any[], options: NavigationExtras = {}) {
		return await this.router.navigate(
			url.filter(x => x),
			options
		);
	}

	async navigateEvent(url: any[] | string, options: NavigationExtras = {}) {
		const queryParams: { [prop: string]: string } = {};

		if (typeof url === 'string') {
			const split = url.split('?');

			if (split.length > 1) {
				url = split[0];

				split[1].split('&').forEach(str => {
					const prop = str.split('=');
					if (prop.length < 2) {
						return;
					}
					queryParams[decodeURIComponent(prop[0])] = decodeURIComponent(
						prop[1]
					);
				});
			}

			if (url.startsWith('./')) {
				// Used when linking to other events
				url = [url];
			} else {
				url = LocationService.cleanUrl(url);
			}
		}
		const isAbsoluteInternalLink = url.length > 0 && url[0].startsWith('./');
		if (!isAbsoluteInternalLink) {
			url = ['/', 'event', this.#sessionService.eventSlug, ...url];
		}
		await this.navigate(url, { queryParams, ...options });
	}

	/** Allows components to override the default 'Dynamic' BackButtonStrategy */
	setBackButtonStrategy(strategy: BackButtonStrategy) {
		this.#backButtonStrategy = strategy;
	}

	goBack(strategy?: BackButtonStrategy) {
		if (!strategy) {
			strategy = this.#backButtonStrategy;
		}
		if (strategy === BackButtonStrategy.Dynamic) {
			strategy = this.resolveDynamicBackButtonStrategy();
		}
		switch (strategy) {
			case BackButtonStrategy.AngularRouterImperative: {
				const prevEntry = this.history[this.history.length - 1];
				if (!prevEntry) {
					this.goHome();
					return;
				}
				this.router.navigateByUrl(prevEntry?.route); // We rely on setupNavigationHistoryHandler to keep the history synced
				return;
			}
			case BackButtonStrategy.BrowserHistoryBack:
			default: {
				this.#location.back();
				return;
			}
		}
	}

	get showBack() {
		return this.history.length > 0;
	}

	isUrlWithoutAnchor(url: string) {
		const anchor = this.#urlSerializer.parse(url)?.fragment;
		return !anchor;
	}

	/**
	 * Determine whether or not the back button can use Location.back(),
	 * or if it should imperatively navigate to the previous URL.
	 */
	resolveDynamicBackButtonStrategy():
		| BackButtonStrategy.BrowserHistoryBack
		| BackButtonStrategy.AngularRouterImperative {
		// If we are in the CMS scope of Connect, always use the BrowserHistoryBack strategy
		const isEventRoute = EVENT_URL_REGEX.test(this.router.url);
		if (!isEventRoute) {
			return BackButtonStrategy.BrowserHistoryBack;
		}

		// If there is any iframe, fallback to imperative routing to avoid potential issues with in-iframe navigation
		const anyIframesInDocument =
			this.#document.querySelectorAll('iframe').length > 0;
		return anyIframesInDocument
			? BackButtonStrategy.AngularRouterImperative
			: BackButtonStrategy.BrowserHistoryBack;
	}

	goHome() {
		if (this.#sessionService.eventSlug) {
			this.router.navigate(['/event', this.#sessionService.eventSlug]);
		} else {
			this.router.navigate(['/client', this.#sessionService.clientSlug]);
		}
	}

	/** Setup navigation history registration */
	setupNavigationHistoryHandler() {
		this.#routerRoutesRecognizedEvents$
			.pipe(
				map(({ urlAfterRedirects }) => urlAfterRedirects),
				// side-effect (clear history)
				tap(url => {
					if (!/^\/event\/[^/]+\/?/.test(url)) {
						this.history = [];
						persistHistory(this.history);
					}
				}),
				// Only include history from within /event/{eventName}
				filter(url => EVENT_URL_REGEX.test(url)),
				pairwise()
			)
			.subscribe(([fromUrl, toUrl]) => {
				const currentState = this.router.getCurrentNavigation();
				if (!currentState) {
					return;
				}
				if (
					currentState.trigger === 'imperative' &&
					currentState.extras.replaceUrl
				) {
					return;
				}

				if (fromUrl === toUrl) {
					return;
				}

				// Back
				const prevEntry = this.history[this.history.length - 1] ?? null;
				if (prevEntry?.route === toUrl) {
					this.history.pop();
				} else {
					const newEntry: NavigationHistoryEntry = {
						route: fromUrl,
						scrollPosition: this.#viewportScroller.getScrollPosition(),
						id: currentState.id,
						trigger: currentState.trigger,
						state: currentState?.extras?.state ?? null,
					};
					this.history.push(newEntry);
				}
				persistHistory(this.history);
			});
	}

	/**
	 * Due to legacy reasons, scrollPositionRestoration cannot be set to 'enabled' for app-wide routing,
	 * as large sections of the CMS depends on non-changed scroll position on navigation (the equivalent of
	 * setting scrollPositionRestoration to 'disabled').
	 *
	 * In the UFA parts of the application, however, the desired behaviour is scrollPositionRestoration 'enabled',
	 * which this helps simulate.
	 *
	 * For 'imperative' routing events, i.e. "forward" navigation triggered with Router.navigate or Router.navigateByUrl,
	 * the desired behaviour is that we scroll to top, unless an anchor is present in the URL
	 * (if an anchor is present, we let Angular handle scroll).
	 *
	 * Importantly, the scroll to top happens on 'NavigationEnd' in the Router event lifecycle, not on 'Scroll'.
	 * This is to preempt change detection in the target component, before scroll position is changed, as unnecessary
	 * data loads would otherwise trigger in components with 'lazy data loading', such as the Modular
	 * Dashboard, which uses IntersectionObserver to determine which cards to load data for.
	 *
	 * Note: For similar handling of 'popstate' navigation events see {@link setupUfaPopStateNavigationScrollHandler}
	 */
	setupUfaImperativeNavigationScrollHandler() {
		this.#routerNavigationEndEvents$
			.pipe(
				filter(
					event =>
						EVENT_URL_REGEX.test(event.urlAfterRedirects) && // Only include routing events in the UFA scope of Connect
						this.isUrlWithoutAnchor(event.urlAfterRedirects) && // Ensure that no anchor is present in the resolved URL
						this.router.getCurrentNavigation()?.trigger === 'imperative' // Only include imperative events
				)
			)
			.subscribe(_ => {
				this.#viewportScroller.scrollToPosition([0, 0]); // Scroll to top
			});
	}

	/**
	 * Due to legacy reasons, scrollPositionRestoration cannot be set to 'enabled' for app-wide routing,
	 * as large sections of the CMS depends on non-changed scroll position on navigation (the equivalent of
	 * setting scrollPositionRestoration to 'disabled').
	 *
	 * In the UFA parts of the application, however, the desired behaviour is scrollPositionRestoration 'enabled',
	 * which this helps simulate.
	 *
	 * For 'popstate' routing events, i.e. navigation triggered with the browser's Back/Forward buttons, history.back() or
	 * Angular's Location.back(), the desired behaviour is that we scroll to the last known scroll position on the route,
	 * unless an anchor is present in the URL (if an anchor is present, we let Angular handle scroll).
	 *
	 * Importantly, the scroll happens on the 'Scroll'  in the Router event lifecycle, as it occurs after the initial change detection
	 * in the target component. This ensures that there is no attempt to scroll to a position that isn't yet available in the DOM (which could
	 * leave the user 'stranded' in another scroll position than the one we attempted to restore)
	 *
	 * Note: For similar handling of 'imperative' navigation events see {@link setupUfaImperativeNavigationScrollHandler}
	 */
	setupUfaPopStateNavigationScrollHandler() {
		this.#routerScrollEvents$
			.pipe(
				filter(
					event =>
						event.routerEvent instanceof NavigationEnd &&
						EVENT_URL_REGEX.test(event.routerEvent.urlAfterRedirects) && // Only include routing events in the UFA scope of Connect
						this.isUrlWithoutAnchor(event.routerEvent.urlAfterRedirects) // Ensure that no anchor is present in the resolved URL
				)
			)
			.subscribe(scrollEvent => {
				if (scrollEvent?.position) {
					// Only navigation events with a 'popstate' trigger has a non-null position
					this.#viewportScroller.scrollToPosition(scrollEvent.position);
				}
			});
	}
}
