import { Deploy } from 'cordova-plugin-ionic';
import { inject, Injectable } from '@angular/core';
import {
	CheckForUpdateResponse,
	ICurrentConfig,
	ISnapshotInfo,
} from 'cordova-plugin-ionic/dist/IonicCordova';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppflowLiveUpdateAvailableModalComponent } from './update-available-modal/appflow-live-update-available-modal.component';
import { Capacitor } from '@capacitor/core';
import { from, map, mergeMap, of, Subscription, tap, timer } from 'rxjs';
import { ComponentStore } from '@ngrx/component-store';
import { AppflowLiveUpdateNoUpdateAvailableModalComponent } from './no-update-available-modal/appflow-live-update-no-update-available-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Preferences } from '@capacitor/preferences';
import { Router } from '@angular/router';

const FIFTEEN_MINUTES = 15 * 60 * 1000;

const APPFLOW_LIVE_UPDATE_RELOAD_SAVED_ROUTE =
	'appflowLiveupdateReloadSavedRoute' as const;

export type AppFlowLiveUpdateStage =
	| 'notInitiated'
	| 'downloading'
	| 'extracting'
	| 'readyForReload'
	| 'failed';

export interface AppflowLiveUpdateState {
	readonly checkInterval: number;
	readonly lastUpdateCheck: Date | null;
	readonly updateAvailable: boolean;
	readonly availableBuildId: string | null;
	readonly updateStage: AppFlowLiveUpdateStage;
	readonly downloadProgress: number | null;
	readonly extractProgress: number | null;
	readonly error: unknown | null;
	readonly appflowConfig: ICurrentConfig | null;
	readonly curVersionInfo: ISnapshotInfo | null;
}

@Injectable({ providedIn: 'root' })
export class AppflowLiveUpdateService extends ComponentStore<AppflowLiveUpdateState> {
	readonly #snack = inject(MatSnackBar);
	readonly #dialog = inject(MatDialog);
	readonly #router = inject(Router);
	#recurringCheckSub: Subscription | null = null;
	#updateModalRef: MatDialogRef<AppflowLiveUpdateAvailableModalComponent> | null =
		null;
	readonly isNative = Capacitor.isNativePlatform();
	readonly versionInfo$ = this.select(state => ({
		binaryVersionName: state.appflowConfig?.binaryVersionName,
		appflowBuildId: state.curVersionInfo?.buildId,
	}));

	/** Check for available updates for the currently configured app id and channel. */
	async #isUpdateAvailable(): Promise<boolean> {
		const status: CheckForUpdateResponse = await Deploy.checkForUpdate();
		this.patchState({
			updateAvailable: status.available,
			availableBuildId: status.build,
			lastUpdateCheck: new Date(),
		});
		return status.available;
	}

	/** Download the new files from an available update found by the checkForUpdate method and prepare the update. */
	async #downloadUpdate(): Promise<boolean> {
		const downloaded = await Deploy.downloadUpdate(progress => {
			this.patchState({ downloadProgress: progress });
		});
		return downloaded;
	}

	/** Extract a downloaded bundle of updated files. */
	async #extractUpdate(): Promise<boolean> {
		const extracted = await Deploy.extractUpdate(progress => {
			this.patchState({ extractProgress: progress });
		});
		return extracted;
	}

	/** Reload the app if a more recent version of the app is available. */
	async #reloadApp(): Promise<boolean> {
		const reloaded = await Deploy.reloadApp();
		return reloaded;
	}

	/** Setup recurring checks for updates from AppFlow */
	setupRecurringCheck(interval: number = FIFTEEN_MINUTES): void {
		if (!this.isNative) {
			console.warn(
				'Not checking for AppFlow updates as app is in non-Capacitor mode'
			);
			return;
		}
		this.#recurringCheckSub?.unsubscribe();
		/** Check for an update immediately, then every 15 minutes unless otherwise specificed */
		this.#recurringCheckSub = timer(0, interval)
			.pipe(mergeMap(() => from(this.#isUpdateAvailable())))
			.subscribe(isUpdateAvailable => {
				if (isUpdateAvailable) {
					this.#showUpdateAvailableModal();
				}
			});
	}

	#showUpdateAvailableModal() {
		if (this.#updateModalRef) {
			// Don't trigger modal if already shown
			return;
		}
		this.#updateModalRef = this.#dialog.open(
			AppflowLiveUpdateAvailableModalComponent,
			{
				data: { newVersionId: 'test' },
				minWidth: 'min(400px, 90vw)',
				maxWidth: '90vw',
				maxHeight: '90vh',
				disableClose: true,
			}
		);
		this.#updateModalRef
			.afterClosed()
			.subscribe(() => (this.#updateModalRef = null));
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	readonly updateApp = this.effect<void>($ =>
		$.pipe(
			tap(() =>
				this.patchState({
					updateStage: 'downloading',
					error: null,
					downloadProgress: 0,
					extractProgress: 0,
				})
			),
			mergeMap(() => from(this.#downloadUpdate())),
			mergeMap(downloaded => {
				if (!downloaded) {
					this.patchState({
						error: 'Failed to download update',
						updateStage: 'failed',
					});
					return of(false);
				}
				this.patchState({ updateStage: 'extracting' });
				return from(this.#extractUpdate());
			}),
			mergeMap(extracted => {
				if (!extracted) {
					this.patchState({
						error: 'Failed to extract data',
						updateStage: 'failed',
					});
					return of(false);
				}
				this.patchState({ updateStage: 'readyForReload' });
				return from(
					Preferences.set({
						key: APPFLOW_LIVE_UPDATE_RELOAD_SAVED_ROUTE,
						value: this.#router.url,
					})
				).pipe(map(() => true));
			}),
			tap(success => {
				if (success) {
					this.#reloadApp();
				} else {
					this.patchState({
						error: 'Failed to extract data',
						updateStage: 'failed',
					});
				}
			})
		)
	);

	async manualUpdateCheck() {
		if (!this.isNative) {
			return;
		}
		const updateAvailable = await this.#isUpdateAvailable();
		if (updateAvailable) {
			this.#showUpdateAvailableModal();
		} else {
			this.#dialog.open(AppflowLiveUpdateNoUpdateAvailableModalComponent, {
				minWidth: 'min(400px, 90vw)',
				maxWidth: '90vw',
				maxHeight: '90vh',
				disableClose: true,
			});
		}
	}

	constructor() {
		super({
			checkInterval: FIFTEEN_MINUTES,
			lastUpdateCheck: null,
			updateAvailable: false,
			availableBuildId: null,
			updateStage: 'notInitiated',
			downloadProgress: null,
			extractProgress: null,
			error: null,
			appflowConfig: null,
			curVersionInfo: null,
		});

		if (this.isNative) {
			/* Check if we should display 'updated' message after update sucess */
			Preferences.get({ key: APPFLOW_LIVE_UPDATE_RELOAD_SAVED_ROUTE }).then(
				result => {
					const savedRoute = result.value;
					if (savedRoute) {
						this.#router
							.navigateByUrl(savedRoute)
							.then(() => {
								this.#snack.open('Updated app successfully', undefined, {
									duration: 2500,
									panelClass: '!tw-mb-safe',
								});
								Preferences.remove({
									key: APPFLOW_LIVE_UPDATE_RELOAD_SAVED_ROUTE,
								});
								// Check after 1 second if we have succeeded in redirecting to savedRoute, if not, retry.
								setTimeout(() => {
									if (!this.#router.url.includes(savedRoute)) {
										this.#router.navigateByUrl(savedRoute);
									}
								}, 1000);
							})
							.catch(() => {
								// We try again
								this.#router.navigateByUrl(savedRoute);
							});
					}
				}
			);
			/** Get current AppFlow config */
			Deploy.getConfiguration().then(config =>
				this.patchState({ appflowConfig: config })
			);
			/** Get current applied AppFlow update info */
			Deploy.getCurrentVersion().then(curVersion =>
				this.patchState({ curVersionInfo: curVersion ?? null })
			);
		}
	}
}
