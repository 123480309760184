import { FileTypes } from '@consensus/shared/shared/files/domain';

const powerpointMimes = [
	'application/vnd.ms-powerpoint',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

const wordMimes = [
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const excelMimes = [
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const zipMimes = [
	'application/x-rar-compressed',
	'application/zip',
	'application/x-zip-compressed',
	'application/x-7z-compressed',
	'application/gzip',
	'application/x-tar',
];

export function mimeToFileType(mime: string, filename?: string) {
	if (!mime || mime === 'application/octet-stream') {
		if (filename) {
			const fileExt = extractFileExtension(filename);
			if (fileExt === '.riv') {
				return FileTypes.Rive;
			}
			if (fileExt === '.splinecode') {
				return FileTypes.SplineCode;
			}
		}
		return FileTypes.Other;
	}

	if (mime.substring(0, 6) === 'image/') {
		return FileTypes.Image;
	}
	if (mime.substring(0, 6) === 'video/') {
		return FileTypes.Video;
	}
	if (mime.substring(0, 6) === 'audio/') {
		return FileTypes.Audio;
	}

	if (mime === 'application/pdf') {
		return FileTypes.Pdf;
	}

	if (powerpointMimes.includes(mime)) {
		return FileTypes.Powerpoint;
	}

	if (wordMimes.includes(mime)) {
		return FileTypes.Word;
	}

	if (excelMimes.includes(mime)) {
		return FileTypes.Excel;
	}

	if (zipMimes.includes(mime)) {
		return FileTypes.Zip;
	}

	return FileTypes.Other;
}

/**
 * Get a array of strings representing the mime types
 * for a member of the FileTypes enum
 */
export const getMimesForFileType = (fileType: FileTypes): string[] => {
	switch (fileType) {
		case FileTypes.Audio: {
			return ['audio/*'];
		}
		case FileTypes.Excel: {
			return excelMimes;
		}
		case FileTypes.Image: {
			return ['image/*'];
		}
		case FileTypes.Pdf: {
			return ['application/pdf'];
		}
		case FileTypes.Powerpoint: {
			return powerpointMimes;
		}
		case FileTypes.Video: {
			return ['video/*'];
		}
		case FileTypes.Word: {
			return wordMimes;
		}
		case FileTypes.Zip: {
			return zipMimes;
		}
		case FileTypes.Other: {
			return ['*/*'];
		}
		default:
			return [];
	}
};

export function dataUrlPngToFile(dataUrl: string, fileName: string): File {
	const decodedString = atob(dataUrl.replace(/^data:image\/png;base64,/, ''));

	let length = decodedString.length;

	const u8arr = new Uint8Array(length);

	while (length--) {
		u8arr[length] = decodedString.charCodeAt(length);
	}

	return new File([u8arr], fileName, { type: 'image/png' });
}

export function canRenderFile(fileType: FileTypes) {
	return previewTypes.includes(fileType);
}

const previewTypes = [
	FileTypes.Pdf,
	FileTypes.Audio,
	FileTypes.Video,
	FileTypes.Image,
	FileTypes.Word,
	FileTypes.Excel,
	FileTypes.Powerpoint,
];

export interface FileUpload {
	type: FileTypes;
	file: File;
}

const extractFileExtension = (fileName: string | null): string | null => {
	if (!fileName) {
		return null;
	}
	const array = /^.+\.([^.]+)$/.exec(fileName);
	const ext = array === null ? '' : array[1];
	if (!ext) {
		return null;
	}
	let cleanedExt = ext.toLowerCase();
	if (!cleanedExt.startsWith('.')) {
		cleanedExt = '.' + cleanedExt;
	}
	return cleanedExt;
};
