import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import {
	ufaEventUsersActions,
	ufaEventUsersWebsocketActions,
} from './ufa-event-users.actions';
import {
	catchError,
	combineLatest,
	exhaustMap,
	filter,
	map,
	mergeMap,
	of,
	switchMap,
	take,
} from 'rxjs';
import { Action } from '@ngrx/store';
import { ConnectSharedDataAccessWebsocketService } from '@consensus/connect/shared/data-access-websocket';
import { User } from '@shared/models';
import { SessionService } from '@store/scope';
import { UsersClient } from '../users.client';

@Injectable()
export class UfaEventUsersEffects implements OnInitEffects {
	readonly #actions$ = inject(Actions);
	readonly #usersClient = inject(UsersClient);
	readonly #websocketService = inject(ConnectSharedDataAccessWebsocketService);
	readonly #sessionService = inject(SessionService);

	loadEventUsers$ = createEffect(() =>
		this.#actions$.pipe(
			ofType(ufaEventUsersActions.loadEventUsers),
			mergeMap(() =>
				combineLatest([
					this.#sessionService.clientId$,
					this.#sessionService.eventId$,
				])
			),
			filter(([clientId, eventId]) => !!clientId && !!eventId),
			take(1),
			exhaustMap(() =>
				this.#usersClient.loadEventUsers().pipe(
					map(users => ufaEventUsersActions.loadEventUsersSuccess(users)),
					catchError(error =>
						of(ufaEventUsersActions.loadEventUsersFailure(error))
					)
				)
			)
		)
	);

	handleSetUserWebsocketMessage$ = createEffect(() =>
		this.#websocketService.connectionState$
			.pipe(
				filter(x => x === 'Connected'),
				take(1)
			)
			.pipe(
				switchMap(() =>
					this.#websocketService.action<User>('[Users] User: Set')
				),
				map(user => ufaEventUsersWebsocketActions.setEventUser(user))
			)
	);

	ngrxOnInitEffects(): Action {
		return ufaEventUsersActions.loadEventUsers();
	}
}
