/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { NgFor, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UploadProgressService } from '@consensus/shared/shared/files/data-access-files';
import { SharedFileSizePipe } from '@consensus/shared/shared/files/ui';
import { CoTrackByFunction } from '@consensus/co/util-control-flow';

@Component({
	standalone: true,
	selector: 'co-upload-progress',
	imports: [NgIf, NgFor, MatProgressBarModule, SharedFileSizePipe],
	templateUrl: './upload-progress.component.html',
	styleUrls: ['./upload-progress.component.scss'],
})
export class UploadProgressComponent {
	files = inject(UploadProgressService).files;

	readonly trackByIdentity: CoTrackByFunction<unknown> = (
		_index: number,
		value: unknown
	) => value;
}
