import {
	AbstractControl,
	AbstractControlOptions,
	AsyncValidatorFn,
	UntypedFormArray,
	ValidatorFn,
} from '@angular/forms';
import { cloneAbstractControl } from './utils';
import { FormControls, FormTemplate } from './form-types';
import { FormLayer } from './form-layer';
import { FormNode } from './form-node';
import { MoveModel } from '@shared/models';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
	BehaviorSubject,
	Observable,
	asyncScheduler,
	throttleTime,
} from 'rxjs';

export class FormList<TGroup = any> extends UntypedFormArray {
	template: ListTemplate<TGroup>;
	controlList: ListControls<TGroup>;
	inputTemplate: FormTemplate<TGroup>;
	startLength: number;

	modified = false;

	value: TGroup[];
	protected _value$: BehaviorSubject<TGroup[]>;
	value$: Observable<TGroup[]>;
	throttledValue$: Observable<TGroup[]>;

	constructor(
		inputTemplate: FormTemplate<TGroup>,
		startLength = 0,
		validatorOrOpts:
			| ValidatorFn
			| ValidatorFn[]
			| AbstractControlOptions = null,
		asyncValidator: AsyncValidatorFn | AsyncValidatorFn[] = null
	) {
		super([], validatorOrOpts, asyncValidator);
		this.inputTemplate = inputTemplate;
		this.startLength = startLength;

		this.template = (
			inputTemplate instanceof FormNode
				? inputTemplate
				: inputTemplate instanceof FormList
				? inputTemplate
				: inputTemplate instanceof Object
				? new FormLayer(inputTemplate)
				: null
		) as ListTemplate<TGroup>;

		this.setSize(startLength);
		this.modified = false;

		this._value$ = new BehaviorSubject<TGroup[]>(this.value);
		this.valueChanges.subscribe(this._value$);

		this.value$ = this._value$.asObservable();
		this.throttledValue$ = this._value$.pipe(
			throttleTime(500, asyncScheduler, { trailing: true })
		);
	}

	clear() {
		super.clear();
		this.controlList = [] as ListControls<TGroup>;
		this.modified = true;
	}

	patchValue(
		value: TGroup[],
		options?: { onlySelf?: boolean; emitEvent?: boolean }
	) {
		super.patchValue(value, options);
		this.controlList = this.getControls();
		this.modified = true;
	}

	setValue(
		value: TGroup[],
		options?: { onlySelf?: boolean; emitEvent?: boolean }
	) {
		super.setValue(value, options);
		this.controlList = this.getControls();
		this.modified = true;
	}

	reset(
		value?: TGroup[],
		options?: { onlySelf?: boolean; emitEvent?: boolean }
	) {
		super.reset(value, options);
		this.controlList = this.getControls();
		this.modified = false;
	}

	setSize(length: number) {
		this.clear();
		for (let i = 0; i < length; i++) {
			super.push(cloneAbstractControl(this.template));
		}
		this.controlList = this.getControls();
	}

	addElement(value?: Partial<TGroup>): ListTemplate<TGroup> {
		const control = cloneAbstractControl(this.template);
		if (value) {
			control.patchValue(value as any);
		}
		super.push(control);
		this.controlList = this.getControls();
		this.modified = true;
		return control;
	}

	setElement(filter: (x: TGroup) => boolean, value: Partial<TGroup>) {
		const index = this.controls.findIndex(x => filter(x.value));

		if (index > -1) {
			const control = this.controls[index];
			control.patchValue(value);
			return control;
		}

		return this.addElement(value);
	}

	updateElement(filter: (x: TGroup) => boolean, value: Partial<TGroup>) {
		const control = this.controls.find(x => filter(x.value));
		if (!control) {
			return null;
		}
		control.patchValue(value);
		return control;
	}

	toggleElement(filter: (x: TGroup) => boolean, value: Partial<TGroup>) {
		const removed = this.removeElement(filter);
		if (!removed) {
			return this.addElement(value);
		}
		return null;
	}

	removeElement(filter: (x: TGroup) => boolean) {
		const index = this.controls.findIndex(x => filter(x.value));
		if (index < 0) {
			return false;
		}

		this.removeAt(index);
		return true;
	}

	removeAt(index: number) {
		super.removeAt(index);
		this.controlList = this.getControls();
		this.modified = true;
	}

	moveSortableElement(data: MoveModel) {
		const oldIndex = this.controls.findIndex(x => x['id'] === data.id);
		this.moveElement(oldIndex, data.sortingKey);
	}

	moveCdkElement(data: CdkDragDrop<any>) {
		this.moveElement(data.previousIndex, data.currentIndex);
	}

	moveElement(oldIndex: number, newIndex: number) {
		const control = this.controls[oldIndex];
		this.removeAt(oldIndex);
		this.insert(newIndex, control);
		this.modified = true;
		this.controlList = this.getControls();
	}

	removeControl(control: ListTemplate<TGroup>) {
		const index = this.controls.findIndex(x => x === control);
		if (index < 0) {
			return;
		}

		this.removeAt(index);
	}

	/**
	 * Deprecated
	 * @param control
	 */
	push(_control: AbstractControl) {
		console.error('Push is deprecated. Please use addElement');
	}

	getValue(): TGroup[] {
		const values = [];

		this.controls.forEach(x => {
			if (x instanceof FormLayer) {
				return values.push(x.getValue());
			}
			if (x instanceof FormNode) {
				return values.push(x.getValue());
			}
			if (x instanceof FormList) {
				return values.push(x.getValue());
			}
		});

		return values as TGroup[];
	}

	protected getControls(): ListControls<TGroup> {
		const controls = [];

		this.controls.forEach(x => {
			if (x instanceof FormLayer) {
				return controls.push(x.controlMap);
			}
			if (x instanceof FormNode) {
				return controls.push(x);
			}
			if (x instanceof FormList) {
				return controls.push(x);
			}
		});

		return controls as ListControls<TGroup>;
	}

	novofy(isSema: boolean) {
		this.controls.forEach(x => {
			if (x instanceof FormLayer) {
				x.novofy(isSema);
			}
			if (x instanceof FormNode) {
				x.novofy(isSema);
			}
			if (x instanceof FormList) {
				x.novofy(isSema);
			}
		});
	}
}

type ListTemplate<T> = T extends (infer A)[]
	? FormList<A>
	: T extends object
	? FormLayer<T>
	: FormNode<T>;

type ListControls<T> = T extends (infer A)[]
	? FormList<A>[]
	: T extends object
	? ({ [K in keyof T]?: FormControls<T[K]> } & { this: FormLayer<T> })[]
	: FormNode<T>[];
