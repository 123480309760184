import { animate, style, transition, trigger } from '@angular/animations';
import { CUBIC_IN_OUT_EASING } from './constants';

export const fadeAnimation = trigger('fade', [
	transition(':enter', [
		style({ opacity: 0 }),
		animate(`200ms ${CUBIC_IN_OUT_EASING}`, style({ opacity: '*' })),
	]),
	transition(':leave', [
		style({ opacity: '*' }),
		animate(`200ms ${CUBIC_IN_OUT_EASING}`, style({ opacity: 0 })),
	]),
]);
