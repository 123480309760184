<div class="background" (click)="close.emit()"></div>

<div
	class="overlay-content simple-scroll-bar"
	[style.maxWidth]="maxWidth ? maxWidth + 'px' : undefined"
	[class.scrollable]="withScroll"
>
	<ng-content />
</div>

<i class="fas fa-times close" *ngIf="closeButton" (click)="close.emit()"></i>
