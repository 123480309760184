import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	BehaviorSubject,
	combineLatest,
	distinctUntilChanged,
	map,
} from 'rxjs';
import {
	getLivestreams,
	Livestream,
} from '@consensus/connect/ufa/livestream/data-access';
import { sortDateAsc } from '@lib/helpers';
import { SessionService } from '@store/scope';
import { LocationService } from '@consensus/shared/shared/navigation/data-access-navigation';
import Timer = NodeJS.Timer;
import {
	LivestreamClient,
	LivestreamLogEntryType,
} from '@consensus/connect/cms/livestream/data-access';
import { LivestreamPlayer } from './livestream-player';
import { LivestreamPlayerInstance } from './livestream-player-instance';

@Injectable({ providedIn: 'root' })
export class LivestreamService {
	readonly #locationService = inject(LocationService);
	readonly #sessionService = inject(SessionService);
	readonly #client = inject(LivestreamClient);
	readonly #store = inject(Store);
	#livestreams$ = new BehaviorSubject<Livestream[]>([]);

	get livestreams() {
		return this.#livestreams$.value;
	}

	#livestreamId$ = new BehaviorSubject<string>(null);

	set livestreamId(id: string) {
		this.#livestreamId$.next(id);
	}

	get livestreamId() {
		return this.#livestreamId$.value;
	}

	#livestream$ = new BehaviorSubject<Livestream>(null);

	get livestream$() {
		return this.#livestream$.asObservable();
	}

	get livestream() {
		return this.#livestream$.value;
	}

	#instanceName$ = new BehaviorSubject<string>(null);

	#livestreamPlayer = new LivestreamPlayer();

	#instanceStack: LivestreamPlayerInstance[] = [];

	countdownTimer: Timer;
	countdownText: string;
	countdownMax: number;
	countdownPercentage: number;
	countdownSeconds: number;

	get countingDown() {
		return this.countdownText != null;
	}

	#getCountdownMax(seconds: number) {
		switch (true) {
			case seconds <= 60:
				return 60;
			case seconds <= 60 * 10:
				return 60 * 10;
			case seconds <= 60 * 30:
				return 60 * 30;
			case seconds <= 60 * 60:
				return 60 * 60;
			default:
				return (Math.floor(seconds / 3600) + 1) * 3600;
		}
	}

	constructor() {
		this.#store.select(getLivestreams.selector).subscribe(this.#livestreams$);

		const livestream$ = combineLatest([
			this.#livestreams$,
			this.#livestreamId$,
		]).pipe(
			map(([livestreams, id]) => {
				let livestream = livestreams.find(x => x.id == id);
				if (livestream) {
					return livestream;
				}

				const nonOptional = livestreams.filter(x => !x.optional);
				if (nonOptional.length > 0) {
					livestream = [...nonOptional].sort(
						sortDateAsc(x => x.activatedAt)
					)[0];
				}

				if (livestream) {
					this.livestreamId = livestream.id;
				}

				return livestream;
			})
		);

		const distinctLivestream$ = livestream$.pipe(
			distinctUntilChanged((x, y) => x?.id === y?.id)
		);

		livestream$.subscribe(this.#livestream$);
		livestream$.subscribe(l => this.#setupCountdown(l));
		distinctLivestream$.subscribe(l => this.handleForceFullscreen(l));

		combineLatest([distinctLivestream$, this.#instanceName$]).subscribe(
			([livestream, instanceName]) => {
				/* eslint-disable no-restricted-syntax */
				// no-restricted-syntax: Allow console.debug usage
				if (!instanceName) {
					console.debug('No Livestream Player Active');
					return;
				}

				if (!livestream) {
					console.debug('No Livestream Active');
					return;
				}

				console.debug(
					`Watching livestream '${livestream.id}' in the '${instanceName}' player`
				);
				this.#client
					.createLivestreamLogEntry({
						id: livestream.id,
						livestreamPlayerInstanceName: instanceName,
						muted: this.#livestreamPlayer.isMuted,
						livestreamLogEntryType: LivestreamLogEntryType.Open,
					})
					.toPromise();
				/* eslint-enable no-restricted-syntax */
			}
		);
	}

	getPlayer(instanceName: string) {
		if (this.#instanceStack.length > 0) {
			this.#instanceStack.forEach(i => (i.active = false));
		}

		const instance = new LivestreamPlayerInstance(
			this.#livestreamPlayer,
			i => this.#popPlayer(i),
			instanceName
		);
		this.#instanceStack.push(instance);
		this.#instanceName$.next(instanceName);
		return instance;
	}

	sync(livestreamId: string) {
		if (this.#livestreamPlayer.livestream.id == livestreamId) {
			this.#livestreamPlayer.refresh();
		}
	}

	#popPlayer(instance: LivestreamPlayerInstance) {
		const index = this.#instanceStack.indexOf(instance);
		if (index < 0) {
			return;
		}
		this.#instanceStack.splice(index, 1);

		const instanceName =
			this.#instanceStack.length > 0
				? this.#instanceStack[this.#instanceStack.length - 1].name
				: null;
		if (this.#instanceName$.value !== instanceName) {
			this.#instanceName$.next(instanceName);
		}

		if (this.#instanceStack.length < 1) {
			return;
		}
		this.#instanceStack[this.#instanceStack.length - 1].active = true;
	}

	handleForceFullscreen(l: Livestream) {
		if (!l || l.optional || !l.activatedAt) {
			return;
		}
		if (!this.#sessionService.event?.forceLivestreamFullscreen) {
			return;
		}

		this.#locationService.navigateEvent(['livestream', l.id]);
	}

	#setupCountdown(livestream: Livestream) {
		const parsedStartTime = this.#sessionService.parseEventDate(
			livestream?.startTime
		);

		clearInterval(this.countdownTimer);
		const now = new Date();

		if (!parsedStartTime || parsedStartTime < now) {
			this.countdownText = null;
			this.#livestreamPlayer.setup(this.livestream);
		} else {
			const seconds = Math.floor(
				(parsedStartTime.getTime() - now.getTime()) / 1000
			);
			this.countdownMax = this.#getCountdownMax(seconds);
			this.countdownTimer = setInterval(
				() => this.#updateCountdown(livestream?.startTime),
				1000
			);
		}
	}

	#updateCountdown(livestreamStart: Date) {
		const now = new Date();
		const parsedStartTime =
			this.#sessionService.parseEventDate(livestreamStart);

		if (!parsedStartTime || parsedStartTime < now) {
			clearInterval(this.countdownTimer);
			this.countdownText = null;
			this.#livestreamPlayer.setup(this.livestream);
		} else {
			this.#setCountdown(now, parsedStartTime);
		}
	}

	#setCountdown(now: Date, livestreamStart: Date) {
		let seconds = Math.floor(
			(livestreamStart.getTime() - now.getTime()) / 1000
		);
		this.countdownSeconds = seconds;
		this.countdownPercentage = (seconds * 100) / this.countdownMax;
		const hours = Math.floor(seconds / (60 * 60));
		seconds -= hours * 60 * 60;
		const minutes = Math.floor(seconds / 60);
		seconds -= minutes * 60;
		this.countdownText =
			(hours > 0 ? `${hours}:` : '') +
			`${minutes.toString().padStart(2, '0')}:${seconds
				.toString()
				.padStart(2, '0')}`;
	}
}
