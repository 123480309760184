export function sortBySortingKeyAsc<T extends { sortingKey: number }>(
	a: T,
	b: T
) {
	return a.sortingKey - b.sortingKey;
}

export function sortBySortingKeyDesc<T extends { sortingKey: number }>(
	a: T,
	b: T
) {
	return b.sortingKey - a.sortingKey;
}

export function sortAlphAsc<T>(
	getString: (x: T) => string
): (a: T, b: T) => number {
	return (a: T, b: T) => {
		const stringA = getString(a);
		const stringB = getString(b);
		return stringA.localeCompare(stringB);
	};
}

export function sortAlphDesc<T>(
	getString: (x: T) => string
): (a: T, b: T) => number {
	return (a: T, b: T) => {
		const stringA = getString(a);
		const stringB = getString(b);
		return stringB.localeCompare(stringA);
	};
}

export function sortNumAsc<T>(
	getNum: (x: T) => number
): (a: T, b: T) => number {
	return (a: T, b: T) => {
		const numA = getNum(a);
		const numB = getNum(b);
		return numA - numB;
	};
}

export function sortNumDesc<T>(
	getNum: (x: T) => number
): (a: T, b: T) => number {
	return (a: T, b: T) => {
		const numA = getNum(a);
		const numB = getNum(b);
		return numB - numA;
	};
}

export function sortDateAsc<T>(
	getDate: (x: T) => Date | null | undefined
): (a: T, b: T) => number {
	return (a: T, b: T) => {
		const dateA = getDate(a);
		const dateB = getDate(b);
		return (dateA?.getTime() ?? 0) - (dateB?.getTime() ?? 0);
	};
}

export function sortDateDesc<T>(
	getDate: (x: T) => Date | null | undefined
): (a: T, b: T) => number {
	return (a: T, b: T) => {
		const dateA = getDate(a);
		const dateB = getDate(b);
		return (dateB?.getTime() ?? 0) - (dateA?.getTime() ?? 0);
	};
}

export function sortByDateThenSortingKeyAsc<T extends { sortingKey: number }>(
	getDate: (x: T) => Date | null | undefined
): (a: T, b: T) => number {
	return (a: T, b: T) => {
		const dateA = getDate(a);
		const dateB = getDate(b);
		if (dateA) {
			if (!dateB) {
				return -1;
			}
			return dateA.getTime() - dateB.getTime();
		}
		if (dateB) {
			return 1;
		}
		return a.sortingKey - b.sortingKey;
	};
}

export function sortDateTimeAsc<T>(
	getDate: (x: T) => Date | null | undefined
): (a: T, b: T) => number {
	return (a: T, b: T) => {
		const dateA = getDate(a);
		const dateB = getDate(b);
		const timeA = dateA ? dateA.getHours() * 60 + dateA.getMinutes() : 0;
		const timeB = dateB ? dateB.getHours() * 60 + dateB.getMinutes() : 0;
		return timeA - timeB;
	};
}

export function sortDateTimeDesc<T>(
	getDate: (x: T) => Date | null | undefined
): (a: T, b: T) => number {
	return (a: T, b: T) => {
		const dateA = getDate(a);
		const dateB = getDate(b);
		const timeA = dateA ? dateA.getHours() * 60 + dateA.getMinutes() : 0;
		const timeB = dateB ? dateB.getHours() * 60 + dateB.getMinutes() : 0;
		return timeB - timeA;
	};
}

export function sortByDateTimeThenSortingKeyAsc<
	T extends { sortingKey: number }
>(getDate: (x: T) => Date | null | undefined): (a: T, b: T) => number {
	return (a: T, b: T) => {
		const dateA = getDate(a);
		const dateB = getDate(b);
		if (dateA) {
			if (!dateB) {
				return -1;
			}
			const timeA = dateA.getHours() * 60 + dateA.getMinutes();
			const timeB = dateB.getHours() * 60 + dateB.getMinutes();
			return timeA - timeB;
		}
		if (dateB) {
			return 1;
		}
		return a.sortingKey - b.sortingKey;
	};
}

export function sortBoolAsc<T>(
	getBool: (x: T) => boolean
): (a: T, b: T) => number {
	return (a: T, b: T) => (getBool(a) === getBool(b) ? 0 : getBool(a) ? 1 : -1);
}

export function sortBoolDesc<T>(
	getBool: (x: T) => boolean
): (a: T, b: T) => number {
	return (a: T, b: T) => (getBool(a) === getBool(b) ? 0 : getBool(a) ? -1 : 1);
}
