/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { Component, ElementRef, inject, Input } from '@angular/core';
import { LivestreamPlayer } from '@consensus/connect/shared/livestream/data-access';
import { MatRippleModule } from '@angular/material/core';
import { NoClickBubbleDirective } from '@shared/directives';
import { NgIf } from '@angular/common';
import { CoSpinnerComponent } from '@consensus/co/ui-progress';

@Component({
	selector: 'co-livestream-video',
	templateUrl: './livestream-video.component.html',
	styleUrls: ['./livestream-video.component.scss'],
	standalone: true,
	imports: [NgIf, CoSpinnerComponent, NoClickBubbleDirective, MatRippleModule],
})
export class LivestreamVideoComponent {
	readonly #element: ElementRef<HTMLElement> = inject(ElementRef);
	_player: LivestreamPlayer;

	@Input() set player(player: LivestreamPlayer) {
		this._player = player;
		this.#element.nativeElement.querySelector('video')?.remove();
		this.#element.nativeElement.append(player.videoElement);

		player.videoElement.play().catch(() => {
			player.toggleMute(true);
			player.videoElement.play();
		});
	}

	get player() {
		return this._player;
	}
}
