<ng-container *ngIf="mediaUrl && !loading" [ngSwitch]="fileType">
	<img
		[src]="mediaUrl | coBypassSecurityTrustResourceUrl"
		(load)="imageLoad($event)"
		*ngSwitchCase="fileTypes.Image"
		draggable="false"
	/>
	<video
		[muted]="muted"
		[autoplay]="autoplay"
		[loop]="loop"
		[src]="mediaUrl | coBypassSecurityTrustResourceUrl"
		preload="auto"
		playsinline
		*ngSwitchCase="fileTypes.Video"
		(loadedmetadata)="videoLoad($event)"
		(ended)="videoEnded.emit()"
		#videoElement
	></video>
	<audio
		preload
		[autoplay]="autoplay"
		[src]="mediaUrl | coBypassSecurityTrustResourceUrl"
		[controls]="!loop"
		[muted]="muted"
		[loop]="loop"
		*ngSwitchCase="fileTypes.Audio"
	></audio>
	<co-drive-rive-display
		*ngSwitchCase="fileTypes.Rive"
		[fileUrl]="mediaUrl"
	/>
	<co-spline-player
		*ngSwitchCase="fileTypes.SplineCode"
		[sceneUrl]="mediaUrl"
		[hideLoadingSpinner]="true"
		class="tw-flex tw-justify-center"
	/>
</ng-container>
