<div
	class="modal is-active"
	[class.simple]="simple"
	[class.scrollable]="enableScroll"
>
	<div
		class="modal-background"
		(click)="!noClose && close.emit()"
		@background
	></div>
	<div
		[class.modal-card]="!simple"
		[class.narrow]="narrow"
		[class.vertical-center]="verticalCenter"
		class="modal-content"
		@content
		(@content.done)="animatingChange.emit(false)"
		(@content.start)="animatingChange.emit(true)"
	>
		<header class="modal-card-head" *ngIf="title && !simple">
			<p class="modal-card-title">{{ title }}</p>
		</header>
		<div class="modal-body" [class.modal-card-body]="!simple">
			<ng-content />
		</div>
		<footer
			class="modal-card-foot"
			*ngIf="!simple"
			[class.center-content]="centerFooterContent"
		>
			<ng-content select="footer" />
		</footer>

		<button
			*ngIf="!noClose && internalClose && !narrow"
			class="modal-close internal"
			aria-label="close"
			(click)="close.emit()"
		></button>
	</div>
	<button
		@background
		*ngIf="!noClose && !internalClose && !narrow"
		class="modal-close is-large"
		aria-label="close"
		(click)="close.emit()"
	></button>
</div>
