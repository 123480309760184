import { SupportEffects } from '@store/support';
import { ToastEffects } from '@store/toast';
import { Injectable, Type } from '@angular/core';
import { ConnectSupportState } from '@consensus/connect/shared/support/domain';
import { GlobalEffects } from '@store/global';
import { supportKey, supportReducer } from '@store/support';
import { ActionReducerMap } from '@ngrx/store';
import { GlobalUserState, globalUserKey, userStore } from '@store/user';
import { GroupsState } from '@store/groups';
import {
	ThemingEffects,
	themingKey,
	themingReducer,
	ThemingState,
} from '@store/theming';
import { GlobalScopeState, globalScopeKey, scopeStore } from '@store/scope';
import { CustomEffects, FeatureStore } from '@lib/redux';
import { groupsStore } from '@store/groups';
import { ScopeEffects } from '@store/scope';

export const globalFeatureStore = new FeatureStore('global', {
	[globalScopeKey]: scopeStore,
	groups: groupsStore,
	[globalUserKey]: userStore,
});

@Injectable()
export class GlobalFeatureEffects extends CustomEffects {
	constructor() {
		super(globalFeatureStore);
	}
}

export interface GlobalState extends GlobalScopeState, GlobalUserState {
	[supportKey]: ConnectSupportState;
	groups: GroupsState;
	[themingKey]: ThemingState;
}

export const globalReducers: ActionReducerMap<GlobalState> = {
	[supportKey]: supportReducer,
	[globalUserKey]: globalFeatureStore.reducers.user,
	groups: globalFeatureStore.reducers.groups,
	[themingKey]: themingReducer,
	[globalScopeKey]: globalFeatureStore.reducers.scope,
};

export const rootEffects: Type<unknown>[] = [
	SupportEffects,
	ToastEffects,
	GlobalEffects,
	ThemingEffects,
	GlobalFeatureEffects,
	ScopeEffects,
];
