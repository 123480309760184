import {
	EventEmitter,
	Directive,
	NgZone,
	OnDestroy,
	Output,
	Input,
	ContentChildren,
	QueryList,
	inject,
} from '@angular/core';
import {
	Artboard,
	SMIInput,
	StateMachine,
	StateMachineInstance,
} from '@rive-app/canvas-advanced';
import {
	BehaviorSubject,
	of,
	Subscription,
	filter,
	map,
	switchMap,
} from 'rxjs';
import { CoRiveCanvasDirective } from './canvas.directive';
import { CoRiveService } from './rive.service';
import { isNonNullish } from '@consensus/co/util-types';
import { CoRiveSMInputDirective } from './state-machine-input.directive';
import { CoReportedRiveEvent } from '@consensus/co/domain-rive';

function assertStateMachine(
	animation: StateMachine,
	artboard: Artboard,
	name: string | number
) {
	if (animation) {
		return;
	}
	const artboardName = artboard.name ?? 'Default';
	const count = artboard.stateMachineCount();
	if (typeof name === 'number') {
		throw new Error(
			`Provided index "${name}" for the animation of artboard "${artboardName}" is not available. Animation count is: ${count}`
		);
	} else {
		const names: string[] = [];
		for (let i = 0; i < count; i++) {
			names.push(artboard.stateMachineByIndex(i).name);
		}
		throw new Error(
			`Provided name "${name}" for the animation of artboard "${artboardName}" is not available. Availables names are: ${JSON.stringify(
				names
			)}`
		);
	}
}

///////////////////
// STATE MACHINE //
///////////////////

interface StateMachineState {
	speed: number;
	playing: boolean;
}

/**
 * Rive directive for State Machines, which is a way to combine animations
 * and manage the transition between them, through Inputs
 * ({@link CoRiveSMInputDirective}) which it is possible to control
 * programmatically.
 */
@Directive({
	selector: 'co-rive-state-machine, [coRiveStateMachine]',
	standalone: true,
})
export class CoRiveStateMachineDirective implements OnDestroy {
	readonly #zone = inject(NgZone);
	readonly #canvas = inject(CoRiveCanvasDirective);
	readonly #service = inject(CoRiveService);
	#sub?: Subscription;
	/** @internal: public only for RiveInput */
	instance?: StateMachineInstance;
	readonly state = new BehaviorSubject<StateMachineState>({
		speed: 1,
		playing: false,
	});

	inputs: Record<string, SMIInput> = {};
	@ContentChildren(CoRiveSMInputDirective)
	private riveInputs?: QueryList<CoRiveSMInputDirective>;

	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() load = new EventEmitter<StateMachineInstance>();
	@Output() stateChange = new EventEmitter<string[]>();
	@Output() reportedEventsChange = new EventEmitter<CoReportedRiveEvent[]>();

	@Input()
	set name(name: string | undefined | null) {
		if (typeof name !== 'string') {
			return;
		}
		this.#zone.runOutsideAngular(() => {
			this.#register(name);
		});
	}

	@Input()
	set index(value: number | string | undefined | null) {
		const index = typeof value === 'string' ? parseInt(value) : value;
		if (typeof index !== 'number') {
			return;
		}
		this.#zone.runOutsideAngular(() => {
			this.#register(index);
		});
	}

	@Input()
	set speed(value: number | string | undefined | null) {
		const speed = typeof value === 'string' ? parseFloat(value) : value;
		if (typeof speed === 'number') {
			this.#update({ speed });
		}
	}
	get speed() {
		return this.state.getValue().speed;
	}

	@Input() set play(playing: boolean | '' | undefined | null) {
		if (playing === true || playing === '') {
			this.#update({ playing: true });
		} else if (playing === false) {
			this.#update({ playing: false });
		}
	}
	get play() {
		return this.state.getValue().playing;
	}

	ngOnDestroy() {
		const name = this.instance?.name;
		if (name) {
			delete this.#canvas.stateMachines[name];
		}
		this.#sub?.unsubscribe();
		setTimeout(() => this.instance?.delete(), 100);
	}

	#update(state: Partial<StateMachineState>) {
		this.state.next({ ...this.state.getValue(), ...state });
	}

	#setInput(input: SMIInput) {
		this.inputs[input.name] = input;
		const riveInput = this.riveInputs?.find(item => item.name === input.name);
		if (riveInput) {
			riveInput.init(input);
		}
	}

	#getFrame(state: StateMachineState) {
		if (state.playing && this.#service.frame) {
			return this.#service.frame.pipe(map(time => [state, time] as const));
		} else {
			return of(null);
		}
	}

	#initStateMachine(name: string | number) {
		if (!this.#canvas.rive) {
			throw new Error('Could not load state machine instance before rive');
		}
		if (!this.#canvas.artboard) {
			throw new Error('Could not load state machine instance before artboard');
		}
		const ref =
			typeof name === 'string'
				? this.#canvas.artboard.stateMachineByName(name)
				: this.#canvas.artboard.stateMachineByIndex(name);

		assertStateMachine(ref, this.#canvas.artboard, name);

		// Fetch the inputs from the runtime if we don't have them
		this.instance = new this.#canvas.rive.StateMachineInstance(
			ref,
			this.#canvas.artboard
		);
		this.#canvas.stateMachines[this.instance.name] = this.instance;
		for (let i = 0; i < this.instance.inputCount(); i++) {
			this.#setInput(this.instance.input(i));
		}
		this.load.emit(this.instance);
	}

	#register(name: string | number) {
		// Stop subscribing to previous animation if any
		this.#sub?.unsubscribe();

		// Update on frame change if playing
		const onFrameChange = this.state.pipe(
			switchMap(state => this.#getFrame(state)),
			filter(isNonNullish),
			map(([state, time]) => (time / 1000) * state.speed)
		);

		// Wait for canvas & animation to be loaded
		this.#sub = this.#canvas
			.onReady()
			.pipe(
				map(() => this.#initStateMachine(name)),
				switchMap(() => onFrameChange)
			)
			.subscribe(delta => this.#applyChange(delta));
	}

	#applyChange(delta: number) {
		if (!this.instance) {
			throw new Error(
				'Could not load state machine instance before running it'
			);
		}
		this.#canvas.draw(this.instance, delta);
		// Check for any state machines that had a state change
		const changeCount = this.instance.stateChangedCount();
		if (changeCount) {
			const states = [];
			for (let i = 0; i < changeCount; i++) {
				states.push(this.instance.stateChangedNameByIndex(i));
			}
			this.stateChange.emit(states);
		}
	}
}
