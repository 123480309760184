import { ChangeDetectionStrategy } from '@angular/core';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'app-update-required',
	imports: [MatButtonModule],
	templateUrl: './update-required.component.html',
	styleUrls: ['./update-required.component.scss'],
})
export class UpdateRequiredComponent implements AfterViewInit {
	@ViewChild('button', { static: true }) button: ElementRef;
	refreshing = false;

	ngAfterViewInit(): void {
		this.button.nativeElement?.focus();
	}

	async refresh() {
		this.refreshing = true;
		const regs = await navigator.serviceWorker.getRegistrations();
		const cacheKeys = await caches.keys();
		cacheKeys.forEach(key => {
			caches.delete(key);
		});
		regs.forEach(registration => {
			registration.unregister();
		});
		window.location.reload();
	}

	buttonBlurred() {
		this.button.nativeElement?.focus();
	}
}
