import { BaseState } from '@shared/models';
import * as v from 'valibot';

export interface ClientState extends BaseState, ClientLoadModel {}

export interface ClientLoadModel {
	readonly clients: readonly Client[];
	readonly events: readonly ClientEvent[];
}

export const samlLoginProviderResponseSchema = v.pipe(
	v.object(
		{
			idpId: v.string(),
			idpInitiatedUrl: v.nullable(v.string()),
			loginButtonBackgroundColor: v.nullable(v.string()),
			loginButtonTextColor: v.nullable(v.string()),
			loginButtonText: v.nullable(v.string()),
			loginButtonHidden: v.boolean(),
			eventIds: v.pipe(
				v.array(v.string(), 'SamlLoginProvider.eventIds'),
				v.readonly()
			),
			sortingKey: v.pipe(v.number(), v.integer()),
		},
		'SamlLoginProvider'
	),
	v.readonly()
);
export interface SamlLoginProvider {
	readonly idpId: string;
	readonly idpInitiatedUrl: string | null;
	readonly loginButtonBackgroundColor: string | null;
	readonly loginButtonTextColor: string | null;
	readonly loginButtonText: string | null;
	readonly loginButtonHidden: boolean;
	readonly eventIds: readonly string[];
	readonly sortingKey: number;
}

export const clientViewModelResponseSchema = v.pipe(
	v.object(
		{
			id: v.string(),
			name: v.string(),
			slug: v.string(),
			vanityUrl: v.nullable(v.string()),
			themeId: v.nullable(v.string()),
			supportId: v.nullable(v.string()),
			showForgotPassword: v.boolean(),
			forgotPasswordText: v.nullable(v.string()),
			showLoginSupport: v.boolean(),
			oauthSingleSignOnEnabled: v.nullable(v.boolean()),
			portalEventId: v.nullable(v.string()),
			portalEventSlug: v.nullable(v.string()),
			portalButtonText: v.nullable(v.string()),
			samlProviders: v.pipe(
				v.array(
					samlLoginProviderResponseSchema,
					'ClientViewModel.samlProviders'
				),
				v.readonly()
			),
			samlEnabled: v.boolean(),
			pinLogin: v.boolean(),
		},
		'ClientViewModel'
	),
	v.readonly()
);
/**
 * @remarks Named `ClientViewModel` in Client API.
 */
export interface Client {
	readonly id: string;
	readonly name: string;
	readonly slug: string;
	readonly vanityUrl: string | null;
	readonly themeId: string | null;
	readonly supportId: string | null;
	readonly showForgotPassword: boolean;
	readonly forgotPasswordText: string | null;
	readonly showLoginSupport: boolean;
	readonly oauthSingleSignOnEnabled: boolean | null;
	readonly portalEventId: string | null;
	readonly portalEventSlug: string | null;
	readonly portalButtonText: string | null;
	readonly pinLogin: boolean;
	readonly samlEnabled: boolean;
	readonly samlProviders: readonly SamlLoginProvider[];
}

/**
 * @remarks Named `ClientEventViewModel` in Client API.
 */
export interface ClientEvent {
	readonly id: string;
	readonly name: string;
	readonly slug: string;
	readonly clientId: string;
	readonly deletedAt: Date | null;
}

// action models
