import { SubmitWordCloudDto } from '@consensus/connect/ufa/word-cloud/data-access';
import { WordCloudSubmission } from '@consensus/connect/ufa/word-cloud/domain';
import { EventSessionItem } from '@consensus/connect/ufa/agenda/domain';
import { createAction } from '@ngrx/store';
import { ApiAction } from '@lib/redux';
import { SessionItemState } from './session-item.reducer';

export const loadSessionItems = new ApiAction<
	SessionItemState,
	void,
	EventSessionItem[]
>('Session Items', 'Load', 'Session Items', {
	showErrors: true,
});

export const completeSurvey = createAction(
	'[Surveys] Complete Survey',
	(payload: string) => ({ payload })
);

export const addSessionItem = createAction(
	'[Session Item socket] Add Session Item',
	(payload: EventSessionItem) => ({ payload })
);
export const removeSessionItem = createAction(
	'[Session Item socket] Remove Session Item',
	(payload: string) => ({ payload })
);

export const loadWordCloudUserSubmissions = new ApiAction<
	SessionItemState,
	string,
	WordCloudSubmission[]
>('WordCloudSubmissions', 'Load', 'Session Items', {
	showErrors: true,
	errorMessage: 'Sorry, we could not fetch previous WordCloud Submissions',
});

export const submitWordCloudWord = new ApiAction<
	SessionItemState,
	SubmitWordCloudDto,
	WordCloudSubmission
>('WordCloudSubmission', 'Submit', 'Session Items', {
	showErrors: true,
});
