import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	standalone: true,
	name: 'daysLeft',
})
export class DaysLeftPipe implements PipeTransform {
	transform(value: Date, target: Date): number {
		if (!value) {
			return -1;
		}
		return Math.floor(
			(target.getTime() - value.getTime()) / 1000 / 60 / 60 / 24
		);
	}
}
