import { Injectable } from '@angular/core';
import { ThemeColor } from '@lib/helpers';
import { ThemeStyles } from '@store/theming';

@Injectable({ providedIn: 'root' })
export class ThemeService {
	primaryColor: ThemeColor;
	secondaryColor: ThemeColor;

	updateTheme(styles: ThemeStyles) {
		this.primaryColor = new ThemeColor(styles.primary, styles.primaryText);
		this.primaryColor.storeColors('primary');
		this.secondaryColor = new ThemeColor(
			styles.secondary,
			styles.secondaryText
		);
		this.secondaryColor.storeColors('secondary');
	}

	getColorList() {
		return [
			this.primaryColor?.getHex('300'),
			this.primaryColor?.getHex('500'),
			this.primaryColor?.getHex('700'),
			this.primaryColor?.getHex('contrast'),
			this.secondaryColor?.getHex('300'),
			this.secondaryColor?.getHex('500'),
			this.secondaryColor?.getHex('700'),
			this.secondaryColor?.getHex('contrast'),
		].filter(x => !!x);
	}
}
