import { Action, ActionCreator, Store } from '@ngrx/store';
import { ApiAction, CustomAction, IStartAction } from './action';
import { globalInjector } from '@lib/global-injector';

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export function rawDispatchAsync<TPayload, TState, TResponse>(
	store: Store,
	action: ApiAction<TState, TPayload, TResponse> | IStartAction<TPayload>,
	payload?: TPayload,
	successOnBlock = false,
	forceReload = false
): Promise<TResponse | null> {
	return new Promise<TResponse | null>((resolve, reject) => {
		let newAction: IStartAction<TPayload>;

		if ('type' in action) {
			newAction = action;
		} else {
			newAction = action.start(payload);
		}

		newAction.onSuccess = response => resolve(response);
		newAction.onError = (error: string, blocked = false) =>
			blocked && successOnBlock ? resolve(null) : reject(error);
		newAction.forceLoad = forceReload;

		store.dispatch(newAction);
	});
}

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export function dispatchAsync<TPayload, TState, TResponse>(
	action: ApiAction<TState, TPayload, TResponse>,
	payload?: TPayload,
	successOnBlock = false,
	forceReload = false
): Promise<TResponse | null> {
	const store = globalInjector.get(Store);
	return rawDispatchAsync(store, action, payload, successOnBlock, forceReload);
}

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export function redispatchAsync<TPayload, TState, TResponse>(
	action: ApiAction<TState, TPayload, TResponse>,
	payload?: TPayload
): Promise<TResponse | null> {
	return dispatchAsync(action, payload, true, true);
}

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export function dispatch(action: ActionCreator<string, () => Action>): void;
/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export function dispatch<TPayload>(
	action: CustomAction<TPayload, any> & ((x: any) => { payload?: TPayload }),
	payload: TPayload
): void;
export function dispatch<TPayload>(
	action: CustomAction<TPayload, any>,
	payload?: TPayload
): void {
	const store = globalInjector.get(Store);
	store.dispatch(action(payload));
}
