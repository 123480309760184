import { CoEntityComponentStoreEntityNotFoundError } from './errors/co-entity-component-store-entity-not-found.error';
import { Injectable } from '@angular/core';
import {
	EntityComponentStore,
	EntityState,
	ExtractEntity,
	PartialUpdater,
} from '@rx-mind/entity-component-store';
import { Observable } from 'rxjs';

/**
 * Component store with entity updaters and selectors.
 * @see {@link EntityComponentStore}
 *
 * @remarks {@link all$} fixes a timing issue in
 *   {@link EntityComponentStore.all$}.
 * @remarks Bulk entity methods accepts read-only arrays to enforce immutable
 *   shared state.
 *
 * @example
 * interface ProductsState extends EntityState<Product, number> {
 *   query: string;
 * }
 *
 * const initialState = getInitialEntityState<ProductsState>({ query: '' });
 *
 * \@Injectable()
 * export class ProductsStore extends EntityComponentStore<ProductsState> {
 *   private readonly query$ = this.select(s => s.query);
 *
 *   readonly vm$ = this.select(
 *     this.all$,
 *     this.query$,
 *     (allProducts, query) => ({
 *       products: allProducts.filter(p => p.name.includes(query)),
 *       query,
 *     }),
 *   );
 *
 *   constructor(private readonly productsService: ProductsService) {
 *     super({ initialState });
 *   }
 *
 *   readonly loadProducts = this.effect<void>($ => {
 *     return $.pipe(
 *       concatMap(() =>
 *         this.productsService.getAll().pipe(
 *           tapResponse(
 *             products => this.setAll(products);
 *             console.error,
 *           ),
 *         )
 *       ),
 *     );
 *   });
 *
 *   readonly deleteProduct = this.effect<number>(id$ => {
 *     return id$.pipe(
 *       concatMap(id =>
 *         this.productsService.create(id).pipe(
 *           tapResponse(
 *             () => this.removeOne(id),
 *             console.error,
 *           ),
 *         ),
 *       ),
 *     ),
 *   });
 * }
 */
@Injectable()
export abstract class CoEntityComponentStore<
	TState extends EntityState<TEntity, TId>,
	TEntity extends {
		readonly id: TId;
	} = ExtractEntity<TState>,
	TId extends string = string
> extends EntityComponentStore<TState, TEntity, TId> {
	override readonly all$: Observable<Record<string, TEntity>[string][]> =
		this.select(
			this.select(
				{
					ids: this.ids$,
					entities: this.entities$,
				},
				{
					/**
					 * Handle timing issue when state is updated in an asynchroneous
					 * effect.
					 * @see {@link https://github.com/rx-mind/angular-plugins/issues/10}
					 */
					debounce: true,
				}
			),
			({ ids, entities }) => ids.map(id => entities[id])
		);

	override addMany(
		// Only accept a read-only array to enforce immutable data
		entities: readonly TEntity[],
		partialStateOrUpdater?: Partial<TState> | PartialUpdater<TState>
	): void {
		/**
		 * Convert entities to writable array to avoid type errors in
		 * {@link EntityComponentStore.addMany}.
		 */
		super.addMany([...entities], partialStateOrUpdater);
	}

	/**
	 * Look up the entity with the specified ID.
	 *
	 * @remarks ℹ️ While a selector provides a reactive way to read the state from
	 *   {@link import("@ngrx/component-store").ComponentStore} via an
	 *   {@link Observable}, sometimes an imperative read is needed. One of such use
	 *   cases is accessing an entity within an effect and that's where the
	 *   {@link getOne} method could be used.
	 * @remarks ⚠️ The {@link getOne} method is {@link import("@ngrx/component-store").ComponentStore}-private,
	 *   meaning it's accessible only within the {@link import("@ngrx/component-store").ComponentStore}.
	 *   It's done to discourage frequent imperative reads from the state as the
	 *   NgRx team is in a consensus that such reads promote further potentially
	 *   harmful architectural decisions.
	 *
	 * @param id The ID of the entity to look up.
	 * @returns {TEntity} If the entity with the specified {@link id} is found.
	 *   `undefined` if it is not found.
	 */
	protected getOne(id: TId): TEntity | undefined;
	/**
	 * Project the entity with the specified ID.
	 *
	 * @remarks ℹ️ While a selector provides a reactive way to read the state from
	 *   {@link import("@ngrx/component-store").ComponentStore} via an
	 *   {@link Observable}, sometimes an imperative read is needed. One of such use
	 *   cases is accessing an entity within an effect and that's where the
	 *   {@link getOne} method could be used.
	 * @remarks ⚠️ The {@link getOne} method is {@link import("@ngrx/component-store").ComponentStore}-private,
	 *   meaning it's accessible only within the {@link import("@ngrx/component-store").ComponentStore}.
	 *   It's done to discourage frequent imperative reads from the state as the
	 *   NgRx team is in a consensus that such reads promote further potentially
	 *   harmful architectural decisions.
	 *
	 * @param id The ID of the entity to project.
	 * @returns {TResult} If the entity with the specified {@link id} is found.
	 *   `undefined` if it is not found.
	 */
	protected getOne<TResult>(
		id: TId,
		projector: (entity: TEntity) => TResult
	): TResult | undefined;
	protected getOne<TResult>(
		id: TId,
		projector?: (entity: TEntity) => TResult
	): TEntity | TResult | undefined {
		const entity = this.get(state => state.entities)[id];

		return projector?.(entity) ?? entity;
	}

	/**
	 * Look up the entity with the specified ID.
	 *
	 * @remarks ℹ️ While a selector provides a reactive way to read the state from
	 *   {@link import("@ngrx/component-store").ComponentStore} via an
	 *   {@link Observable}, sometimes an imperative read is needed. One of such
	 *   use cases is accessing an entity within an effect and that's where the
	 *   {@link getOneOrThrow} method could be used.
	 * @remarks ⚠️ The {@link getOne} method is {@link import("@ngrx/component-store").ComponentStore}-private,
	 *   meaning it's accessible only within the {@link import("@ngrx/component-store").ComponentStore}.
	 *   It's done to discourage frequent imperative reads from the state as the
	 *   NgRx team is in a consensus that such reads promote further potentially
	 *   harmful architectural decisions.
	 *
	 * @param id The ID of the entity to look up.
	 * @returns {TEntity} If the entity with the specified {@link id} is found.
	 * @throws {CoEntityComponentStoreEntityNotFoundError} Thrown if the entity
	 *   with the specified {@link id} is not found.
	 */
	protected getOneOrThrow(id: TId): TEntity;
	/**
	 * Project the entity with the specified ID.
	 *
	 * @remarks ℹ️ While a selector provides a reactive way to read the state from
	 *   {@link import("@ngrx/component-store").ComponentStore} via an
	 *   {@link Observable}, sometimes an imperative read is needed. One of such
	 *   use cases is accessing an entity within an effect and that's where the
	 *   {@link getOneOrThrow} method could be used.
	 * @remarks ⚠️ The {@link getOne} method is {@link import("@ngrx/component-store").ComponentStore}-private,
	 *   meaning it's accessible only within the {@link import("@ngrx/component-store").ComponentStore}.
	 *   It's done to discourage frequent imperative reads from the state as the
	 *   NgRx team is in a consensus that such reads promote further potentially
	 *   harmful architectural decisions.
	 *
	 * @param id The ID of the entity to project.
	 * @returns {TResult} If the entity with the specified {@link id} is found.
	 * @throws {CoEntityComponentStoreEntityNotFoundError} Thrown if the entity
	 *   with the specified {@link id} is not found.
	 */
	protected getOneOrThrow<TResult>(
		id: TId,
		projector: (entity: TEntity) => TResult
	): TResult;
	protected getOneOrThrow<TResult>(
		id: TId,
		projector?: (entity: TEntity) => TResult
	): TEntity | TResult {
		const entity = this.getOne(id);

		if (entity === undefined) {
			throw new CoEntityComponentStoreEntityNotFoundError(id);
		}

		return projector?.(entity) ?? entity;
	}

	override setAll(
		// Only accept a read-only array to enforce immutable data
		entities: readonly TEntity[],
		partialStateOrUpdater?: Partial<TState> | PartialUpdater<TState>
	): void {
		/**
		 * Convert entities to writable array to avoid type errors in
		 * {@link EntityComponentStore.addMany}.
		 */
		return super.setAll([...entities], partialStateOrUpdater);
	}

	override setMany(
		// Only accept a read-only array to enforce immutable data
		entities: readonly TEntity[],
		partialStateOrUpdater?: Partial<TState> | PartialUpdater<TState>
	): void {
		/**
		 * Convert entities to writable array to avoid type errors in
		 * {@link EntityComponentStore.addMany}.
		 */
		return super.setMany([...entities], partialStateOrUpdater);
	}

	override upsertMany(
		// Only accept a read-only array to enforce immutable data
		entities: readonly TEntity[],
		partialStateOrUpdater?: Partial<TState> | PartialUpdater<TState>
	): void {
		/**
		 * Convert entities to writable array to avoid type errors in
		 * {@link EntityComponentStore.addMany}.
		 */
		return super.upsertMany([...entities], partialStateOrUpdater);
	}
}
