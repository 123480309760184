/* eslint-disable no-restricted-syntax */
// no-restricted-syntax: Allow console.debug usage
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { SessionService } from '@store/scope';
import { LoginService } from '@core/services';
import { logOut as logOutAction } from '@store/global';
import { ScopeService } from '@core/services';
import { PopupService } from '@consensus/legacy/ui-popup';
import { Store } from '@ngrx/store';

export const notLoggedInGuard: CanActivateFn = async (
	route: ActivatedRouteSnapshot
) => {
	function logOut(customRoute: unknown[] | null = null) {
		store.dispatch(logOutAction({ customRoute }));
	}

	const store = inject(Store);
	const scopeService = inject(ScopeService);
	const popupService = inject(PopupService);
	const loginService = inject(LoginService);
	const router = inject(Router);
	const sessionService = inject(SessionService);

	console.debug('# Not Logged In Guard');

	if (!sessionService.isLoggedIn()) {
		console.debug('User is not logged in');
		return true;
	}

	// Handle email links when already logged in
	if (route.firstChild.routeConfig.path == 'login/info') {
		const signOut = await popupService.delete(
			`You are about to log out in order to verify an email`,
			`Verify Email`,
			'Log Out',
			'Stay'
		);

		if (signOut) {
			console.debug(`User is visiting login info - Logging out`);
			logOut();
			return true;
		}
	}

	if (sessionService.isAdmin()) {
		console.debug('Logged in as Admin - Sending to CMS');
		await router.navigate(['/cms']);
		return false;
	}

	const user = await sessionService.user;

	if (user.events?.length < 1) {
		console.debug('User has access to 0 events - Logging out');
		logOut();
		return true;
	}
	if (route.firstChild.params['clientName']) {
		const client = await loginService
			.loadClient(route.firstChild.params['clientName'])
			.catch(e => console.error(e));

		if (client) {
			if (client.id != user.clientId) {
				const signOut = await popupService.delete(
					`You are about to log out in order to access ${client.name}. Are you sure you want to do this?`,
					`Switching to ${client.name}`,
					'Log Out',
					'Stay'
				);

				if (signOut) {
					console.debug(
						`User is visiting a client they can't access - Logging out`
					);
					logOut(['/c', client.slug]);
					return true;
				}
			} else {
				console.debug(
					`User is visiting a client they can access - Opening Client [${client.name}]`
				);
				await router.navigate(['/client', client.slug]);
				return false;
			}
		}
	}

	if (route.firstChild.params['eventName']) {
		const data = await loginService
			.loadEvent(route.firstChild.params['eventName'])
			.catch(e => console.error(e));

		if (data && data.event) {
			if (!user.events.includes(data.event.id)) {
				const signOut = await popupService.delete(
					`You are about to log out in order to access ${data.event.name}. Are you sure you want to do this?`,
					`Switching to ${data.event.name}`,
					'Log Out',
					'Stay'
				);

				if (signOut) {
					console.debug(
						`User is visiting an event they can't access - Logging out`
					);
					logOut(['/e', data.event.slug]);
					return true;
				}
			} else {
				console.debug(
					`User is visiting an event they can access - Opening Event [${data.event.name}]`
				);
				await router.navigate(['/event', data.event.slug]);
				return false;
			}
		}
	}

	if (user.events.length === 1) {
		const data = await scopeService.loadEvent({ id: user.events[0] }, false);
		console.debug(
			`User has access to 1 event - Redirecting to Event [${data.event.name}]`
		);
		await router.navigate(['/event', data.event.slug]);
		return false;
	}

	const client = await scopeService.loadClient({ id: user.clientId });

	console.debug(
		`User has access to ${user.events.length} events - Redirecting to Client [${client.name}]`
	);
	if (client.portalEventSlug) {
		console.debug(
			`Redirecting logged-in user to Portal event '${client.portalEventSlug}'`
		);
		await router.navigate(['/event', client.portalEventSlug]);
	} else {
		await router.navigate(['/client', client.slug]);
	}

	return false;
};
