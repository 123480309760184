import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { coToDateOrNull } from '@consensus/co/util-date-times';
import { WhatCanIDoResponseDto } from './user.model';
import { EventOverviewModel } from '@consensus/shared/shared/event/domain';
import { AnonUpgradeModel } from '@login/models';
import { map, Observable } from 'rxjs';

type GetUserEventsResponse = readonly {
	readonly end: string | null;
	readonly hidden: boolean;
	readonly id: string;
	readonly name: string;
	readonly slug: string;
	readonly start: string | null;
	readonly themeId: string | null;
	readonly timezone: string;
	readonly useOverviewLogo: boolean;
	readonly isPortalEvent: boolean;
	readonly portalSortingKey: number;
	readonly shortName: string | null;
}[];
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class UserClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #httpClient = inject(HttpClient);

	getUserEvents(): Observable<readonly EventOverviewModel[]> {
		return this.#httpClient
			.get<GetUserEventsResponse>(`${this.#apiServer}/api/event/list/user`)
			.pipe(
				map(events =>
					events.map(({ end, start, ...event }) => ({
						...event,
						end: coToDateOrNull(end, event.timezone),
						start: coToDateOrNull(start, event.timezone),
					}))
				)
			);
	}

	upgradeUser(data: AnonUpgradeModel) {
		return this.#httpClient.post<any>(
			`${this.#apiServer}/api/auth/login/upgrade`,
			data
		);
	}

	setCookiePolicyAcceptance(accepted: boolean) {
		return this.#httpClient.patch(
			`${this.#apiServer}/api/user/cookie-policy-acceptance`,
			{
				policyAccepted: accepted,
			}
		);
	}

	loadWhatCanIDo() {
		return this.#httpClient.get<WhatCanIDoResponseDto>(
			`${this.#apiServer}/api/auth/permissions/whatcanido`
		);
	}
}
