/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import {
	AfterViewInit,
	Directive,
	ElementRef,
	EventEmitter,
	HostBinding,
	inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { ControlContainer, UntypedFormControl } from '@angular/forms';
import { hasRequiredField, parseErrors } from './utils';
import { FormNode, FormNodeEvent } from './form-node';
import { asapScheduler, Subject, Subscription } from 'rxjs';
import { ThemePalette } from '@angular/material/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Directive()
export class InputBaseComponent<TVal>
	implements OnInit, AfterViewInit, OnDestroy
{
	readonly #controlContainer = inject(ControlContainer, {
		optional: true,
		host: true,
		skipSelf: true,
	});
	@ViewChild('input') inputElement: ElementRef<HTMLElement>;

	@Input() forceRequired = false;
	fieldRequired = false;

	get required() {
		return this.fieldRequired || this.forceRequired;
	}

	settingValue = false;
	settingNgValue = false;

	@Input() controlName: string;

	_control: UntypedFormControl;
	@Input() set control(control: UntypedFormControl) {
		this._control = control;
		if (control) {
			this.fieldRequired = hasRequiredField(control);
		}
		if (control instanceof FormNode) {
			asapScheduler.schedule(() => this.loadFormNode(control));
		}
	}
	get control() {
		return this._control;
	}

	_disabled = false;
	@Input() set disabled(disabled: boolean) {
		this._disabled = disabled;
		if (!this.control || disabled == null) {
			return;
		}
		if (this._disabled) {
			this.control.disable();
		} else {
			this.control.enable();
		}
	}

	_value: TVal;
	@Input() set value(val: TVal) {
		this._value = val;
		if (this.settingNgValue) {
			this.settingNgValue = false;
			return;
		}
		this.control?.setValue(val);
	}
	@Output() valueChange = new EventEmitter<TVal>();
	valueSubs = new Subscription();

	_modelValue: TVal;
	set modelValue(val: TVal) {
		this._modelValue = val;
		this._inputValue = this.preprocessValue(val);
		this.inputValue$.next(this._inputValue);
	}
	get modelValue() {
		return this._modelValue;
	}

	inputValue$ = new Subject<TVal>();
	_inputValue: TVal;
	set inputValue(val: TVal) {
		this._inputValue = val;
		this._modelValue = this.postprocessValue(val);
		this.settingValue = true;
		this.settingNgValue = true;
		this.setValue(this._modelValue);
	}
	get inputValue() {
		return this._inputValue;
	}

	get isDisabled() {
		return this.control?.disabled;
	}
	get show() {
		return !this.hideDisabled || !this.isDisabled;
	}

	#label: string | null = null;
	#placeholder: string | null = null;

	get hasLabel() {
		return this.#label !== null;
	}

	@Input() type = 'text';
	@Input()
	set label(label: string | null | undefined) {
		this.#label = label || null;
	}
	get label(): string | null {
		return this.#label ?? this.#placeholder;
	}
	@Input()
	set placeholder(placeholder: string | null | undefined) {
		this.#placeholder = placeholder || null;
	}
	get placeholder(): string | null {
		return !this.#label && this.#placeholder ? null : this.#placeholder;
	}
	@Input() autocomplete: string;
	@Input() matAutocomplete: MatAutocomplete | null = null;
	@Input() autofocus = false;
	@HostBinding('class.read-only')
	@Input()
	readonly = false;
	@Input() showCopy = false;
	@Input() tooltip: string;
	@Input() hideDisabled: boolean;
	@Input() color: ThemePalette = 'primary';
	@Input() appearance: MatFormFieldAppearance = 'outline';
	@Input() hideRequired: boolean;
	@Input() hint: string;

	@Input() error: boolean;

	get hasErrors() {
		return (
			this.error ||
			(this.control && this.control.touched && this.control.errors)
		);
	}

	get inputError(): string {
		return this.error || !this.control
			? 'There is an error in this field'
			: parseErrors(this.control.errors)[0];
	}

	ngOnInit(): void {
		if (this.controlName) {
			const control = this.#controlContainer?.control?.get(this.controlName);
			if (control instanceof UntypedFormControl) {
				this.control = control;
			}
		}

		if (!this.control) {
			this.control = new UntypedFormControl(this._value);
			if (this._disabled) {
				this.control.disable();
			}
		}

		this.valueSubs.add(
			this.control.valueChanges.subscribe(x => {
				this.valueChange.emit(x);

				if (this.settingValue) {
					this.settingValue = false;
					return;
				}

				this.modelValue = x;
			})
		);

		this.modelValue = this.control.value;
	}

	ngOnDestroy() {
		this.valueSubs.unsubscribe();
	}

	focus() {
		setTimeout(() => this.inputElement?.nativeElement?.focus(), 0);
	}

	ngAfterViewInit() {
		if (this.autofocus) {
			this.focus();
		}
	}

	/**
	 * Processes the value from the FormNode before it is assigned to the inputValue prop
	 * @param value The value to process
	 */
	preprocessValue(value: TVal): any {
		return value;
	}

	/**
	 * Processes the value generated by the input component and returns the value that is stored in the FormNode
	 * @param value The value to be processes
	 */
	postprocessValue(value: any): TVal {
		return value;
	}

	loadFormNode(node: FormNode) {
		this.type = node.type;
		this.label = node.label;
		this.autocomplete = node.autocomplete;
		this.tooltip = node.tooltip ?? this.tooltip;
		this.hideDisabled = node.hideDisabled;
		this.autofocus = node.autoFocus;
		this.readonly = node.readonly != undefined ? node.readonly : this.readonly;
		this.showCopy = node.showCopy;
		this.hint = node.hint;

		this.valueSubs.add(node.actions$.subscribe(e => this.handleEvents(e)));

		this.afterInit();
	}

	afterInit() {
		// Override this method in the sub-component to do something after the
		// component has been initialized
	}

	setValue(value: TVal) {
		this.control?.setValue(value);
		this.control.markAsDirty();
	}

	handleEvents(event: FormNodeEvent) {
		switch (event) {
			case FormNodeEvent.Focus:
				this.focus();
				break;
		}
	}
}
