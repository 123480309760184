import { FileTypes } from '@consensus/shared/shared/files/domain';
import { BehaviorSubject, Observable } from 'rxjs';
import { globalInjector } from '@lib/global-injector';
import { isEqual } from 'lodash-es';
import { FileUpload } from '@lib/files';
import { MediaService } from './media.service';

export class UploadManager<TPayload = any> {
	#uploadToken: string;
	#lastType: FileTypes;
	#lastPayload: any;

	#progressLookup: { [uploadId: string]: BehaviorSubject<number> } = {};

	get uploadProgress$() {
		return this.getProgress$();
	}

	get uploadProgressThumb$() {
		return this.getThumbnailProgress$();
	}

	static basic<TService>(
		service: new (...args) => TService,
		tokenMethod: (
			service: TService
		) => (id?: string, type?: string) => Promise<string> | Observable<string>,
		uploadMethod: (
			mediaService: MediaService
		) => (
			file: File,
			uploadToken: string,
			thumb?: boolean
		) => [Promise<any>, BehaviorSubject<number>]
	) {
		const injector = globalInjector;
		const mediaService = injector.get(MediaService);
		const serviceInstance = injector.get(service);
		return new UploadManager<string>(
			(type: FileTypes, _file: File, payload: string) =>
				tokenMethod(serviceInstance).apply(serviceInstance, [payload, type]),
			uploadMethod(mediaService).bind(mediaService),
			false
		);
	}

	constructor(
		private getToken: (
			type: FileTypes,
			file: File,
			payload: TPayload
		) => Promise<string> | Observable<string>,
		private uploadFunc: (
			file: File,
			uploadToken: string,
			thumb: boolean
		) => [Promise<any>, BehaviorSubject<number>],
		private alwaysUpdate = true
	) {}

	getProgress$(uploadId = 'default') {
		uploadId = uploadId + '_main';
		return this.#getProgress(uploadId);
	}

	getThumbnailProgress$(uploadId = 'default') {
		uploadId = uploadId + '_thumb';
		return this.#getProgress(uploadId);
	}

	#getProgress(uploadId) {
		if (!this.#progressLookup[uploadId]) {
			this.#progressLookup[uploadId] = new BehaviorSubject<number>(null);
		}
		return this.#progressLookup[uploadId];
	}

	async #getUploadToken(type: FileTypes, file: File, payload: TPayload = null) {
		const result = this.getToken(type, file, payload);
		if (result instanceof Observable) {
			return await result.toPromise();
		}
		return await result;
	}

	async #validateUploadToken(
		type: FileTypes,
		file: File = null,
		payload: TPayload = null
	) {
		if (this.#uploadToken && !this.alwaysUpdate) {
			const typeChanged = type != this.#lastType;
			const payloadChanged = !isEqual(payload, this.#lastPayload);

			if (!typeChanged && !payloadChanged) {
				const split = this.#uploadToken.split('.');

				if (split.length === 3) {
					const data = JSON.parse(atob(split[1]));
					const expiresSoon = new Date(data.exp * 1000 - 10000) <= new Date();

					if (!expiresSoon) {
						return this.#uploadToken;
					}
				}
			}
		}

		this.#lastType = type;
		this.#lastPayload = payload;
		this.#uploadToken = await this.#getUploadToken(type, file, payload);
		return this.#uploadToken;
	}

	async upload(
		{ file, type }: FileUpload,
		payload: TPayload = null,
		uploadId = 'default'
	) {
		const localProgress = this.getProgress$(uploadId);
		localProgress.next(5);

		try {
			await this.#validateUploadToken(type, file, payload);

			const [request, progress] = this.uploadFunc(
				file,
				this.#uploadToken,
				false
			);
			progress.subscribe(p => localProgress.next(p));
			// TODO The uploadFunc should return some type of data, which can
			//  be used to update the relevant entity after a successful upload.
			//  See issue #4807
			return await request.finally(() =>
				setTimeout(() => localProgress.next(null), 300)
			);
		} catch (e) {
			localProgress.next(null);
			console.error('Failed to upload file', e.message);
			throw e;
		}
	}

	async uploadThumb(
		file: File,
		payload: TPayload = null,
		uploadId = 'default'
	) {
		const progress = this.getThumbnailProgress$(uploadId);
		progress.next(5);

		try {
			await this.#validateUploadToken(FileTypes.Video, null, payload);

			const [request, progress] = this.uploadFunc(
				file,
				this.#uploadToken,
				true
			);
			progress.subscribe(p => progress.next(p));
			await request.finally(() => setTimeout(() => progress.next(null), 300));
		} catch (e) {
			progress.next(null);
			console.error('Failed to upload thumbnail', e);
		}
	}
}
