/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { Component, Input } from '@angular/core';

@Component({
	selector: 'co-divider',
	templateUrl: './divider.component.html',
	styleUrls: ['./divider.component.scss'],
	standalone: true,
	imports: [],
})
export class DividerComponent {
	@Input() text: string;
}
