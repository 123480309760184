/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { DOCUMENT, NgIf } from '@angular/common';
import {
	Component,
	EventEmitter,
	HostListener,
	Input,
	Output,
	inject,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { InputBaseComponent } from '@lib/forms';
import { SubscriptSizing } from '@angular/material/form-field';

@Component({
	standalone: true,
	selector: 'co-form-search-input',
	imports: [NgIf, ReactiveFormsModule, MatButtonModule, MatInputModule],
	templateUrl: './search-input.component.html',
	styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent extends InputBaseComponent<string> {
	readonly #document = inject(DOCUMENT);

	@Input() subscriptSizing: SubscriptSizing = 'fixed';
	@Input() globalFocus = false;
	@Input() collapsible = false;
	collapsed = true;

	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() submit = new EventEmitter<string>();
	@Output() expanded = new EventEmitter<boolean>();

	@HostListener('window:keydown', ['$event'])
	keyDown(event: KeyboardEvent) {
		if (!this.globalFocus) {
			return;
		}

		if (event.altKey || event.ctrlKey || event.metaKey) {
			return;
		}

		const letterKey = event.key.length === 1 && /\w/.test(event.key);
		if (letterKey) {
			const noFocus =
				this.#document.activeElement.tagName !== 'INPUT' &&
				this.#document.activeElement.tagName !== 'TEXTAREA' &&
				!this.#document.activeElement.hasAttribute('contenteditable');
			if (noFocus) {
				this.focus();
				this.control.patchValue((this.control.value ?? '') + event.key);
			}
		}
	}

	@HostListener('keydown.enter', ['$event'])
	onEnter(_event: KeyboardEvent) {
		if (!this.submit.observed) {
			return;
		}
		this.submit.emit(this.modelValue);
	}

	@HostListener('keydown.escape', ['$event'])
	escape(event: KeyboardEvent) {
		event.stopPropagation();
		this.control.reset();
		this.inputElement?.nativeElement?.blur();
	}

	setCollapsed(collapsed: boolean) {
		this.collapsed = collapsed;
		this.expanded.emit(!collapsed);
		this.setValue('');
	}
}
