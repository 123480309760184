import { inject, Injectable } from '@angular/core';
import {
	HttpEvent,
	HttpEventType,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { sentryToken } from '@consensus/shared/shared/analytics/data-access';
import { Observable, tap } from 'rxjs';

const isResponseHttpEvent = (
	event: HttpEvent<any>
): event is HttpResponse<any> => {
	return event?.type === HttpEventType.Response;
};

/**
 * @deprecated
 * An interceptor temporarily added to the Connect FE that listens for responses with the 'x-incomplete-body-received'
 * header and logs this occurance to Sentry.
 *
 * Added as a part of our effort to move away from 'patchish' PUT requests that do not contain all
 * the properties of the entity and therefore only 'patches' the existing entity in the DB.
 */
@Injectable()
export class CoIncompleteRequestBodyInterceptor implements HttpInterceptor {
	readonly #sentry = inject(sentryToken);

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			tap(event => {
				if (isResponseHttpEvent(event)) {
					this.checkResponse({ request, response: event });
				}
			})
		);
	}

	checkResponse(props: {
		request: HttpRequest<any>;
		response: HttpResponse<any>;
	}): void {
		const { request, response } = props;
		const incompleteHeader: string | null = response.headers.get(
			'x-incomplete-body-received'
		);
		if (!incompleteHeader) {
			return;
		}
		const msg = `Incomplete request body: ${incompleteHeader}`;
		console.warn(msg);
		this.#sentry.captureMessage(msg, {
			tags: {
				incompleteRequestBodyDto: incompleteHeader,
				incompleteRequestBodyUrl: request?.url,
				incompleteRequestBodyMethod: request?.method,
			},
			extra: {
				sentBodyKeys: Object.keys(request?.body ?? {}),
			},
		});
	}
}
