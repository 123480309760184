import { inject, Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import {
	combineLatest,
	debounceTime,
	EMPTY,
	filter,
	map,
	merge,
	mergeMap,
	pipe,
	skip,
	tap,
	withLatestFrom,
} from 'rxjs';
import { UfaNotificationsClient } from './ufa-notifications.client';
import {
	ClientUserNotificationCount,
	EventUserNotificationCount,
	ManagerNotificationRequestDto,
	UfaNotificationsQueryParams,
	UfaNotificationWebSocketMessages,
	UserNotificationQueryResponseDto,
	UserNotificationResponseDto,
	UserNotificationViewedStatus,
} from './ufa-notifications-api-dto-types';
import { ConnectSharedDataAccessWebsocketService } from '@consensus/connect/shared/data-access-websocket';
import { HttpErrorResponse } from '@angular/common/http';
import { sentryToken } from '@consensus/shared/shared/analytics/data-access';
import { CoSnackService } from '@consensus/co/ui-snackbars';
import { orderBy, sum } from 'lodash-es';
import { ConsensusCapacitorBadgeService } from '@consensus/connect/ufa/capacitor/util-badge';
import { selectUserEvents, selectUserId } from '@store/user';
import {
	selectClientId,
	selectEventId,
	selectPortalEventId,
	SessionService,
} from '@store/scope';
import { isNonNullish } from '@consensus/co/util-types';
import { environmentToken } from '@environments/environment';
import { CoEntityComponentStore } from '@consensus/co/util-component-stores';
import {
	EntityState,
	getInitialEntityState,
} from '@rx-mind/entity-component-store';

interface UfaNotificationsState
	extends Readonly<EntityState<UserNotificationResponseDto, string>> {
	readonly totalUnviewedOnClient: number;
	readonly unviewedCountByEvent: EventUserNotificationCount[];
	readonly dismissedIds: string[];
	readonly snackableIds: string[];
	readonly navbarPopoverShown: boolean;
}

const INITIAL_STATE: UfaNotificationsState = getInitialEntityState({
	totalUnviewedOnClient: 0,
	unviewedCountByEvent: [],
	dismissedIds: [],
	snackableIds: [],
	navbarPopoverShown: false,
});

@Injectable({ providedIn: 'root' })
export class UfaNotificationsFacadeStore extends CoEntityComponentStore<UfaNotificationsState> {
	readonly #session = inject(SessionService);
	readonly #store = inject(Store);
	readonly #sentry = inject(sentryToken);
	readonly #notificationsClient = inject(UfaNotificationsClient);
	readonly #snack = inject(CoSnackService);
	readonly #ws = inject(ConnectSharedDataAccessWebsocketService);
	readonly #dismissedIds$ = this.select(state => state.dismissedIds);
	readonly #snackableIds$ = this.select(state => state.snackableIds);
	readonly #clientId$ = this.#store.select(selectClientId);
	readonly #userId$ = this.#store.select(selectUserId);
	readonly #wrappedAppEnabled = !!inject(environmentToken).wrappedApp.webUrl;
	readonly #userEventIds$ = this.#store
		.select(selectUserEvents)
		.pipe(filter(isNonNullish)); // We have to add the pipe to avoid both timing and typing issues
	readonly curEventId$ = this.#store.select(selectEventId);
	readonly #curClientId$ = this.#store.select(selectClientId);
	readonly #portalEventId$ = this.#store.select(selectPortalEventId);
	readonly isPortalEvent$ = this.select(
		this.#portalEventId$,
		this.curEventId$,
		(portalEventId, curEventId) => portalEventId === curEventId
	);
	readonly unviewedCountByEvent$ = this.select(
		state => state.unviewedCountByEvent
	);
	readonly #capacitorBadge = inject(ConsensusCapacitorBadgeService);
	readonly #wsUpdates$ = merge(
		this.#ws.action<UserNotificationResponseDto>(
			UfaNotificationWebSocketMessages.Notification
		),
		this.#ws.action<UserNotificationResponseDto>(
			UfaNotificationWebSocketMessages.NotificationUpdate
		),
		this.#ws.action<UserNotificationResponseDto>(
			UfaNotificationWebSocketMessages.NotificationRepublish
		)
	);
	readonly #wsRemovals$ = this.#ws.action<string>(
		UfaNotificationWebSocketMessages.NotificationDelete
	);
	readonly #navbarPopoverShown$ = this.select(
		state => state.navbarPopoverShown
	);
	readonly #selectAllIds$ = this.select(state => state.ids);
	readonly selectAll$ = this.select(this.all$, notifications =>
		orderBy(
			notifications,
			['pinSettings.pinned', 'viewed', 'publishDate'],
			['desc', 'asc', 'desc']
		)
	);
	readonly selectById = (id: string) =>
		this.select(this.entities$, entities => entities[id] ?? null);
	readonly selectByIds = (ids: string[]) =>
		this.select(this.all$, all => all.filter(elem => ids.includes(elem.id)));
	readonly selectEventByIdUnviewedCount = (eventId: string) =>
		this.select(
			this.unviewedCountByEvent$,
			unviewedCounts =>
				unviewedCounts.find(elem => elem.eventId === eventId) ?? 0
		);
	readonly unviewedOrPinnedNotifications$ = this.select(
		this.selectAll$,
		notifications =>
			notifications.filter(elem => !elem.viewed || elem.pinSettings.pinned)
	);
	readonly visibleUnviewedOrPinnedNotifications$ = this.select(
		this.unviewedOrPinnedNotifications$,
		this.#dismissedIds$,
		(notifications, dismissedIds) =>
			notifications.filter(elem => !dismissedIds.includes(elem.id))
	);
	readonly popoverNotifications$ = this.select(
		this.unviewedOrPinnedNotifications$,
		notifications => notifications.slice(0, 5)
	);
	readonly snackableNotifications$ = this.select(
		this.select({
			visibleOrPinned: this.visibleUnviewedOrPinnedNotifications$,
			snackableIds: this.#snackableIds$,
			popoverShown: this.#navbarPopoverShown$,
			dismissedIds: this.#dismissedIds$,
		}),
		({ visibleOrPinned, snackableIds, popoverShown, dismissedIds }) => {
			if (popoverShown) {
				return [];
			}
			return visibleOrPinned.filter(
				notification =>
					snackableIds.includes(notification.id) ||
					(notification.pinSettings.pinned &&
						(!notification.pinSettings.dismissable ||
							!dismissedIds.includes(notification.id)))
			);
		}
	);
	readonly shownSnackNotifications$ = this.select(
		this.snackableNotifications$,
		notifications => notifications.slice(0, 3)
	);
	readonly getUnreadNotifications = (amount: number) =>
		this.select(this.unviewedOrPinnedNotifications$, notifications =>
			notifications.slice(0, amount)
		);
	readonly totalUnviewedOnClient$ = this.select(
		state => state.totalUnviewedOnClient
	);
	readonly totalUnviewedInEvent$ = this.select(
		this.unviewedCountByEvent$,
		this.curEventId$,
		(eventCounts, eventId) =>
			eventCounts.find(elem => elem.eventId === eventId)?.count ?? 0
	);
	readonly totalUnviewedInOtherEvents$ = this.select(
		this.unviewedCountByEvent$,
		this.curEventId$,
		(eventCounts, eventId) =>
			sum(
				eventCounts
					.filter(elem => elem.eventId !== eventId)
					.map(elem => elem.count)
			)
	);

	/** For NNI events or any portal event, show client unread count, for other events, show event unread count */
	readonly totalUnviewedForEventHeaderBadge$ = this.select(
		this.select({
			isPortalEvent: this.isPortalEvent$,
			unviewedOnClient: this.totalUnviewedOnClient$,
			unviewedInEvent: this.totalUnviewedInEvent$,
		}),
		data =>
			data.isPortalEvent || this.#wrappedAppEnabled
				? data.unviewedOnClient
				: data.unviewedInEvent
	);

	readonly #resetState = this.effect<void>(
		pipe(tap(() => this.setState(INITIAL_STATE)))
	);

	readonly #setUnviewedInfo = this.updater(
		(
			state,
			info: {
				totalUnviewedOnClient: number;
				unviewedCountByEvent: EventUserNotificationCount[];
			}
		): UfaNotificationsState => ({
			...state,
			...info,
		})
	);

	markNotificationAsViewed = this.effect<{
		notificationId: string;
		eventId: string;
	}>(
		pipe(
			withLatestFrom(this.#userId$),
			mergeMap(([{ notificationId, eventId }, userId]) =>
				this.#notificationsClient
					.markUserNotificationsAsViewed({
						eventId,
						userId,
						notificationIds: [notificationId],
					})
					.pipe(
						tapResponse(
							response => {
								if (response) {
									this.updateOne({
										id: notificationId,
										changes: { viewed: true },
									});
								} else {
									this.#snack.error('Failed to mark notification as read');
								}
							},
							(error: HttpErrorResponse) => this.#sentry.captureException(error)
						)
					)
			)
		)
	);

	markManyNotificationAsViewed = this.effect<string[]>(
		pipe(
			withLatestFrom(this.curEventId$, this.#userId$),
			mergeMap(([notificationIds, eventId, userId]) =>
				this.#notificationsClient
					.markUserNotificationsAsViewed({
						eventId,
						userId,
						notificationIds,
					})
					.pipe(
						tapResponse(
							response => {
								if (response) {
									this.updateMany(
										notificationIds.map(id => ({
											id,
											changes: { viewed: true },
										}))
									);
								} else {
									this.#snack.error('Failed to mark notifications as read');
								}
							},
							(error: HttpErrorResponse) => this.#sentry.captureException(error)
						)
					)
			)
		)
	);

	markAllNotificationAsViewed = this.effect<string[]>(
		pipe(
			withLatestFrom(this.#curClientId$, this.#userId$, this.#selectAllIds$),
			mergeMap(([eventIds, clientId, userId, notificationIds]) =>
				this.#notificationsClient
					.markAllUserNotificationsAsViewed({ clientId, userId, eventIds })
					.pipe(
						tapResponse(
							response => {
								if (response) {
									this.updateMany(
										notificationIds
											.map(x => x.toString())
											.map(id => ({ id, changes: { viewed: true } }))
									);
									this.fetchUnviewedUserNotificationCounts();
								} else {
									this.#snack.error('Failed to mark notifications as read');
								}
							},
							(error: HttpErrorResponse) => this.#sentry.captureException(error)
						)
					)
			)
		)
	);

	fetchUnviewedUserNotificationCounts = this.effect<void>(
		pipe(
			debounceTime(500),
			withLatestFrom(
				this.#clientId$,
				this.#userId$,
				this.#userEventIds$,
				this.curEventId$
			),
			mergeMap(([, clientId, userId, userEventIds, curEventId]) => {
				if (!curEventId) {
					// We are not in an event context
					return EMPTY;
				}
				return this.#notificationsClient
					.getUnviewedUserNotificationCounts({
						clientId,
						userId,
						eventIds: userEventIds.length > 0 ? userEventIds : [curEventId], // Provides a fallback for admin users that are not EventParticipants and therefore have an empty array
					})
					.pipe(
						tapResponse(
							response =>
								this.#setUnviewedInfo({
									totalUnviewedOnClient: response.totalCount,
									unviewedCountByEvent: response.eventCounts,
								}),
							(error: HttpErrorResponse) => this.#sentry.captureException(error)
						)
					);
			})
		)
	);

	undismissNotification = this.updater(
		(state, id: string): UfaNotificationsState => ({
			...state,
			dismissedIds: state.dismissedIds.filter(elem => elem !== id),
		})
	);

	dismissNotification = this.updater(
		(state, id: string): UfaNotificationsState => ({
			...state,
			dismissedIds: [...new Set([...state.dismissedIds, id])],
		})
	);

	dismissManyNotifications = this.updater(
		(state, ids: string[]): UfaNotificationsState => ({
			...state,
			dismissedIds: [...new Set([...state.dismissedIds, ...ids])],
		})
	);

	dismissAllUnpinnedNotifications = this.updater(
		(state): UfaNotificationsState => ({
			...state,
			dismissedIds: [
				...new Set([
					...state.dismissedIds,
					...state.ids
						.map(id => id.toString())
						.filter(id => {
							const notification = state.entities[id];
							return !notification?.pinSettings.pinned;
						}),
				]),
			],
		})
	);

	setNavbarPopoverShown = this.updater(
		(state, shown: boolean): UfaNotificationsState => ({
			...state,
			navbarPopoverShown: shown,
		})
	);

	#addSnackableId = this.updater(
		(state, id: string): UfaNotificationsState => ({
			...state,
			snackableIds: [...new Set([...state.snackableIds, id])],
		})
	);

	handleNavbarPopoverVisibilityChange = this.effect<boolean>(
		pipe(
			tap(shown => {
				if (shown) {
					this.dismissAllUnpinnedNotifications();
				}
			})
		)
	);

	handleWsNotificationUpdates = this.effect<UserNotificationResponseDto>(
		pipe(
			withLatestFrom(this.#navbarPopoverShown$),
			tap(([notification, isPopoverShown]) => {
				if (isPopoverShown) {
					this.dismissNotification(notification.id);
				} else {
					this.undismissNotification(notification.id);
				}
				this.upsertOne(notification);
				this.#addSnackableId(notification.id);
			})
		)
	);

	getNotification = this.effect<{
		notificationId: string;
		markAsDismissed?: boolean;
		onResponse?: (response: UserNotificationResponseDto | null) => void;
	}>(
		pipe(
			withLatestFrom(this.curEventId$, this.#userId$),
			mergeMap(
				([{ notificationId, markAsDismissed, onResponse }, eventId, userId]) =>
					this.#notificationsClient
						.getNotification({ eventId, userId, notificationId })
						.pipe(
							tapResponse(
								response => {
									if (markAsDismissed) {
										this.dismissNotification(response.id);
									}
									this.upsertOne(response);
									if (onResponse) {
										onResponse(response);
									}
								},
								(error: HttpErrorResponse) => {
									if (onResponse) {
										onResponse(null);
									}
									this.#sentry.captureException(error);
									this.#snack.error('Failed to fetch notification');
								}
							)
						)
			)
		)
	);

	#removeNotification = this.effect<string>(
		pipe(tap<string>(id => this.removeOne(id)))
	);

	queryNotifications = this.effect<{
		queryParams: UfaNotificationsQueryParams;
		markAsDismissed: boolean;
		onResponse?: (response: UserNotificationQueryResponseDto) => void;
	}>(
		pipe(
			withLatestFrom(this.#curClientId$, this.#userId$),
			mergeMap(
				([{ queryParams, markAsDismissed, onResponse }, clientId, userId]) =>
					this.#notificationsClient
						.queryNotifications({ clientId, userId, queryParams })
						.pipe(
							tapResponse(
								response => {
									if (markAsDismissed) {
										this.dismissManyNotifications(
											response.notifications
												.filter(elem => elem.pinSettings.pinned !== true)
												.map(elem => elem.id)
										);
									}
									this.upsertMany(response.notifications);
									if (onResponse) {
										onResponse(response);
									}
								},
								(error: HttpErrorResponse) => {
									this.#sentry.captureException(error, {
										tags: { errorType: 'ConnectFailedToFetchNotifications' },
									});
									console.error(error);
								}
							)
						)
			)
		)
	);

	createManagerNotification = this.effect<{
		data: ManagerNotificationRequestDto;
		onResponse: (response: UserNotificationResponseDto) => void;
	}>(
		pipe(
			withLatestFrom(this.curEventId$),
			mergeMap(([{ data, onResponse }, eventId]) =>
				this.#notificationsClient
					.createManagerNotification({ eventId, data })
					.pipe(
						tapResponse(
							response => {
								onResponse(response);
								this.#snack.success('Created notification');
							},
							(error: HttpErrorResponse) => {
								this.#sentry.captureException(error);
								this.#snack.error('Failed to create notification');
							}
						)
					)
			)
		)
	);

	readonly setCapacitorAppBadge = this.effect<number>(
		pipe(tap<number>(count => this.#capacitorBadge.set(count)))
	);

	constructor() {
		super({ initialState: INITIAL_STATE });

		/** Fetch unviewed notifications count */
		this.fetchUnviewedUserNotificationCounts(this.#ws.afterConnected$);

		// Set app badge when totalCount is updated
		this.setCapacitorAppBadge(this.totalUnviewedOnClient$);

		/** Fetch notifications on WS connected */
		this.queryNotifications(
			this.#ws.afterConnected$.pipe(
				withLatestFrom(this.isPortalEvent$, this.curEventId$),
				map(([, isPortalEvent, curEventId]) => ({ isPortalEvent, curEventId })),
				filter(data => !!data.curEventId),
				map(({ isPortalEvent, curEventId }) => {
					const queryParams: UfaNotificationsQueryParams = {
						notificationFilters: [UserNotificationViewedStatus.Unviewed],
						limit: 20, // Overfetch notifications
					};
					if (!isPortalEvent) {
						queryParams.eventIds = [curEventId];
					}
					return { queryParams, markAsDismissed: false };
				})
			)
		);

		/** Listen for incoming WS messages about removed notifications */
		this.#removeNotification(this.#wsRemovals$);

		/** Listen for incoming WS messages about created, reenabled or updated notifications */
		this.handleWsNotificationUpdates(this.#wsUpdates$);

		/** Listen for incoming WS messages about updated notification counts */
		this.#setUnviewedInfo(
			this.#ws
				.action<ClientUserNotificationCount>(
					UfaNotificationWebSocketMessages.NotificationCountUpdate
				)
				.pipe(
					map(payload => ({
						totalUnviewedOnClient: payload.totalCount,
						unviewedCountByEvent: payload.eventCounts,
					}))
				)
		);

		/** Listen for incoming WS messages about need to refetch notification count */
		this.fetchUnviewedUserNotificationCounts(
			this.#ws.action<void>(
				UfaNotificationWebSocketMessages.NotificationCountRefetch
			)
		);

		/** Listen for addition/removal of events that the user is associated with and update unviewed count */
		this.fetchUnviewedUserNotificationCounts(
			combineLatest([
				this.#wsUpdates$,
				this.#wsRemovals$,
				this.curEventId$,
			]).pipe(
				filter(([_update, _removal, curEventId]) => !!curEventId), // Do not react if no event is set
				skip(1), // Only react if event IDs are updated while user is online
				map(() => {
					return;
				})
			)
		);

		/** Listen for changes in visibility for navbar notifications popover */
		this.handleNavbarPopoverVisibilityChange(this.#navbarPopoverShown$);

		/** Reset state when event in session changes */
		this.#resetState(this.#session.resetStores$);
	}
}
