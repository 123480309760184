/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import {
	Component,
	EventEmitter,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { BaseComponent } from '@shared/component-bases';
import { BehaviorSubject, asyncScheduler, throttleTime } from 'rxjs';
import { SearchInputComponent } from '@shared/forms';
import Fuse from 'fuse.js';
import { CoTrackByFunction } from '@consensus/co/util-control-flow';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatRippleModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgIf, NgFor } from '@angular/common';
import { DialogComponent } from '../dialog/dialog.component';
import { isString } from 'lodash-es';

@Component({
	selector: 'co-dialog-select',
	templateUrl: './dialog-select.component.html',
	styleUrls: ['./dialog-select.component.scss'],
	standalone: true,
	imports: [
		DialogComponent,
		NgIf,
		SearchInputComponent,
		ScrollingModule,
		MatListModule,
		MatRippleModule,
		MatDividerModule,
		NgFor,
		MatButtonModule,
	],
})
export class DialogSelectComponent<
	TItem extends { id: string; [key: string]: unknown }
> extends BaseComponent {
	@Input() items: TItem[] = [];
	@Input() blacklist: (TItem | string)[] = [];
	@Input({ required: true }) title!: string;
	@Input({ required: true }) bindLabel!: string | ((x: TItem) => string);
	@Input() bindSubText: string | ((x: TItem) => string) | null = null;
	@Input() htmlLabel = false;
	@Input() htmlSubText = false;
	@Input() closeOnSelect = false;
	@Input() enableSearch = true;
	@Input() selectedItemId: string | null = null;
	#show = false;
	@Input() set show(show: boolean) {
		if (show) {
			setTimeout(() => this.searchInput?.focus(), 200);
		}
		this.#show = show;
	}
	get show() {
		return this.#show;
	}
	@Output() showChange = new EventEmitter<boolean>();
	@Output() selected = new EventEmitter<string>();
	@ViewChild('searchInput') searchInput!: SearchInputComponent;
	readonly query$ = new BehaviorSubject('');
	readonly searcher = new Fuse<TItem>([], {
		keys: ['_label', '_subText'],
		shouldSort: true,
		threshold: 0.3,
	});
	searching = false;
	searchResult: TItem[] = [];
	virtualScroll = false;

	get canSelect() {
		return this.selected.observed;
	}

	_itemList = this.dynamicProp(
		() => {
			if (!this.items) {
				return [];
			}
			let list = this.items;

			if (this.blacklist?.length > 0) {
				const blacklist = new Set(
					typeof this.blacklist[0] === 'string'
						? (this.blacklist as string[])
						: this.blacklist.map(x => (x as TItem).id)
				);

				list = this.items.filter(x => !blacklist.has(x.id));
			}

			this.virtualScroll = list.length > 100;

			return list.map(x => ({
				...x,
				_label: this.getLabel(x),
				_subText: this.getSubText(x),
			}));
		},
		'items',
		'blacklist'
	).then(x => {
		this.searcher.setCollection(x);
		this.search(this.query$.value);
	});
	get itemList(): TItem[] {
		return this.searching ? this.searchResult : this._itemList.value;
	}

	onInit() {
		this.monitorValue(
			this.query$.pipe(throttleTime(200, asyncScheduler, { trailing: true })),
			q => this.search(q)
		);
	}

	select(item: TItem) {
		this.selected.emit(item.id);
		if (this.closeOnSelect) {
			this.close();
		}
	}

	getLabel(item: TItem): string {
		const bind = this.bindLabel;
		const label = bind
			? bind instanceof Function
				? bind(item)
				: item[bind]
			: 'N/A';
		return isString(label) ? label : JSON.stringify(label);
	}

	getSubText(item: TItem): string {
		const bind = this.bindSubText;
		const subText = bind
			? bind instanceof Function
				? bind(item)
				: item[bind]
			: '--';
		return isString(subText) ? subText : JSON.stringify(subText);
	}

	search(query: string) {
		this.searching = query.length > 0;
		if (!this.searching) {
			return;
		}
		this.searchResult = this.searcher.search(query).map(result => result.item);
	}

	close(show = false) {
		if (!show) {
			this.query$.next('');
		}
		this.showChange.emit(show);
	}

	readonly trackByItemId: CoTrackByFunction<TItem> = (
		_index: number,
		item: TItem
	) => item.id;
}
