import { createAction } from '@ngrx/store';
import { ApiAction } from '@lib/redux';
import {
	EliminationGameAnswer,
	EliminationGameState,
} from '@consensus/connect/shared/elimination-game/domain';
import {
	UfaEliminationGame,
	UfaEliminationGameItem,
	UfaEliminationGameQuestionAnswerModel,
	UfaEliminationGameTeam,
} from '@consensus/connect/ufa/elimination-game/domain';
import { UfaEliminationGameStoreState } from './ufa-elimination-game.state';

export const loadEliminationGames = new ApiAction<
	UfaEliminationGameStoreState,
	void,
	UfaEliminationGame[]
>('Elimination Game', 'Load', 'User Elimination Games', {
	showErrors: true,
});

export const loadEliminationGameProgress = new ApiAction<
	UfaEliminationGameStoreState,
	string,
	EliminationGameAnswer[]
>('Elimination Game Progress', 'Load', 'User Elimination Games', {
	showErrors: true,
});

export const answerEliminationGameQuestion = new ApiAction<
	UfaEliminationGameStoreState,
	UfaEliminationGameQuestionAnswerModel,
	EliminationGameAnswer
>('Elimination Game', 'Answer', 'User Elimination Games', {
	showErrors: true,
});

export const addEliminationGame = createAction(
	'[Elimination Game WebSocket] Add Elimination Game',
	(payload: UfaEliminationGame) => ({ payload })
);
export const removeEliminationGame = createAction(
	'[Elimination Game WebSocket] Remove Elimination Game',
	(payload: string) => ({ payload })
);
export const advanceEliminationGame = createAction(
	'[Elimination Game WebSocket] Advance Elimination Game',
	(payload: UfaEliminationGameItem) => ({ payload })
);
export const updateEliminationGame = createAction(
	'[Elimination Game WebSocket] Update Elimination Game',
	(payload: EliminationGameState) => ({ payload })
);
export const setEliminationGameTeam = createAction(
	'[Elimination Game WebSocket] Set Elimination Game Team',
	(payload: UfaEliminationGameTeam) => ({ payload })
);
