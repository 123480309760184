import { createFeatureSelector, createSelector } from '@ngrx/store';
import { groupBy, keyBy } from 'lodash-es';
import { EventAgendaState, UserSession } from './agenda.model';
import {
	sortByDateThenSortingKeyAsc,
	sortByDateTimeThenSortingKeyAsc,
	sortDateTimeAsc,
} from '@lib/helpers';
import { LoadingState } from '@shared/models';
import { isNonNullish } from '@consensus/co/util-types';

export const agendaKey = 'agenda';

interface EventFeatureState {
	[agendaKey]: EventAgendaState;
}

const selectEventState = createFeatureSelector<EventFeatureState>('event');

export const selectAgendaState = createSelector(
	selectEventState,
	state => state.agenda
);

export const selectLoadingAgenda = createSelector(
	selectAgendaState,
	state =>
		state.loaded != LoadingState.Loaded && state.loaded != LoadingState.Error
);

export const selectAgendaData = createSelector(selectAgendaState, state => {
	const sorted = groupBy(
		[...state.sessions].sort(sortByDateTimeThenSortingKeyAsc(s => s.start)),
		s => s.agendaId ?? ''
	);
	return [...state.agendas]
		.sort(sortByDateThenSortingKeyAsc(s => s.date))
		.map(a =>
			Object.prototype.hasOwnProperty.call(sorted, a.id)
				? {
						...a,
						sessions: sorted[a.id] || [],
				  }
				: null
		)
		.filter(isNonNullish);
});

export const selectNoteAgendas = createSelector(selectAgendaState, state => {
	const sorted = groupBy(
		[...state.sessions]
			.filter(x => x.enableNotes)
			.sort(sortByDateTimeThenSortingKeyAsc(s => s.start)),
		s => s.agendaId ?? ''
	);
	return [...state.agendas]
		.sort(sortByDateThenSortingKeyAsc(s => s.date))
		.map(a =>
			Object.prototype.hasOwnProperty.call(sorted, a.id)
				? {
						...a,
						sessions: sorted[a.id] || [],
				  }
				: null
		)
		.filter(isNonNullish);
});

export const selectEventSessions = createSelector(selectAgendaState, s =>
	[...s.sessions].sort(sortByDateTimeThenSortingKeyAsc(s => s.start))
);

export const selectSessionsFromAgenda = createSelector(
	selectAgendaState,
	(state, agendaId: string) => {
		return state.sessions.filter(x => x.agendaId === agendaId);
	}
);

export const selectOrderedSessions = createSelector(
	selectAgendaData,
	agendaData => {
		const sessions: UserSession[] = [];
		agendaData.forEach(x =>
			x.sessions.forEach(s => {
				const session: UserSession = {
					...s,
					start: x.date
						? new Date(
								x.date.getFullYear(),
								x.date.getMonth(),
								x.date.getDate(),
								s.start.getHours(),
								s.start.getMinutes(),
								s.start.getSeconds()
						  )
						: s.start,
					end: x.date
						? new Date(
								x.date.getFullYear(),
								x.date.getMonth(),
								x.date.getDate(),
								s.end.getHours(),
								s.end.getMinutes(),
								s.end.getSeconds()
						  )
						: s.end,
				};
				sessions.push(session);
			})
		);
		return sessions.sort(sortDateTimeAsc(s => s.start));
	}
);

export const selectOrderedSessionLookup = createSelector(
	selectOrderedSessions,
	sessions => {
		return keyBy(sessions, x => x.id);
	}
);
