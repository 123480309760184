export type FroalaPreset =
	| 'simple'
	| 'normal'
	| 'advanced'
	| 'information'
	| 'socialFeed'
	| 'simpleWithTextColor';

const froalaButtons = {
	simple: {
		moreText: {
			buttons: [
				'bold',
				'italic',
				'underline',
				'subscript',
				'superscript',
				'clearFormatting',
			],
			align: 'left',
			buttonsVisible: 3,
		},
		moreMisc: {
			buttons: ['undo', 'redo', 'fullscreen', 'selectAll', 'help'],
			align: 'right',
			buttonsVisible: 2,
		},
	},
	normal: {
		moreText: {
			buttons: [
				'bold',
				'italic',
				'underline',
				'subscript',
				'superscript',
				'clearFormatting',
			],
			align: 'left',
			buttonsVisible: 3,
		},
		moreParagraph: {
			buttons: [
				'formatOLSimple',
				'formatUL',
				'alignLeft',
				'alignCenter',
				'alignRight',
				'alignJustify',
				'paragraphFormat',
				'quote',
			],
			align: 'left',
			buttonsVisible: 3,
		},
		moreMisc: {
			buttons: ['undo', 'redo', 'fullscreen', 'selectAll', 'help'],
			align: 'right',
			buttonsVisible: 2,
		},
	},
	advanced: {
		moreText: {
			buttons: [
				'bold',
				'italic',
				'fontSize',
				'underline',
				'strikeThrough',
				'subscript',
				'superscript',
				'inlineClass',
				'textColor',
				'clearFormatting',
			],
			align: 'left',
			buttonsVisible: 3,
		},
		moreParagraph: {
			buttons: [
				'formatOLSimple',
				'formatUL',
				'paragraphFormat',
				'alignCenter',
				'alignLeft',
				'alignRight',
				'alignJustify',
				'paragraphStyle',
				'quote',
			],
			align: 'left',
			buttonsVisible: 3,
		},
		moreRich: {
			buttons: [
				'insertLink',
				'insertTable',
				'emoticons',
				'fontAwesome',
				'specialCharacters',
				'insertHR',
			],
			align: 'left',
			buttonsVisible: 2,
		},
		moreMisc: {
			buttons: ['undo', 'redo', 'fullscreen', 'selectAll', 'html', 'help'],
			align: 'right',
			buttonsVisible: 2,
		},
	},
	socialFeed: {
		moreText: {
			buttons: ['bold', 'italic', 'underline', 'superscript'],
			align: 'left',
			buttonsVisible: 4,
		},
		moreParagraph: {
			buttons: ['formatOL', 'formatUL'],
			align: 'left',
			buttonsVisible: 2,
		},
		moreRich: {
			buttons: ['insertLink', 'emoticons'],
			align: 'left',
			buttonsVisible: 2,
		},
	},
	information: {
		moreText: {
			buttons: [
				'bold',
				'italic',
				'underline',
				'subscript',
				'superscript',
				'clearFormatting',
			],
			align: 'left',
			buttonsVisible: 3,
		},
		moreRich: {
			buttons: ['insertLink', 'formatOL', 'formatUL'],
			align: 'left',
			buttonsVisible: 3,
		},
		moreMisc: {
			buttons: ['undo', 'redo', 'fullscreen', 'selectAll', 'help'],
			align: 'right',
			buttonsVisible: 2,
		},
	},
	simpleWithTextColor: {
		moreText: {
			buttons: [
				'bold',
				'italic',
				'underline',
				'subscript',
				'superscript',
				'textColor',
				'clearFormatting',
			],
			align: 'left',
			buttonsVisible: 3,
		},
		moreMisc: {
			buttons: ['undo', 'redo', 'fullscreen', 'selectAll', 'help'],
			align: 'right',
			buttonsVisible: 2,
		},
	},
};

export function getFroalaButtons(layout: FroalaPreset) {
	switch (layout) {
		case 'simple':
			return { toolbarButtons: froalaButtons.simple };
		case 'normal':
			return { toolbarButtons: froalaButtons.normal };
		case 'advanced':
			return { toolbarButtons: froalaButtons.advanced };
		case 'socialFeed':
			return { toolbarButtons: froalaButtons.socialFeed };
		case 'information':
			return { toolbarButtons: froalaButtons.information };
		case 'simpleWithTextColor':
			return { toolbarButtons: froalaButtons.simpleWithTextColor };
		default:
			return { toolbarButtons: froalaButtons.normal };
	}
}

export const defaultTextColors = [
	'#FFFFFF',
	'#F4F4F4',
	'#e6e6e6',
	'#c9c9c9',
	'#939498',
	'#202020',
	'#2a918b',
	'#c34055',
	'#5c96e3',
	'REMOVE',
];
export const defaultTableColors = [
	'#FFFFFF',
	'#F4F4F4',
	'#e6e6e6',
	'#c9c9c9',
	'#939498',
	'#202020',
	'#2a918b',
	'#c34055',
	'#5c96e3',
	'REMOVE',
];
