import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
	SignatureLoadModel,
	UserSignature,
	UserSignatureCreateModel,
} from './signature.model';
import { switchMap } from 'rxjs';
import { MediaService } from '@consensus/shared/shared/files/data-access-files';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class SignatureClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #mediaService = inject(MediaService);
	readonly #httpClient = inject(HttpClient);

	loadUserSignatures() {
		return this.#httpClient.get<SignatureLoadModel>(
			`${this.#apiServer}/api/signature`
		);
	}

	createUserSignature({ id, imageData }: UserSignatureCreateModel) {
		return this.#httpClient
			.post<UserSignature>(`${this.#apiServer}/api/signature/${id}/sign`, {})
			.pipe(
				switchMap(x =>
					this.#mediaService
						.uploadSignature(imageData, x.uploadToken)[0]
						.then(() => x)
				)
			);
	}
}
