import { APP_INITIALIZER, FactoryProvider, Injector } from '@angular/core';
import { setGlobalInjector } from './global-injector';

function initializeGlobalInjector(injector: Injector): () => void {
	return () => setGlobalInjector(injector);
}

export const globalInjectorInitializer: FactoryProvider = {
	provide: APP_INITIALIZER,
	multi: true,
	deps: [Injector],
	useFactory: initializeGlobalInjector,
};
