import { Directive, EventEmitter, inject, Input, Output } from '@angular/core';
import { SMIInput } from '@rive-app/canvas-advanced';
import { CoRiveStateMachineDirective } from './state-machine.directive';
import { CoRiveStateMachineInputType } from '@consensus/co/domain-rive';

function getInput(input: SMIInput) {
	if (input.type === CoRiveStateMachineInputType.Number) {
		return input.asNumber();
	}
	if (input.type === CoRiveStateMachineInputType.Boolean) {
		return input.asBool();
	}
	if (input.type === CoRiveStateMachineInputType.Trigger) {
		return input.asTrigger();
	}
	return input;
}

/**
 * Directive for StateMachine Inputs. Used by the RiveStateMachine.
 */
@Directive({
	selector: 'co-rive-input, [coRiveInput]',
	standalone: true,
})
export class CoRiveSMInputDirective {
	#stateMachine = inject(CoRiveStateMachineDirective);
	private _name?: string;
	private _value?: boolean | number;
	#input?: SMIInput;
	private shouldFire?: (input: SMIInput) => void;

	@Input()
	set name(name: string | undefined) {
		if (!name) {
			return;
		}
		this._name = name;
		if (this.#input) {
			return;
		}
		this.init(this.#stateMachine.inputs[name]);
	}
	get name() {
		return this.#input?.name ?? this._name;
	}

	@Input()
	set value(rawValue: string | boolean | number | undefined | null) {
		if (typeof rawValue === 'undefined' || rawValue === null) {
			return;
		}
		const value =
			typeof rawValue === 'string' ? parseFloat(rawValue) : rawValue;
		if (this.#input) {
			this.#input.value = value;
			this.change.emit(this.#input);
		} else {
			this._value = value;
		}
	}
	get value() {
		return this.#input?.value ?? this._value;
	}

	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() change = new EventEmitter<SMIInput>();
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() load = new EventEmitter<SMIInput>();

	/** @internal: Used by the RiveStateMachine */
	init(input?: SMIInput) {
		if (!input || input.name === this.#input?.name) {
			return;
		}
		this.#input = getInput(input);
		this.load.emit(input);
		if (typeof this._value !== 'undefined') {
			this.#input.value = this._value;
			this.change.emit(this.#input);
		}
		if (this.shouldFire) {
			this.shouldFire(input);
			delete this.shouldFire;
		}
	}

	fire() {
		const fire = (input: SMIInput) => {
			if (input.type === CoRiveStateMachineInputType.Trigger) {
				input.fire();
				this.change.emit(input);
			}
		};
		this.#input ? fire(this.#input) : (this.shouldFire = fire);
	}
}
