import { inject, Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, filter, tap } from 'rxjs';
import { CoSnackService } from '@consensus/co/ui-snackbars';

@Injectable()
export class ToastEffects {
	readonly #snack = inject(CoSnackService);
	readonly #actions$ = inject(Actions);
	toastSuccess$: Observable<Action> = createEffect(
		() =>
			this.#actions$.pipe(
				filter((a: any) => a.toastSuccess),
				tap(
					({ message, title }: any) =>
						message && this.#snack.success(message, title)
				)
			),
		{ dispatch: false }
	);

	toastError$: Observable<Action> = createEffect(
		() =>
			this.#actions$.pipe(
				filter((a: any) => a.toastError),
				tap(
					({ message, title, payload }: any) =>
						message && this.#snack.error(message, title, false, payload)
				)
			),
		{ dispatch: false }
	);
}
