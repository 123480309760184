import { CustomStore } from '@lib/redux';
import { UfaDashboardState } from './ufa-dashboard.model';
import { UfaDashboardClient } from './ufa-dashboard.client';
import { MoveModel } from '@shared/models';
import {
	Dashboard,
	DashboardItem,
} from '@consensus/connect/shared/dashboard/domain';
import {
	moveListItem,
	removeListItem,
	updateList,
	updateListItem,
} from '@lib/redux';
import { sortBySortingKeyAsc } from '@lib/helpers';
import { dispatchAsync } from '@lib/redux';

export const ufaDashboardKey = 'dashboards';

export const ufaDashboardStore = new CustomStore<
	UfaDashboardState,
	UfaDashboardClient
>('Event Dashboard', { dashboards: [] }, UfaDashboardClient, 'Event');

const store = ufaDashboardStore;

export const loadDashboards = store
	.addApiAction('Dashboards', 'Load')
	.withError()
	.withEffect(c => c.loadDashboards)
	.withReducer(dashboards => ({ dashboards }));

export const visitDashboardItem = store
	.addApiAction('Dashboard Item', 'Visit')
	.withError()
	.withEffect(c => c.visitDashboardItem)
	.noReducer();

export const completeDashboardItemActivity = store
	.addApiAction('Dashboard Item', 'Complete Activity')
	.withError()
	.withEffect(c => c.completeDashboardItemActivity)
	.withReducer(({ id }, { dashboards }) => ({
		dashboards: updateList(dashboards, x => ({
			dashboardItems: updateListItem(x.dashboardItems, y => y.id === id, {
				isCompleted: true,
			}),
		})),
	}));

store
	.addSocketAction<Dashboard>('Dashboards', 'Dashboard', 'Set')
	.withSideEffect(_action => dispatchAsync(loadDashboards))
	.set('dashboards');

store
	.addSocketAction<string>('Dashboards', 'Dashboard', 'Removed')
	.delete('dashboards');

store
	.addSocketAction<DashboardItem>('Dashboards', 'Dashboard Item', 'Set')
	.withSideEffect(_action => dispatchAsync(loadDashboards))
	.withReducer((item, { dashboards }) => ({
		dashboards: updateListItem(
			dashboards,
			x => x.id === item.dashboardId,
			x => ({
				dashboardItems: updateListItem(
					x.dashboardItems,
					y => y.id === item.id,
					item,
					true
				),
			})
		),
	}));

store
	.addSocketAction<string>('Dashboards', 'Dashboard Item', 'Removed')
	.withReducer((id, { dashboards }) => ({
		dashboards: updateList(dashboards, x => ({
			dashboardItems: removeListItem(x.dashboardItems, y => y.id === id),
		})),
	}));

store
	.addSocketAction<MoveModel>('Dashboards', 'Dashboard Item', 'Moved')
	.withReducer(({ id, sortingKey }, { dashboards }) => ({
		dashboards: updateList(dashboards, x => ({
			dashboardItems: moveListItem(
				x.dashboardItems,
				y => y.id === id,
				sortingKey,
				y => y.dashboardId
			),
		})),
	}));

export const getDashboards = store.baseSelector.create(
	state =>
		state.dashboards.map(d => {
			return {
				...d,
				dashboardItems: [...d.dashboardItems].sort(sortBySortingKeyAsc),
			};
		}) as Dashboard[]
);
