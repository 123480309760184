import { AdminUser, UserInformation } from '@shared/models';

export function getUserInitials(user: AdminUser) {
	if (!user?.username) {
		return '?';
	}
	// Resolve @typescript-eslint/no-non-null-assertion: Safe because of conditions
	/* eslint-disable @typescript-eslint/no-non-null-assertion */
	if (user.lastName) {
		if (user.firstName) {
			return (
				String.fromCodePoint(user.firstName.codePointAt(0)!) +
				String.fromCodePoint(user.lastName.codePointAt(0)!)
			);
		} else {
			return String.fromCodePoint(user.lastName.codePointAt(0)!);
		}
	} else if (user.firstName) {
		return String.fromCodePoint(user.firstName.codePointAt(0)!);
	}

	return String.fromCodePoint(user.username.codePointAt(0)!);
	/* eslint-enable @typescript-eslint/no-non-null-assertion */
}

export function getUsersName(user: UserInformation) {
	if (!user) {
		return '';
	}
	return user.firstName
		? user.lastName
			? user.firstName + ' ' + user.lastName
			: user.firstName
		: user.lastName || user.username;
}
