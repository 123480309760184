import { inject, Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '@store/scope';
import { novofy } from '@lib/helpers';

@Pipe({
	standalone: true,
	name: 'novofy',
})
export class NovofyPipe implements PipeTransform {
	readonly #session = inject(SessionService);

	transform(str: string): string {
		return novofy(this.#session.isSema, str);
	}
}
