import { inject, Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '@store/scope';

@Pipe({
	standalone: true,
	name: 'hasScopePermission',
})
export class HasScopePermissionPipe implements PipeTransform {
	readonly #sessionService = inject(SessionService);

	transform(scope: 'globalCms' | 'clientCms' | 'eventCms') {
		return this.#sessionService.hasScopePermission(scope);
	}
}
