/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	inject,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { SessionService } from '@store/scope';
import { clamp } from '@lib/helpers';
import { VideoOutroService } from '@core/services';
import { NgIf, AsyncPipe } from '@angular/common';
import { EncodedMediaComponent } from '../encoded-media/encoded-media.component';

@Component({
	selector: 'co-zoom-media',
	templateUrl: './zoom-media.component.html',
	styleUrls: ['./zoom-media.component.scss'],
	standalone: true,
	imports: [EncodedMediaComponent, NgIf, AsyncPipe],
})
export class ZoomMediaComponent {
	videoOutroService = inject(VideoOutroService);
	readonly #sessionService = inject(SessionService);
	readonly #container: ElementRef<HTMLElement> = inject(ElementRef);
	#zoom = 100;
	zoom = 100;
	#maxZoom = 400;

	#offsetX = 0;
	offsetX = 0;
	#offsetY = 0;
	offsetY = 0;
	#pinching = false;
	#pinchCenter;
	#panning = false;
	heightLock = false;

	aspectRatio: number;

	@ViewChild('content') content: ElementRef<HTMLDivElement>;
	@ViewChild('zoomWrapper') zoomWrapper: ElementRef<HTMLDivElement>;

	@Input() loop = false;
	@Input() useAspectRatio = false;
	@Input() src: string;
	@Input() enablePlayOutroVideo?: boolean = false;

	@Input()
	set academyResource([moduleId, resourceId]: [string, string]) {
		this.src = `academy/module/${moduleId}/resource/${resourceId}`;
	}

	@Output() loaded = new EventEmitter();

	onInit() {
		this.#sessionService.resize$.subscribe(() => this.updateDimensions());
	}

	@HostListener('pan', ['$event'])
	onPan(event) {
		if (!this.#pinching) {
			this.#panning = true;
			const rect = this.content.nativeElement.getBoundingClientRect();

			let x = (event.deltaX * 100) / rect.width;
			x += this.offsetX;

			let y = (event.deltaY * 100) / rect.height;
			y += this.offsetY;

			[x, y] = this.#constrictCoords(x, y);

			this.#offsetX = x;
			this.#offsetY = y;

			this.content.nativeElement.style.transform = `translateX(${x}%) translateY(${y}%)`;
		}

		if (event.isFinal) {
			this.#onFinal();
		}
	}

	@HostListener('pinch', ['$event'])
	onPinch(event) {
		if (this.#panning) {
			this.#panning = false;
			this.#onFinal();
		}

		if (!this.#pinching) {
			this.#pinchCenter = event.center;
		}

		const pageRect = this.#container.nativeElement.getBoundingClientRect();

		let zoom = this.zoom * event.scale;
		zoom = Math.min(this.#maxZoom, Math.max(100, zoom));
		this.#zoom = zoom;
		const zStart = (100 * 100) / this.zoom;
		const zEnd = (100 * 100) / this.#zoom;
		const relX = (this.#pinchCenter.x - pageRect.left) / pageRect.width;
		const relY = (this.#pinchCenter.y - pageRect.top) / pageRect.height;

		this.#offsetX = this.offsetX + zEnd * relX - zStart * relX;
		this.#offsetY = this.offsetY + zEnd * relY - zStart * relY;
		this.zoomWrapper.nativeElement.style.transform = `scale(${zoom / 100})`;
		this.content.nativeElement.style.transform = `translateX(${
			this.#offsetX
		}%) translateY(${this.#offsetY}%)`;
		this.#pinching = true;

		if (event.isFinal) {
			this.#onFinal();
		}
	}

	@HostListener('wheel', ['$event'])
	onWheel(event: WheelEvent) {
		event.stopPropagation();
		event.preventDefault();

		if (event.shiftKey) {
			this.#zoom = this.zoom - (event.deltaY || event.deltaX) / 10;
		} else {
			const rect = this.content.nativeElement.getBoundingClientRect();
			this.#offsetX = this.offsetX - (event.deltaX * 100) / rect.width;
			this.#offsetY = this.offsetY - (event.deltaY * 100) / rect.height;
		}

		this.#onFinal();
	}

	#constrictCoords(x: number, y: number) {
		if (!this.content) {
			return [x, y];
		}

		const pageRect = this.#container.nativeElement.getBoundingClientRect();
		const rect = this.content.nativeElement.getBoundingClientRect();

		// Container width relative to element
		const w = (pageRect.width * 100) / rect.width;

		x = w <= 100 ? clamp(x, w - 100, 0) : clamp(x, 0, w - 100);

		// Container height relative to element
		const h = (pageRect.height * 100) / rect.height;

		y = h <= 100 ? clamp(y, h - 100, 0) : clamp(y, 0, h - 100);

		return [x, y];
	}

	#onFinal() {
		this.zoom = Math.min(this.#maxZoom, Math.max(100, this.#zoom));

		if (this.content) {
			[this.#offsetX, this.#offsetY] = this.#constrictCoords(
				this.#offsetX,
				this.#offsetY
			);
			this.content.nativeElement.style.transform = `translateX(${
				this.#offsetX
			}%) translateY(${this.#offsetY}%)`;
		}

		this.#pinching = false;
		this.#panning = false;
		this.offsetX = this.#offsetX;
		this.offsetY = this.#offsetY;
	}

	updateDimensions(aspect: number = null) {
		if (aspect != null) {
			this.aspectRatio = aspect;
		}

		setTimeout(() => {
			const pageRect = this.#container.nativeElement.getBoundingClientRect();
			const rect = this.content.nativeElement.getBoundingClientRect();

			// Bug on mobile devices happens because rect.width sometimes is 0
			// To fix this issue we set the rect.width to be the width of pageRect
			if (rect.width === 0) {
				rect.width = pageRect.width;
			}
			this.heightLock =
				pageRect.width / pageRect.height > rect.width / rect.height;

			setTimeout(() => {
				const pageRect = this.#container.nativeElement.getBoundingClientRect();
				const rect = this.content.nativeElement.getBoundingClientRect();
				const w = (pageRect.width * 100) / rect.width;
				const h = (pageRect.height * 100) / rect.height;

				if (w > 100) {
					this.#offsetX = (w - 100) / 2;
				}

				if (h > 100) {
					this.#offsetY = (h - 100) / 2;
				}

				this.#zoom = 100;

				this.#onFinal();
			}, 0);
		}, 0);
	}
}
