<div class="wrapper" [@harmonica]="show && notEmpty">
	<div class="hoz-wrapper">
		<ng-select
			[items]="_items"
			[bindLabel]="bindLabel"
			[bindValue]="bindValue"
			[multiple]="multiple"
			[selectableGroup]="selectGroups"
			[selectableGroupAsModel]="false"
			[clearable]="clearable"
			[clearSearchOnAdd]="clearSearchOnAdd"
			[formControl]="control"
			[closeOnSelect]="!multiple"
			[placeholder]="label + (!required || hideRequired ? '' : '*')"
			[groupBy]="groupBy"
			[searchFn]="searchFn"
			[virtualScroll]="virtualScroll"
			[readonly]="readonly"
			[appendTo]="appendTo"
		>
			<ng-template ng-option-tmp let-item="item">
				{{
					bindOption || bindLabel
						? item[bindOption || bindLabel]
						: item
				}}
			</ng-template>
		</ng-select>
		<i
			class="fad fa-question-circle tooltip"
			*ngIf="tooltip"
			[matTooltip]="tooltip"
		></i>
	</div>
	<p class="error mat-error" *ngIf="hasErrors">{{ inputError }}</p>
</div>
