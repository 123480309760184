import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
} from '@angular/core';
import {
	MatProgressSpinner,
	MatProgressSpinnerModule,
} from '@angular/material/progress-spinner';

export type CoSpinnerLayout =
	| 'centered'
	| 'default'
	| 'icon'
	| 'inline'
	| 'overlay';

function transformLayoutWithFallback(
	layout: CoSpinnerLayout | '' | null | undefined
): CoSpinnerComponent['layout'] {
	return layout || 'default';
}

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'co-spinner',
	templateUrl: './co-spinner.component.html',
	styleUrls: ['./co-spinner.component.scss'],
	standalone: true,
	imports: [MatProgressSpinnerModule],
})
export class CoSpinnerComponent {
	@HostBinding('class.centered')
	get cssCentered(): boolean {
		return this.layout === 'centered';
	}
	@HostBinding('style.--co-spinner-size')
	get cssCoSpinnerSize(): string {
		return `${this.diameter}px`;
	}
	@HostBinding('class.on-accent')
	get getCssOnAccent(): boolean {
		return this.color === 'accent-contrast';
	}
	@HostBinding('class.on-primary')
	get getCssOnPrimary(): boolean {
		return this.color === 'primary-contrast';
	}
	@HostBinding('class.on-warn')
	get getCssOnWarn(): boolean {
		return this.color === 'warn-contrast';
	}
	@HostBinding('class.overlay')
	get cssOverlay(): boolean {
		return this.layout === 'overlay';
	}

	@Input()
	color?:
		| NonNullable<MatProgressSpinner['color']>
		| `${NonNullable<MatProgressSpinner['color']>}-contrast`;
	@Input()
	@Input({
		transform: transformLayoutWithFallback,
	})
	layout: CoSpinnerLayout = 'default';

	get diameter(): MatProgressSpinner['diameter'] {
		switch (this.layout) {
			case 'inline':
				return 18;
			case 'icon':
				return 24;
			case 'centered':
			// Fall through
			case 'overlay':
			// Fall through
			case 'default':
				return 48;
			default: {
				const exhaustiveCheck = this.layout satisfies never;

				throw new Error(`Unexpected layout: ${exhaustiveCheck}`);
			}
		}
	}
	get strokeWidth(): MatProgressSpinner['strokeWidth'] {
		switch (this.layout) {
			case 'inline':
			// Fall through
			case 'icon':
				return 2;
			case 'centered':
			// Fall through
			case 'overlay':
			// Fall through
			case 'default':
				return 4;
			default: {
				const exhaustiveCheck = this.layout satisfies never;

				throw new Error(`Unexpected layout: ${exhaustiveCheck}`);
			}
		}
	}
}
