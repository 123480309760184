/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { NgIf } from '@angular/common';
import { Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormNode, FormSelectNode } from '@lib/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { harmonicaAnimation } from '@consensus/co/ui-component-animations';
import { InputBaseComponent } from '@lib/forms';
import { cloneDeep } from 'lodash-es';

@Component({
	standalone: true,
	selector: 'co-form-select',
	imports: [NgIf, ReactiveFormsModule, MatTooltipModule, NgSelectModule],
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss'],
	animations: [harmonicaAnimation()],
})
export class SelectComponent<TItem = any>
	extends InputBaseComponent<TItem[]>
	implements OnDestroy
{
	_items: TItem[] = [];
	@Input() set items(items: TItem[]) {
		if (
			items?.length > 0 &&
			items[0] != null &&
			items[0] instanceof Object &&
			(this.bindLabelFunc || this.bindOptionFunc)
		) {
			items = items.map(x => {
				const option = { ...x };
				if (this.bindLabelFunc) {
					option['__ngLabel'] = this.bindLabelFunc(x);
				}
				if (this.bindOptionFunc) {
					option['__ngOption'] = this.bindOptionFunc(x);
				}
				return option;
			});
		}
		this._items = items;
	}

	get items() {
		return this._items;
	}

	get notEmpty() {
		return !this.hideEmpty || this.items.length > 0;
	}

	@Input() bindLabel: string;
	@Input() bindLabelFunc: (item: TItem) => string;
	@Input() bindOption: string;
	@Input() bindOptionFunc: (item: TItem) => string;
	@Input() bindValue: string;
	@Input() multiple = false;
	@Input() groupBy: string | ((x: TItem) => string);
	@Input() selectGroups = false;
	@Input() searchFn: (query: string, ses: TItem) => boolean;
	@Input() clearable = true;
	@Input() clearSearchOnAdd = false;
	@Input() hideEmpty = false;
	@Input() appendTo: string | null = null;

	_disabled = false;
	@Input() set disabled(disabled: boolean) {
		this._disabled = disabled;
		if (!this.control || disabled == null) {
			return;
		}
		if (this.disabled) {
			this.control.disable();
		} else {
			this.control.enable();
		}
	}

	@HostBinding('attr.data-testid')
	readonly attributeDataTestid = 'co-select';

	get disabled() {
		return this._disabled;
	}

	get virtualScroll() {
		return this._items?.length > 500;
	}

	loadFormNode(node: FormNode) {
		super.loadFormNode(node);

		if (!(node instanceof FormSelectNode)) {
			return;
		}

		if (node.bindLabel && node.bindLabel instanceof Function) {
			this.bindLabel = '__ngLabel';
			this.bindLabelFunc = node.bindLabel;
		} else if (node.bindLabel) {
			this.bindLabel = node.bindLabel as string;
		}

		if (node.bindOption && node.bindOption instanceof Function) {
			this.bindOption = '__ngOption';
			this.bindOptionFunc = node.bindOption;
		} else if (node.bindOption) {
			this.bindOption = node.bindOption as string;
		}

		this.bindValue = node.bindValue ?? this.bindValue;
		this.multiple = node.multiple ?? this.multiple;
		this.groupBy = node.groupProp ?? this.groupBy;
		this.selectGroups = node.selectGroups ?? this.selectGroups;
		this.searchFn = node.searchFn ?? this.searchFn;
		this.clearable = node.clearable ?? this.clearable;
		this.hideEmpty = node.hideWhenEmpty ?? this.hideEmpty;

		if (node.items$) {
			this.valueSubs.add(node.items$.subscribe(i => (this.items = i)));
		} else {
			this.items = node.items;
		}

		//Temp hack to fix NgSelect rendering bug
		setTimeout(() => node.patchValue(cloneDeep(node.value)), 1000);
	}
}
