import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { A11yModule } from '@angular/cdk/a11y';

@Component({
	standalone: true,
	selector: 'co-appflow-live-update-no-update-available-modal',
	templateUrl: './appflow-live-update-no-update-available-modal.component.html',
	imports: [MatDialogModule, MatButtonModule, A11yModule, FontAwesomeModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppflowLiveUpdateNoUpdateAvailableModalComponent {
	readonly infoIcon = faInfoCircle;
}
