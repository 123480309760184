/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InputBaseComponent } from '@lib/forms';
import { brandIconSearcher, iconSearcher } from '@shared/models';
import { BehaviorSubject, asyncScheduler, throttleTime } from 'rxjs';
import { harmonicaAnimation } from '@consensus/co/ui-component-animations';
import { SearchInputComponent } from '../search-input/search-input.component';
import { CoTrackByFunction } from '@consensus/co/util-control-flow';

@Component({
	standalone: true,
	selector: 'co-form-icon-search',
	imports: [
		NgFor,
		NgIf,
		MatButtonModule,
		MatRippleModule,
		MatSelectModule,
		MatTooltipModule,
		SearchInputComponent,
	],
	templateUrl: './icon-search.component.html',
	styleUrls: ['./icon-search.component.scss'],
	animations: [harmonicaAnimation()],
})
export class IconSearchFormComponent
	extends InputBaseComponent<string>
	implements OnInit
{
	iconWeight = 'far';
	icons: string[] = [];
	moreIcons = false;
	query$ = new BehaviorSubject('');

	ngOnInit() {
		super.ngOnInit();
		this.valueSubs.add(
			this.query$
				.pipe(throttleTime(200, asyncScheduler, { trailing: true }))
				.subscribe(q => this.search(q))
		);
	}

	search(query: string) {
		if (!query) {
			this.icons = [];
			return;
		}
		const raw =
			this.iconWeight === 'fab'
				? brandIconSearcher.search(query).map(result => result.item)
				: iconSearcher.search(query).map(result => result.item);

		this.moreIcons = raw.length > 127;
		this.icons = this.moreIcons ? raw.slice(0, 127) : raw;
	}

	preprocessValue(value: string) {
		if (!value) {
			return null;
		}

		const match = value.match(/^(\w{3})\s/);
		if (match) {
			this.iconWeight = match[1];
			return value.replace(match[0], '').replace(/^fa-/, '');
		}

		return null;
	}

	postprocessValue(value) {
		if (!value) {
			return null;
		}
		return `${this.iconWeight} fa-${value}`;
	}

	setWeight(weight: string) {
		const oldWeight = this.iconWeight;
		this.iconWeight = weight;

		if ((oldWeight === 'fab') !== (weight === 'fab')) {
			this.inputValue = null;
			this.search(this.query$.value);
		} else {
			// eslint-disable-next-line no-self-assign -- this will trigger a set value
			this.inputValue = this.inputValue;
		}
	}

	selectIcon(icon: string) {
		this.inputValue = icon;
		this.query$.next('');
	}

	readonly trackByIdentity: CoTrackByFunction<unknown> = (
		_index: number,
		value: unknown
	) => value;
}
