import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { TokenModel } from '@shared/models';
import { Observable, map } from 'rxjs';
import { environmentToken } from '@environments/environment';

@Injectable({
	providedIn: 'root',
})
export class PageEditorClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #http = inject(HttpClient);

	createPageFile(pageId: string): Observable<{ id: string; pageId: string }> {
		return this.#http.post<{ id: string; pageId: string }>(
			`${this.#apiServer}/api/pageEditor/pages/${pageId}/files`,
			{}
		);
	}

	getPageFileToken(id: string): Observable<string> {
		return this.#http
			.get<TokenModel>(
				`${this.#apiServer}/api/pageEditor/pages/files/${id}/token`
			)
			.pipe(map(x => x.token));
	}
}
