import { SessionItemState } from './session-item.reducer';
import { selectSessionItemState } from './session-item.selectors';
import {
	loadSessionItems,
	loadWordCloudUserSubmissions,
	submitWordCloudWord,
} from './session-item.actions';
import { Actions, createEffect } from '@ngrx/effects';
import { ApiEffects } from '@lib/redux';
import { inject, Injectable } from '@angular/core';
import { WordCloudClient } from '@consensus/connect/ufa/word-cloud/data-access';
import { UfaPushoutClient } from '@consensus/connect/ufa/pushout/data-access';

@Injectable()
export class SessionItemEffects extends ApiEffects<SessionItemState> {
	readonly #wordCloudClient = inject(WordCloudClient);
	readonly #actions$ = inject(Actions);
	readonly #client = inject(UfaPushoutClient);
	constructor() {
		super(selectSessionItemState);
	}

	loadPushouts$ = createEffect(() =>
		this.generateApiEffect(loadSessionItems, () =>
			this.#client.loadSessionItems({ includeHiddenFromSidepanel: true })
		)(this.#actions$)
	);

	loadWordCloudSubmissions$ = createEffect(() =>
		this.generateApiEffect(loadWordCloudUserSubmissions, action =>
			this.#wordCloudClient.getWordCloudUserSubmissions(action.payload)
		)(this.#actions$)
	);

	submitWordCloudWord$ = createEffect(() =>
		this.generateApiEffect(submitWordCloudWord, action =>
			this.#wordCloudClient.submitWordCloudWord(action.payload)
		)(this.#actions$)
	);
}
