import { APP_INITIALIZER, FactoryProvider, isDevMode } from '@angular/core';
import { ConnectEnvironment } from '@environments/environment';
import { environmentToken } from '@environments/environment';
import { ConnectVersion, versionToken } from '@environments/environment';
import { CoPostHogWrapper, posthogToken } from '../posthog/posthog.token';
import { SentryAngular, sentryToken } from './sentry.token';

function initializeSentryFactory(
	sentry: SentryAngular,
	posthog: CoPostHogWrapper,
	environment: ConnectEnvironment,
	connectVersion: ConnectVersion
): () => void {
	const isProductionMode = !isDevMode();
	const {
		posthog: { capturePolicy },
		sentry: sentryConfig,
	} = environment;

	return () => {
		sentry.init({
			enabled: (!connectVersion.isPrerelease && sentryConfig.enabled) ?? false,
			dsn: sentryConfig.dsn ?? undefined,
			release: connectVersion.version,
			environment: sentryConfig.environment,
			maxValueLength: 10000,
			beforeSend(event, _hint) {
				if (connectVersion.isPrerelease) {
					/** If build is a prerelease for staging or similar, do not submit error to Sentry */
					return null; // Returning null in beforeSend drops the event and does not submit it to Sentry
				}

				if (sentryConfig.showErrorDialog && event.exception) {
					sentry.showReportDialog({ eventId: event.event_id });
				}

				return event;
			},
			integrations(integrations) {
				// integrations will be all default integrations
				integrations = integrations.filter(
					i => isProductionMode || i.name !== 'Breadcrumbs'
					// https://github.com/getsentry/sentry-javascript/issues/1003#issuecomment-380310913
				);
				if (capturePolicy !== 'DoNotCapture') {
					/**
					 * The PostHog/Sentry integration correlates error data with product metrics, by:
					 *   - Adding a direct link in Sentry to the profile of the person affected in PostHog.
					 *   - Sending an $exception event to PostHog with a direct link to Sentry.
					 */
					if (!posthog.instance) {
						return integrations;
					}
					const sentryIntegrationFactory = posthog.instance?.SentryIntegration;
					const posthogIntegration = new sentryIntegrationFactory(
						posthog.instance,
						sentryConfig.organizationId ?? undefined,
						sentryConfig.projectId ?? undefined
					);

					integrations = [...integrations, posthogIntegration];
				}

				return integrations;
			},
		});
	};
}

export const sentryInitializer: FactoryProvider = {
	provide: APP_INITIALIZER,
	multi: true,
	deps: [sentryToken, posthogToken, environmentToken, versionToken],
	useFactory: initializeSentryFactory,
};
