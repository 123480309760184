import { CustomStore } from '@lib/redux';
import { LivestreamClient } from './livestream.client';
import { AdminLivestreamState } from './livestream.model';

export const livestreamKey = 'livestreams';

export const livestreamAdminStore = new CustomStore<
	AdminLivestreamState,
	LivestreamClient
>('Admin Livestreams', { livestreams: [] }, LivestreamClient, 'Event');

const store = livestreamAdminStore;

export const loadAdminLivestreams = store
	.addApiAction('Livestreams', 'Load')
	.withError()
	.isInitial()
	.parseDates()
	.withEffect(s => s.loadAdminLivestreams)
	.withReducer(livestreams => ({ livestreams }));

export const createLivestream = store
	.addApiAction('Livestream', 'Create')
	.withError()
	.parseDates()
	.withEffect(s => s.createLivestream)
	.addition('livestreams');

export const updateLivestream = store
	.addApiAction('Livestream', 'Update')
	.withError()
	.parseDates()
	.withEffect(s => s.updateLivestream)
	.update('livestreams');

export const moveLivestream = store
	.addApiAction('Livestream', 'Move')
	.withError()
	.withDelayedEffect(s => s.moveLivestream)
	.move('livestreams', x => x.id);

export const deleteLivestream = store
	.addApiAction('Livestream', 'Delete')
	.withError()
	.withDelayedEffect(s => s.deleteLivestream)
	.deleteWithIndex('livestreams', x => x.id);

export const toggleLivestream = store
	.addApiAction('Livestream', 'Toggle')
	.withError()
	.parseDates()
	.withEffect(s => s.toggleLivestream)
	.update('livestreams');

export const getAdminLivestreams = store.addSelector(x => x.livestreams);
