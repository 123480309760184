/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
	selector: 'co-create-box',
	templateUrl: './create-box.component.html',
	styleUrls: ['./create-box.component.scss'],
	standalone: true,
	imports: [NgIf, FormsModule],
})
export class CreateBoxComponent {
	@ViewChild('insertInput') insertInput: ElementRef;

	@Input() buttonText: string;
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() submit = new EventEmitter<string>();
	@Input() disabled = false;

	showInsert = false;
	text = '';

	toggleInsert() {
		if (this.showInsert) {
			return;
		}
		this.showInsert = true;
		setTimeout(() => this.insertInput.nativeElement.focus(), 0);
	}

	onSubmit() {
		this.submit.emit(this.text);
		this.insertInput.nativeElement.blur();
		this.text = '';
	}
}
