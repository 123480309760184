import { Injectable } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {
	MatTableDataSource,
	MatTableDataSourcePaginator,
} from '@angular/material/table';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, pipe, tap, withLatestFrom } from 'rxjs';

interface MatTableState<TData, TPaginator extends MatTableDataSourcePaginator> {
	readonly dataSource: MatTableDataSource<TData, TPaginator>;
}

/**
 * Create a {@link MatTableDataSource}. Support RxJS observables to configure
 * and update it.
 */
@Injectable()
export class MatTableStore<
	TData,
	TPaginator extends MatTableDataSourcePaginator = MatTableDataSourcePaginator
> extends ComponentStore<MatTableState<TData, TPaginator>> {
	readonly dataSource$: Observable<
		MatTableDataSource<TData, MatTableDataSourcePaginator>
	> = this.select(state => state.dataSource);

	constructor() {
		/**
		 * @remarks The initial state is used as an objects literal instead of a
		 *   constant to support type parameters.
		 */
		super({
			dataSource: new MatTableDataSource<TData, TPaginator>(),
		});
	}

	readonly updateData = this.effect<readonly TData[]>(
		pipe(
			withLatestFrom(this.dataSource$),
			tap(([data, dataSource]) => (dataSource.data = [...data]))
		)
	);

	readonly updateFilter = this.effect<MatTableDataSource<TData>['filter']>(
		pipe(
			withLatestFrom(this.dataSource$),
			tap(([filter, dataSource]) => (dataSource.filter = filter))
		)
	);

	readonly updateFilterPredicate = this.effect<
		MatTableDataSource<TData>['filterPredicate']
	>(
		pipe(
			withLatestFrom(this.dataSource$),
			tap(
				([filterPredicate, dataSource]) =>
					(dataSource.filterPredicate = filterPredicate)
			)
		)
	);

	readonly updatePaginator = this.effect<TPaginator | null>(
		pipe(
			withLatestFrom(this.dataSource$),
			tap(([paginator, dataSource]) => (dataSource.paginator = paginator))
		)
	);

	readonly updateSort = this.effect<MatSort | null>(
		pipe(
			withLatestFrom(this.dataSource$),
			tap(([sort, dataSource]) => (dataSource.sort = sort))
		)
	);

	readonly updateSortData = this.effect<MatTableDataSource<TData>['sortData']>(
		pipe(
			withLatestFrom(this.dataSource$),
			tap(([sortData, dataSource]) => (dataSource.sortData = sortData))
		)
	);

	readonly updateSortingDataAccessor = this.effect<
		MatTableDataSource<TData>['sortingDataAccessor']
	>(
		pipe(
			withLatestFrom(this.dataSource$),
			tap(
				([sortingDataAccessor, dataSource]) =>
					(dataSource.sortingDataAccessor = sortingDataAccessor)
			)
		)
	);
}
