import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EventUsersState } from './ufa-event-users.reducer';
import { keyBy } from 'lodash-es';
import { sortAlphAsc } from '@lib/helpers';

export const usersKey = 'users';

export interface GlobalEventUsersState {
	[usersKey]: EventUsersState;
}

const selectEventUsersState = createSelector(
	createFeatureSelector<GlobalEventUsersState>('event'),
	state => state.users
);

export const selectAllEventUsers = createSelector(
	selectEventUsersState,
	state => state.users
);

export const selectAllEventUsersLookup = createSelector(
	selectAllEventUsers,
	x => keyBy(x, x => x.id)
);

export const selectAllPresentableEventUsers = createSelector(
	selectAllEventUsers,
	users =>
		users.slice().sort(sortAlphAsc(u => u.firstName?.toLocaleLowerCase() ?? ''))
);
