import { CustomStore } from '@lib/redux';
import { UsersClient } from './users.client';
import { groupBy, keyBy } from 'lodash-es';
import {
	createEventUser,
	deleteEventUser,
	updateEventUser,
} from './event-cms-users.store';
import { removeListItem, updateList, updateListItem } from '@lib/redux';
import { AdminUser, BaseState } from '@shared/models';
import { EventParticipant } from './client-users.model';
import { isNonNullish } from '@consensus/co/util-types';

export interface ClientCmsUsersState extends BaseState {
	users: AdminUser[];
	eventParticipation: EventParticipant[];
}

export const clientCmsUsersStore = new CustomStore<
	ClientCmsUsersState,
	UsersClient
>(
	'Client CMS Users',
	{ users: [], eventParticipation: [] },
	UsersClient,
	'Client'
);

const store = clientCmsUsersStore;

export const loadClientCmsUsers = store
	.addApiAction('Users', 'Load')
	.isInitial()
	.withError()
	.withEffect(s => s.loadClientCmsUsers)
	.withReducer(users => ({ users }));

export const loadEventParticipants = store
	.addApiAction('Participants', 'Load')
	.configure({ initialLoad: true, parseDates: true })
	.withEffect(s => s.loadEventParticipants)
	.withReducer(eventParticipation => ({ eventParticipation }));

export const createClientUser = store
	.addApiAction('User', 'Create')
	.withError()
	.withEffect(s => s.createUser)
	.addition('users');

store
	.addSideEffect(createEventUser)
	.withReducer((user, { users }) => ({ users: [...users, user] }));

export const updateClientUser = store
	.addApiAction('User', 'Update')
	.withError()
	.withEffect(s => s.updateUser)
	.update('users');

store.addSideEffect(updateEventUser).withReducer((user, { users }) => ({
	users: updateListItem(users, x => x.id == user.id, user),
}));

export const deleteClientUser = store
	.addApiAction('User', 'Delete')
	.withError()
	.withDelayedEffect(s => s.deleteUser)
	.delete('users');

store.addSideEffect(deleteEventUser).withReducer((id, { users }) => ({
	users: removeListItem(users, x => x.id == id),
}));

export const addUserToEvent = store
	.addApiAction('User', 'Add to Event')
	.withError()
	.withEffect(s => s.addUserToEvent)
	.addition('eventParticipation');

export const removeUserFromEvent = store
	.addApiAction('User', 'Remove from Event')
	.withError()
	.withDelayedEffect(s => s.removeUserFromEvent)
	.withReducer(({ userId, eventId }, { eventParticipation }) => ({
		eventParticipation: removeListItem(
			eventParticipation,
			x => x.eventId == eventId && x.userId == userId
		),
	}));

export const createClientUserClaim = store
	.addApiAction('UserClaim', 'Create')
	.withError()
	.withEffect(s => s.createUserClaim)
	.withReducer((item, { users }) => ({
		users: updateListItem(
			users,
			x => x.id === item.userId,
			x => ({
				claims: [...x.claims, item],
			})
		),
	}));

export const updateClientUserClaim = store
	.addApiAction('UserClaim', 'Update')
	.withError()
	.withEffect(s => s.updateUserClaim)
	.withReducer((item, { users }) => ({
		users: updateListItem(
			users,
			x => x.id === item.userId,
			x => ({
				claims: updateListItem(x.claims, y => y.id === item.id, item),
			})
		),
	}));

export const deleteClientUserClaim = store
	.addApiAction('UserClaim', 'Delete')
	.withError()
	.withDelayedEffect(s => s.deleteUserClaim)
	.withReducer((id, { users }) => ({
		users: updateList(users, x => ({
			claims: removeListItem(x.claims, y => y.id === id),
		})),
	}));

// Selectors

export const getClientCmsUsers = store.addSelector(x => x.users);
export const getClientCmsUsersLookup = getClientCmsUsers.create(x =>
	keyBy(x, x => x.id)
);
export const getClientCmsUserClaimTypes = getClientCmsUsers.create(x => {
	const types = x.map(x => x.claims.map(c => c.type));
	const flattenedTypes = ([] as string[]).concat(...types);
	const distinctTypes = [...Array.from(new Set(flattenedTypes))];
	return distinctTypes;
});

export const getEventParticipants = store.addSelector(
	x => x.eventParticipation
);
export const getEventParticipantsEventLookup = getEventParticipants.create(x =>
	groupBy(x, y => y.eventId ?? '')
);
export const getEventParticipantsUserLookup = getEventParticipants.create(x =>
	groupBy(x, y => y.userId ?? '')
);
export const getUsersEvents = getEventParticipants.createWithParam(
	(events, userId: string) =>
		(events ?? [])
			.map(x => (x.userId == userId ? x.eventId : null))
			.filter(isNonNullish)
);
