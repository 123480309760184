<div
	*ngIf="!(loaded$ | ngrxPush) && !hideLoadingSpinner"
	class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center"
>
	<co-spinner />
</div>
<canvas
	#splinePlayerCanvas
	[class.tw-hidden]="!(loaded$ | ngrxPush)"
	[class.tw-opacity-100]="loaded$ | ngrxPush"
	class="tw-opacity-0 tw-transition-opacity tw-duration-500"
>
	<p>Not supported</p>
</canvas>
