import { inject, Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { AgendaClient } from './agenda.client';
import {
	activatePushout,
	activatePushoutResults,
	createAgenda,
	createSession,
	createSessionItem,
	createVote,
	deactivatePushout,
	deactivatePushoutResults,
	deleteAgenda,
	deleteSession,
	deleteSessionItem,
	duplicateSession,
	duplicateSessionItem,
	loadAgendas,
	loadSessionItemPushouts,
	moveAgenda,
	moveSession,
	moveSessionItem,
	patchSurveySectionFile,
	toggleSessionItem,
	updateAgenda,
	updateAgendaSessionsQaEnabled,
	updateConnected,
	updateFlashcardSet,
	updatePushoutLink,
	upsertSelfie,
	updateSession,
	updateSessionItem,
	updateSessionItemPushouts,
	updateVote,
	createWordCloud,
} from './agenda.actions';
import { ApiEffects } from '@lib/redux';
import { AgendaState } from './agenda.model';
import { selectAgendaState } from './agenda.selectors';
import { CmsSurveyClient } from '@consensus/connect/cms/survey/data-access';

@Injectable()
export class AgendaEffects extends ApiEffects<AgendaState> {
	readonly #surveyClient = inject(CmsSurveyClient);
	readonly #client = inject(AgendaClient);
	readonly #actions$ = inject(Actions);
	loadAgendas$ = createEffect(() =>
		this.generateApiEffect(loadAgendas, () => this.#client.loadAgendas())(
			this.#actions$
		)
	);

	createAgenda$ = createEffect(() =>
		this.generateApiEffect(createAgenda, action =>
			this.#client.createAgenda(action.payload)
		)(this.#actions$)
	);

	updateAgenda$ = createEffect(() =>
		this.generateApiEffect(updateAgenda, action =>
			this.#client.updateAgenda(action.payload)
		)(this.#actions$)
	);

	deleteAgenda$ = createEffect(() =>
		this.generateApiEffect(deleteAgenda, action =>
			this.#client.deleteAgenda(action.payload)
		)(this.#actions$)
	);

	swapAgendas$ = createEffect(() =>
		this.generateApiEffect(moveAgenda, action =>
			this.#client.moveAgenda(action.payload)
		)(this.#actions$)
	);

	createSession$ = createEffect(() =>
		this.generateApiEffect(createSession, action =>
			this.#client.createSession(action.payload)
		)(this.#actions$)
	);

	createSessionItem$ = createEffect(() =>
		this.generateApiEffect(createSessionItem, action =>
			this.#client.createSessionItem(action.payload)
		)(this.#actions$)
	);

	deleteSessionItem$ = createEffect(() =>
		this.generateApiEffect(deleteSessionItem, action =>
			this.#client.deleteSessionItem(action.payload)
		)(this.#actions$)
	);

	deleteSession$ = createEffect(() =>
		this.generateApiEffect(deleteSession, action =>
			this.#client.deleteSession(action.payload)
		)(this.#actions$)
	);

	updateSessionItem$ = createEffect(() =>
		this.generateApiEffect(updateSessionItem, action =>
			this.#client.updateSessionItem(action.payload)
		)(this.#actions$)
	);

	activatePushout$ = createEffect(() =>
		this.generateApiEffect(activatePushout, action =>
			this.#client.activatePushout(action.payload)
		)(this.#actions$)
	);

	deactivatePushout$ = createEffect(() =>
		this.generateApiEffect(deactivatePushout, action =>
			this.#client.deactivatePushout(action.payload)
		)(this.#actions$)
	);

	activatePushoutResults$ = createEffect(() =>
		this.generateApiEffect(activatePushoutResults, action =>
			this.#client.activatePushoutResults(action.payload)
		)(this.#actions$)
	);

	deactivatePushoutResults$ = createEffect(() =>
		this.generateApiEffect(deactivatePushoutResults, action =>
			this.#client.deactivatePushoutResults(action.payload)
		)(this.#actions$)
	);

	toggleSessionItem$ = createEffect(() =>
		this.generateApiEffect(toggleSessionItem, action =>
			this.#client.updateSessionItem(action.payload)
		)(this.#actions$)
	);

	duplicateSessionItem$ = createEffect(() =>
		this.generateApiEffect(duplicateSessionItem, action =>
			this.#client.duplicateSessionItem(action.payload)
		)(this.#actions$)
	);

	updateSession$ = createEffect(() =>
		this.generateApiEffect(updateSession, action =>
			this.#client.updateSession(action.payload)
		)(this.#actions$)
	);

	updateAgendaSessionsQaEnabled$ = createEffect(() =>
		this.generateApiEffect(updateAgendaSessionsQaEnabled, action =>
			this.#client.updateAgendaSessionsQaEnabled(action.payload)
		)(this.#actions$)
	);

	duplicateSession$ = createEffect(() =>
		this.generateApiEffect(duplicateSession, action =>
			this.#client.duplicateSession(action.payload)
		)(this.#actions$)
	);

	swapSessionItems$ = createEffect(() =>
		this.generateApiEffect(moveSessionItem, action =>
			this.#client.moveSessionItem(action.payload)
		)(this.#actions$)
	);

	swapSessions$ = createEffect(() =>
		this.generateApiEffect(moveSession, action =>
			this.#client.moveSession(action.payload)
		)(this.#actions$)
	);

	loadSessionItemPushouts$ = createEffect(() =>
		this.generateApiEffect(loadSessionItemPushouts, action =>
			this.#client.loadSessionItemPushouts(action.payload)
		)(this.#actions$)
	);

	updateVote$ = createEffect(() =>
		this.generateApiEffect(updateVote, action =>
			this.#client.updateVote(action.payload)
		)(this.#actions$)
	);

	createVote$ = createEffect(() =>
		this.generateApiEffect(createVote, action =>
			this.#client.createVote(action.payload)
		)(this.#actions$)
	);

	upsertSelfie$ = createEffect(() =>
		this.generateApiEffect(upsertSelfie, action =>
			this.#client.upsertSelfie(action.payload)
		)(this.#actions$)
	);

	updateFlashcardSet$ = createEffect(() =>
		this.generateApiEffect(updateFlashcardSet, action =>
			this.#client.updateFlashcardSet(action.payload)
		)(this.#actions$)
	);

	updateConnected$ = createEffect(() =>
		this.generateApiEffect(updateConnected, action =>
			this.#client.updateConnected(action.payload)
		)(this.#actions$)
	);

	updatePushoutLink$ = createEffect(() =>
		this.generateApiEffect(updatePushoutLink, action =>
			this.#client.updatePushoutLink(action.payload)
		)(this.#actions$)
	);

	updatePushout$ = createEffect(() =>
		this.generateApiEffect(updateSessionItemPushouts, action =>
			this.#client.updateSessionItemPushout(action.payload)
		)(this.#actions$)
	);

	createWordCloud$ = createEffect(() =>
		this.generateApiEffect(createWordCloud, action =>
			this.#client.createWordCloud(action.payload)
		)(this.#actions$)
	);

	patchSurveySectionFileType$ = createEffect(() =>
		this.generateApiEffect(patchSurveySectionFile, action =>
			this.#surveyClient.patchSectionFileType(action.payload)
		)(this.#actions$)
	);

	constructor() {
		super(selectAgendaState);
	}
}
