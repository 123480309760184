import { Directive, DoCheck, inject, OnDestroy, OnInit } from '@angular/core';
import {
	MemoizedSelector,
	MemoizedSelectorWithProps,
	Store,
} from '@ngrx/store';
import {
	BehaviorSubject,
	Observable,
	Subject,
	Subscriber,
	Subscription,
	first,
	skip,
} from 'rxjs';
import { forOwn, isEqual } from 'lodash-es';
import { ParameterSelector, Selector } from '@lib/redux';

/**
 * @deprecated ❌ `@shared/component-bases` is deprecated.
 * Do not extend this interface, as it will be removed in the future.
 */
@Directive()
export class BaseComponent implements OnInit, OnDestroy, DoCheck {
	readonly #store = inject(Store);
	_subscriptions = new Subscription();
	// eslint-disable-next-line @typescript-eslint/naming-convention
	__subs: Subscriber<any>[];
	// eslint-disable-next-line @typescript-eslint/naming-convention
	__init$: Subject<BaseComponent>;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	__destroy$: Subject<BaseComponent>;

	_initialized = false;

	#changeHooks: { [id: string]: DynamicProp[] } = {};
	#changeCache: { [id: string]: any } = {};

	#switchSubs: { [subId: string]: Subscription } = {};
	#switchSubValues: { [subId: string]: any } = {};

	/**
	 * @deprecated ❌ `@shared/component-bases` is deprecated.
	 * Implement the `OnInit` interface in a component that doesn't
	 * depend on `BaseComponent` instead.
	 */
	ngOnInit(): void {
		this._initialized = true;
		if (!this.__subs) {
			this.__subs = [];
		}
		if (!this.__init$) {
			this.__init$ = new Subject<BaseComponent>();
		}
		if (!this.__destroy$) {
			this.__destroy$ = new Subject<BaseComponent>();
		}

		this.__init$.next(this);
		this.onInit();
	}

	/**
	 * @deprecated ❌ `@shared/component-bases` is deprecated.
	 * Implement the `OnInit` interface in a component that doesn't
	 * depend on `BaseComponent` instead.
	 */
	onInit() {
		// Override this method in the sub-component to do something when the
		// component is being initialized
	}

	/**
	 * @deprecated ❌ `@shared/component-bases` is deprecated.
	 * Use the `@consensus/util-rxjs-destroy` injectable class with the `takeUntil`
	 * `OperatorFunction` or implement the `OnDestroy` interface in a component that
	 * doesn't depend on `BaseComponent` instead.
	 */
	ngOnDestroy(): void {
		if (!this._initialized) {
			console.warn(
				'This component was not initialized before being destroyed. Make sure you are not overriding the BaseComponent ngOnInit implementation:',
				this.constructor.name
			);
		}
		this._subscriptions.unsubscribe();

		forOwn(this.#switchSubs, s => {
			s.unsubscribe();
		});

		if (this.__subs) {
			this.__subs.forEach(s => s.unsubscribe());
			this.__subs.length = 0;
		}

		this.onDestroy();
		this.__destroy$?.next(this);
	}

	/**
	 * @deprecated ❌ `@shared/component-bases` is deprecated.
	 * Implement the `OnDestroy` interface in a component that doesn't
	 * depend on `BaseComponent` instead.
	 */
	onDestroy() {
		// Override this method in the sub-component to do something when the
		// component is being deactivated
	}

	/**
	 * @deprecated ❌ `@shared/component-bases` is deprecated.
	 * Implement the `DoCheck` interface in a component that doesn't
	 * depend on `BaseComponent` instead.
	 */
	ngDoCheck(): void {
		const updateSet = new Set<DynamicProp>();

		for (const key in this.#changeHooks) {
			if (!Object.prototype.hasOwnProperty.call(this.#changeHooks, key)) {
				continue;
			}
			if (!Object.prototype.hasOwnProperty.call(this, key)) {
				continue;
			}

			if (
				!Object.prototype.hasOwnProperty.call(this.#changeCache, key) ||
				this.#changeCache[key] !== this[key] ||
				(this.#changeCache[key] instanceof DynamicProp &&
					this[key] instanceof DynamicProp &&
					this.#changeCache[key].value !== this[key].value)
			) {
				this.#changeCache[key] = this[key];
				this.#changeHooks[key].forEach(x => updateSet.add(x));
			}
		}

		updateSet.forEach(x => x.update());
	}

	/**
	 * @deprecated ❌ `@shared/component-bases` is deprecated.
	 * Use the `select` method from `@ngrx/store` instead.
	 */
	dynamicProp<TKey extends keyof this, TVal>(
		func: () => TVal,
		...dependencies: (TKey | DynamicProp | Observable<any>)[]
	): DynamicProp<TVal> {
		const prop = new DynamicProp(func);
		dependencies.forEach(d => {
			if (d instanceof DynamicProp) {
				this.monitorValue(d.changed$, () => prop.update());
			} else if (d instanceof Observable) {
				this.monitorValue(d, () => prop.update());
			} else {
				if (!this.#changeHooks[d as string]) {
					this.#changeHooks[d as string] = [];
				}
				this.#changeHooks[d as string].push(prop);
			}
		});
		return prop;
	}

	/**
	 * @deprecated ❌ `@shared/component-bases` is deprecated.
	 * Use the `@consensus/util-rxjs-destroy` injectable class with the `takeUntil`
	 * `OperatorFunction` or maintain subscriptions in component instead.
	 */
	addSub(sub: Subscription) {
		this._subscriptions.add(sub);
	}

	/**
	 * @deprecated ❌ `@shared/component-bases` is deprecated.
	 * Use the `select` method from `@ngrx/store` instead.
	 */
	monitorStoreRaw<TFuncState, TVal>(
		// eslint-disable-next-line @ngrx/no-typed-global-store
		store: Store<any>,
		selector:
			| MemoizedSelector<TFuncState, TVal>
			| Selector<TFuncState, any, TVal>,
		applyFunc: (val: TVal) => void,
		onlyOnce = false
	) {
		if (selector instanceof Selector) {
			selector = selector.selector;
		}
		if (onlyOnce) {
			store.select(selector).pipe(first()).subscribe(applyFunc);
		} else {
			this.addSub(store.select(selector).subscribe(applyFunc));
		}
	}

	/**
	 * @deprecated ❌ `@shared/component-bases` is deprecated.
	 * Use the `select` method from `@ngrx/store` instead.
	 */
	monitorStore<TVal>(
		selector: MemoizedSelector<any, TVal> | Selector<any, any, TVal>,
		applyFunc: (val: TVal) => void,
		onlyOnce = false
	) {
		if (selector instanceof Selector) {
			selector = selector.selector;
		}
		if (onlyOnce) {
			this.#store.select(selector).pipe(first()).subscribe(applyFunc);
		} else {
			// eslint-disable-next-line @ngrx/no-store-subscription
			this.addSub(this.#store.select(selector).subscribe(applyFunc));
		}
	}

	/**
	 * @deprecated ❌ `@shared/component-bases` is deprecated.
	 * Use the `select` method from `@ngrx/store` and override subscription manually.
	 */
	monitorStoreSwitch<TPayload, TVal>(
		selector:
			| MemoizedSelectorWithProps<any, TPayload, TVal>
			| ParameterSelector<any, TVal, TPayload>,
		payload: TPayload,
		applyFunc: (val: TVal) => void,
		subId: string,
		onlyOnce = false
	) {
		if (selector instanceof ParameterSelector) {
			selector = selector.selector;
		}
		if (!onlyOnce) {
			if (isEqual(this.#switchSubValues[subId], payload)) {
				return;
			}
			this.#switchSubValues[subId] = payload;
		}
		this.monitorValueSwitch(
			this.#store.select(selector, payload),
			applyFunc,
			subId,
			onlyOnce
		);
	}

	/**
	 * @deprecated ❌ `@shared/component-bases` is deprecated.
	 * Subscribe to observable directly instead.
	 */
	monitorValue<TVal>(
		observable$: Observable<TVal>,
		applyFunc: (val: TVal) => void,
		onlyOnce = false
	) {
		if (!observable$) {
			return;
		}
		if (onlyOnce) {
			observable$.pipe(first()).subscribe(applyFunc);
		} else {
			this.addSub(observable$.subscribe(applyFunc));
		}
	}

	/**
	 * @deprecated ❌ `@shared/component-bases` is deprecated.
	 * Subscribe to observable directly instead and override subscription manually.
	 */
	monitorValueSwitch<TVal>(
		observable$: Observable<TVal>,
		applyFunc: (val: TVal) => void,
		subId: string,
		onlyOnce = false
	) {
		const sub = onlyOnce
			? observable$.pipe(first()).subscribe(applyFunc)
			: observable$.subscribe(applyFunc);
		if (this.#switchSubs[subId]) {
			this.#switchSubs[subId].unsubscribe();
		}
		this.#switchSubs[subId] = sub;
	}

	/**
	 * @deprecated ❌ `@shared/component-bases` is deprecated.
	 * Maintain subscriptions to observable manually instead.
	 */
	clearMonitorSwitch(subId: string) {
		if (this.#switchSubs[subId]) {
			this.#switchSubs[subId].unsubscribe();
		}
		delete this.#switchSubs[subId];
	}

	/**
	 * @deprecated ❌ `@shared/component-bases` is deprecated.
	 * Subscribe to observables manually instead.
	 */
	monitorValues<TVal1, TVal2>(
		observable1$: BehaviorSubject<TVal1>,
		observable2$: BehaviorSubject<TVal2>,
		applyFunc: (x: TVal1, y: TVal2, i?: number) => void
	) {
		applyFunc(observable1$.value, observable2$.value, 0);
		this.addSub(
			observable1$
				.pipe(skip(1))
				.subscribe(x => applyFunc(x, observable2$.value, 1))
		);
		this.addSub(
			observable2$
				.pipe(skip(1))
				.subscribe(y => applyFunc(observable1$.value, y, 2))
		);
	}
}

/**
 * @deprecated ❌ `@shared/component-bases` is deprecated.
 * Use the `select` method from `@ngrx/store` instead.
 */
export class DynamicProp<TVal = any> {
	get value() {
		return this.value$.value;
	}

	/**
	 * @deprecated ❌ `@shared/component-bases` is deprecated.
	 * Use the `select` method from `@ngrx/store` instead.
	 */
	value$ = new BehaviorSubject<TVal>(null);
	/**
	 * @deprecated ❌ `@shared/component-bases` is deprecated.
	 * Use the `select` method from `@ngrx/store` instead.
	 */
	changed$ = new Subject<void>();

	#afterUpdate: (val: TVal) => any;

	/**
	 * @deprecated ❌ `@shared/component-bases` is deprecated.
	 * Use the `select` method from `@ngrx/store` instead.
	 */
	constructor(private initFunction: () => TVal) {}

	/**
	 * @deprecated ❌ `@shared/component-bases` is deprecated.
	 * Use the `select` method from `@ngrx/store` instead.
	 */
	update() {
		const newValue = this.initFunction();
		const changed = this.value$.value != newValue;
		if (changed) {
			this.value$.next(newValue);
			this.changed$.next();
		}
		if (this.#afterUpdate) {
			this.#afterUpdate(this.value);
		}
	}

	/**
	 * @deprecated ❌ `@shared/component-bases` is deprecated.
	 * Use the `select` method from `@ngrx/store` instead.
	 */
	then(func: (val: TVal) => any) {
		this.#afterUpdate = func;
		return this;
	}
}
