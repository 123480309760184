import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
	standalone: true,
	name: 'coBypassSecurityTrustResourceUrl',
})
export class BypassSecurityTrustResourceUrlPipe implements PipeTransform {
	readonly #sanitizer = inject(DomSanitizer);

	/**
	 * Bypass security and trust the given value to be a safe resource URL, i.e. a
	 * location that may be used to load executable code from, like
	 * `<script src>`, or `<iframe src>`.
	 *
	 * @remark **WARNING**: Using this pipe with untrusted user data exposes your
	 * application to XSS security risks!
	 */
	transform(url: string): SafeResourceUrl;
	transform(url: null | undefined): null;
	transform(url: string | null | undefined): SafeResourceUrl | null {
		if (url == null) {
			return null;
		}

		return this.#sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
