import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
	ClientUserNotificationCount,
	ManagerNotificationRequestDto,
	UfaNotificationsQueryParams,
	UserNotificationQueryResponseDto,
	UserNotificationResponseDto,
} from './ufa-notifications-api-dto-types';
import { map, Observable } from 'rxjs';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class UfaNotificationsClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #http = inject(HttpClient);

	getNotification({
		eventId,
		userId,
		notificationId,
	}: {
		eventId: string;
		userId: string;
		notificationId: string;
	}) {
		return this.#http
			.get<UserNotificationResponseDto>(
				`${
					this.#apiServer
				}/api/events/${eventId}/user/${userId}/notifications/${notificationId}`
			)
			.pipe(map(notification => ({ ...notification, eventId: eventId })));
	}

	queryNotifications({
		clientId,
		userId,
		queryParams,
	}: {
		clientId: string;
		userId: string;
		queryParams: UfaNotificationsQueryParams;
	}) {
		const params = new HttpParams({
			fromObject: Object.fromEntries(
				// Remove null and undefined values
				Object.entries(queryParams).filter(
					([_key, value]) => value !== null && value !== undefined
				)
			),
		});
		return this.#http.get<UserNotificationQueryResponseDto>(
			`${this.#apiServer}/api/clients/${clientId}/user/${userId}/notifications`,
			{ params }
		);
	}

	markUserNotificationsAsViewed({
		eventId,
		userId,
		notificationIds,
	}: {
		eventId: string;
		userId: string;
		notificationIds: string[];
	}): Observable<boolean> {
		return this.#http
			.post<void>(
				`${
					this.#apiServer
				}/api/events/${eventId}/user/${userId}/notifications/mark-as-viewed`,
				{ notificationIds },
				{ observe: 'response' }
			)
			.pipe(map(response => response.status === 204));
	}

	markAllUserNotificationsAsViewed({
		clientId,
		eventIds,
		userId,
	}: {
		clientId: string;
		userId: string;
		eventIds: string[];
	}): Observable<boolean> {
		const params = // If no eventIds, all notifications on the client will be marked as viewed
			eventIds.length > 0
				? new HttpParams({ fromObject: { eventIds } })
				: new HttpParams();
		return this.#http
			.post<void>(
				`${
					this.#apiServer
				}/api/clients/${clientId}/user/${userId}/notifications/mark-all-as-viewed`,
				{},
				{ observe: 'response', params }
			)
			.pipe(map(response => response.status === 204));
	}

	getUnviewedUserNotificationCounts({
		clientId,
		userId,
		eventIds,
	}: {
		clientId: string;
		userId: string;
		eventIds: string[];
	}) {
		const params = new HttpParams({ fromObject: { eventIds } });
		return this.#http.get<ClientUserNotificationCount>(
			`${
				this.#apiServer
			}/api/clients/${clientId}/user/${userId}/notifications/unviewed-counts`,
			{ params }
		);
	}

	/** Endpoint for allowing managers to create notifications for users */
	createManagerNotification({
		eventId,
		data,
	}: {
		eventId: string;
		data: ManagerNotificationRequestDto;
	}) {
		return this.#http.post<UserNotificationResponseDto>(
			`${this.#apiServer}/api/events/${eventId}/notifications/manager-create`,
			data
		);
	}
}
