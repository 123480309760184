import { capacitorFileDownload } from '@consensus/connect/ufa/capacitor/util-file-download';
/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { DOCUMENT, NgIf } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	inject,
	Input,
	OnDestroy,
	Output,
	QueryList,
	ViewChildren,
} from '@angular/core';
import { FileCacheService } from '@consensus/shared/shared/files/data-access-files';
import {
	NgxExtendedPdfViewerComponent,
	NgxExtendedPdfViewerModule,
} from 'ngx-extended-pdf-viewer';
import { map, Observable, Subscription } from 'rxjs';
import { PdfService } from '@core/services';
import { BreakpointObserver } from '@angular/cdk/layout';
import { SessionService } from '@store/scope';
import { Capacitor } from '@capacitor/core';
import { HttpClient } from '@angular/common/http';
import { LetDirective } from '@ngrx/component';

@Component({
	standalone: true,
	selector: 'co-encoded-pdf',
	imports: [NgIf, NgxExtendedPdfViewerModule, LetDirective],
	templateUrl: './encoded-pdf.component.html',
	styleUrls: ['./encoded-pdf.component.scss'],
	// For now we will not enable OnPush, but instead just markForCheck whenever appropriate
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EncodedPdfComponent implements OnDestroy {
	readonly #sessionService = inject(SessionService);
	readonly #http = inject(HttpClient);
	readonly #breakpointObserver = inject(BreakpointObserver);
	readonly #changeDetectionRef = inject(ChangeDetectorRef);
	readonly #service = inject(PdfService);
	readonly #fileService = inject(FileCacheService);
	readonly #document = inject(DOCUMENT);
	#oldSrc: string;
	/** The PDF viewer has an issue showing the sidebar when the width is less than 840px, so we auto-hide it in those scenarios */
	readonly autohideSidebar$ = this.#breakpointObserver
		.observe('(max-width: 840px)')
		.pipe(map(result => result.matches));
	url: string;
	failed = false;
	loading = false;
	retry = 0;
	#reload = new Subscription();

	readonly active$: Observable<boolean> = this.#service.register();
	@Input() allowSharing = false;
	readonly isAdmin$ = this.#sessionService.isAdmin$;

	_src: string;
	@Input() set src(src: string) {
		if (src === this._src) {
			return;
		}

		this._src = src;
		this.retry = 3;
		this.#loadFile();

		this.#changeDetectionRef.markForCheck();
	}

	@Input() set driveFileId(id: string) {
		this.src = `materials/${id}`;
	}
	@Input() set attachment(id: string) {
		this.src = `attachment/${id}`;
	}
	@Input() set surveySection(id: string) {
		this.src = `survey-section/${id}`;
	}
	@Input() set academyResource([moduleId, resourceId]: [string, string]) {
		this.src = `academy/module/${moduleId}/resource/${resourceId}`;
	}
	@Input() initialSidebar = true;

	@Output() loaded = new EventEmitter<void>();

	@ViewChildren(NgxExtendedPdfViewerComponent, { read: ElementRef })
	viewer?: QueryList<ElementRef<HTMLElement>>;

	fullscreen = false;
	zoom: string | number = 'auto';

	readonly #presentationModeClickListener: EventListener = (event: Event) => {
		// We are repurposing the ngx-extended-pdf-viewer presentation mode button to make our
		// alternative version of fullscreen mode, so we are preventing the click event from
		// reaching the button and do our own fullscreen instead
		// We decided to keep the toolbar visible, so the presentation mode button will now work as a toggle
		event.preventDefault();
		event.stopPropagation();

		if (this.#document.fullscreenElement) {
			this.#document.exitFullscreen();
			this.fullscreen = false;
			this.zoom = 'auto';
		} else {
			this.viewer?.first?.nativeElement.requestFullscreen({
				navigationUI: 'hide',
			});
			this.fullscreen = true;
			this.zoom = 'page-fit';
		}
	};

	pdfLoaded() {
		const presentationButton: HTMLButtonElement | null =
			this.viewer?.first?.nativeElement.querySelector('#presentationMode') ??
			null;

		if (presentationButton) {
			presentationButton.addEventListener(
				'click',
				this.#presentationModeClickListener,
				{ capture: true }
			);
		}
	}

	ngOnDestroy() {
		this.#service.deregister(this.active$);

		const presentationButton: HTMLButtonElement | null =
			this.viewer?.first?.nativeElement.querySelector('#presentationMode') ??
			null;
		if (presentationButton) {
			presentationButton.removeEventListener(
				'click',
				this.#presentationModeClickListener,
				{ capture: true }
			);
		}
	}

	async #loadFile() {
		if (this._src === this.#oldSrc) {
			return;
		}

		if (!this._src) {
			this.#oldSrc = null;
			this.loading = false;
			this.failed = false;
			if (this.#reload) {
				this.#reload.unsubscribe();
			}
			this.#reload = null;

			this.#changeDetectionRef.markForCheck();

			return;
		}

		if (['http', 'blob'].includes(this._src.substring(0, 4).toLowerCase())) {
			this.url = this._src;
			this.failed = false;

			this.#changeDetectionRef.markForCheck();

			return;
		}

		this.loading = true;

		this.#changeDetectionRef.markForCheck();

		try {
			this.#oldSrc = this._src;
			this.url = await this.#fileService.getFileUrl(this._src);
			this.failed = false;
		} catch (e) {
			console.error(e);
			this.failed = true;
			this.#oldSrc = '';

			if (this.retry > 0) {
				this.retry--;
				setTimeout(() => {
					if (this.url) {
						return;
					}
					this.#loadFile();
				}, 5000);
			}
		} finally {
			this.loading = false;

			if (this.#reload) {
				this.#reload.unsubscribe();
			}
			this.#reload = this.#fileService.onReload(this._src, () => {
				this.#oldSrc = null;
				this.#loadFile();
			});

			this.#changeDetectionRef.markForCheck();
		}
	}

	onError(error: Error) {
		console.error('Failed to load PDF:', error);

		this.failed = true;
		this.#oldSrc = '';

		if (this.retry > 0) {
			this.retry--;
			setTimeout(() => {
				if (this.url) {
					return;
				}
				this.#loadFile();
			}, 5000);
		}

		this.#changeDetectionRef.markForCheck();
	}

	/**
	 * For Capacitor, the built-in download functionality of the PDF viewer does not work
	 *
	 * The built-in download functionality becomes a no-op, but we can listen for the pdfDownloaded event
	 * which is fired in any case and then download the file and pass it to the capacitorFileDownload handler
	 */
	capacitorDownloadHandler() {
		if (!Capacitor.isNativePlatform()) {
			return;
		}
		this.#http.get(this.url, { responseType: 'blob' }).subscribe(blob => {
			capacitorFileDownload({
				blob,
				filename: 'download.pdf', // We do not have a good way of getting the file name, so we settle for download.pdf
			});
		});
	}
}
