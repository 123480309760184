import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConnectSupportLoadDto } from '@consensus/connect/shared/support/domain';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class SupportClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #http = inject(HttpClient);

	getCurrentSupport() {
		return this.#http.get<ConnectSupportLoadDto>(
			`${this.#apiServer}/api/support`
		);
	}

	getSupport(supportId: string) {
		return this.#http.get<ConnectSupportLoadDto>(
			`${this.#apiServer}/api/support/${supportId}`
		);
	}
}
