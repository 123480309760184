<div class="wrapper" [@harmonica]="show">
	<input
		[matDatepicker]="datepicker"
		(dateChange)="setDate($event)"
		[ngModel]="date"
	/>
	<input [ngxMatTimepicker]="timepicker" [ngModel]="time" />

	<mat-form-field
		[class.has-no-label]="!hasLabel"
		[appearance]="appearance"
		[hideRequiredMarker]="hideRequired"
		[color]="color"
		[matTooltip]="tooltip"
		matTooltipPosition="above"
	>
		<mat-label>
			{{ label }}
			<i class="fad fa-question-circle" *ngIf="tooltip"></i>
		</mat-label>

		<input
			type="datetime-local"
			matInput
			[placeholder]="placeholder"
			[required]="required"
			[disabled]="isDisabled"
			[(ngModel)]="inputValue"
			[readonly]="true"
			#input
			(click)="datepicker.open()"
		/>

		<button
			mat-icon-button
			class="!tw-text-base"
			*ngIf="
				!isDisabled && !readonly && !required && modelValue;
				else openBtn
			"
			matIconSuffix
			(click)="value = null"
		>
			<i class="fad fa-x"></i>
		</button>

		<ng-template #openBtn>
			<mat-datepicker-toggle
				matIconSuffix
				[for]="datepicker"
				[disabled]="isDisabled || readonly"
			/>
		</ng-template>

		<mat-error *ngIf="hasErrors">{{ inputError }}</mat-error>
		<mat-hint *ngIf="hint">{{ hint }}</mat-hint>
	</mat-form-field>

	<mat-datepicker #datepicker [startAt]="now">
		<mat-datepicker-actions>
			<button mat-button matDatepickerCancel>Cancel</button>
			<button mat-raised-button color="primary" matDatepickerApply>
				Time
				<i class="fad fa-arrow-right"></i>
			</button>
		</mat-datepicker-actions>
	</mat-datepicker>
	<ngx-mat-timepicker
		#timepicker
		(timeChanged)="setTime($event)"
		[cancelBtnTmpl]="timePickerNoCancelBtnTmpl"
		[confirmBtnTmpl]="timePickerConfirmBtnTmpl"
	/>
	<ng-template #timePickerNoCancelBtnTmpl>
		<!-- We do not want a cancel button in the time picker, as it would only cancel time entry, not date entry -->
	</ng-template>
	<ng-template #timePickerConfirmBtnTmpl>
		<button mat-raised-button color="primary">OK</button>
	</ng-template>
</div>
