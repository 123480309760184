/** Locales used on the event-level to specify how dates and times should be formated */

export enum SupportedLocale {
	EnUs = 'en-US',
	EnGb = 'en-GB',
}

export const isSupportedLocale = (
	locale: string | null | undefined
): locale is SupportedLocale => {
	if (locale) {
		return Object.values(SupportedLocale)
			.map(member => member?.toString())
			.includes(locale);
	}
	return false;
};

const exampleDate = new Date(2000, 1, 1, 14, 0);

const createLocaleExampleStr = (locale: SupportedLocale) => {
	return new Intl.DateTimeFormat(locale, {
		dateStyle: 'short',
		timeStyle: 'short',
	}).format(exampleDate);
};

export const localeOptions: {
	id: SupportedLocale | null;
	displayName: string;
}[] = [
	{ id: null, displayName: "User's device locale" },
	{
		id: SupportedLocale.EnUs,
		displayName: `United States (en-US): ${createLocaleExampleStr(
			SupportedLocale.EnUs
		)}`,
	},
	{
		id: SupportedLocale.EnGb,
		displayName: `United Kingdom (en-GB): ${createLocaleExampleStr(
			SupportedLocale.EnGb
		)}`,
	},
];
