import { FileTypes } from '@consensus/shared/shared/files/domain';

export function getFileTypeName(type: FileTypes) {
	switch (type) {
		case FileTypes.Pdf:
			return 'PDF';
		case FileTypes.Image:
			return 'Image';
		case FileTypes.Video:
			return 'Video';
		case FileTypes.Audio:
			return 'Audio';
		case FileTypes.Powerpoint:
			return 'Powerpoint';
		case FileTypes.Word:
			return 'Word';
		case FileTypes.Excel:
			return 'Excel';
		case FileTypes.Zip:
			return 'ZIP File';
		case FileTypes.Other:
			return 'Unknown File';
		default:
			return 'Unknown File';
	}
}
