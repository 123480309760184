import { AcademyGlossaryEntry } from '@consensus/academy/courses/domain';
import { globalInjector } from '@lib/global-injector';
import {
	GlossarySelectInterface,
	GlossarySelectService,
} from '@consensus/academy/courses/ui';

const popupId = 'glossaryPlugin.popup';

export function glossaryPlugin(froalaEditor) {
	//Add config options
	froalaEditor.DEFAULTS = {
		...froalaEditor.DEFAULTS,
		allowGlossaryInsert: false,
		htmlAllowedTags: [...froalaEditor.DEFAULTS.htmlAllowedTags, 'glossary'],
	};

	froalaEditor.POPUP_TEMPLATES = {
		...froalaEditor.POPUP_TEMPLATES,
		[popupId]: '[_CONTENT_]',
	};

	froalaEditor.DefineIcon('insertGlossary', {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		NAME: 'book',
		template: 'font_awesome_5',
	});
	froalaEditor.RegisterCommand('insertGlossary', {
		title: 'Insert Glossary',
		undo: true,
		focus: true,
		showOnMobile: true,
		refreshAfterCallback: true,
		callback: function () {
			this.glossary.startInsert();
		},
		refresh: function (btn) {
			this.glossary.updateButton(btn);
		},
	});

	froalaEditor.PLUGINS.glossary = GlossaryPlugin;
}

class GlossaryPlugin {
	enabled = false;

	service: GlossarySelectService;
	selectInterface: GlossarySelectInterface;

	constructor(private editor: any) {}

	/**
	 * @remarks Intentionally public Froala hook, do not convert to ECMAScript
	 *   private method.
	 */
	_init() {
		this.enabled = this.editor.opts.allowGlossaryInsert;
		if (!this.enabled) {
			return;
		}
		this.service = globalInjector.get(GlossarySelectService);
	}

	updateButton(btn) {
		const multiSelect =
			this.editor.selection.element() !== this.editor.selection.endElement();
		btn.toggleClass('fr-disabled', multiSelect);

		const inGlossary =
			!multiSelect && this.editor.selection.element().tagName === 'GLOSSARY';
		btn.toggleClass('fr-active', inGlossary);
	}

	startInsert() {
		if (!this.enabled) {
			return;
		}

		const multiSelect =
			this.editor.selection.element() !== this.editor.selection.endElement();

		if (multiSelect) {
			return;
		}

		const inGlossary = this.editor.selection.element().tagName === 'GLOSSARY';

		if (inGlossary) {
			if (
				this.editor.selection.get().isCollapsed &&
				this.editor.selection.info(this.editor.selection.element()).atEnd
			) {
				this.editor.selection.setAfter(this.editor.selection.endElement());
				this.editor.selection.restore();
				setTimeout(() => this.editor.html.insert(' '));
				return;
			}

			this.editor.selection.element().outerHTML =
				this.editor.selection.element().innerHTML;

			return;
		}

		const selection = this.editor.selection.text();

		this.editor.selection.save();

		this.selectInterface = this.service.openSelect(
			selection,
			entry => this.insert(entry),
			() => {
				this.editor.selection.restore();
				this.selectInterface = null;
			}
		);
	}

	closeModal() {
		this.selectInterface?.close();
	}

	insert(glossary: AcademyGlossaryEntry) {
		if (!this.enabled) {
			return;
		}
		this.closeModal();

		this.editor.selection.restore();

		const text = this.editor.selection.text();
		const trimmed = text.trim();
		const addSpace = !text.length || text.length !== trimmed.length;

		setTimeout(() =>
			this.editor.html.insert(
				`<glossary data-id="${glossary.id}">${
					trimmed || glossary.name.trim()
				}</glossary>${addSpace ? `&nbsp` : ''}`
			)
		);
	}

	showEdit() {
		if (!this.enabled) {
			return;
		}

		const popup = this.editor.popups.get(popupId);
		if (!popup) {
			new GlossaryPopup(this.editor);
		}

		this.editor.popups.setContainer(popupId, this.editor.$sc);
		this.editor.popups.refresh(popupId);

		this.editor.popups.show(popupId, 0, 0, 0);
	}
}

// TODO: Add ability to remove glossary (without deleting)
// TODO: Add ability to change glossary styling (specific list)
class GlossaryPopup {
	constructor(private editor: any) {
		this.editor.popups.create(popupId, {
			content: `<div class="custom-layer">Hello World!</div>`,
		});

		this.editor.popups.onRefresh(popupId, () => this.onRefresh());
		this.editor.popups.onHide(popupId, () => this.onHide());
	}

	onHide() {
		console.log('hide');
	}

	onRefresh() {
		console.log('refresh');
	}
}
