import { CustomStore } from '@lib/redux';
import { PushoutResultsState } from './pushout-results.model';
import { PushoutResultClient } from './pushout-results.client';
import { removeListItem } from '@lib/redux';
import { PushoutResult } from '@consensus/connect/shared/pushout-results/domain';

export const pushoutResultsKey = 'pushoutResults';

export const pushoutResultsStore = new CustomStore<
	PushoutResultsState,
	PushoutResultClient
>(
	'Pushout Results',
	{
		pushoutResults: [],
	},
	PushoutResultClient,
	'Event'
);

const store = pushoutResultsStore;

export const loadPushoutResults = store
	.addApiAction('Pushout Results', 'Load')
	.isInitial()
	.withError()
	.withEffect(s => s.loadUserPushoutResults)
	.withReducer(pushoutResults => ({ pushoutResults }));

export const addPushoutResults = store
	.addSocketAction<PushoutResult>('Pushout', 'Results', 'Add')
	.withReducer((pushoutResult, { pushoutResults }) => {
		return {
			pushoutResults: [
				pushoutResult,
				...removeListItem(pushoutResults, p => p.id === pushoutResult.id),
			],
		};
	});

export const removePushoutResults = store
	.addSocketAction<string>('Pushout', 'Results', 'Remove')
	.delete('pushoutResults');

export const getActivePushoutResults = store.baseSelector.create(
	state => state.pushoutResults
);
