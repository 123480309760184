<div class="wrapper" [@harmonica]="show">
	<mat-form-field
		[class.has-no-label]="!hasLabel"
		[appearance]="appearance"
		[hideRequiredMarker]="hideRequired"
		[color]="color"
		[matTooltip]="tooltip"
		matTooltipPosition="above"
	>
		<mat-label>
			{{ label }}
			<i class="fad fa-question-circle" *ngIf="tooltip"></i>
		</mat-label>

		<div class="color" matIconPrefix>
			<div class="fill" [style]="colorStyle"></div>
		</div>

		<input
			matInput
			[placeholder]="placeholder"
			[required]="required"
			[disabled]="isDisabled"
			[ngModel]="inputValue"
			(ngModelChange)="inputValue = $event"
			[autofocus]="autofocus"
			[autocomplete]="autocomplete"
			[readonly]="readonly"
			#input
		/>

		<div
			class="color-drop"
			matIconSuffix
			(clickOutside)="showSwatch = false"
			clickOutsideEvents="click,touchstart"
		>
			<button
				mat-icon-button
				type="button"
				class="color-picker"
				(click)="showSwatch = !showSwatch"
				coNoClickBubble
			>
				<i class="far fa-palette"></i>
			</button>
			<color-github
				[hidden]="!showSwatch"
				(onChangeComplete)="
					setValue($event.color.hex); showSwatch = false
				"
				triangle="top-right"
			/>
		</div>

		<mat-error *ngIf="hasErrors">{{ inputError }}</mat-error>
		<mat-hint *ngIf="hint">{{ hint }}</mat-hint>
	</mat-form-field>

	<div class="options">
		<label *ngIf="showOpacity"
			>Opacity
			<mat-slider color="accent" [min]="0" [max]="255">
				<input
					matSliderThumb
					[value]="opacity"
					(valueChange)="setOpacity($event)"
				/>
			</mat-slider>
		</label>
		<label *ngIf="showBlur"
			>Blur
			<mat-slider color="accent" [min]="0" [max]="255">
				<input
					matSliderThumb
					[value]="blur"
					(valueChange)="setBlur($event)"
				/>
			</mat-slider>
		</label>
	</div>
</div>
