import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PageTheming, Theme } from './theming.model';
import { defaultTheme } from './theming.reducer';
import { ThemeCreateModel, ThemeUpdateModel } from './themes.model';
import { of } from 'rxjs';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class ThemingClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #httpClient = inject(HttpClient);

	getTheme(themeId: string) {
		if (!themeId) {
			return of(defaultTheme);
		}
		return this.#httpClient.get<Theme>(
			`${this.#apiServer}/api/theming/${themeId}`
		);
	}

	getCurrentTheme() {
		return this.#httpClient.get<Theme>(`${this.#apiServer}/api/theming`);
	}

	getThemes() {
		return this.#httpClient.get<Theme[]>(
			`${this.#apiServer}/api/theming/themes`
		);
	}

	createTheme(data: ThemeCreateModel) {
		return this.#httpClient.post<Theme>(
			`${this.#apiServer}/api/theming/themes`,
			data
		);
	}

	updateTheme({ id, ...data }: ThemeUpdateModel) {
		return this.#httpClient.put<Theme>(
			`${this.#apiServer}/api/theming/theme/${id}`,
			data
		);
	}

	getPageTheming() {
		return this.#httpClient.get<PageTheming[]>(
			`${this.#apiServer}/api/theming/pages`
		);
	}

	updatePageTheming({ pageId, ...page }: PageTheming) {
		return this.#httpClient.put<PageTheming>(
			`${this.#apiServer}/api/theming/page/${pageId}`,
			page
		);
	}
}
