import { AuthedUser } from '@shared/models';
import { PermissionEnum } from '@consensus/connect/shared/access-control/domain';

export interface AuthResponse {
	token: string;
	callbackUrl: string;
	expiration: Date;
	temporary: boolean;
	tempAction: AuthAction;
	user: AuthedUser;
}

export enum AuthAction {
	ResetPassword = 'ResetPassword',
	AddEmail = 'AddEmail',
	AwaitingEmail = 'AwaitingEmail',
	ConfirmEmail = 'ConfirmEmail',
	AddPhoneNumber = 'AddPhoneNumber',
	ConfirmPhoneNumber = 'ConfirmPhoneNumber',
	TwoFactor = 'TwoFactor',
}

// Action models

export interface ChangePasswordModel {
	newPassword: string;
	newPasswordRepeat: string;
}

export interface ForgotPasswordModel {
	email: string;
	clientId: string;
}

export interface AddEmailModel {
	email: string;
}

export interface AddPhoneNumberModel {
	phoneNumber: string;
}

export interface VerifyPhoneModel {
	token: number;
}

export interface TwoFactorModel {
	token: number;
}

export interface WhatCanIDo {
	events: Record<string, Set<PermissionEnum>>;
	clients: Record<string, Set<PermissionEnum>>;
	global: Set<PermissionEnum>;
}

export interface WhatCanIDoResponseDto {
	events: Record<string, PermissionEnum[]>;
	clients: Record<string, PermissionEnum[]>;
	global: PermissionEnum[];
}
