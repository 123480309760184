import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { LoadUserAgendaModel } from './agenda.model';
import { environmentToken } from '@environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AgendaClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #http = inject(HttpClient);

	loadAgendasUser(): Observable<LoadUserAgendaModel> {
		return this.#http.get<LoadUserAgendaModel>(`${this.#apiServer}/api/agenda`);
	}
}
