import { Pipe, PipeTransform } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { getFileDuotoneIconDefinition } from '@lib/files';
import { FileTypes } from '@consensus/shared/shared/files/domain';
import { faFile } from '@fortawesome/pro-duotone-svg-icons';
@Pipe({
	standalone: true,
	name: 'coSharedFileTypeIcon',
	pure: true,
})
export class SharedFileTypeIconPipe implements PipeTransform {
	transform(fileType: FileTypes | null): IconDefinition {
		if (fileType) {
			return getFileDuotoneIconDefinition(fileType);
		} else {
			return faFile;
		}
	}
}
