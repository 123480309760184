import { sortBySortingKeyAsc } from '@lib/helpers';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { groupBy } from 'lodash-es';
import { ConnectSupportState } from '@consensus/connect/shared/support/domain';

export const supportKey = 'support';

interface GlobalState {
	[supportKey]: ConnectSupportState;
}

const selectGlobalState = createFeatureSelector<GlobalState>('global');

export const selectSupportState = createSelector(
	selectGlobalState,
	state => state.support
);

export const selectSupport = createSelector(selectSupportState, s => s.support);
export const selectSupportSections = createSelector(selectSupportState, s => {
	const sort = groupBy(
		[...s.faqs].sort(sortBySortingKeyAsc),
		x => x.supportSectionId ?? ''
	);
	return s.sections
		.map(sec => ({ ...sec, faqs: sort[sec.id] ?? [] }))
		.sort(sortBySortingKeyAsc);
});
