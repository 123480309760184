import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import {
	BehaviorSubject,
	catchError,
	filter,
	finalize,
	lastValueFrom,
	Observable,
	tap,
} from 'rxjs';
import { FileCacheService } from './file-cache.service';
import { SessionService } from '@store/scope';
import { dataUrlPngToFile } from '@lib/files';
import { UploadProgressService } from './upload-progress.service';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class MediaService {
	readonly #mediaServer = inject(environmentToken).mediaServer;
	readonly #progressService = inject(UploadProgressService);
	readonly #sessionService = inject(SessionService);
	readonly #fileService = inject(FileCacheService);
	readonly #httpClient = inject(HttpClient);

	#uploadFile$(
		url: string,
		file: File,
		uploadToken: string,
		thumb = false,
		loadUrlGen?: (data: UploadTokenData) => string,
		formFieldName?: string
	): [Observable<HttpEvent<unknown>>, BehaviorSubject<number>] {
		if (!uploadToken) {
			throw Error("You can't upload images without an upload token");
		}

		const progress = new BehaviorSubject<number>(10);

		const postData = new FormData();
		postData.append(formFieldName ?? 'file', file);

		const request = this.#httpClient
			.post<HttpEvent<unknown>>(
				this.#mediaServer +
					'/' +
					url.replace(/^\/+/, '') +
					(thumb ? '?thumbnail=1' : ''),
				postData,
				{
					headers: {
						uploadToken: uploadToken,
					},
					reportProgress: true,
					observe: 'events',
				}
			)
			.pipe(
				tap(event => {
					if (event.type === HttpEventType.UploadProgress) {
						progress.next(
							Math.round(10 + (70 * event.loaded) / (event.total || 0))
						);
					}
				}),
				catchError(error => {
					progress.error(error);
					throw error;
				}),
				finalize(() => progress.complete()),
				filter(event => event.type === HttpEventType.Response),
				tap(() => {
					progress.next(100);
					progress.complete();
					const tokenData = JSON.parse(atob(uploadToken.split('.')[1]));
					const loadUrl = loadUrlGen
						? loadUrlGen(tokenData)
						: url + '/' + tokenData.id;
					this.#fileService.refreshCache(
						loadUrl + (thumb ? '?thumbnail=1' : '')
					);
				})
			);

		this.#progressService.addFile(progress, file.name, file.size);

		return [request, progress];
	}

	uploadFile(
		url: string,
		file: File,
		uploadToken: string,
		thumb = false,
		loadUrlGen?: (data: UploadTokenData) => string,
		formFieldName?: string
	): [Promise<HttpEvent<unknown>>, BehaviorSubject<number>] {
		const [request, progress] = this.#uploadFile$(
			url,
			file,
			uploadToken,
			thumb,
			loadUrlGen,
			formFieldName
		);
		return [lastValueFrom(request), progress];
	}

	uploadAttachment(file: File, uploadToken: string, thumb = false) {
		return this.uploadFile(`attachment`, file, uploadToken, thumb);
	}

	uploadDashboard(file: File, uploadToken: string) {
		return this.uploadFile(`dashboard`, file, uploadToken);
	}

	uploadModularDashboard(file: File, uploadToken: string) {
		return this.uploadFile(`modular-dashboard`, file, uploadToken);
	}

	uploadCountdownCalendar(file: File, uploadToken: string) {
		return this.uploadFile(`countdown-calendar`, file, uploadToken);
	}

	uploadMaterial(file: File, uploadToken: string, thumb = false) {
		return this.uploadFile(`materials`, file, uploadToken, thumb);
	}

	uploadMaterialLearningPackage(
		file: File,
		uploadToken: string,
		postbackUrl: string,
		thumb = false
	) {
		return this.uploadFile(
			`materials/scorm?postbackUrl=${postbackUrl}`,
			file,
			uploadToken,
			thumb,
			undefined,
			'scormPackageZip'
		);
	}

	uploadSpeaker(file: File, uploadToken: string, thumb = false) {
		return this.uploadFile(`speakers`, file, uploadToken, thumb);
	}

	uploadPageBuilderFile(file: File, uploadToken: string) {
		return this.uploadFile(`page-builder`, file, uploadToken);
	}

	uploadSelfie(selfieId: string, file: File, uploadToken: string) {
		return this.uploadFile(`selfies/${selfieId}`, file, uploadToken);
	}

	uploadAcademyResource(file: File, uploadToken: string, thumb = false) {
		return this.uploadFile(
			`academy/module/resource`,
			file,
			uploadToken,
			thumb,
			({ id, parent }) => `academy/module/${parent}/resource/${id}`
		);
	}

	uploadAcademyLearningPackage(
		file: File,
		uploadToken: string,
		postbackUrl: string,
		thumb = false
	) {
		return this.uploadFile(
			`academy/modules/scorm?postbackUrl=${postbackUrl}`,
			file,
			uploadToken,
			thumb,
			undefined,
			'scormPackageZip'
		);
	}

	uploadAcademySignature(file: File, uploadToken: string) {
		return this.uploadFile(
			`academy/course/signature`,
			file,
			uploadToken,
			false,
			({ parent }) => `academy/course/${parent}/signature`
		);
	}

	uploadAcademyIcon(
		file: File,
		uploadToken: string
	): [Promise<unknown>, BehaviorSubject<number>] {
		return this.uploadFile(`academy/icon`, file, uploadToken);
	}

	uploadAcademyIcon$(
		file: File,
		uploadToken: string
	): Observable<HttpEvent<unknown>> {
		const [request] = this.#uploadFile$(`academy/icon`, file, uploadToken);
		return request;
	}

	uploadAcademyTrack(file: File, uploadToken: string) {
		return this.uploadFile(`academy/track`, file, uploadToken);
	}

	uploadCoachingExerciseFolder(file: File, uploadToken: string) {
		return this.uploadFile(
			`academy/coaching/exercise-folder`,
			file,
			uploadToken
		);
	}

	uploadCoachingExercise(file: File, uploadToken: string) {
		return this.uploadFile(`academy/coaching/exercise`, file, uploadToken);
	}

	uploadCoachingInstanceVideo(file: File, uploadToken: string) {
		return this.uploadFile(
			`academy/coaching/instance-video`,
			file,
			uploadToken
		);
	}

	uploadCoachingFeedback(file: File, uploadToken: string) {
		return this.uploadFile(`academy/coaching/feedback`, file, uploadToken);
	}

	uploadTheming(file: File, uploadToken: string, thumb = false) {
		return this.uploadFile(`theme`, file, uploadToken, thumb);
	}

	uploadSignature(dataUrl: string, uploadToken: string) {
		const file = dataUrlPngToFile(
			dataUrl,
			`${this.#sessionService.userName}_signature.png`
		);
		return this.uploadFile(`signatures`, file, uploadToken);
	}

	uploadProfile(file: File, uploadToken: string) {
		return this.uploadFile(`user`, file, uploadToken);
	}

	uploadSurveySectionFile(file: File, uploadToken: string) {
		return this.uploadFile(`survey-section`, file, uploadToken);
	}

	uploadSurveySectionItemFile(file: File, uploadToken: string) {
		return this.uploadFile(
			`events/${this.#sessionService.eventId}/survey-section-item`,
			file,
			uploadToken
		);
	}

	uploadBrainstormBackgroundImage(file: File, uploadToken: string) {
		return this.uploadFile(
			`events/${this.#sessionService.eventId}/brainstorms`,
			file,
			uploadToken
		);
	}

	uploadNotificationFile(file: File, uploadToken: string, thumb = false) {
		return this.uploadFile(`notification`, file, uploadToken, thumb);
	}

	uploadPageEditorFile(file: File, uploadToken: string) {
		return this.uploadFile(`pageeditor`, file, uploadToken);
	}

	uploadDiplomaBackgroundImage(file: File, uploadToken: string) {
		return this.uploadFile(`diploma/template-images`, file, uploadToken);
	}
}

export class UploadError extends Error {
	constructor(error: string) {
		super(error);
		this.error = { error: error };
	}

	error: { error: string };
}

export interface UploadTokenData {
	id: string;
	parent?: string;
}
