import { createActionGroup, emptyProps } from '@ngrx/store';
import { User } from '@shared/models';

export const ufaEventUsersActions = createActionGroup({
	source: 'UFA Event Users',
	events: {
		/* eslint-disable @typescript-eslint/naming-convention */
		'Load Event Users': emptyProps(),
		'Load Event Users Success': (users: User[]) => ({ users }),
		'Load Event Users Failure': (error: Error) => ({ error }),
		/* eslint-enable @typescript-eslint/naming-convention */
	},
});

export const ufaEventUsersWebsocketActions = createActionGroup({
	source: 'UFA Event User Websocket',
	events: {
		/* eslint-disable @typescript-eslint/naming-convention */
		'Set Event User': (user: User) => ({ user }),
		/* eslint-enable @typescript-eslint/naming-convention */
	},
});
