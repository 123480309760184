// TODO(@LayZeeDK): [no-restricted-globals] Rewrite this file at some point
/* eslint-disable no-restricted-globals */
import Hls from 'hls.js';
import { Livestream } from '@consensus/connect/ufa/livestream/data-access';
import { asyncScheduler, fromEvent, throttleTime } from 'rxjs';
import { Capacitor } from '@capacitor/core';

export class LivestreamPlayer {
	readonly videoElement: HTMLVideoElement;

	readonly #isSupported;
	readonly #usingNative;

	#livestream: Livestream;
	get livestream() {
		return this.#livestream;
	}

	#hls: Hls;

	#loading = false;
	#playing = false;
	#buffering = false;
	#hasStream = false;
	retries: number;

	get isLoading() {
		return this.#loading || this.#buffering;
	}

	get hasContent() {
		return this.#hasStream;
	}

	#error: string;

	get errorMessage() {
		return !this.#isSupported ? 'Livestreaming is not supported' : this.#error;
	}

	get isMuted() {
		return this.videoElement?.muted;
	}

	get isPlaying() {
		return !this.videoElement.paused && this.#playing && !this.#loading;
	}

	constructor() {
		this.videoElement = document.createElement('video');
		this.videoElement.autoplay = true;
		this.videoElement.controls = false;
		this.videoElement.setAttribute('playsinline', 'true');

		/* eslint-disable no-restricted-syntax */
		// no-restricted-syntax: Allow console.debug usage
		this.videoElement.onplaying = () => {
			this.#playing = true;
			this.#buffering = false;
			console.debug('Playing Livestream');
			if (Capacitor.isNativePlatform()) {
				if (this.isMuted) {
					this.toggleMute();

					setTimeout(() => {
						if (this.isMuted) {
							this.toggleMute();
						}
					}, 1000);
				}
			}
		};
		fromEvent(this.videoElement, 'timeupdate')
			.pipe(throttleTime(800, asyncScheduler, { trailing: true }))
			.subscribe(() => {
				this.#playing = true;
				this.#buffering = false;
				console.debug('Updating Livestream Time');
			});
		this.videoElement.onended = () => {
			this.#playing = false;
			console.debug('Livestream Ended');
		};
		this.videoElement.onpause = () => {
			this.#playing = false;
			console.debug('Livestream Paused');
			if (Capacitor.isNativePlatform()) {
				// ISSUE #5238: Remove once issue has been solved
				this.play();
				console.debug(
					'Livestream Resumed - Hotfix for Capacitor Video Issue in iOS 17'
				);
				setTimeout(() => {
					if (this.isMuted) {
						this.toggleMute();
					}
				}, 3000);
			}
		};
		this.videoElement.onwaiting = () => {
			this.#buffering = true;
			console.debug('Buffering Livestream');
		};
		this.videoElement.onerror = () => {
			this.#loadFailed(this.videoElement?.error?.message);
			console.debug('Livestream Load Failed');
		};
		/* eslint-enable no-restricted-syntax */

		if (Hls.isSupported()) {
			this.#isSupported = true;
		} else if (this.videoElement.canPlayType('application/vnd.apple.mpegurl')) {
			this.#usingNative = true;
			this.#isSupported = true;
			this.#setupMobileStream();
		} else {
			this.#isSupported = false;
		}
	}

	#setupMobileStream() {
		/* eslint-disable no-restricted-syntax */
		// no-restricted-syntax: Allow console.debug usage
		this.videoElement.oncanplay = () => {
			this.#loaded();
			console.debug('Can Play Livestream');
		};
		this.videoElement.onloadedmetadata = () => {
			console.debug('Loaded Livestream Metadata');
		};
		/* eslint-enable no-restricted-syntax */
	}

	#loaded() {
		if (!this.#loading) {
			return;
		}
		this.#loading = false;
		this.retries = 2;
		this.videoElement.play().catch(() => {
			this.toggleMute(true);
			this.videoElement.play();
		});
	}

	#loadFailed(error: string) {
		if (this.retries-- >= 0) {
			this.refresh();
			return;
		}
		this.#loading = false;
		this.#error = error ?? 'Failed to load stream';
		console.error(this.#error);
	}

	setup(livestream: Livestream) {
		const newSrc = this.#livestream?.livestreamUrl != livestream?.livestreamUrl;
		this.#livestream = livestream;

		if (newSrc) {
			this.retries = 2;
			this.#load(livestream?.livestreamUrl);
		}
	}

	#load(src: string) {
		if (!this.#isSupported) {
			return;
		}

		this.#loading = true;
		this.#error = null;

		if (this.#usingNative) {
			this.#hasStream = !!src;
			this.videoElement.src = null;
			this.videoElement.src = src;
		} else {
			if (src == null) {
				this.#hls?.destroy();
				this.#hasStream = false;
			} else {
				this.#startHls(src);
				this.#hasStream = true;
			}
		}
	}

	#startHls(url: string) {
		this.#hls?.destroy();

		this.#hls = new Hls({
			liveSyncDurationCount: 2,
			liveMaxLatencyDurationCount: 3,
			liveBackBufferLength: 30,
		});

		this.#hls.on(Hls.Events.MANIFEST_PARSED, () => {
			this.#loaded();
		});

		this.#hls.on(Hls.Events.ERROR, (_event, data) => {
			if (data.fatal) {
				switch (data.type) {
					case Hls.ErrorTypes.NETWORK_ERROR:
						console.warn('fatal network error encountered, try to recover');
						this.#hls.startLoad();
						break;
					case Hls.ErrorTypes.MEDIA_ERROR:
						console.warn('fatal media error encountered, try to recover');
						this.#hls.recoverMediaError();
						break;
					default:
						this.#hls?.destroy();
						this.#hls = null;
						this.#loadFailed(data.details);
						break;
				}
			}
		});

		this.#hls.attachMedia(this.videoElement);
		this.#hls.loadSource(url);
	}

	play() {
		if (this.videoElement.paused) {
			this.refresh();
		}
	}

	refresh() {
		this.#load(this.#livestream.livestreamUrl);
	}

	toggleMute(muted: boolean = null) {
		if (!this.videoElement) {
			return;
		}

		if (muted != null) {
			this.videoElement.muted = muted;
		} else {
			this.videoElement.muted = !this.videoElement.muted;
		}
	}
}
