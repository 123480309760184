import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCookie, faExternalLink } from '@fortawesome/pro-regular-svg-icons';

@Component({
	standalone: true,
	selector: 'co-analytics-snack',
	imports: [MatSnackBarModule, MatButtonModule, FontAwesomeModule],
	templateUrl: './co-analytics-snack.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoAnalyticsSnackComponent {
	readonly #snackbar: MatSnackBarRef<CoAnalyticsSnackComponent> =
		inject(MatSnackBarRef);
	readonly cookieIcon = faCookie;
	readonly linkIcon = faExternalLink;

	onConsentGrant() {
		this.#snackbar.dismissWithAction();
	}

	onConsentReject() {
		this.#snackbar.dismiss();
	}
}
