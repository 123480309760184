import {
	addSidepanelItem,
	hideSidepanelItem,
	hideSidepanelSection,
	loadSidepanel,
	moveSidepanelItem,
	moveSidepanelItemToSection,
	updateSidepanelItem,
} from './sidepanel.actions';
import { BaseState, initialBaseState } from '@shared/models';

import { ApiReducer } from '@lib/redux';
import {
	moveListItem,
	removeListItem,
	removeListItemWithIndex,
	updateListItem,
} from '@lib/redux';
import { sortBySortingKeyDesc } from '@lib/helpers';
import { SidepanelItem } from '@consensus/connect/shared/sidepanel/domain';

export interface GlobalSidepanelState {
	[sidepanelKey]: SidepanelState;
}

export interface SidepanelState extends BaseState {
	items: SidepanelItem[];
}

export const sidepanelKey = 'sidepanel';

const initialState: SidepanelState = {
	...initialBaseState,
	items: [],
};

const reducer = new ApiReducer(initialState, 'Event');

reducer.addApiReducer(loadSidepanel, items => ({ items }));

reducer.addReducer(updateSidepanelItem, (item, { items }) => ({
	items: updateListItem(items, x => x.id === item.id, item),
}));

reducer.addReducer(addSidepanelItem, (item, { items }) => ({
	items: updateListItem(items, x => x.id === item.id, item, true),
}));

reducer.addReducer(hideSidepanelItem, (id, state) => ({
	items: removeListItem(state.items, x => x.id === id),
}));

reducer.addReducer(moveSidepanelItem, ({ id, sortingKey }, { items }) => ({
	items: moveListItem(
		items,
		x => x.id === id,
		sortingKey,
		x => x.sectionId
	),
}));

reducer.addReducer(moveSidepanelItemToSection, ({ id, section }, { items }) => {
	const item = items.find(x => x.id === id);
	if (!item) {
		return {};
	}

	return {
		items: [
			...removeListItemWithIndex(
				items,
				x => x.id === id,
				x => x.sectionId
			),
			{ ...item, sectionId: section },
		],
	};
});

reducer.addReducer(hideSidepanelSection, (id, { items }) => {
	const temp = [...items].sort(sortBySortingKeyDesc);
	let index = temp.length > 0 ? temp[0].sortingKey : -1;
	return {
		sections: removeListItemWithIndex(
			items,
			s => s.id === id,
			s => s.sectionId
		),
		items: items.map(i => {
			if (i.sectionId !== id) {
				return i;
			}
			index++;
			return { ...i, index, sectionId: null };
		}),
	};
});

export const sidepanelReducer = reducer.getReducer();
