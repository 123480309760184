import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EventSessionItem } from '@consensus/connect/ufa/agenda/domain';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class UfaPushoutClient {
	readonly #httpClient = inject(HttpClient);
	readonly #apiServer = inject(environmentToken).server;

	loadSessionItems(
		props: {
			id?: string;
			includeHiddenFromSidepanel?: boolean;
			launchCode?: string;
		} = {}
	) {
		const params = new HttpParams({ fromObject: props });
		return this.#httpClient.get<EventSessionItem[]>(
			`${this.#apiServer}/api/pushout/old`,
			{
				params,
			}
		);
	}
}
