import { inject, Injectable } from '@angular/core';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { Preferences } from '@capacitor/preferences';
import { Capacitor } from '@capacitor/core';
import { HttpClient } from '@angular/common/http';
import {
	first,
	from,
	map,
	Observable,
	of,
	Subject,
	switchMap,
	throttleTime,
	catchError,
	tap,
} from 'rxjs';
import { sentryToken } from '@consensus/shared/shared/analytics/data-access';

enum PushDevicePermission {
	Granted = 'Granted',
	Denied = 'Denied',
	Error = 'Error',
}

export interface PushDeviceRegistration {
	permission: PushDevicePermission;
	deviceToken: string | null;
	userId: string | null;
	savedOnServer: boolean;
	error: unknown;
}

const PUSH_DEVICE_REGISTRATION_LOCAL_STORAGE_KEY = 'PUSH_DEVICE_REGISTRATION';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class PushNotificationRegistrationService {
	readonly #apiServer = inject(environmentToken).server;
	readonly #sentry = inject(sentryToken);
	readonly #httpClient = inject(HttpClient);
	readonly #registrationSubject = new Subject<PushDeviceRegistration>();
	readonly #registration$ = this.#registrationSubject.asObservable();

	/** Add listeners for events from the Capacitor push notifications plugin */
	setup(): void {
		if (!this.#pluginAvailable()) {
			console.warn(
				'Called setup for push notifications, but Capacitor plugin is not available'
			);
			return;
		}
		/** Setup flow for storing registration in local storage */
		this.#registration$.subscribe(registration =>
			this.#storeRegistration(registration)
		);
		/** Add listener for registration success */
		PushNotifications.addListener('registration', (token: Token) => {
			this.#registrationSubject.next({
				permission: PushDevicePermission.Granted,
				deviceToken: token.value,
				userId: null,
				savedOnServer: false,
				error: null,
			});
		});
		/** Add listener for registration error */
		PushNotifications.addListener('registrationError', (error: unknown) => {
			this.#registrationSubject.next({
				permission: PushDevicePermission.Error,
				deviceToken: null,
				userId: null,
				savedOnServer: false,
				error: error,
			});
			this.#sentry.captureMessage('pushNotification registrationError', {
				tags: {
					pushNotification: true,
				},
				extra: {
					error: JSON.stringify(error),
				},
			});
		});
	}

	/** Store the push device registration with the @capacitor/preferences plugin */
	#storeRegistration(registration: PushDeviceRegistration): Observable<void> {
		return from(
			Preferences.set({
				key: PUSH_DEVICE_REGISTRATION_LOCAL_STORAGE_KEY,
				value: JSON.stringify(registration),
			})
		);
	}

	/** Retrieve the push device registration from storage with the @capacitor/preferences plugin */
	#hydrateRegistration(): Observable<PushDeviceRegistration> {
		return from(
			Preferences.get({
				key: PUSH_DEVICE_REGISTRATION_LOCAL_STORAGE_KEY,
			})
		).pipe(
			map(({ value }) => {
				return value ? JSON.parse(value) : null;
			})
		);
	}

	/** Current Capacitor platform in the convention expected by the register endpoint */
	#getNotificationPlatform(): 'Ios' | 'Android' {
		const capacitorPlatformName = Capacitor.getPlatform();
		switch (capacitorPlatformName) {
			case 'ios': {
				return 'Ios';
			}
			case 'android': {
				return 'Android';
			}
			default: {
				throw new Error(
					`Unsupported Capacitor platform for Connect push notifications: ${capacitorPlatformName}`
				);
			}
		}
	}

	/**
	 * Check permission to receive push notifications.
	 * On Android the status is always granted because you can always receive push notifications.
	 * If you need to check if the user allows to display notifications on Android, use local-notifications plugin.
	 */
	#checkPermissions() {
		return from(PushNotifications.checkPermissions());
	}

	/**
	 * Request permission to receive push notifications.
	 *
	 * On Android it doesn't prompt for permission because you can always receive push notifications.
	 *
	 * On iOS, the first time you use the function, it will prompt the user for push notification permission
	 * and return granted or denied based on the user selection. On following calls it will show currect status
	 * of the permission without prompting again.
	 */
	#requestPermissions() {
		return from(PushNotifications.requestPermissions());
	}

	/** Register the device with the platform provider */
	#registerWithPlatform(): Observable<PushDeviceRegistration> {
		// Register with Apple/Google to receive push via APNS/FCM
		PushNotifications.register();
		return this.#registration$.pipe(
			// We add throttle to allow for login flows to finalize, both from a system and user experience perspective
			throttleTime(1000),
			// Grab first available registration
			first()
		);
	}

	/** Register the device token for the provided user in the Connect API */
	#registerDeviceInConnect(props: {
		userId: string;
		deviceToken: string;
	}): Observable<PushDeviceRegistration> {
		const url = `${this.#apiServer}/api/user/${
			props.userId
		}/register-push-device`;
		return this.#httpClient
			.post<boolean>(
				url,
				{
					deviceToken: props.deviceToken,
					platform: this.#getNotificationPlatform(),
				},
				{ observe: 'response' }
			)
			.pipe(
				map(response => {
					return response.status === 202
						? {
								permission: PushDevicePermission.Granted,
								deviceToken: props.deviceToken,
								userId: props.userId,
								error: null,
								savedOnServer: true,
						  }
						: {
								permission: PushDevicePermission.Error,
								userId: props.userId,
								deviceToken: props.deviceToken,
								error: 'Failed to save token on server',
								savedOnServer: false,
						  };
				}),
				catchError(_error => {
					return of({
						permission: PushDevicePermission.Error,
						userId: props.userId,
						deviceToken: props.deviceToken,
						error: 'Failed to save token on server',
						savedOnServer: false,
					});
				}),
				tap(result => this.#registrationSubject.next(result))
			);
	}

	/** Deregister the device token for the user in the Connect API */
	#deregisterDeviceInConnect(): Observable<boolean> {
		return this.#hydrateRegistration().pipe(
			switchMap(registration => {
				if (registration && registration?.deviceToken) {
					const url = `${this.#apiServer}/api/user/${
						registration.userId
					}/deregister-push-device`;
					return this.#httpClient
						.post<boolean>(
							url,
							{
								deviceToken: registration.deviceToken,
							},
							{ observe: 'response' }
						)
						.pipe(
							map(response => response.status === 204),
							catchError(() => of(false))
						);
				} else {
					return of(false);
				}
			})
		);
	}

	/** Check if the @capacitor/push-notifications Capacitor plugin is available */
	#pluginAvailable(): boolean {
		return Capacitor.isPluginAvailable('PushNotifications');
	}

	/** Run device push notification deregistration flow for user */
	deregister(): Observable<boolean> {
		if (!this.#pluginAvailable()) {
			const msg =
				'Called deregister for push notifications, but Capacitor plugin is not available';
			console.warn(msg);
			return of(true);
		}
		return this.#deregisterDeviceInConnect();
	}

	/** Run the full push notification permission and registration flow for a user */
	register(userId: string): Observable<PushDeviceRegistration> {
		const deniedResult: PushDeviceRegistration = {
			permission: PushDevicePermission.Denied,
			deviceToken: null,
			userId: null,
			error: null,
			savedOnServer: false,
		};

		if (!this.#pluginAvailable()) {
			const msg =
				'Called register for push notifications, but Capacitor plugin is not available';
			console.warn(msg);
			return of({ ...deniedResult, error: msg });
		}

		return this.#checkPermissions().pipe(
			/** Check permission status and prompt permission flow if needed */
			switchMap(status => {
				switch (status.receive) {
					case 'denied': {
						return of(deniedResult);
					}
					case 'granted': {
						return this.#registerWithPlatform();
					}
					case 'prompt':
					case 'prompt-with-rationale':
					default: {
						return this.#requestPermissions().pipe(
							switchMap(permission =>
								permission.receive === 'granted'
									? this.#registerWithPlatform()
									: of(deniedResult)
							)
						);
					}
				}
			}),
			/** Check if device token is available */
			switchMap(result => {
				if (result.deviceToken !== null) {
					// If token available, register it with the Connect API
					return this.#registerDeviceInConnect({
						userId,
						deviceToken: result.deviceToken,
					});
				} else {
					// If no token, simply return result
					return of(result);
				}
			})
		);
	}
}
