import { Action, FunctionWithParametersType, createAction } from '@ngrx/store';

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export interface BaseAction extends Action {
	type: string;
	name: string;
}

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export interface IStartAction<TPayload = any> extends BaseAction {
	payload?: TPayload;
	onSuccess?: (data: any) => any;
	onError?: (error: string, blocked?: boolean) => any;
	forceLoad?: boolean;
}

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export interface ISuccessAction<TResponse = any, TPayload = any>
	extends BaseAction {
	data?: TResponse;
	payload?: TPayload;
	message?: string;
	toastSuccess?: boolean;
}

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export interface IErrorAction<TPayload = unknown> extends BaseAction {
	message?: string;
	toastError?: boolean;
	payload?: TPayload;
}

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export interface ApiActionSettings {
	/** Eager evaluation. Merges the post data directly into redux  */
	eager: boolean;
	/** Indicates that the action is used to load the base state  */
	initialLoad: boolean;
	/** The Payload will be parsed turning date strings into Dates */
	parseDates: boolean;
	/** Tells the date parser if the dates are in UTC format. Default true */
	utcDates: boolean;
	/** use concatMap instead of switchMap, allowing concurrent requests */
	useConcatMap: boolean;
}

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export interface ApiActionExtraSettings {
	/** Display error messages  */
	showErrors: boolean;
	/** Display this message on success  */
	successMessage: string;
	/** Display this error on errors  */
	errorMessage: string;
}

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export type CustomAction<TPayload, TAction> = FunctionWithParametersType<
	Parameters<(payload?: TPayload) => TAction>,
	ReturnType<(payload?: TPayload) => TAction> & Action
> &
	Action;

/**
 * The return type of {@link createAction}.
 *
 * Should be able to replace {@link CustomAction}.
 */
export type NgrxActionCreator<
	TActionType extends string = string,
	TParameters extends any[] = any[],
	TReturnValue extends object = object
> = ReturnType<typeof createAction<TActionType, TParameters, TReturnValue>>;

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export class ApiAction<TState, TPayload = any, TResponse = any> {
	start: NgrxActionCreator<
		`(STARTING)${string}`,
		[TPayload | undefined],
		IStartAction<TPayload>
	>;
	success: NgrxActionCreator<
		`(SUCCESS)${string}`,
		[TResponse],
		ISuccessAction<TResponse, TPayload>
	>;
	successWithData: NgrxActionCreator<
		`(SUCCESS)${string}`,
		[{ data: TResponse; payload: TPayload }],
		ISuccessAction<TResponse, TPayload>
	>;
	fail: NgrxActionCreator<`(FAILED)${string}`, [string], IErrorAction>;
	failWithData: NgrxActionCreator<
		`(FAILED)${string}`,
		[{ error: string }],
		IErrorAction
	>;

	#rollback?: TState;
	readonly #actionName: string;

	settings: ApiActionSettings;

	constructor(
		private modelName: string,
		private action: string,
		private domain: string,
		{
			successMessage = '',
			showErrors = false,
			errorMessage = '',
			eager = false,
			initialLoad = false,
			parseDates = false,
			utcDates = true,
			useConcatMap = false,
		}: Partial<ApiActionSettings & ApiActionExtraSettings> = {}
	) {
		this.#actionName = `${modelName}: ${action}`;
		this.settings = {
			eager,
			initialLoad,
			parseDates,
			utcDates,
			useConcatMap,
		};

		this.start = createAction(
			`(STARTING) ${this.getName()}`,
			(payload?: TPayload) => ({ payload, name: this.getName() })
		);

		this.success = createAction(
			`(SUCCESS)  ${this.getName()}`,
			(data: TResponse) => {
				return {
					data,
					name: this.getName(),
					message: successMessage,
					toastSuccess: !!successMessage,
				};
			}
		);

		this.successWithData = createAction(
			`(SUCCESS)  ${this.getName()}`,
			(data: { data: TResponse; payload: TPayload }) => {
				return {
					data: data.data,
					name: this.getName(),
					message: successMessage,
					toastSuccess: !!successMessage,
					payload: data.payload,
				};
			}
		);

		this.fail = createAction(
			`(FAILED)   ${this.getName()}`,
			(error: string) => ({
				message: errorMessage || error,
				name: this.getName(),
				toastError: showErrors,
			})
		);

		this.failWithData = createAction(
			`(FAILED)   ${this.getName()}`,
			(error: { error: string }) => ({
				message: errorMessage || error.error,
				name: this.getName(),
				toastError: showErrors,
				payload: error,
			})
		);
	}

	saveBackup(data: TState) {
		this.#rollback = data;
	}

	loadBackup(): TState {
		console.log(`%c${this.#actionName} rolled back`, 'font-weight: bold');
		if (!this.#rollback) {
			throw Error('No rollback set for action');
		}
		return this.#rollback;
	}

	getName(): string {
		return `[${this.domain}] ${this.action}: ${this.modelName}`;
	}
}
