import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	ElementRef,
	inject,
	Input,
	OnInit,
	ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import type { Application as SplineRuntime } from '@splinetool/runtime';
import { CoSpinnerComponent } from '@consensus/co/ui-progress';
import { NgIf } from '@angular/common';
import { PushPipe } from '@ngrx/component';
import { filterNonNullish } from '@consensus/co/util-rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { sentryToken } from '@consensus/shared/shared/analytics/data-access';

@Component({
	selector: 'co-spline-player',
	standalone: true,
	imports: [CoSpinnerComponent, NgIf, PushPipe],
	templateUrl: './co-spline-player.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoSplinePlayerComponent implements OnInit {
	readonly #destroyRef = inject(DestroyRef);
	readonly #sceneUrl$ = new BehaviorSubject<string | null>(null);
	readonly #sentry = inject(sentryToken);

	@Input({ required: true }) set sceneUrl(sceneUrl: string) {
		this.#sceneUrl$.next(sceneUrl);
	}
	@Input() enableGlobalEvents = false;
	@Input() hideLoadingSpinner = false;

	@ViewChild('splinePlayerCanvas', { static: true })
	readonly canvas!: ElementRef<HTMLCanvasElement>;

	readonly loaded$ = new BehaviorSubject(false);

	async ngOnInit() {
		const canvas = this.canvas.nativeElement;
		// [@typescript-eslint/naming-convention] It's a class constructor
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const SplineRuntime = await import('@splinetool/runtime').then(
			m => m.Application
		);
		const splineRuntime = new SplineRuntime(canvas);
		splineRuntime.setGlobalEvents(this.enableGlobalEvents);

		this.#sceneUrl$
			.pipe(filterNonNullish, takeUntilDestroyed(this.#destroyRef))
			.subscribe(sceneUrl => this.#loadScene(splineRuntime, sceneUrl));

		this.#destroyRef.onDestroy(() => splineRuntime.dispose());
	}

	async #loadScene(splineRuntime: SplineRuntime, splineSceneUrl: string) {
		try {
			this.loaded$.next(false);
			await splineRuntime.load(splineSceneUrl);
			console.info('Successfully loaded spline scene');
		} catch (error) {
			this.#sentry.captureException(error);
			console.error('Failed to load spline scene', error);
		} finally {
			this.loaded$.next(true);
		}
	}
}
