import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Observable } from 'rxjs';
import { PopupService } from '../popup.service';
import { modalAnimations } from '@consensus/co/ui-component-animations';
import { Popup, PopupButton } from '../popup-types';
import { CoTrackByFunction } from '@consensus/co/util-control-flow';

/**
 * @deprecated ❌ `@consensus/legacy/ui-popup` is deprecated.
 * Use MatDialog from Angular Material instead.
 */
@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'co-popup',
	imports: [AsyncPipe, NgIf, NgFor, MatButtonModule],
	templateUrl: './popup.component.html',
	styleUrls: ['./popup.component.scss'],
	animations: modalAnimations,
})
export class PopupComponent {
	readonly #popupService = inject(PopupService);

	popup$: Observable<Popup | null> = this.#popupService.popup$;

	onPopupButtonClick(button: PopupButton) {
		this.#popupService.clickButton(button);
	}

	// This allows for automatic migration to the new control flow detailed in Angular RFC #50719
	readonly trackByIdentity: CoTrackByFunction<unknown> = (
		_index: number,
		value: unknown
	) => value;
}
