/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { NgIf } from '@angular/common';
import {
	Component,
	EventEmitter,
	HostBinding,
	inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { OverlayService, OverlayToken } from '@core/services';

@Component({
	standalone: true,
	selector: 'co-overlay',
	imports: [NgIf],
	templateUrl: './overlay.component.html',
	styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent implements OnInit, OnDestroy {
	readonly #overlayService = inject(OverlayService);
	@HostBinding('style.z-index') get zIndexStyle() {
		return this.zIndex ?? this.overlayToken.zIndex;
	}

	@Input() maxWidth = 1600;
	@Input() zIndex: number;
	@Input() withScroll = false;
	@Input() closeButton = false;

	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() close = new EventEmitter<void>();

	overlayToken: OverlayToken;

	ngOnInit() {
		this.overlayToken = this.#overlayService.stackOverlay();
		this.overlayToken.handleEscape(() => this.close.emit());
	}

	ngOnDestroy() {
		this.overlayToken?.destroy();
	}
}
