<div class="wrapper ng-select-append-to" [@harmonica]="show">
	<mat-form-field
		[class.has-no-label]="!hasLabel"
		[appearance]="appearance"
		[hideRequiredMarker]="hideRequired"
		[color]="color"
		[matTooltip]="tooltip"
		matTooltipPosition="above"
	>
		<ng-select
			[virtualScroll]="true"
			[items]="countries"
			[(ngModel)]="country"
			matPrefix
			[clearable]="false"
			bindValue="country"
			coNoClickBubble
			[searchFn]="search"
			(change)="setCode()"
			appendTo=".ng-select-append-to"
		>
			<ng-template ng-label-tmp ng-option-tmp let-item="item">
				<div class="country">
					<img
						[src]="item.flag"
						[alt]="item.country"
						style="max-width: 15px"
					/>
					+{{ item.extension }}
				</div>
			</ng-template>
		</ng-select>

		<mat-label>
			{{ label }}
			<i class="fad fa-question-circle" *ngIf="tooltip"></i>
		</mat-label>

		<input
			type="tel"
			matInput
			[placeholder]="placeholder"
			[required]="required"
			[readonly]="readonly"
			[(ngModel)]="inputValue"
			autocomplete="tel-national"
			[autofocus]="autofocus"
			#input
		/>

		<mat-hint>{{ preview }}</mat-hint>

		<mat-error *ngIf="hasErrors">{{ inputError }}</mat-error>
		<mat-hint *ngIf="hint">{{ hint }}</mat-hint>
	</mat-form-field>
</div>
