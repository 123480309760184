import { inject, Pipe, PipeTransform } from '@angular/core';
import { selectAllEventUsersLookup } from '@consensus/shared/ufa/users/data-access';
import { Store } from '@ngrx/store';
import { UserInformation } from '@shared/models';
import { map, Observable, of } from 'rxjs';

@Pipe({
	name: 'coSelectUserById',
	standalone: true,
})
export class SelectUserByIdPipe implements PipeTransform {
	readonly #store = inject(Store);
	transform(id: string | null): Observable<UserInformation | null> {
		if (!id) {
			return of(null);
		}
		return this.#store
			.select(selectAllEventUsersLookup)
			.pipe(map(users => users[id] ?? null));
	}
}
