import { isEqual } from 'lodash-es';
import { Sorted } from './sorted';

export function updateListItem<T>(
	list: T[],
	selector: (x: T) => boolean,
	data: T,
	create: true
): T[];
export function updateListItem<T>(
	list: T[],
	selector: (x: T) => boolean,
	data?: T | Partial<T> | null
): T[];
export function updateListItem<T>(
	list: T[],
	selector: (x: T) => boolean,
	data: (x: T) => Partial<T>
): T[];
export function updateListItem<T>(
	list: T[],
	selector: (x: T) => boolean,
	data?: Partial<T> | T | null | ((x: T) => Partial<T>),
	create = false
): T[] {
	list = list ?? [];
	const newList = [...list];
	const index = list.findIndex(selector);

	if (index !== -1) {
		const newData = data instanceof Function ? data(list[index]) : data ?? [];
		newList[index] =
			newData instanceof Object ? { ...list[index], ...newData } : newData;
	} else if (create && !(data instanceof Function)) {
		newList.push(data as T);
	}
	return newList;
}

export function updateListItems<T>(
	list: T[],
	filter: (x: T) => boolean,
	data: Partial<T> | ((x: T) => Partial<T>)
): T[] {
	list = list ?? [];
	return list.map(x => {
		if (!filter(x)) {
			return x;
		}
		const newData = data instanceof Function ? data(x) : data;
		return { ...x, ...newData };
	});
}

function updateListItemWithParent<T extends Sorted, TParent>(
	list: T[],
	getParent: (x: T) => TParent,
	identifier: (x: T) => boolean,
	data: Partial<T>
) {
	const oldList = list;
	list = [...list];
	const index = list.findIndex(x => identifier(x));
	const item = list[index];

	if (!item) {
		return oldList;
	}

	const sortingKey = item.sortingKey;
	const oldParent = getParent(item);
	const newItem = { ...item, ...data };
	list[index] = newItem;

	const newParent = getParent(newItem);

	if (!isEqual(oldParent, newParent)) {
		list.forEach((val, i) => {
			const parent = getParent(val);
			if (val.sortingKey > sortingKey && isEqual(oldParent, parent)) {
				list[i] = { ...val, sortingKey: val.sortingKey - 1 };
			}
		});
	}

	return list;
}

export function removeListItem<T>(list: T[], selector: (x: T) => boolean): T[] {
	const newList = [...list];
	const index = list.findIndex(selector);
	if (index !== -1) {
		newList.splice(index, 1);
	}
	return newList;
}

export function moveListItem<T extends { sortingKey: number }>(
	list: T[],
	selector: (x: T) => boolean,
	newIndex: number,
	identifier: ((x: T) => any) | null = null
) {
	const items = list.map(f => ({ ...f }));
	const item = items.find(selector);

	if (!item) {
		return items;
	}

	const oldIndex = item.sortingKey;
	const identity = identifier ? identifier(item) : null;

	items
		.filter(
			i =>
				i.sortingKey >= newIndex && (!identifier || identifier(i) === identity)
		)
		.forEach(i => i.sortingKey++);

	item.sortingKey = newIndex;

	items
		.filter(
			i =>
				i.sortingKey > oldIndex && (!identifier || identifier(i) === identity)
		)
		.forEach(i => i.sortingKey--);
	return items;
}

export function relocateListItem<T extends Sorted, TParent extends Partial<T>>(
	list: T[],
	selector: (x: T) => boolean,
	getParent: (x: T) => TParent,
	newParent: TParent
) {
	const sortingKey =
		list
			.filter(x => isEqual(getParent(x), newParent))
			.reduce((max, val) => Math.max(max, val.sortingKey), -1) + 1;

	return updateListItemWithParent(list, getParent, selector, {
		...newParent,
		sortingKey,
	});
}

export function relocateListItemToIndex<T extends Sorted>(
	list: T[],
	selector: (x: T) => boolean,
	identifier: ((x: T) => any) | null = null,
	newParentSelector: (x: T) => boolean,
	newIndex: number
) {
	const itemToRelocate = list.find(x => selector(x));

	if (!itemToRelocate) {
		return list;
	}

	// Remove from old list
	const updatedList = removeListItemWithIndex(list, selector, identifier);

	// Add to new list
	return addListItemWithIndex(
		updatedList,
		itemToRelocate,
		newIndex,
		newParentSelector
	);
}

export function relocateListItems<
	T extends Sorted,
	TId,
	TParent extends Partial<T>
>(
	list: T[],
	selector: (x: T) => TId,
	ids: TId[],
	getParent: (x: T) => TParent,
	newParent: TParent
) {
	let sortingKey =
		list
			.filter(x => isEqual(getParent(x), newParent))
			.reduce((max, val) => Math.max(max, val.sortingKey), -1) + 1;

	for (const id of ids) {
		const newList = updateListItemWithParent(
			list,
			getParent,
			x => selector(x) === id,
			{ ...newParent, sortingKey }
		);
		if (newList !== list) {
			list = newList;
			sortingKey++;
		}
	}

	return list;
}

export function removeListItemWithIndex<T extends { sortingKey: number }>(
	list: T[],
	selector: (x: T) => boolean,
	identifier: ((x: T) => any) | null = null
) {
	const items = list.map(f => ({ ...f }));
	const itemIndex = items.findIndex(selector);

	if (itemIndex < 0) {
		return items;
	}

	const item = items[itemIndex];

	const oldIndex = item.sortingKey;
	const identity = identifier ? identifier(item) : null;

	items.splice(itemIndex, 1);

	items
		.filter(
			i =>
				i.sortingKey >= oldIndex && (!identifier || identifier(i) === identity)
		)
		.forEach(i => i.sortingKey--);
	return items;
}

export function addListItemWithIndex<T extends Sorted>(
	list: T[],
	newItem: T,
	newIndex: number,
	newParentSelector: (x: T) => boolean
): T[] {
	const newList = list.map(item => {
		// shift the sortingKey of items that are placed after the new item
		if (item.sortingKey >= newIndex && newParentSelector(item)) {
			return { ...item, sortingKey: item.sortingKey + 1 };
		}
		return item;
	});
	const newItemWithSortingKey = { ...newItem, sortingKey: newIndex };
	return [...newList, newItemWithSortingKey];
}

export function updateList<T>(
	list: T[],
	data: T | Partial<T> | ((x: T) => Partial<T>)
): T[] {
	return list.map(x => {
		const newData = data instanceof Function ? data(x) : data;
		return newData instanceof Object ? { ...x, ...newData } : newData;
	});
}

export function appendAndOverride<T extends { id: string }>(
	baseList: T[],
	tail: T[]
): T[];
export function appendAndOverride<T>(
	baseList: T[],
	tail: T[],
	identifier: (item: T) => any
): T[];
/**
 * Appends 'tail' to 'baseList' and overrides existing elements
 * @param baseList
 * @param tail
 * @param identifier
 */
export function appendAndOverride<T>(
	baseList: T[],
	tail: T[],
	identifier: (item: T) => any = x => (x as any)['id']
): T[] {
	const newIds = new Set(tail.map(identifier));
	return [...baseList.filter(x => !newIds.has(identifier(x))), ...tail];
}
