export interface Color {
	color: string;
	blur: number;
}

export function parseColor(encodedColor: string): Color {
	const match = encodedColor.match(/#([\dA-F]+)-([\dA-F]{2})/i);
	if (!match) {
		return { color: encodedColor, blur: 0 };
	}
	return { color: `#${match[1]}`, blur: parseInt(match[2], 16) };
}

export function getColorStyles(colorStr: string): Partial<CSSStyleDeclaration> {
	if (!colorStr) {
		return {};
	}
	const color = parseColor(colorStr);
	const styles: Partial<CSSStyleDeclaration> = { background: color.color };
	if (color.blur > 0) {
		styles['backdropFilter'] = `blur(${color.blur / 20}px)`;
	}
	return styles;
}

// Based on https://stackoverflow.com/a/41491220/887539
/**
 * @returns `null` when the color cannot be parsed
 */
export function isDarkColor(encodedColor: string): boolean | null {
	const { color } = parseColor(encodedColor);

	// Colors can be:
	// #RGB
	// #RGBA
	// #RRGGBB
	// #RRGGBBAA

	const shortRegex = /^#([\dA-F])([\dA-F])([\dA-F])([\dA-F])?$/i;
	const longRegex = /^#([\dA-F]{2})([\dA-F]{2})([\dA-F]{2})([\dA-F]{2})?$/i;

	const matches = longRegex.exec(color) || shortRegex.exec(color);
	if (!matches) {
		return null;
	}

	const r = parseInt(matches[1], 16);
	const g = parseInt(matches[2], 16);
	const b = parseInt(matches[3], 16);
	const c = [r / 255, g / 255, b / 255].map(col => {
		if (col <= 0.03928) {
			return col / 12.92;
		}
		return Math.pow((col + 0.055) / 1.055, 2.4);
	});
	const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
	return L <= 0.179;
}
