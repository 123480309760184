<div class="overlay">
	<div class="content">
		<p>
			A newer version is available which is required to proceed. Press
			refresh to update.
		</p>

		<button
			#button
			mat-flat-button
			(blur)="buttonBlurred()"
			autofocus
			[disabled]="refreshing"
			color="primary"
			(click)="refresh()"
		>
			{{ refreshing ? "Refreshing" : "Refresh" }}
		</button>
	</div>
</div>
