<div class="wrapper" [@harmonica]="show">
	<mat-form-field
		[class.has-no-label]="!hasLabel"
		[appearance]="appearance"
		[hideRequiredMarker]="hideRequired"
		[color]="color"
		[matTooltip]="tooltip"
		matTooltipPosition="above"
		(click)="forceDatepicker && datepicker.open()"
		[class.forceDatepicker]="forceDatepicker"
	>
		<mat-label>
			{{ label }}
			<i class="fad fa-question-circle" *ngIf="tooltip"></i>
		</mat-label>

		<input
			matInput
			[placeholder]="placeholder"
			[required]="required"
			[disabled]="isDisabled"
			[(ngModel)]="inputValue"
			[readonly]="forceDatepicker || readonly"
			[autocomplete]="autocomplete"
			[autofocus]="autofocus"
			[matDatepicker]="datepicker"
			#input
		/>

		<mat-datepicker #datepicker [startAt]="now" />
		<mat-datepicker-toggle matIconSuffix [for]="datepicker" />

		<mat-error *ngIf="hasErrors">{{ inputError }}</mat-error>
		<mat-hint *ngIf="hint">{{ hint }}</mat-hint>
	</mat-form-field>
</div>
