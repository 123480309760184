import { ProgressSectionType } from '@shared/models';
import {
	ColearnCompetitionShared,
	EngagementMetricType,
} from '@consensus/connect/shared/colearn/domain';

export interface ColearnCompetition extends ColearnCompetitionShared {
	tiers: ColearnTier[];
}

export interface ColearnProgressSection {
	section: ProgressSectionType;
	enabled: boolean;
}

export interface ColearnTier {
	id: string;
	competitionId: string;
	name: string;
	userIsInTierMessage: string;
	userIsNotInTierMessage: string;
	type: ColearnTierType;
	size: number;
	textColor: string;
	backgroundColor: string;

	sortingKey: number;
}

export interface ColearnEngagementSection {
	engagementSectionShowTotalCount: boolean;
	engagementSectionShowCompletedLearningActivities: boolean;
}

export interface ColearnDescriptionSection {
	descriptionSectionIcon: string;
	descriptionSectionColor: string;
	descriptionSectionText: string;
	descriptionSectionTitle: string;
}

export interface ColearnBadgesSection {
	badgesSectionTitle: string;
}

export enum EngagementType {
	CompletePodcast = 'CompletePodcast',
	ReadPdf = 'ReadPdf',
	WatchVideo = 'WatchVideo',
	CompleteKnowledgeCheck = 'CompleteKnowledgeCheck',
	AcademyModuleCompletion = 'AcademyModuleCompletion',
	MaterialVisited = 'MaterialVisited',
	MaterialAudioVisited = 'MaterialAudioVisited',
	MaterialImageVisited = 'MaterialImageVisited',
	MaterialPdfVisited = 'MaterialPdfVisited',
	MaterialVideoVisited = 'MaterialVideoVisited',
	MaterialCompleted = 'MaterialCompleted',
	MaterialAudioCompleted = 'MaterialAudioCompleted',
	MaterialVideoCompleted = 'MaterialVideoCompleted',
}

export interface ColearnBadge {
	id: string;
	name: string;
	icon: string;
	count: number;
	sortingKey: number;
	description: string;
	completedAt: string;
}

export interface EngagementAggregate {
	metricType: EngagementType;
	count: number;
	total: number;
	sortingKey: number;
	icon?: string;
	displayName?: string;
}

export interface ColearnUser {
	userId: string;
	teamId: string;
	userName: string;
	teamName: string;
	competitionName: string;
	isManager: boolean;
	subjects: ColearnSubject[];

	engagement?: EngagementAggregate[];
	badges?: ColearnBadge[];
	barometer?: number;
	points?: number;
	learningActivities?: LearningActivity[];
}

export interface ColearnSubject {
	userId: string;
	teamId: string;
	userName: string;
	teamName: string;
	competitionName: string;

	barometer?: number;
	points?: number;
}

export interface ColearnCache {
	barometer?: number;
	engagement?: EngagementAggregate[];
	badges?: ColearnBadge[];
	points?: number;
	learningActivities?: LearningActivity[];
}

export interface LeaderboardItem {
	name: string;
	tier: LeaderboardItemTier | null;
	score: number;
	rank?: number;
	userId: string;
	me: boolean;
}

export interface LeaderboardItemTier {
	id: string;
	textColor: string;
	backgroundColor: string;
}

export enum ColearnTierType {
	PercentageOfUsers = 'PercentageOfUsers',
	Users = 'Users',
}

export interface ScoreOverviewViewModel {
	scoreSettingsName: string;
	createdAt: string;
	points: number;
}

export interface LearningActivity {
	completedAt: Date;
	name: string;
}

export interface EngagementTotalUpdate {
	type: EngagementMetricType;
	total: number;
}

export interface ColearnSectionOptions
	extends ColearnEngagementSection,
		ColearnDescriptionSection,
		ColearnBadgesSection {}
