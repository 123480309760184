import { ActivatedRouteSnapshot } from '@angular/router';

export function getRouteParams(route: ActivatedRouteSnapshot) {
	let params = {};
	let traversedRoute: ActivatedRouteSnapshot | null = route;

	while (traversedRoute) {
		params = { ...params, ...traversedRoute.params };
		traversedRoute = traversedRoute.firstChild;
	}

	return params;
}
