import { Directive, inject, Input, TemplateRef } from '@angular/core';

@Directive({
	standalone: true,
	selector: 'ng-template[coTableTemplate]',
})
export class TableTemplateDirective {
	templateRef: TemplateRef<any> = inject(TemplateRef);

	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('coTableTemplate') column: string;
}
