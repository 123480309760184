/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { DOCUMENT, NgIf } from '@angular/common';
import {
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnDestroy,
	OnInit,
	Output,
	inject,
} from '@angular/core';
import { modalAnimations } from '@consensus/co/ui-component-animations';

@Component({
	selector: 'co-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
	animations: modalAnimations,
	standalone: true,
	imports: [NgIf],
})
export class ModalComponent implements OnInit, OnDestroy {
	readonly #document = inject(DOCUMENT);

	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() close = new EventEmitter<void>();
	@Output() animatingChange = new EventEmitter<boolean>();
	@Input() simple = false;
	@Input() internalClose = false;
	@Input() noClose: boolean;
	@Input() title: string;
	@Input() narrow = false;
	@Input() enableScroll: boolean;
	@Input() verticalCenter = false;
	@Input() centerFooterContent = false;

	@HostBinding('@modal') animatePage = true;

	ngOnInit() {
		this.#document.documentElement.classList.add('no-scroll');
	}

	ngOnDestroy() {
		this.#document.documentElement.classList.remove('no-scroll');
	}
}
