import { ApiAction } from '@lib/redux';
import {
	Signature,
	SignatureLoadModel,
	SignatureState,
	UserSignature,
	UserSignatureCreateModel,
} from './signature.model';
import { createAction } from '@ngrx/store';

export const loadSignatures = new ApiAction<
	SignatureState,
	void,
	SignatureLoadModel
>('Signatures', 'Load', 'Event Signatures', {
	showErrors: true,
});

export const createUserSignature = new ApiAction<
	SignatureState,
	UserSignatureCreateModel,
	UserSignature
>('User Signatures', 'Create', 'Event Signatures', {
	showErrors: true,
});

export const addSignature = createAction(
	'[Signature socket] Add Signature',
	(payload: Signature) => ({ payload })
);
export const updateSignature = createAction(
	'[Signature socket] Update Signature',
	(payload: Signature) => ({ payload })
);
export const removeSignature = createAction(
	'[Signature socket] Remove Signature',
	(payload: string) => ({ payload })
);
export const removeUserSignature = createAction(
	'[Signature socket] Remove User Signature',
	(payload: string) => ({ payload })
);
