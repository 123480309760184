import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivateChildFn,
	Router,
} from '@angular/router';
import { AuthService } from '@consensus/shared/shared/iam/data-access';
import { ErrorTypes } from '@shared/models';

let run = false;

export const verifyLoginGuard: CanActivateChildFn = async (
	route: ActivatedRouteSnapshot
): Promise<boolean> => {
	const router = inject(Router);
	const auth = inject(AuthService);

	if (run) {
		return true;
	}

	let retries = 3;

	while (retries >= 0) {
		try {
			await auth.verifyToken(
				route.firstChild?.routeConfig?.path == 'login/info'
			);
			run = true;
			return true;
		} catch (e) {
			console.error(e);
		}
		retries--;
		await new Promise<void>(resolve => setTimeout(() => resolve(), 5000));
	}

	await router.navigate(['/error'], {
		queryParams: { error: ErrorTypes.TokenError },
	});
	return false;
};
