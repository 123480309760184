/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	ViewChild,
	ChangeDetectorRef,
	inject,
} from '@angular/core';
import { mimeToFileType } from '@lib/files';
import { FileTypes } from '@consensus/shared/shared/files/domain';
import { BypassSecurityTrustResourceUrlPipe } from '@shared/pipes';
import { Subscription } from 'rxjs';
import { FileCacheService } from '@consensus/shared/shared/files/data-access-files';
import { CoDriveRiveDisplayComponent } from '@consensus/shared/shared/file-display';
import { CoSplinePlayerComponent } from '@consensus/co/ui-spline-player';

@Component({
	standalone: true,
	selector: 'co-encoded-media',
	imports: [
		NgIf,
		NgSwitch,
		NgSwitchCase,
		BypassSecurityTrustResourceUrlPipe,
		CoDriveRiveDisplayComponent,
		CoSplinePlayerComponent,
	],
	templateUrl: './encoded-media.component.html',
	styleUrls: ['./encoded-media.component.scss'],
})
export class EncodedMediaComponent {
	readonly #changeDetectorRef = inject(ChangeDetectorRef);
	readonly #fileService = inject(FileCacheService);
	@Output() loaded = new EventEmitter();
	@Output() domLoaded = new EventEmitter<number>();
	@Output() videoEnded = new EventEmitter<void>();
	@Output() videoFailedToLoad = new EventEmitter<void>();

	@ViewChild('videoElement') videoElement: ElementRef<HTMLVideoElement>;

	@Input() muted = false;
	@Input() autoplay = false;
	@Input() loop = false;
	@Input() showOnlyLastVideoFrame?: boolean;

	fileTypes = FileTypes;
	#reload = new Subscription();
	loading = false;
	failed = false;
	mediaUrl: string;
	fileType: FileTypes;

	#src = '';
	@Input()
	set src(src: string) {
		if (src === this.#src) {
			return;
		}

		this.#src = src;
		this.loadMedia();
	}

	@Input()
	set academyResource([moduleId, resourceId]: [string, string]) {
		this.src = `academy/module/${moduleId}/resource/${resourceId}`;
	}

	@Input()
	set driveFileId(id: string) {
		this.src = `materials/${id}`;
	}

	async loadMedia() {
		this.loading = true;
		this.#changeDetectorRef.markForCheck();

		try {
			const file = await this.#fileService.getFile(this.#src);

			this.fileType = mimeToFileType(file.fileType, file.fileName);
			this.mediaUrl = file.url;

			this.failed = false;
			this.loaded.emit();
		} catch (e) {
			this.mediaUrl = null;
			this.failed = true;

			this.videoFailedToLoad.emit();
		} finally {
			this.loading = false;
			this.#changeDetectorRef.markForCheck();

			if (this.#reload) {
				this.#reload.unsubscribe();
			}
			this.#reload = this.#fileService.onReload(this.#src, () =>
				this.loadMedia()
			);
		}
	}

	videoLoad(event: Event) {
		const target = event.target as HTMLVideoElement;
		this.domLoaded.emit(target.videoWidth / target.videoHeight);

		if (this.showOnlyLastVideoFrame) {
			this.videoElement.nativeElement.currentTime =
				this.videoElement.nativeElement.duration - 0.1;
			this.videoElement.nativeElement.pause();
			return;
		}
		if (this.autoplay) {
			this.videoElement?.nativeElement?.play().catch(error => {
				if (error instanceof DOMException && error.name === 'AbortError') {
					console.log('Ignoring the following error', error);
					return;
				}
				throw error;
			});
		}
	}

	imageLoad(event: Event) {
		const target = event.target as HTMLImageElement;
		this.domLoaded.emit(target.width / target.height);
	}
}
