import {
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	inject,
	Input,
	Output,
} from '@angular/core';

@Directive({
	standalone: true,
	selector: '[coFileDrop]',
})
export class FileDropDirective {
	readonly #element: ElementRef<HTMLElement> = inject(ElementRef);

	#timeout: NodeJS.Timeout;

	@Output('coFileDrop') fileDrop = new EventEmitter<DragEvent>();
	@Input() fileDropDisable = false;

	@HostListener('dragover', ['$event']) onDragOver(event: Event) {
		if (this.fileDropDisable) {
			return;
		}
		event.stopPropagation();
		event.preventDefault();
		this.#element.nativeElement.classList.add('file-hover');
		clearTimeout(this.#timeout);
	}

	@HostListener('dragleave', ['$event']) onDragLeave(event: Event) {
		if (this.fileDropDisable) {
			return;
		}
		event.stopPropagation();
		event.preventDefault();
		this.#timeout = setTimeout(
			() => this.#element.nativeElement.classList.remove('file-hover'),
			100
		);
	}

	@HostListener('drop', ['$event']) onDrop(event: Event) {
		if (this.fileDropDisable) {
			return;
		}
		event.stopPropagation();
		event.preventDefault();
		this.#element.nativeElement.classList.remove('file-hover');
		this.fileDrop.emit(event as DragEvent);
	}
}
