<div class="popup-wrapper" *ngIf="popup$ | async as popup" @modal>
	<div class="background" @background></div>
	<div class="popup" @content>
		<div class="popup-header">{{ popup.title }}</div>
		<div class="popup-content">{{ popup.text }}</div>
		<div class="popup-footer">
			<button
				mat-button
				*ngFor="let button of popup.buttons; trackBy: trackByIdentity"
				[color]="button.color"
				(click)="onPopupButtonClick(button)"
			>
				{{ button.text }}
			</button>
		</div>
	</div>
</div>
