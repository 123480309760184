import { Subject } from 'rxjs';
import { SocketActionConfig } from './socket-action-config';

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use
 *   `@consensus/connect/shared/data-access-websocket` instead.
 */
export const socketConfigs: SocketActionConfig<unknown, unknown>[] = [];

const configs$ = new Subject<SocketActionConfig<unknown, unknown>>();
export const socketConfigs$ = configs$.asObservable();

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use
 *   `@consensus/connect/shared/data-access-websocket` instead.
 */
export function addSocketConfig(config: SocketActionConfig<unknown, unknown>) {
	socketConfigs.push(config);
	configs$.next(config);
}
