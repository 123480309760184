import { Share } from '@capacitor/share';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import { saveAs } from 'file-saver';

const convertBlobToBase64 = (blob: Blob): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onerror = reject;
		reader.onload = () => {
			resolve(reader.result as string);
		};
		reader.readAsDataURL(blob);
	});

const shareFile = async (blob: Blob, fileName: string) => {
	const base64Data = await convertBlobToBase64(blob);
	const fileInfo = await Filesystem.writeFile({
		path: fileName,
		directory: Directory.Cache,
		data: base64Data,
	});
	await Share.share({
		title: fileName,
		files: [fileInfo.uri],
		dialogTitle: 'Share file',
	});
};

/**
 * File download handler that works in both Capacitor and web contexts
 *
 * If it is a native context, the file will be written to the app's cache and the native Share API will be triggered to let the user control the further handling of the file.
 *
 * If it is a web context, saveAs from file-saver will be used.
 */
export const capacitorFileDownload = async ({
	blob,
	filename,
}: {
	blob: Blob;
	filename: string;
}): Promise<void> => {
	if (Capacitor.isNativePlatform()) {
		await shareFile(blob, filename);
	} else {
		saveAs(blob, filename);
	}
};
