export class CoEntityComponentStoreEntityNotFoundError extends Error {
	/**
	 * The ID of the entity that was not found.
	 */
	readonly id: string;

	constructor(id: string) {
		super(`The entity with the ID "${id}" was not found.`);
		this.name = 'CoEntityComponentStoreEntityNotFoundError';
		this.id = id;

		// Non-standard V8 function for maintaining a stack trace
		const ErrorWithCaptureStackTrace = Error as unknown as Error & {
			captureStackTrace?: (
				targetObject: object,
				// eslint-disable-next-line @typescript-eslint/ban-types
				constructorOpt?: Function
			) => void;
		};
		ErrorWithCaptureStackTrace.captureStackTrace?.(this, this.constructor);
	}
}
