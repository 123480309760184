<button
	mat-icon-button
	(click)="setCollapsed(true)"
	[class.shown]="!collapsed"
	*ngIf="collapsible"
>
	<i class="collapse-icon far fa-chevron-right fa-xs"></i>
</button>

<mat-form-field
	[class.collapsible]="collapsible"
	appearance="outline"
	[color]="color"
	[class.collapsed]="collapsible && collapsed"
	[subscriptSizing]="subscriptSizing"
>
	<i
		class="search-icon far fa-search"
		matIconPrefix
		(click)="setCollapsed(false)"
	></i>
	<input
		type="search"
		matInput
		[autofocus]="autofocus"
		[placeholder]="label"
		[readonly]="readonly"
		[formControl]="control"
		#input
	/>
	<mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>
