<div matSnackBarLabel class="tw-flex tw-flex-col tw-space-y-2">
	<div class="tw-text-2xl tw-font-semibold">
		<fa-icon [icon]="cookieIcon" class="tw-mr-1" />
		Cookie consent
	</div>
	<div>
		<p>
			We care about you and your data. We would love to use cookies to
			continuously improve your experience.
		</p>
	</div>
</div>
<div class="tw-flex-row tw-space-x-2 tw-p-2" matSnackBarActions>
	<button
		mat-raised-button
		matSnackBarAction
		(click)="onConsentGrant()"
		color="primary"
		class="!tw-text-primary-contrast-500"
	>
		ACCEPT
	</button>
	<button mat-button matSnackBarAction (click)="onConsentReject()">
		DECLINE
	</button>
	<div class="tw-grow"></div>
	<a
		mat-button
		matSnackBarAction
		href="https://www.consensus.dk/cookie-policy"
		target="_blank"
		data-testid="learn-more-link"
	>
		<fa-icon [icon]="linkIcon" class="tw-mr-1" />
		LEARN MORE
	</a>
</div>
