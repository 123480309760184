import {
	HttpEvent,
	HttpInterceptorFn,
	HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { coHttpCacheableEndpointUrlsToken } from './co-http-cacheable-endpoint-urls.token';

/**
 * Invalidate the server HTTP cache by setting the HTTP header `Cache-Control`
 * to `'no-cache'`.
 *
 * @param request The original HTTP request.
 * @returns A cloned HTTP request with the HTTP header `Cache-Control` set to
 *   `'no-cache'`.
 */
function invalidateServerHttpCache(
	request: HttpRequest<unknown>
): HttpRequest<unknown> {
	return request.clone({
		setHeaders: {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'Cache-Control': 'no-cache',
		},
	});
}

/**
 * Invalidate the server cache for the first HTTP request to a cacheable
 * endpoint URL as indicated by {@link coHttpCacheableEndpointUrlsToken}.
 *
 * @see `provideCoHttpCacheStore` Required to clear the cacheable endpoint URLs
 *
 * @param request The original HTTP request.
 * @param next The next HTTP interceptor in the chain.
 * @returns An observable of the HTTP event.
 */
export const coHttpAddCacheControlHeaderToFirstGetRequest: HttpInterceptorFn = (
	request,
	next
): Observable<HttpEvent<unknown>> => {
	const cacheableEndpointUrls = inject(coHttpCacheableEndpointUrlsToken);
	const { url: endpointUrl } = request;

	if (request.method === 'GET' && !cacheableEndpointUrls.has(endpointUrl)) {
		return next(invalidateServerHttpCache(request)).pipe(
			tap(() => cacheableEndpointUrls.add(endpointUrl))
		);
	} else {
		return next(request);
	}
};
