<div
	class="button is-white add-box tw-rounded-md tw-bg-white tw-p-4 tw-shadow-xl"
	(click)="disabled || toggleInsert()"
	[class.disabled]="disabled"
>
	<input
		name="title"
		type="text"
		[placeholder]="buttonText"
		(keydown.enter)="onSubmit()"
		*ngIf="showInsert"
		class="input"
		#insertInput
		(blur)="showInsert = false"
		[(ngModel)]="text"
	/>
	<p *ngIf="!showInsert">
		<i class="fas fa-plus"></i>
		{{ buttonText }}
	</p>
</div>
