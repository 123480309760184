import { inject, Injectable } from '@angular/core';
import { RouterStore } from '@ngworker/router-component-store';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
	AddEmailModel,
	AddPhoneNumberModel,
	AuthResponse,
	ChangePasswordModel,
	ForgotPasswordModel,
	TwoFactorModel,
	VerifyPhoneModel,
} from '@store/user';
import { AnonLoginModel, LoginModel, PinLoginModel } from '@login/models';
import {
	map,
	firstValueFrom,
	from,
	concatMap as switchMap,
	Observable,
} from 'rxjs';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #httpClient = inject(HttpClient);
	readonly #routerStore = inject(RouterStore);
	externalToken: string | null = null;

	async #getExternalTokenHeader(): Promise<{
		readonly externalToken?: string;
	}> {
		let externalToken = await firstValueFrom(
			this.#routerStore
				.selectQueryParam('externalToken')
				.pipe(map(x => x ?? null))
		);

		if (!externalToken) {
			externalToken = localStorage.getItem('externalLoginToken');
		}

		if (externalToken) {
			this.externalToken = externalToken;
		}

		return this.externalToken ? { externalToken: this.externalToken } : {};
	}

	userLogin(login: LoginModel, clientId: string) {
		return from(this.#getExternalTokenHeader()).pipe(
			switchMap(externalTokenHeader =>
				this.#httpClient.post<AuthResponse>(
					`${this.#apiServer}/api/auth/login`,
					login,
					{
						headers: { ...externalTokenHeader, clientId },
					}
				)
			)
		);
	}

	adminLogin(login: LoginModel): Observable<AuthResponse> {
		return from(this.#getExternalTokenHeader()).pipe(
			switchMap(externalTokenHeader =>
				this.#httpClient.post<AuthResponse>(
					`${this.#apiServer}/api/auth/login/admin`,
					login,
					{
						headers: externalTokenHeader,
					}
				)
			)
		);
	}

	anonLogin(data: AnonLoginModel): Observable<AuthResponse> {
		return from(this.#getExternalTokenHeader()).pipe(
			switchMap(externalTokenHeader =>
				this.#httpClient.post<AuthResponse>(
					`${this.#apiServer}/api/auth/login/anon`,
					data,
					{
						headers: externalTokenHeader,
					}
				)
			)
		);
	}

	verifyToken(): Observable<AuthResponse> {
		return from(this.#getExternalTokenHeader()).pipe(
			switchMap(externalTokenHeader =>
				this.#httpClient.get<AuthResponse>(
					`${this.#apiServer}/api/auth/token/validate`,
					{
						headers: externalTokenHeader,
					}
				)
			)
		);
	}

	pinLogin(
		data: PinLoginModel,
		settings: { clientLogin: boolean } = { clientLogin: false }
	): Observable<AuthResponse> {
		return from(this.#getExternalTokenHeader()).pipe(
			switchMap(externalTokenHeader =>
				this.#httpClient.post<AuthResponse>(
					`${this.#apiServer}/api/auth/login/pin`,
					data,
					{
						headers: externalTokenHeader,
						params: new HttpParams({ fromObject: settings }),
					}
				)
			)
		);
	}

	resetPassword(
		{ newPasswordRepeat, ...data }: ChangePasswordModel,
		authToken: string
	): Observable<AuthResponse> {
		return from(this.#getExternalTokenHeader()).pipe(
			switchMap(externalTokenHeader =>
				this.#httpClient.post<AuthResponse>(
					`${this.#apiServer}/api/auth/password/change`,
					{ ...data, authToken },
					{ headers: externalTokenHeader }
				)
			)
		);
	}

	forgotPassword(data: ForgotPasswordModel): Observable<void> {
		return this.#httpClient.post<void>(
			`${this.#apiServer}/api/auth/password/forgot`,
			data
		);
	}

	saveEmail(data: AddEmailModel, authToken: string): Observable<AuthResponse> {
		return from(this.#getExternalTokenHeader()).pipe(
			switchMap(externalTokenHeader =>
				this.#httpClient.patch<AuthResponse>(
					`${this.#apiServer}/api/auth/email/update`,
					{ ...data, authToken },
					{ headers: externalTokenHeader }
				)
			)
		);
	}

	sendEmail(authToken: string): Observable<void> {
		return this.#httpClient.post<void>(
			`${this.#apiServer}/api/auth/email/send`,
			{ authToken }
		);
	}

	verifyEmail(authToken: string): Observable<AuthResponse> {
		return from(this.#getExternalTokenHeader()).pipe(
			switchMap(externalTokenHeader =>
				this.#httpClient.post<AuthResponse>(
					`${this.#apiServer}/api/auth/email/verify`,
					{ authToken },
					{ headers: externalTokenHeader }
				)
			)
		);
	}

	savePhone(
		data: AddPhoneNumberModel,
		authToken: string
	): Observable<AuthResponse> {
		return from(this.#getExternalTokenHeader()).pipe(
			switchMap(externalTokenHeader =>
				this.#httpClient.patch<AuthResponse>(
					`${this.#apiServer}/api/auth/phone/update`,
					{ ...data, authToken },
					{ headers: externalTokenHeader }
				)
			)
		);
	}

	sendSms(authToken: string): Observable<void> {
		return this.#httpClient.post<void>(
			`${this.#apiServer}/api/auth/phone/send`,
			{ authToken }
		);
	}

	verifyPhone(
		data: VerifyPhoneModel,
		authToken: string
	): Observable<AuthResponse> {
		return from(this.#getExternalTokenHeader()).pipe(
			switchMap(externalTokenHeader =>
				this.#httpClient.post<AuthResponse>(
					`${this.#apiServer}/api/auth/phone/verify`,
					{ ...data, authToken },
					{ headers: externalTokenHeader }
				)
			)
		);
	}

	verifyTwoFactor(
		data: TwoFactorModel,
		authToken: string
	): Observable<AuthResponse> {
		return from(this.#getExternalTokenHeader()).pipe(
			switchMap(externalTokenHeader =>
				this.#httpClient.post<AuthResponse>(
					`${this.#apiServer}/api/auth/2fa/confirm`,
					{ ...data, authToken },
					{ headers: externalTokenHeader }
				)
			)
		);
	}

	loginAs(userId: string): Observable<AuthResponse> {
		return this.#httpClient.get<AuthResponse>(
			`${this.#apiServer}/api/auth/token`,
			{
				params: { userId },
			}
		);
	}

	getRedirect(): Observable<string> {
		return this.#httpClient.get<string>(`${this.#apiServer}/api/auth/redirect`);
	}

	updateCookieConsent(consentStatus: boolean): Observable<void> {
		return this.#httpClient.patch<void>(
			`${this.#apiServer}/api/user/cookie-policy-acceptance`,
			{
				policyAccepted: consentStatus,
			}
		);
	}
}
