import { ValueEqualityFn } from '@angular/core';

/**
 * Always notify on inner notification (always distinct).
 *
 * @remarks Pass this function as the `equal` option for `ComponentStore#select`
 * of selectors emitting `undefined` (`Observable<void>`).
 *
 * @param _a Ignored
 * @param _b Ignored
 * @returns Always returns `false` to signify a distinct value.
 *
 * @example
 * ```typescript
 * export class MyStore extends ComponentStore<MyState> {
 *   readonly eventChange$ = this.select(
 *     this.#eventId$,
 *     () => undefined,
 *     {
 *       equal: coAlwaysDistinct
 *     },
 *   );
 * }
 * ```
 */
export const coAlwaysDistinct: ValueEqualityFn<unknown> = <T>(_a: T, _b: T) =>
	false;
