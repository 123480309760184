<div
	matSnackBarLabel
	(click)="onMessageClick()"
	[class.tw-cursor-pointer]="!!data.onClick"
	class="tw-flex tw-flex-row tw-items-center tw-space-x-2"
>
	<fa-icon
		*ngIf="data.type !== 'plain' && data.icon === null"
		[icon]="typeIcon"
		[class]="{
			'tw-text-green-500': data.type === 'success',
			'tw-text-blue-500': data.type === 'info',
			'tw-text-yellow-500': data.type === 'warning',
		}"
	/>
	<span
		[class]="{
			'tw-text-green-500': data.type === 'success',
			'tw-text-blue-500': data.type === 'info',
			'tw-text-yellow-500': data.type === 'warning',
		}"
	>
		<i
			*ngIf="data.type !== 'plain' && data.icon !== null"
			class="tw-mr-2"
			[class]="data.icon"
		></i>
	</span>
	<div class="tw-flex tw-flex-row">
		<div class="tw-flex tw-flex-col">
			<div *ngIf="data.title" class="tw-font-bold">{{ data.title }}</div>
			<div *ngIf="!data.isHtml">{{ data.message }}</div>
			<div *ngIf="data.isHtml" [innerHTML]="data.message"></div>
		</div>
	</div>
</div>
<div matSnackBarActions>
	<button matSnackBarAction mat-button (click)="onMessageDismiss()">
		CLOSE
	</button>
</div>
