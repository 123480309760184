/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { harmonicaAnimation } from '@consensus/co/ui-component-animations';
import { InputBaseComponent } from '@lib/forms';

@Component({
	standalone: true,
	selector: 'co-form-plain-input',
	imports: [
		NgIf,
		ReactiveFormsModule,
		ClipboardModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatInputModule,
		MatTooltipModule,
	],
	templateUrl: './plain-input.component.html',
	styleUrls: ['./plain-input.component.scss'],
	animations: [harmonicaAnimation()],
})
export class PlainInputComponent<TVal> extends InputBaseComponent<TVal> {
	copied = false;

	onCopied() {
		this.copied = true;
		setTimeout(() => (this.copied = false), 2000);
	}
}
