import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Livestream } from './livestream.model';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class LivestreamClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #httpClient = inject(HttpClient);

	loadUserLivestreams() {
		return this.#httpClient.get<Livestream[]>(
			`${this.#apiServer}/api/livestream`
		);
	}
}
