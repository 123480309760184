<div class="wrapper" [@harmonica]="show">
	<mat-form-field
		[class.has-no-label]="!hasLabel"
		[appearance]="appearance"
		[hideRequiredMarker]="hideRequired"
		[color]="color"
		[matTooltip]="tooltip"
		matTooltipPosition="above"
	>
		<mat-label>
			{{ label }}
			<i class="fad fa-question-circle" *ngIf="tooltip"></i>
		</mat-label>

		<input
			matInput
			[type]="showPassword ? 'text' : 'password'"
			[placeholder]="placeholder"
			[formControl]="control"
			[required]="required"
			[autocomplete]="autocomplete"
			[readonly]="readonly"
			[autofocus]="autofocus"
			#input
		/>

		<button
			mat-icon-button
			matIconSuffix
			(mousedown)="$event.preventDefault()"
			(click)="toggleShow($event)"
			type="button"
			tabindex="-1"
		>
			<i
				class="far fa-xs"
				[class]="showPassword ? 'fa-eye-slash' : 'fa-eye'"
			></i>
		</button>

		<mat-error *ngIf="hasErrors">{{ inputError }}</mat-error>
		<mat-hint *ngIf="hint">{{ hint }}</mat-hint>
	</mat-form-field>
</div>
