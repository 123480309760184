<div class="wrapper" [@harmonica]="show">
	<mat-form-field
		[class.has-no-label]="!hasLabel"
		[appearance]="appearance"
		[hideRequiredMarker]="hideRequired"
		[color]="color"
		[matTooltip]="tooltip"
		matTooltipPosition="above"
	>
		<mat-label>
			{{ label }}
			<i class="fad fa-question-circle" *ngIf="tooltip"></i>
		</mat-label>

		<input
			matInput
			[placeholder]="placeholder"
			[required]="required"
			[disabled]="isDisabled"
			[(ngModel)]="inputValue"
			[autocomplete]="autocomplete"
			[autofocus]="autofocus"
			[ngxMatTimepicker]="timepicker"
			[readonly]="readonly"
			#input
		/>

		<ngx-mat-timepicker #timepicker />
		<ngx-mat-timepicker-toggle matIconSuffix [for]="timepicker" />

		<mat-error *ngIf="hasErrors">{{ inputError }}</mat-error>
		<mat-hint *ngIf="hint">{{ hint }}</mat-hint>
	</mat-form-field>
</div>
