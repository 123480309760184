import { Injectable } from '@angular/core';
import { Badge, PermissionStatus } from '@capawesome/capacitor-badge';

@Injectable({ providedIn: 'root' })
export class ConsensusCapacitorBadgeService {
	async get(): Promise<number> {
		const isSupported = await this.isSupported();
		if (!isSupported) {
			return 0;
		}
		const result = await Badge.get();
		return result.count;
	}

	async set(count: number): Promise<void> {
		const isSupported = await this.isSupported();
		if (!isSupported) {
			return;
		}
		await Badge.set({ count });
	}

	async increase(): Promise<void> {
		const isSupported = await this.isSupported();
		if (!isSupported) {
			return;
		}
		await Badge.increase();
	}

	async decrease(): Promise<void> {
		const isSupported = await this.isSupported();
		if (!isSupported) {
			return;
		}
		await Badge.decrease();
	}

	async clear(): Promise<void> {
		const isSupported = await this.isSupported();
		if (!isSupported) {
			return;
		}
		await Badge.clear();
	}

	async isSupported(): Promise<boolean> {
		const result = await Badge.isSupported();
		if (!result.isSupported) {
			console.warn('Badge plugin is not supported');
		}
		return result.isSupported;
	}

	async checkPermissions(): Promise<PermissionStatus> {
		const isSupported = await this.isSupported();
		if (!isSupported) {
			return { display: 'denied' };
		}
		return await Badge.checkPermissions();
	}

	async requestPermissions(): Promise<PermissionStatus> {
		const isSupported = await this.isSupported();
		if (!isSupported) {
			return { display: 'denied' };
		}
		return await Badge.requestPermissions();
	}
}
