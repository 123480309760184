import { UploadScope } from './upload-scopes.enum';

export function validateMimeType(
	scope: UploadScope,
	mimeType: string
): boolean {
	switch (scope) {
		case UploadScope.All:
			return true;
		case UploadScope.Supported:
			return (
				validateMimeType(UploadScope.Video, mimeType) ||
				validateMimeType(UploadScope.Image, mimeType) ||
				validateMimeType(UploadScope.Audio, mimeType) ||
				validateMimeType(UploadScope.Pdf, mimeType)
			);
		case UploadScope.Video:
			return mimeType.search(/^video\//) >= 0;
		case UploadScope.Image:
			return mimeType.search(/^image\//) >= 0;
		case UploadScope.Audio:
			return mimeType.search(/^audio\//) >= 0;
		case UploadScope.Pdf:
			return mimeType === UploadScope.Pdf;
		case UploadScope.Xlsx:
			return mimeType === UploadScope.Xlsx;
		default:
			return true;
	}
}
