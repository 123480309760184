import { inject, InjectionToken } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import {
	CaptureOptions,
	CaptureResult,
	IsFeatureEnabledOptions,
	posthog,
	PostHog,
	Properties,
	RequestCallback,
} from 'posthog-js';
import {
	ConnectEnvironment,
	environmentToken,
} from '@environments/environment';
import { PosthogCapturePolicy } from '@consensus/shared/shared/analytics/domain';
import { CoPosthogFeatureFlag } from './posthog-feature-flag';

type PostHogPeople = PostHog['people'];

type CoPostHogPeople = Pick<PostHogPeople, 'set' | 'set_once'>;

export class CoPostHogWrapper
	implements
		Pick<
			PostHog,
			| 'opt_in_capturing'
			| 'opt_out_capturing'
			| 'clear_opt_in_out_capturing'
			| 'capture'
			| 'identify'
			| 'reset'
			| 'alias'
			| 'register_once'
			| 'register'
			| 'isFeatureEnabled'
		>
{
	instance: PostHog | null;
	constructor(posthog: PostHog | null) {
		this.instance = posthog;
	}

	people: CoPostHogPeople = {
		set: (
			prop: string | Properties,
			to?: string,
			callback?: RequestCallback
		): void => {
			return this.instance?.people.set(prop, to, callback);
		},
		// eslint-disable-next-line @typescript-eslint/naming-convention
		set_once: (
			prop: string | Properties,
			to?: string,
			callback?: RequestCallback
		) => {
			return this.instance?.people.set_once(prop, to, callback);
		},
	};

	// eslint-disable-next-line @typescript-eslint/naming-convention
	opt_out_capturing(): void {
		return this.instance?.opt_out_capturing();
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention
	opt_in_capturing(options?: {
		captureEventName?: string | null | false;
		captureProperties?: Properties;
	}): void {
		return this.instance?.opt_in_capturing(options);
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention
	clear_opt_in_out_capturing(): void {
		return this.instance?.clear_opt_in_out_capturing();
	}

	capture(
		// eslint-disable-next-line @typescript-eslint/naming-convention
		event_name: string,
		properties?: Properties,
		options?: CaptureOptions
	): void | CaptureResult {
		return this.instance?.capture(event_name, properties, options);
	}

	identify(
		// eslint-disable-next-line @typescript-eslint/naming-convention
		new_distinct_id?: string,
		userPropertiesToSet?: Properties,
		userPropertiesToSetOnce?: Properties
	): void {
		return this.instance?.identify(
			new_distinct_id,
			userPropertiesToSet,
			userPropertiesToSetOnce
		);
	}

	reset(
		// eslint-disable-next-line @typescript-eslint/naming-convention
		reset_device_id?: boolean
	): void {
		return this.instance?.reset(reset_device_id);
	}

	register(properties: Properties, days?: number): void {
		return this.instance?.register(properties, days);
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention
	register_once(properties: Properties, days?: number): void {
		return this.instance?.register_once(properties, days);
	}

	alias(alias: string, original?: string): number | void | CaptureResult {
		return this.instance?.alias(alias, original);
	}

	isFeatureEnabled(
		key: CoPosthogFeatureFlag,
		options?: IsFeatureEnabledOptions | undefined
	): boolean | undefined {
		return this.instance?.isFeatureEnabled(key, options);
	}
}

/**
 * Create an initialized PostHog instance.
 *
 * @throws {Error} When PostHog fails to initialize.
 */
function createPosthog(
	posthogConfig: ConnectEnvironment['posthog']
): PostHog | never {
	const {
		apiHost,
		uiHost,
		apiKey,
		autocaptureIfEnabled,
		capturePolicy,
		disableSessionRecording,
		persistence,
	} = posthogConfig;
	const optOutByDefaultCapturePolicies: readonly PosthogCapturePolicy[] = [
		'DoNotCapture',
		'RequireInformedConsent',
	];
	const maybePosthogInstance = posthog.init(apiKey ?? 'null', {
		/* eslint-disable @typescript-eslint/naming-convention */
		api_host: apiHost ?? undefined,
		ui_host: uiHost ?? undefined,
		autocapture: autocaptureIfEnabled,
		disable_session_recording: disableSessionRecording,
		persistence,
		opt_out_capturing_by_default:
			optOutByDefaultCapturePolicies.includes(capturePolicy),
		/*eslint-enable @typescript-eslint/naming-convention */
	});

	if (maybePosthogInstance === undefined) {
		throw new Error('PostHog failed to initialize.');
	}

	return maybePosthogInstance as PostHog;
}

/**
 * Unlike Sentry, we have no way of initializing PostHog in the code without also
 * activating it. So, instead of having to add manual checks for whether PostHog is
 * activated at all manual capture points, we create a wrapper that includes these checks
 */
function posthogFactory(): CoPostHogWrapper {
	const { posthog: posthogConfig } = inject(environmentToken);
	const { capturePolicy, enabled } = posthogConfig;

	const posthogWrapper = new CoPostHogWrapper(
		enabled ? createPosthog(posthogConfig) : null
	);

	if (capturePolicy === 'DoNotCapture') {
		/**
		 * See https://posthog.com/docs/privacy/gdpr-compliance
		 */
		posthogWrapper.opt_out_capturing();
	}

	return posthogWrapper;
}

export const posthogToken = new InjectionToken<CoPostHogWrapper>(
	'posthogToken',
	{
		providedIn: 'root',
		factory: posthogFactory,
	}
);
