<div
	class="zoom-wrapper"
	#zoomWrapper
	[style.transform]="'scale(' + zoom / 100 + ')'"
	[class]="heightLock ? 'height' : 'width'"
>
	<div class="relative-wrapper" #content>
		<co-encoded-media
			[src]="src"
			(domLoaded)="updateDimensions($event)"
			[muted]="true"
			[autoplay]="true"
			[loop]="loop"
			(loaded)="loaded.emit()"
			[showOnlyLastVideoFrame]="
				videoOutroService.setVideoToLastFrame$ | async
			"
			(videoEnded)="
				enablePlayOutroVideo && videoOutroService.outroVideoEnded()
			"
			(videoFailedToLoad)="
				enablePlayOutroVideo &&
					videoOutroService.videoFailedToLoad(
						src,
						enablePlayOutroVideo
					)
			"
		/>
		<ng-content />
	</div>
</div>

<div
	class="padding"
	*ngIf="useAspectRatio"
	[style.paddingBottom]="((1 / aspectRatio) * 100).toFixed(2) + '%'"
></div>
