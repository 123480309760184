import { Injector, ProviderToken } from '@angular/core';

let theGlobalInjector: Injector;

export function setGlobalInjector(injector: Injector) {
	if (theGlobalInjector != null) {
		console.error(
			'`setGlobalInjector` was called more than once. Ignoring `injector` argument: ',
			injector
		);
	} else {
		if (injector == null) {
			throw new Error(
				'`null` or `undefined` was passed to `setGlobalInjector`.'
			);
		}

		theGlobalInjector = injector;
	}
}

export const globalInjector = {
	/**
	 * Retrieves an instance from the injector based on the provided token.
	 * @returns The instance from the injector if defined, otherwise throws.
	 * @throws When the the instance from the injector is not defined.
	 */
	get<T>(token: ProviderToken<T>): T {
		if (theGlobalInjector == null) {
			throw new Error('`setGlobalInjector` has not been called.');
		}

		return theGlobalInjector.get(token);
	},
};
