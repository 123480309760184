/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	inject,
	Input,
	OnDestroy,
	Output,
	ViewChild,
} from '@angular/core';
import { FileTypes } from '@consensus/shared/shared/files/domain';
import { FileCacheService } from '@consensus/shared/shared/files/data-access-files';
import { FileUpload, mimeToFileType } from '@lib/files';
import { Observable, Subscription } from 'rxjs';
import { BypassSecurityTrustResourceUrlPipe } from '@shared/pipes';

import { EncodedPdfComponent } from '../encoded-pdf/encoded-pdf.component';
import { ModalComponent } from '../modal/modal.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FileDropDirective } from '@shared/directives';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
	selector: 'co-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.scss'],
	standalone: true,
	imports: [
		NgIf,
		FileDropDirective,
		MatTooltipModule,
		MatButtonModule,
		NgSwitch,
		NgSwitchCase,
		ModalComponent,
		EncodedPdfComponent,
		BypassSecurityTrustResourceUrlPipe,
	],
})
export class FileUploadComponent implements OnDestroy {
	readonly #changeDetectorRef = inject(ChangeDetectorRef);
	readonly #fileService = inject(FileCacheService);
	@Input() noDelete = false;
	@Input() label: string;
	@Input() showTooltip: boolean;
	@Input() tooltipText: string;
	@Input() disabled = false;

	_uploadProgress: number;
	_uploadProgressSub = new Subscription();
	@Input() set uploadProgress(x: number | Observable<number>) {
		if (x instanceof Observable) {
			this._uploadProgressSub.unsubscribe();
			this._uploadProgressSub = x.subscribe(p => (this._uploadProgress = p));
			return;
		}

		this._uploadProgress = x;
	}
	@Input() uploadProgressThumb: number | Observable<number>;
	@Input() type: string;
	@Input() deleted = false;

	oldUrl: string;
	@Input() set src(url: string) {
		if (!url || url === this.oldUrl) {
			return;
		}
		this.#loadFile(url);
	}

	file: File;
	@Output() upload = new EventEmitter<FileUpload>();
	@Output() uploadThumb = new EventEmitter<File>();
	@Output() delete = new EventEmitter<void>();
	@Output() deleteThumb = new EventEmitter<void>();

	@ViewChild('uploadInput', { static: true })
	uploadInput: ElementRef<HTMLInputElement>;

	fileType: FileTypes;
	fileName: string;
	fileTypesEnum = FileTypes;

	_fileUrl: string;
	get fileUrl() {
		return this.deleted ? null : this._fileUrl;
	}
	set fileUrl(url: string) {
		this._fileUrl = url;
	}

	loading = false;
	showBig = false;

	#loadFile(src: string) {
		this.oldUrl = src;

		this.fileUrl = null;
		this.fileType = null;
		this.fileName = null;

		this.loading = true;
		this.#fileService
			.getFile(src, true)
			.then(file => {
				this.fileUrl = file.url;
				this.fileType = mimeToFileType(file.fileType);
				this.fileName = file.fileName;
			})
			.finally(() => {
				this.loading = false;
				this.#changeDetectorRef.markForCheck(); // Needed for onPush contexts
			});
	}

	uploadFile(event) {
		const files = event.dataTransfer
			? event.dataTransfer.files
			: (event.target.files as FileList);
		if (!files) {
			return;
		}

		const file = files.length > 0 ? files.item(0) : null;
		if (!file) {
			return;
		}

		this.file = file;

		this.fileType = mimeToFileType(file.type);
		this.fileName = file.name;

		this.reset();
		this.fileUrl = URL.createObjectURL(this.file);

		if (this.oldUrl) {
			this.upload.emit({ file: this.file, type: this.fileType });
		}
	}

	uploadClick() {
		if (!this.file) {
			return;
		}
		this.upload.emit({ file: this.file, type: this.fileType });
		this.file = null;
		this.fileUrl = null;
		this.fileName = null;
		this.fileType = null;
		this.uploadInput.nativeElement.value = null;
	}

	reset() {
		if (this._fileUrl && this._fileUrl.substring(0, 5) === 'blob:') {
			URL.revokeObjectURL(this._fileUrl);
		}
	}

	ngOnDestroy() {
		this.reset();
		this._uploadProgressSub.unsubscribe();
	}

	onDelete() {
		this.delete.emit();
		this.reset();
		this.file = null;
		this.fileUrl = null;
		this.fileName = null;
		this.fileType = null;
		this.uploadInput.nativeElement.value = '';
	}
}
