/* eslint-disable no-restricted-syntax */
// no-restricted-syntax: Allow console.debug usage
import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivateFn,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { SessionService } from '@store/scope';
import { getRouteParams } from '@lib/helpers';

export const loggedInGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
) => {
	const sessionService = inject(SessionService);
	const router = inject(Router);

	console.debug('# Logged In Guard');

	if (!sessionService.isLoggedIn()) {
		const params = getRouteParams(route);

		const eventName = params['eventName'];

		sessionStorage.setItem('redirect', state.url);

		if (eventName) {
			console.debug(
				`Not logged in - Redirecting to Event login [${eventName}]`
			);
			router.navigate([`/e/${eventName}`]);
			return false;
		}

		const clientName = params['clientName'];

		if (clientName) {
			console.debug(
				`Not logged in - Redirecting to Client login [${clientName}]`
			);
			router.navigate([`/c/${clientName}`]);
			return false;
		}

		console.debug(`Not logged in - Redirecting to Root`);
		router.navigate(['/']);
		return false;
	}

	const redirect = sessionStorage.getItem('redirect');

	if (redirect) {
		sessionStorage.removeItem('redirect');
		console.debug(
			`Logged in with saved redirect - Redirecting to "${redirect}"`
		);
		router.navigate([redirect]);
		return false;
	}

	console.debug(`Logged in`);
	return true;
};
