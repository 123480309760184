import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import {
	AdminUser,
	TokenModel,
	UserClaim,
	UserInformation,
} from '@shared/models';
import {
	EventParticipant,
	EventParticipantsToggleModel,
} from './client-users.model';
import {
	CreateUserClaimModel,
	CreateUserModel,
	UpdatePasswordModel,
	UpdateUserClaimModel,
	UpdateUserModel,
} from './users.model';
import { CreateAdminModel, UpdateAdminModel } from './admin.models';
import {
	DistributionSummary,
	DistributionSummaryLoadModel,
} from '@store/distributions';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class UsersClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #httpClient = inject(HttpClient);

	searchUsers(query: string) {
		return this.#httpClient.get<UserInformation[]>(
			`${this.#apiServer}/api/users/search`,
			{
				params: { query },
			}
		);
	}

	searchContentOwners(query: string) {
		return this.#httpClient.get<UserInformation[]>(
			`${this.#apiServer}/api/users/search/content-owners`,
			{ params: { query } }
		);
	}

	loadEventCmsUsers() {
		return this.#httpClient.get<AdminUser[]>(
			`${this.#apiServer}/api/user/event/all`
		);
	}

	loadOnlineUsers() {
		return this.#httpClient.get<string[]>(
			`${this.#apiServer}/api/user/connected`
		);
	}

	loadClientCmsUsers() {
		return this.#httpClient.get<AdminUser[]>(
			`${this.#apiServer}/api/user/client/all`
		);
	}

	loadCmsAdmins() {
		return this.#httpClient.get<AdminUser[]>(
			`${this.#apiServer}/api/user/admins/all`
		);
	}

	loadEventParticipants() {
		return this.#httpClient.get<EventParticipant[]>(
			`${this.#apiServer}/api/user/event/participants`
		);
	}

	createUser(data: CreateUserModel) {
		return this.#httpClient.post<AdminUser>(
			`${this.#apiServer}/api/user`,
			data
		);
	}

	updateUser({ id, ...data }: UpdateUserModel) {
		return this.#httpClient.put<AdminUser>(
			`${this.#apiServer}/api/user/${id}`,
			data
		);
	}

	deleteUser(id: string) {
		return this.#httpClient.delete<void>(`${this.#apiServer}/api/user/${id}`);
	}

	createAdmin(data: CreateAdminModel) {
		return this.#httpClient.post<AdminUser>(
			`${this.#apiServer}/api/user/admins`,
			data
		);
	}

	updateAdmin({ id, ...data }: UpdateAdminModel) {
		return this.#httpClient.put<AdminUser>(
			`${this.#apiServer}/api/user/admin/${id}`,
			data
		);
	}

	updatePassword({ userId, ...data }: UpdatePasswordModel) {
		return this.#httpClient.patch<void>(
			`${this.#apiServer}/api/user/${userId}/password`,
			data
		);
	}

	addUserToEvent({ eventId, userId }: EventParticipantsToggleModel) {
		return this.#httpClient.post<EventParticipant>(
			`${this.#apiServer}/api/user/${userId}/event/${eventId}`,
			{}
		);
	}

	removeUserFromEvent({ eventId, userId }: EventParticipantsToggleModel) {
		return this.#httpClient.delete<void>(
			`${this.#apiServer}/api/user/${userId}/event/${eventId}`
		);
	}

	getUserUploadToken(userId: string) {
		return this.#httpClient
			.get<TokenModel>(`${this.#apiServer}/api/user/${userId}/upload`)
			.pipe(map(x => x.token));
	}

	uploadRoster({ structureFile, userFile }: RosterUploadModel) {
		const formData = new FormData();

		if (structureFile) {
			formData.append('StructureFile', structureFile);
		}

		if (userFile) {
			formData.append('UserFile', userFile);
		}

		return this.#httpClient.post<void>(
			`${this.#apiServer}/api/user/import/novo`,
			formData
		);
	}

	createUserClaim(data: CreateUserClaimModel) {
		return this.#httpClient.post<UserClaim>(
			`${this.#apiServer}/api/user/claim`,
			data
		);
	}

	updateUserClaim({ id, ...data }: UpdateUserClaimModel) {
		return this.#httpClient.put<UserClaim>(
			`${this.#apiServer}/api/user/claim/${id}`,
			data
		);
	}

	deleteUserClaim(id: string) {
		return this.#httpClient.delete<void>(
			`${this.#apiServer}/api/user/claim/${id}`
		);
	}

	distributionSummary(data: DistributionSummaryLoadModel) {
		return this.#httpClient.post<DistributionSummary>(
			`${this.#apiServer}/api/user/distribution/summary`,
			data
		);
	}
}

export interface RosterUploadModel {
	structureFile: File;
	userFile: File;
}
