/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { NgForOf, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { harmonicaAnimation } from '@consensus/co/ui-component-animations';
import { InputBaseComponent } from '@lib/forms';
import { NoClickBubbleDirective } from '@shared/directives';
import {
	CountryCode,
	getCountries,
	getCountryCallingCode,
	parsePhoneNumberFromString,
} from 'libphonenumber-js';
import { sortAlphAsc } from '@lib/helpers';
import { isNil } from 'lodash-es';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
	standalone: true,
	selector: 'co-form-phone-input',
	imports: [
		NgIf,
		FormsModule,
		MatInputModule,
		MatTooltipModule,
		NoClickBubbleDirective,
		MatSelectModule,
		NgForOf,
		NgSelectModule,
	],
	templateUrl: './phone-input.component.html',
	styleUrls: ['./phone-input.component.scss'],
	animations: [harmonicaAnimation()],
})
export class PhoneInputComponent extends InputBaseComponent<string> {
	countries = countries;
	_country: CountryCode = 'US';
	set country(c: CountryCode) {
		if (c == this._country) {
			return;
		}
		this._country = c;
		this.createPreview(this.inputValue);
	}
	get country() {
		return this._country;
	}

	preview: string;

	preprocessValue(value) {
		const number = parsePhoneNumberFromString(value ?? '');
		this.country = number?.country ?? this.country ?? 'US';
		this.createPreview(value);
		return number?.nationalNumber ?? '';
	}

	postprocessValue(value) {
		if (isNil(value) || value?.trim()?.length < 1) {
			return null;
		}
		const code = getCountryCallingCode(this.country)?.toString() ?? '1';
		return `+${code}${value}`;
	}

	setCode() {
		this.inputElement?.nativeElement?.focus();
		// eslint-disable-next-line no-self-assign -- this will trigger a set value
		this.inputValue = this.inputValue;
	}

	createPreview(value: string) {
		const number = parsePhoneNumberFromString(value ?? '', this.country);
		this.preview = number?.formatInternational();
	}

	search(query: string, code: PhoneCode) {
		const q = query.toLowerCase();
		return code.country.toLowerCase().includes(q) || code.extension.includes(q);
	}
}

interface PhoneCode {
	country: CountryCode;
	extension: string;
	flag: string;
}

const blacklist = ['AC', 'TA'];

const countries: PhoneCode[] = getCountries()
	.filter(x => !blacklist.includes(x))
	.sort(sortAlphAsc(x => x))
	.map(x => ({
		country: x,
		extension: getCountryCallingCode(x).toString(),
		flag: `https://storage.googleapis.com/co-eu-connect-prod-public/flags/4x3/${x.toLowerCase()}.svg`,
	}));
