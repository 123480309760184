<label class="input-label" *ngIf="label">{{ label }}</label>

<div class="slider-wrapper">
	<div class="slider" #slider>
		<div class="range" #range></div>

		<div class="min-wrapper handle-wrapper">
			<div
				class="handle min-handle"
				#minHandle
				cdkDrag
				cdkDragLockAxis="x"
				cdkDragBoundary=".handle-wrapper"
				[cdkDragDisabled]="readonly"
				(cdkDragEnded)="dropStart($event)"
				(cdkDragMoved)="moveStart($event)"
				[matTooltip]="startControl.label"
				[matTooltipDisabled]="!startControl.label"
			></div>
		</div>

		<div class="max-wrapper handle-wrapper">
			<div
				class="handle max-handle"
				#maxHandle
				cdkDrag
				cdkDragLockAxis="x"
				cdkDragBoundary=".handle-wrapper"
				[cdkDragDisabled]="readonly"
				(cdkDragEnded)="dropEnd($event)"
				(cdkDragMoved)="moveEnd($event)"
				[matTooltip]="endControl.label"
				[matTooltipDisabled]="!endControl.label"
			></div>
		</div>
	</div>
</div>

<div class="labels">
	<div
		class="slider-label"
		*ngFor="let label of labels; trackBy: trackByIdentity"
		[style.left]="label.position + '%'"
		[style.width]="label.width + '%'"
	>
		<div class="line"></div>
		<label>{{ label.label }}</label>
	</div>
</div>
