import { Pipe, PipeTransform } from '@angular/core';
import pluralize from 'pluralize';

@Pipe({
	standalone: true,
	name: 'plural',
})
export class PluralPipe implements PipeTransform {
	transform(word: string): string {
		return pluralize.isPlural(word) ? word : pluralize.plural(word);
	}
}
