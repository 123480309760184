import { bootstrapApplication } from '@angular/platform-browser';
import { loadEnvironment } from '@environments/environment';
import { provideEnvironment } from '@environments/environment';
import {
	buildingBlocksPlugin,
	glossaryPlugin,
	imageOverloadPlugin,
} from '@lib/froala';
import FroalaEditor from 'froala-editor';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/third_party/font_awesome.min';

buildingBlocksPlugin(FroalaEditor);
glossaryPlugin(FroalaEditor);
imageOverloadPlugin(FroalaEditor);

window.addEventListener('dragover', e => {
	e.preventDefault();
});

window.addEventListener('drop', e => {
	e.preventDefault();
});

loadEnvironment()
	.then(environment =>
		bootstrapApplication(AppComponent, {
			...appConfig,
			providers: [provideEnvironment(environment), ...appConfig.providers],
		})
	)
	.catch(err => console.error(err));
