/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import {
	Component,
	DestroyRef,
	ElementRef,
	EventEmitter,
	inject,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterStore } from '@ngworker/router-component-store';
import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { MoveModel } from '@shared/models';
import { harmonicaAnimation } from '@consensus/co/ui-component-animations';
import { sortBySortingKeyAsc } from '@lib/helpers';
import { BaseComponent } from '@shared/component-bases';
import { BehaviorSubject, map, throttleTime } from 'rxjs';
import { clamp } from '@lib/helpers';
import { ActivatedRoute, Router } from '@angular/router';
import { DividerComponent } from '../divider/divider.component';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule } from '@angular/forms';
import { NoClickBubbleDirective } from '@shared/directives';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { SearchInputComponent } from '@shared/forms';
import { NgIf, NgFor } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
	selector: 'co-drop-list',
	templateUrl: './drop-list.component.html',
	styleUrls: ['./drop-list.component.scss'],
	animations: [harmonicaAnimation()],
	standalone: true,
	imports: [
		NgIf,
		SearchInputComponent,
		MatButtonModule,
		MatListModule,
		DragDropModule,
		NgFor,
		MatSlideToggleModule,
		NoClickBubbleDirective,
		FormsModule,
		MatDividerModule,
		DividerComponent,
	],
})
export class DropListComponent extends BaseComponent {
	readonly #destroy = inject(DestroyRef);
	readonly #route = inject(ActivatedRoute);
	readonly #router = inject(Router);
	readonly #routerStore = inject(RouterStore);
	readonly #element: ElementRef<HTMLElement> = inject(ElementRef);
	@Input() selection: string | null = null;
	@Output() selectionChange = new EventEmitter<string>();
	@Input() childSelection: string | null = null;
	@Output() childSelectionChange = new EventEmitter<string>();

	@Output() selected = new EventEmitter<[string, string?]>();

	@Output() extraClick = new EventEmitter<string>();
	@Output() childExtraClick = new EventEmitter<[string, string]>();
	@Output() visibleClick = new EventEmitter<string>();
	@Output() childVisibleClick = new EventEmitter<[string, string]>();

	@Input() bindChildren: string;
	@Input() bindHasChildren: string;

	@Input() bindLabel: string | ((x: any) => string);
	@Input() bindChildLabel: string | ((x: any) => string);
	@Input() bindIcon: string | ((x: any) => string);
	@Input() bindChildIcon: string | ((x: any) => string);
	@Input() bindExtra: string | ((x: any) => string);
	@Input() bindChildExtra: string | ((x: any) => string);
	@Input() bindVisible: string | ((x: any) => string);
	@Input() bindChildVisible: string | ((x: any) => string);

	@Input() search = false;
	@Input() visibilityToggle = false;
	@Input() collapsible = false;
	@Input() expandOnSelect = true;

	@Input() childName: string;
	@Input() parentName: string;

	@Input() routeId: string;
	@Input() childRouteId: string;

	expanded: { [id: string]: any } = {};

	get parents() {
		return this._items;
	}

	filteredParents: { id: string }[] = [];
	filteredChildren: { id: string; _parentId: string }[] = [];

	_items: { id: string }[];
	@Input() set items(items: { id: string }[]) {
		if (!items) {
			this._items = null;
			return;
		}

		const hasChildren = this.showDragChild() && this.bindChildren;

		const tempItems = items.map(x =>
			hasChildren
				? {
						...x,
						[this.bindChildren]: [...(x[this.bindChildren] ?? [])].sort(
							sortBySortingKeyAsc
						),
				  }
				: { ...x }
		);

		if (this.showDragParent()) {
			this._items = (tempItems as { id: string; sortingKey: number }[]).sort(
				sortBySortingKeyAsc
			);
		} else {
			this._items = tempItems;
		}

		this.searchQuery$.next(this.searchQuery$.value);
	}

	get items() {
		return this._items;
	}

	@Output() moveItem = new EventEmitter<MoveModel>();
	@Output() moveChild = new EventEmitter<MoveModel>();

	@Output() pressCreateChild = new EventEmitter<string>();
	@Output() createChild = new EventEmitter<{
		parentId: string;
		name: string;
	}>();
	@Output() pressCreateParent = new EventEmitter();
	@Output() createParent = new EventEmitter<string>();

	@ViewChild('createParentElement')
	newParentInput: ElementRef<HTMLInputElement>;

	creatingChild: string;
	creatingChildText = '';
	creatingParent = false;
	creatingParentText = '';

	hideInvisible = false;
	searching = false;
	searchQuery$ = new BehaviorSubject('');

	onInit() {
		this.monitorValue(this.searchQuery$.pipe(throttleTime(100)), query => {
			this.searching = query.length > 0;
			const removeInvisibleParents = this.hideInvisible && !!this.bindVisible;
			const removeInvisibleChildren =
				this.hideInvisible && (!!this.bindVisible || this.bindChildVisible);

			if (!this.searching) {
				return;
			}

			const q = query.toLowerCase();
			const parents = [];
			const children = [];

			this.parents.forEach(parent => {
				if (removeInvisibleParents && !this.getVisible(parent)) {
					return;
				}

				if (this.getName(parent).toLowerCase().includes(q)) {
					parents.push(parent);
				}
				if (!this.bindChildren) {
					return;
				}

				parent[this.bindChildren]?.forEach(child => {
					if (removeInvisibleChildren && !this.getVisible(child, true)) {
						return;
					}
					if (!this.getName(child, true).toLowerCase().includes(q)) {
						return;
					}
					children.push({ ...child, _parentId: parent.id });
				});
			});

			this.filteredParents = parents;
			this.filteredChildren = children;
		});

		if (this.selection) {
			this.expanded[this.selection] = true;
		}

		if (this.routeId) {
			this.#routerStore
				.selectRouteParam(this.routeId)
				.pipe(
					map(x => x ?? null),
					takeUntilDestroyed(this.#destroy)
				)
				.subscribe(selection => (this.selection = selection));
		}

		if (this.childRouteId) {
			this.#routerStore
				.selectRouteParam(this.childRouteId)
				.pipe(
					map(x => x ?? null),
					takeUntilDestroyed(this.#destroy)
				)
				.subscribe(childSelection => (this.childSelection = childSelection));
		}
	}

	isSelected(id: string, child = false) {
		if (!child) {
			return id === this.selection;
		}
		return id === this.childSelection;
	}

	select(id: string, parent: string = null, silent = false) {
		if (id) {
			this.#element.nativeElement.focus();
		}

		if (parent) {
			this.selected.emit([parent, id]);
		} else {
			this.selected.emit([id]);
		}

		if (this.expandOnSelect && !silent) {
			this.expanded[!parent ? id : parent] = true;
		}

		if (this.routeId || this.childRouteId) {
			if (parent) {
				this.#router.navigate(this.routeId ? [parent, id] : [id], {
					relativeTo: this.#route,
				});
			} else if (this.routeId) {
				this.#router.navigate([id], { relativeTo: this.#route });
			}
		}

		if (!parent) {
			this.childSelectionChange.emit(null);
			this.selectionChange.emit(id);
			return;
		}

		this.selectionChange.emit(parent);
		this.childSelectionChange.emit(id);
	}

	dropItem(event: CdkDragDrop<any[]>, parentId: string) {
		if (this.searching && parentId == null) {
			return;
		}
		if (parentId != null && !this.bindChildren) {
			return;
		}

		const item = event.item.data;

		const list =
			parentId == null
				? this.parents
				: this.parents?.find(x => x.id === parentId)?.[this.bindChildren] ?? [];

		if (list.length < 1) {
			return;
		}

		if (event.currentIndex >= list.length) {
			return;
		}
		let index = list[event.currentIndex].sortingKey;
		if (item.sortingKey < index) {
			index++;
		}
		if (item.sortingKey === index) {
			return;
		}

		if (parentId == null) {
			this.moveItem.emit({ id: item.id, sortingKey: index });
		} else {
			this.moveChild.emit({ id: item.id, sortingKey: index });
		}
	}

	showChildren(item: any) {
		return this.bindHasChildren ? !!item[this.bindHasChildren] : true;
	}

	getExtra(item: any, child = false): string {
		const bind = child ? this.bindChildExtra || this.bindExtra : this.bindExtra;
		return bind ? (bind instanceof Function ? bind(item) : item[bind]) : null;
	}

	getVisible(item: any, child = false): boolean {
		const bind = child
			? this.bindChildVisible || this.bindVisible
			: this.bindVisible;
		return bind ? (bind instanceof Function ? bind(item) : item[bind]) : null;
	}

	getName(item: any, child = false): string {
		const bind = child ? this.bindChildLabel || this.bindLabel : this.bindLabel;
		return bind ? (bind instanceof Function ? bind(item) : item[bind]) : 'N/A';
	}

	getIcon(item: any, child = false): string {
		const bind = child ? this.bindChildIcon || this.bindIcon : this.bindIcon;
		return bind ? (bind instanceof Function ? bind(item) : item[bind]) : null;
	}

	getChildren(item: any) {
		if (this.bindChildren) {
			return item[this.bindChildren] ?? [];
		}

		return [];
	}

	getId(index: number, item: any) {
		return `${index}__${item.id}`;
	}

	showCreateChild() {
		return this.createChild.observed || this.pressCreateChild.observed;
	}

	showCreateParent() {
		return this.createParent.observed || this.pressCreateParent.observed;
	}

	hasChildren(item: any) {
		return this.showCreateChild() || this.getChildren(item).length > 0;
	}

	getItems() {
		return this.searching ? this.filteredParents : this.parents;
	}

	showDragChild() {
		return this.moveChild.observed;
	}

	showDragParent() {
		return this.moveItem.observed && !this.searching;
	}

	addChild(id: string) {
		if (this.creatingChildText.length < 1) {
			return;
		}
		this.createChild.emit({ parentId: id, name: this.creatingChildText });
		this.creatingChild = null;
		this.creatingChildText = '';
	}

	addParent() {
		if (this.creatingParentText.length < 1) {
			return;
		}
		this.createParent.emit(this.creatingParentText);
		this.creatingParent = false;
		this.creatingParentText = '';
	}

	toggleNewChild(id: string) {
		if (this.creatingChild === id) {
			this.creatingChild = null;
			return;
		}

		if (this.pressCreateChild.observed) {
			this.pressCreateChild.emit(id);
			return;
		}

		this.creatingChild = id;
		setTimeout(() => {
			const item = this.#element.nativeElement.querySelector(
				`.create_form_${id}`
			) as HTMLInputElement;
			item?.focus();
		}, 0);
	}

	toggleNewParent() {
		if (this.creatingParent) {
			this.creatingParent = false;
			return;
		}

		if (this.pressCreateParent.observed) {
			this.pressCreateParent.emit();
			return;
		}

		this.creatingParent = true;
		setTimeout(() => {
			this.newParentInput?.nativeElement?.focus();
		}, 0);
	}

	rootKeyDown(event: KeyboardEvent) {
		if (event.code === 'Escape') {
			this.select(null, null);
			return;
		}

		const items = this.getItems() ?? [];
		const parentIndex = this.selection
			? items.findIndex(x => x.id === this.selection)
			: null;
		const parent = parentIndex != null ? items[parentIndex] : null;
		const children = parent ? this.getChildren(parent) ?? [] : [];
		const childIndex = this.childSelection
			? children.findIndex(x => x.id === this.childSelection)
			: null;
		const child = childIndex != null ? children[childIndex] : null;

		if (event.code === 'ArrowLeft') {
			event.preventDefault();
			if (child) {
				this.select(parent.id, null, true);
			}
			return;
		}

		if (event.code === 'ArrowRight') {
			event.preventDefault();
			if (!parent && items.length > 0) {
				this.select(items[0].id, null, true);
			}
			if (children.length > 0 && !child) {
				this.select(children[0].id, parent.id);
			}
			return;
		}

		let up: boolean;
		if (event.code == 'ArrowUp') {
			up = true;
		}
		if (event.code == 'ArrowDown') {
			up = false;
		}
		if (up == null) {
			return;
		}

		event.preventDefault();

		if (!parent) {
			const newParent = items[up ? items.length - 1 : 0]?.id;
			this.select(newParent, null, true);
			return;
		}

		if (child) {
			const newIndex = clamp(
				up ? childIndex - 1 : childIndex + 1,
				0,
				children.length - 1
			);
			this.select(children[newIndex].id, parent.id);
		} else {
			const newIndex = clamp(
				up ? parentIndex - 1 : parentIndex + 1,
				0,
				items.length - 1
			);
			this.select(items[newIndex].id, null, true);
		}
	}

	inputKeyDown(e: KeyboardEvent) {
		e.stopPropagation();
		if (e.code === 'Escape') {
			(e.target as HTMLElement).blur();
		}
	}

	toggleHideInvisible() {
		this.hideInvisible = !this.hideInvisible;
		this.searchQuery$.next(this.searchQuery$.value);
	}
}
