import { CustomStore } from '@lib/redux';
import {
	ModularDashboardLoadModel,
	ModularDashboardMixedBatchIds,
	ModularDashboardMixedBatchModels,
} from './modular-dashboard.model';
import { sortBySortingKeyAsc } from '@lib/helpers';
import { ModularDashboardClient } from './modular-dashboard.client';
import { appendAndOverride, moveListItem } from '@lib/redux';
import { BaseState, MoveModel } from '@shared/models';

export interface ModularDashboardState
	extends BaseState,
		ModularDashboardLoadModel {}

export const modularDashboardKey = 'modularDashboard';

export const modularDashboardStore = new CustomStore<
	ModularDashboardState,
	ModularDashboardClient
>(
	'ModularDashboard',
	{
		dashboards: [],
		dashboardSections: [],
		highlightItems: [],
	},
	ModularDashboardClient,
	'Event'
);

export const loadModularDashboards = modularDashboardStore
	.addApiAction('ModularDashboard', 'Load')
	.configure({ initialLoad: true, showErrors: true, parseDates: true })
	.withEffect(s => s.loadModularDashboards)
	.withReducer();

export const getModularDashboards = modularDashboardStore.baseSelector.create(
	({ dashboards, dashboardSections }) => {
		return dashboards.map(dashboard => {
			return {
				...dashboard,
				sections: dashboardSections
					.filter(i => i.dashboardId === dashboard.id)
					.sort(sortBySortingKeyAsc),
			};
		});
	}
);

export const getDashboardHighlightItems =
	modularDashboardStore.baseSelector.create(({ highlightItems }) => {
		return [...highlightItems].sort((a, z) => {
			const diff =
				a.layout.x + a.layout.y * 12 - (z.layout.x + z.layout.y * 12);
			if (diff !== 0) {
				return diff;
			}

			// This branch should only happen for MixedContentSwimlane items, as two
			// "regular" highlight items cannot be place the same place.
			return sortBySortingKeyAsc(a, z);
		});
	});

export const setModularDashboardData = modularDashboardStore
	.addSocketAction<ModularDashboardMixedBatchModels>(
		'ModularDashboard',
		'MixedBatchModels',
		'Set'
	)
	.withReducer((data, { dashboards, dashboardSections, highlightItems }) => {
		return {
			dashboards: appendAndOverride(dashboards, data.dashboards),
			dashboardSections: appendAndOverride(
				dashboardSections,
				data.dashboardSections
			),
			highlightItems: appendAndOverride(highlightItems, data.highlightItems),
		};
	});

export const removeModularDashboardData = modularDashboardStore
	.addSocketAction<ModularDashboardMixedBatchIds>(
		'ModularDashboard',
		'MixedBatchIds',
		'Removed'
	)
	.withReducer((idBatch, { dashboards, dashboardSections, highlightItems }) => {
		const excludeIds = <T extends { id: string }>(
			models: T[],
			ids: string[]
		): T[] => {
			if (!ids) {
				return models;
			}
			const removeIds = new Set(ids);
			return models.filter(model => !removeIds.has(model.id));
		};

		return {
			dashboards: excludeIds(dashboards, idBatch.dashboards),
			dashboardSections: excludeIds(
				dashboardSections,
				idBatch.dashboardSections
			),
			highlightItems: excludeIds(highlightItems, idBatch.highlightItems),
		};
	});

export const moveDashboardSection = modularDashboardStore
	.addSocketAction<MoveModel>('ModularDashboard', 'DashboardSection', 'Moved')
	.withReducer(({ id, sortingKey }, { dashboardSections }) => ({
		dashboardSections: moveListItem(
			dashboardSections,
			x => x.id === id,
			sortingKey,
			x => x.dashboardId
		),
	}));

export const moveHighlightItem = modularDashboardStore
	.addSocketAction<MoveModel>('ModularDashboard', 'HighlightItem', 'Moved')
	.withReducer(({ id, sortingKey }, { highlightItems }) => ({
		highlightItems: moveListItem(
			highlightItems,
			x => x.id === id,
			sortingKey,
			x => x.sectionId
		),
	}));
