import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SessionService } from '@store/scope';
import { EliminationGameAnswer } from '@consensus/connect/shared/elimination-game/domain';
import {
	UfaEliminationGame,
	UfaEliminationGameQuestionAnswerModel,
} from '@consensus/connect/ufa/elimination-game/domain';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class UfaEliminationGameClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #http = inject(HttpClient);
	readonly #session = inject(SessionService);

	answerQuestion({
		questionId,
		...data
	}: UfaEliminationGameQuestionAnswerModel) {
		return this.#http.post<EliminationGameAnswer>(
			`${this.#apiServer}/api/events/${
				this.#session.eventId
			}/elimination-game/question/${questionId}/answer`,
			data
		);
	}

	loadUserEliminationGames() {
		return this.#http.get<UfaEliminationGame[]>(
			`${this.#apiServer}/api/events/${this.#session.eventId}/elimination-game`
		);
	}
}
