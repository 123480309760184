import {
	AbstractControl,
	UntypedFormArray,
	UntypedFormControl,
	UntypedFormGroup,
	NgControl,
} from '@angular/forms';
import { mapValues } from 'lodash-es';
import { FormList } from './form-list';
import { forOwn, isEqual } from 'lodash-es';
import { FormLayerTemplate } from './form-types';
import { sortBySortingKeyAsc } from '@lib/helpers';
import { FormRoot } from './form-root';
import { FormNode, FormSelectNode } from './form-node';
import { FormLayer } from './form-layer';
import { isNonNullish } from '@consensus/co/util-types';

/**
 * Detects if a control is set as required
 * @param abstractControl
 */
export function hasRequiredField(
	abstractControl: AbstractControl | NgControl
): boolean {
	if (abstractControl.validator) {
		const validator = abstractControl.validator({} as AbstractControl);
		if (validator && validator.required) {
			return true;
		}
	}
	return false;
}

/**
 * Parses form errors into human readable text
 * @param errors
 */
export function parseErrors(errors: { [name: string]: any }): string[] {
	const result = [];
	let unknown = false;

	for (const name in errors) {
		if (!Object.prototype.hasOwnProperty.call(errors, name)) {
			continue;
		}
		const val = errors[name];

		switch (name) {
			case 'required':
				result.push('Field is required');
				break;
			case 'minlength': {
				const minLength = val as {
					requiredLength: number;
					actualLength: number;
				};
				result.push(
					`A minimum length of ${minLength.requiredLength} is required [${minLength.actualLength}/${minLength.requiredLength}]`
				);
				break;
			}
			case 'email':
				result.push('This is not a valid email');
				break;
			case 'max':
				result.push(`The maximum value is: ${val.max}`);
				break;
			case 'min':
				result.push(`The minimum value is: ${val.min}`);
				break;
			case 'password': {
				const message = val as string;
				result.push(message);
				break;
			}
			default:
				unknown = true;
				break;
		}
	}

	if (unknown && result.length < 1) {
		result.push('The field is not correct');
	}

	return result;
}

/**
 * Creates a deep copy of a FormControl element
 * @param control
 */
export function cloneAbstractControl<
	T extends AbstractControl | FormLayerTemplate<any>
>(control: T): T {
	if (control instanceof FormRoot) {
		return new FormRoot(cloneAbstractControl(control.inputTemplate)) as any;
	}

	if (control instanceof FormLayer) {
		return new FormLayer(cloneAbstractControl(control.inputTemplate)) as any;
	}

	if (control instanceof UntypedFormGroup) {
		return new UntypedFormGroup(
			mapValues(control.controls, control => cloneAbstractControl(control)),
			control.validator,
			control.asyncValidator
		) as any;
	}

	if (control instanceof FormList) {
		return new FormList(
			cloneAbstractControl(control.inputTemplate),
			control.startLength,
			control.validator,
			control.asyncValidator
		) as any;
	}

	if (control instanceof UntypedFormArray) {
		return new UntypedFormArray(
			(control.controls ?? [])
				.map(control => cloneAbstractControl(control))
				.filter(isNonNullish),
			control.validator,
			control.asyncValidator
		) as any;
	}

	if (control instanceof FormSelectNode) {
		return FormSelectNode.clone(control) as any;
	}

	if (control instanceof FormNode) {
		return FormNode.clone(control) as any;
	}

	if (control instanceof UntypedFormControl) {
		return new UntypedFormControl(
			control.value,
			control.validator,
			control.asyncValidator
		) as any;
	}

	if (control instanceof Object) {
		const controls = {};
		forOwn(control, (x, k) => {
			controls[k] = cloneAbstractControl(x as any);
		});
		return controls as any;
	}

	return null;
}

/**
 * Applies new values (complete or partial) to a control structure
 * @param group
 * @param data
 * @param reset
 */
export function patchAbstractControl(
	group: AbstractControl,
	data: any,
	reset: any = undefined
) {
	if (group instanceof FormList) {
		let list = data && Array.isArray(data) ? [...data] : null;
		const resetVal = reset && Array.isArray(reset) ? [...reset] : [];

		if (
			list &&
			list.length > 0 &&
			Object.prototype.hasOwnProperty.call(list[0], 'sortingKey')
		) {
			list = list.sort(sortBySortingKeyAsc);
		}

		group.setSize(list ? list.length : resetVal.length);

		if (reset !== undefined) {
			group.modified = false;
		}

		group.controls.forEach((x, i) => {
			const val = list && list[i];
			let res = resetVal && resetVal[i];
			if (res == null) {
				res = group.template.value;
			}
			patchAbstractControl(
				x,
				val == null ? null : val,
				reset === undefined ? undefined : res == null ? null : res
			);
		});

		return;
	}

	if (group instanceof UntypedFormArray) {
		group.clear();
		return;
	}

	if (group instanceof UntypedFormGroup) {
		forOwn(group.controls, (x, k) => {
			const val = data && data[k];
			const res = reset && reset[k];
			patchAbstractControl(
				x,
				val == null ? null : val,
				reset === undefined ? undefined : res == null ? null : res
			);
		});
		return;
	}

	if (group instanceof UntypedFormControl) {
		group.setValue(data != null ? data : reset === undefined ? null : reset);
	}
}

/**
 * Detects if a form has changed
 * @param oldItem
 * @param newItem
 * @param formGroup
 */
export function formUpdated<T>(
	oldItem: T,
	newItem: T,
	formGroup: UntypedFormGroup
) {
	if (oldItem == null) {
		return newItem != null;
	}
	if (newItem == null) {
		return true;
	}

	if (oldItem['id'] !== newItem['id']) {
		return true;
	}

	for (const key in newItem) {
		if (!Object.prototype.hasOwnProperty.call(newItem, key)) {
			continue;
		}
		if (!formGroup.get(key)) {
			continue;
		}
		if (!Object.prototype.hasOwnProperty.call(oldItem, key)) {
			return true;
		}
		if (!isEqual(oldItem[key], newItem[key])) {
			return true;
		}
	}
	return false;
}
