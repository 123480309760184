import { FormLayerControls, FormLayerTemplate } from './form-types';
import { patchAbstractControl } from './utils';
import { FormGroup } from '@angular/forms';
import { FormNode } from './form-node';
import { FormList } from './form-list';
import {
	asyncScheduler,
	BehaviorSubject,
	Observable,
	throttleTime,
} from 'rxjs';
import { forOwn } from 'lodash-es';

export class FormLayer<TLayer extends object = any> extends FormGroup {
	/**
	 * Contains the raw values of the form
	 */
	value: TLayer;

	protected _value$: BehaviorSubject<TLayer>;
	value$: Observable<TLayer>;
	throttledValue$: Observable<TLayer>;

	controlMap: FormLayerControls<TLayer>;

	protected _resetValue: TLayer;

	inputTemplate: FormLayerTemplate<TLayer>;

	constructor(template: FormLayerTemplate<TLayer>) {
		const controls: Record<string, FormNode | FormList | FormLayer> = {};

		forOwn(template, (x: unknown, k: string) => {
			if (x == null) {
				return;
			}
			if (x instanceof FormNode || x instanceof FormList) {
				controls[k] = x;
			} else if (x instanceof Object) {
				controls[k] = new FormLayer(x as FormLayerTemplate<any>);
			}
		});

		super(controls);

		this.inputTemplate = template;

		this.controlMap = this.getControls();

		this._resetValue = this.value;

		this._value$ = new BehaviorSubject<TLayer>(this.value);
		this.valueChanges.subscribe(this._value$);

		this.value$ = this._value$.asObservable();
		this.throttledValue$ = this._value$.pipe(
			throttleTime(500, asyncScheduler, { trailing: true })
		);
	}

	static fromTemplate<TGroup extends object>(
		template: FormLayerTemplate<TGroup>
	) {
		return new FormLayer<TGroup>(template);
	}

	/**
	 * Returns the processed value of the form/layer
	 */
	getValue(): TLayer {
		const values = {};

		forOwn(this.controls, (x, k) => {
			if (x instanceof FormLayer) {
				values[k] = x.getValue();
			} else if (x instanceof FormNode) {
				values[k] = x.getValue();
			} else if (x instanceof FormList) {
				values[k] = x.getValue();
			}
		});

		return values as TLayer;
	}

	reset(value: Partial<TLayer> = {}): void {
		patchAbstractControl(this, value, this._resetValue);
		this.markAsPristine();
		this.markAsUntouched();
	}

	patchValue(value: Partial<TLayer>): void {
		patchAbstractControl(this, value, undefined);
	}

	protected getControls(): FormLayerControls<TLayer> {
		const controls = {};

		forOwn(this.controls, (x, k) => {
			if (x instanceof FormLayer) {
				controls[k] = x.controlMap;
			} else if (x instanceof FormNode) {
				controls[k] = x;
			} else if (x instanceof FormList) {
				controls[k] = x;
			}
		});

		controls['this'] = this;

		return controls as FormLayerControls<TLayer>;
	}

	novofy(isSema: boolean) {
		forOwn(this.controls, x => {
			if (x instanceof FormLayer) {
				x.novofy(isSema);
			} else if (x instanceof FormNode) {
				x.novofy(isSema);
			} else if (x instanceof FormList) {
				x.novofy(isSema);
			}
		});
	}
}
