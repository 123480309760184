import { ApiAction } from '@lib/redux';
import { QaSettings } from '@consensus/connect/shared/qa/domain';
import {
	CreateQaQuestionModel,
	QaLoadModel,
	QaQuestion,
	QaTopic,
	UpdateQaQuestionModel,
} from './qa.model';
import { QaState } from './qa.reducer';
import { createAction } from '@ngrx/store';

export const loadQa = new ApiAction<QaState, void, QaLoadModel>(
	'Q&A',
	'Load',
	'Q&A Page',
	{
		showErrors: true,
	}
);

export const loadUserQuestions = new ApiAction<QaState, void, QaQuestion[]>(
	'User Questions',
	'Load',
	'Q&A Page',
	{
		showErrors: true,
		parseDates: true,
	}
);

export const loadPublicQuestions = new ApiAction<QaState, void, QaQuestion[]>(
	'Public Questions',
	'Load',
	'Q&A Page',
	{
		showErrors: true,
		parseDates: true,
	}
);

export const createQaQuestion = new ApiAction<
	QaState,
	CreateQaQuestionModel,
	QaQuestion
>('Q&A Question', 'Create', 'Q&A Page', {
	showErrors: true,
	successMessage: 'Your question has been sent',
	parseDates: true,
});

export const updateQaQuestion = new ApiAction<
	QaState,
	UpdateQaQuestionModel,
	QaQuestion
>('Q&A Question', 'Update', 'Q&A Page', {
	showErrors: true,
	successMessage: 'Your question has been updated',
	parseDates: true,
});

export const deleteQaQuestion = new ApiAction<QaState, string, string>(
	'Q&A Question',
	'Delete',
	'Q&A Page',
	{
		showErrors: true,
		eager: true,
		successMessage: 'Your question has been deleted',
	}
);

export const setQaSettings = createAction(
	'[QA] Settings: Set',
	(payload: QaSettings) => ({ payload })
);
export const setQaSession = createAction(
	'[QA] Session: Set',
	(payload: string) => ({ payload })
);
export const removeQaSession = createAction(
	'[QA] Session: Removed',
	(payload: string) => ({ payload })
);
export const setQaSessions = createAction(
	'[QA] Sessions: Set',
	(payload: string[]) => ({ payload })
);
export const removeQaSessions = createAction(
	'[QA] Sessions: Removed',
	(payload: string[]) => ({ payload })
);
export const setQaTopic = createAction(
	'[QA] Topic: Set',
	(payload: QaTopic) => ({ payload })
);
export const removeQaTopic = createAction(
	'[QA] Topic: Removed',
	(payload: string) => ({ payload })
);
export const addUserQaQuestion = createAction(
	'[QA] Question: Added',
	(payload: QaQuestion) => ({ payload })
);
export const setModQaQuestion = createAction(
	'[QA] Question: Set',
	(payload: QaQuestion) => ({ payload })
);
export const removeQaQuestion = createAction(
	'[QA] Question: Removed',
	(payload: string) => ({ payload })
);
