import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { PushoutResult } from '@consensus/connect/shared/pushout-results/domain';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class PushoutResultClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #http = inject(HttpClient);

	loadUserPushoutResults() {
		return this.#http.get<PushoutResult[]>(
			`${this.#apiServer}/api/pushout/old/results`
		);
	}
}
