import { BaseSelector } from './selector';
import {
	Action,
	ActionCreator,
	ActionReducerMap,
	createAction,
	Store,
} from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
	from,
	Observable,
	of,
	catchError,
	filter,
	map,
	switchMap,
	tap,
	withLatestFrom,
	concatMap,
} from 'rxjs';
import {
	ApiAction,
	ApiActionExtraSettings,
	ApiActionSettings,
	CustomAction,
	IStartAction,
} from './action';
import { ApiReducer } from './api-reducer';
import {
	BaseState,
	BaseStateExclude,
	BulkRelocateModel,
	initialBaseState,
	LoadingState,
	MoveModel,
	RelocateModel,
} from '@shared/models';
import { parseObject } from '@lib/helpers';
import { parseDate } from '@lib/helpers';
import { inject, isDevMode, Type } from '@angular/core';
import { Sorted } from './sorted';
import { StoreScopes } from './store-scopes';
import { SocketActionConfig } from './socket-action-config';
import { addSocketConfig } from './socket.config';
import {
	moveListItem,
	relocateListItem,
	relocateListItems,
	removeListItem,
	removeListItemWithIndex,
	updateListItem,
} from './reducer-utils';

type FeatureConfig = { [key: string]: BaseState };
type StoreMap<TFeature extends FeatureConfig> = {
	[K in keyof TFeature]?: CustomStore<TFeature[K], any>;
};

/**
 * A main store created for a feature.
 * This will contain all the stores for the module
 *
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export class FeatureStore<TState extends FeatureConfig> {
	featureKey: string;
	stores: CustomStore<any, any>[] = [];

	/**
	 * Create a new Feature Store
	 * @param featureKey - The parameter name for the store
	 * @param storeConfig - A map of sub-stores
	 * @template TState
	 *
	 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
	 *   NgRx ComponentStore instead.
	 */
	constructor(featureKey: string, storeConfig: StoreMap<TState>) {
		this.featureKey = featureKey;
		const reducers: ActionReducerMap<any> = {};

		for (const key in storeConfig) {
			if (!Object.prototype.hasOwnProperty.call(storeConfig, key)) {
				continue;
			}
			const store: CustomStore<any, any> = storeConfig[key]!;
			store.init(this, key);
			reducers[key as string] = store.reducer.getReducer();
			this.stores.push(store);
		}

		this.reducers = reducers;
	}

	reducers: ActionReducerMap<TState>;
}

interface ActionConfig<TState, TService> {
	action: ApiAction<TState>;
	effect: (service: TService) => (data: any) => Observable<any> | Promise<any>;
	service: Type<TService>;
}

interface SideEffectConfig {
	actionType: string;
	effect: (data: any) => void;
}

/**
 * A redux store.
 * This is where all Redux logic is defined.
 *
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export class CustomStore<TState extends BaseState, TService> {
	initialState: TState;

	baseSelector: BaseSelector<TState, unknown>;
	reducer: ApiReducer<TState>;
	actionConfigs: ActionConfig<TState, TService>[] = [];
	socketActions: SocketActionConfig<unknown, unknown>[] = [];
	sideEffects: SideEffectConfig[] = [];
	featureKey?: string;
	storeKey?: string;
	service: Type<TService>;
	scope: StoreScopes;

	/**
	 * Create a new Redux Store
	 * @param name - The unique name of the store (Used in CMS strings)
	 * @param initialState - The initial state of the store
	 * @param service - The service used to hydrate the store
	 * @param scope - The scope of the store. This determines when the store should be invalidated
	 * @template TState, TService
	 */
	constructor(
		private name: string,
		initialState: Omit<TState, BaseStateExclude>,
		service: Type<TService>,
		scope: StoreScopes
	) {
		this.service = service;
		this.scope = scope;
		this.initialState = { ...initialBaseState, ...initialState } as TState;
		this.reducer = new ApiReducer<TState>(this.initialState, scope);
		this.baseSelector = new BaseSelector<TState, any>(
			(s: any) => s[this.featureKey as any],
			(s: any) => s?.[this.storeKey as any]
		);
	}

	init<TFeature extends FeatureConfig>(
		featureStore: FeatureStore<TFeature>,
		key: string
	) {
		this.featureKey = featureStore.featureKey;
		this.storeKey = key;
	}

	/**
	 * Adds an action that interacts with an endpoint
	 * @param model - The name of the entity affected
	 * @param action - The action that will be applied
	 * @returns {ApiActionContext<TState, TService>}
	 */
	addApiAction(model: string, action: string) {
		return new ApiActionContext<TState, TService>(
			this.name,
			model,
			action,
			this.reducer,
			this.actionConfigs,
			this.service
		);
	}

	/**
	 * Adds an action that only mutates the store
	 * @param model - The name of the entity affected
	 * @param action - The action that will be applied
	 * @returns {ActionContext<TState, TPayload>}
	 */
	addAction<TPayload = void>(model: string, action: string) {
		const actionEntity = createAction(
			`[${this.name}] ${action}: ${model}`,
			(payload: TPayload) => ({ payload })
		);
		return new ActionContext<TState, TPayload>(
			actionEntity,
			this.reducer,
			this.sideEffects
		);
	}

	/**
	 * Created an action that has no payload, and serves as a signal to the Store
	 * @param model - The name of the entity affected
	 * @param action - The action that will be applied
	 * @returns {SignalActionContext<TState>}
	 */
	addSignalAction(model: string, action: string) {
		const actionEntity = createAction(`[${this.name}] ${action}: ${model}`);
		return new SignalActionContext<TState>(actionEntity, this.reducer);
	}

	/**
	 * Listens to an already defined action
	 * @param action - The action to react to
	 * @returns {SideEffectContext<TState, TPayload, TData>}
	 */
	addSideEffect<TPayload, TData>(action: ApiAction<any, TPayload, TData>) {
		return new SideEffectContext<TState, TPayload, TData>(action, this.reducer);
	}

	/**
	 * Creates an action that is bound to a socket signal from the Backend
	 * @param groupName - Name of the Socket Group (eg. Social Feed)
	 * @param itemName - Name of the item affected (eg. Post)
	 * @param actionName - Name of the action applied (eg. Remove)
	 * @returns {SocketActionContext<TState, TPayload>}
	 */
	addSocketAction<TPayload = void>(
		groupName: string,
		itemName: string,
		actionName: string
	) {
		const action = createAction(
			`[${groupName} Socket] ${itemName}: ${actionName}`,
			(payload: TPayload) => ({ payload })
		);
		return new SocketActionContext<TState, TPayload, unknown>(
			action,
			this.reducer,
			this.sideEffects,
			this.socketActions as SocketActionConfig<TPayload, unknown>[],
			`[${groupName}] ${itemName}: ${actionName}`
		);
	}

	/**
	 * Creates a selector based on the Store State
	 * @param selector
	 * @returns {Selector<TState, TState, TMap>}
	 */
	addSelector<TMap>(selector: (state: TState) => TMap) {
		return this.baseSelector.create(selector);
	}
}

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export class SideEffectContext<TState extends BaseState, TPayload, TData> {
	/**
	 * @template TState, TPayload, TData
	 */
	constructor(
		private action: ApiAction<any, TPayload, TData>,
		private reducer: ApiReducer<TState>
	) {}

	/**
	 * Add a reducer to modify the store
	 * @param reducer - The reducer to apply
	 * @returns {ApiAction<any, TPayload, TData>} - A finished action
	 */
	withReducer(
		reducer: (data: TData, state: TState, payload: TPayload) => Partial<TState>
	) {
		this.reducer.addSideEffect(this.action, reducer as any);
		return this.action;
	}
}

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export class SignalActionContext<TState extends BaseState> {
	/**
	 * @template TState
	 */
	constructor(
		private action: ActionCreator<string, () => Action>,
		private reducer: ApiReducer<TState>
	) {}

	/**
	 * Add a reducer to modify the store
	 * @param reducer
	 * @returns {ActionCreator<string, () => Action>}
	 */
	withReducer(reducer: (state: TState) => Partial<TState>) {
		this.reducer.addReducer(this.action, reducer);
		return this.action;
	}
}

type KeysOfType<T, TProp> = {
	[P in keyof T]: T[P] extends TProp ? P : never;
}[keyof T];
type ArrayType<T> = T extends (infer A)[] ? A : never;

abstract class AddReducerContext<
	TContextTarget,
	TState extends BaseState,
	TPayload,
	TExtra,
	TAction extends ApiAction<TState, any, TPayload> | SimpleAction<TPayload>
> {
	/**
	 * @template TState, TPayload, TAction
	 */
	protected constructor(
		protected action: TAction,
		protected reducer: ApiReducer<TState>
	) {}

	protected abstract preProcess(
		reducer: (
			payload: TPayload,
			state: TContextTarget,
			extra: TExtra
		) => Partial<TContextTarget>
	): (payload: TPayload, state: TState, extra: TExtra) => Partial<TState>;

	protected finish(
		reducer: (
			payload: TPayload,
			state: TContextTarget
		) => Partial<TContextTarget>
	): TAction {
		const newReducer = this.preProcess(reducer);

		if (this.action instanceof ApiAction) {
			this.reducer.addApiReducer(this.action, newReducer);
		} else {
			this.reducer.addReducer(
				this.action as SimpleAction<TPayload>,
				newReducer as any
			);
		}

		this.postProcess(this.action);
		return this.action;
	}

	protected postProcess(_action: TAction) {
		// Override in the sub-class to get notified when an action is registered
	}

	/**
	 * Adds the payload to the end of a list
	 * @param target - The key to the list property
	 * @returns {TAction} - A finished action
	 */
	addition(target: KeysOfType<TContextTarget, TPayload[]>): TAction {
		return this.finish(
			(data: TPayload, state: TContextTarget) =>
				({ [target]: [...((state?.[target] as any) ?? []), data] } as any)
		);
	}

	/**
	 * Updates an item in a list (Based off of the Id prop)
	 * @param target - The key to the list property
	 * @returns {TAction} - A finished action
	 */
	update(target: KeysOfType<TContextTarget, TPayload[]>): TAction;
	/**
	 * Updates an item in a list
	 * @param target - The key to the list property
	 * @param identifier - The prop to identify an item by
	 * @returns {TAction} - A finished action
	 */
	update<TId>(
		target: KeysOfType<TContextTarget, TPayload[]>,
		identifier: (x: TPayload) => TId
	): TAction;
	update<TId = string>(
		target: KeysOfType<TContextTarget, TPayload[]>,
		identifier: (x: TPayload) => TId = x => (x as any)['id']
	): TAction {
		return this.finish(
			(data: TPayload, state: TContextTarget) =>
				({
					[target]: updateListItem(
						(state?.[target] as unknown as TPayload[]) ?? [],
						x => identifier(x) === identifier(data),
						data
					),
				} as any)
		);
	}

	/**
	 * Updates an item in a list
	 * @param target - The key to the list property
	 * @param updateData - The data to update the model with
	 * @param identifier - The property used for identifying an item
	 * @param selector - Used to modify the identifier
	 * @returns {TAction} - A finished action
	 */
	manualUpdate<
		TTarget extends KeysOfType<TContextTarget, any[]>,
		TIdentifier = string
	>(
		target: TTarget,
		updateData: Partial<ArrayType<TContextTarget[TTarget]>>,
		identifier: (x: ArrayType<TContextTarget[TTarget]>) => TIdentifier = x =>
			(x as any)['id'],
		selector: (x: TPayload) => TIdentifier = x => x as any
	): TAction {
		return this.finish(
			(data: TPayload, state: TContextTarget) =>
				({
					[target]: updateListItem(
						(state?.[target] as ArrayType<TContextTarget[TTarget]>[]) ?? [],
						x => identifier(x) === selector(data),
						updateData
					),
				} as any)
		);
	}

	/**
	 * Updates an item in a list if found. If the item is not found then it will be appended. (Identified by Id)
	 * @param target - The key to the list property
	 * @returns {TAction} - A finished action
	 */
	set(target: KeysOfType<TContextTarget, TPayload[]>): TAction;
	/**
	 * Updates an item in a list if found. If the item is not found then it will be appended.
	 * @param target - The key to the list property
	 * @param identifier - The property used for identifying an item
	 * @returns {TAction} - A finished action
	 */
	set<TId>(
		target: KeysOfType<TContextTarget, TPayload[]>,
		identifier: (x: TPayload) => TId
	): TAction;
	set<TId = string>(
		target: KeysOfType<TContextTarget, TPayload[]>,
		identifier: (x: TPayload) => TId = x => (x as any)['id']
	): TAction {
		return this.finish(
			(data: TPayload, state: TContextTarget) =>
				({
					[target]: updateListItem(
						(state?.[target] as unknown as TPayload[]) ?? [],
						x => identifier(x) === identifier(data),
						data,
						true
					),
				} as any)
		);
	}

	/**
	 * Updates an item in a list of items where items are sorted into sub-sets based on a parent identifier. (Identified by Id)
	 * @param target - The key to the list property
	 * @param getParent - The parent identifier
	 * @returns {TAction} - A finished action
	 */
	updateWithIndex<TParent>(
		target: KeysOfType<TContextTarget, (TPayload & Sorted)[]>,
		getParent: (x: TPayload) => TParent
	): TAction;
	/**
	 * Updates an item in a list of items where items are sorted into sub-sets based on a parent identifier.
	 * @param target - The key to the list property
	 * @param getParent - The parent identifier
	 * @param identifier - The property used for identifying an item
	 * @returns {TAction} - A finished action
	 */
	updateWithIndex<TParent, TId>(
		target: KeysOfType<TContextTarget, (TPayload & Sorted)[]>,
		getParent: (x: TPayload) => TParent,
		identifier: (x: TPayload) => TId
	): TAction;
	updateWithIndex<TParent, TId = string>(
		target: KeysOfType<TContextTarget, (TPayload & Sorted)[]>,
		getParent: (x: TPayload) => TParent,
		identifier: (x: TPayload) => TId = x => (x as any)['id']
	): TAction {
		return this.finish((data: TPayload, state: TContextTarget) => {
			const list = [
				...((state?.[target] as unknown as (TPayload & Sorted)[]) ?? []),
			];
			const index = list.findIndex(x => identifier(x) === identifier(data));
			const item = list[index];
			if (!item) {
				return state;
			}
			const sortingKey = item['sortingKey'];
			const oldParent = getParent(item);
			const newItem = { ...item, ...data };
			list[index] = newItem;

			const newParent = getParent(newItem);

			if (oldParent !== newParent) {
				list.forEach((val, i) => {
					const parent = getParent(val);
					if (parent === oldParent && val.sortingKey > sortingKey) {
						list[i] = { ...val, sortingKey: val.sortingKey - 1 };
					}
				});
			}

			return { [target]: list } as any;
		});
	}

	/**
	 * Deletes an item from a list, based on the given string (Id)
	 * @param target - The key to the list property
	 * @param identifier - The property used for identifying an item
	 * @param selector - Used to modify the identifier
	 * @returns {TAction} - A finished action
	 */
	delete<
		TTarget extends KeysOfType<TContextTarget, any[]>,
		TIdentifier = string
	>(
		target: TTarget,
		identifier: (x: ArrayType<TContextTarget[TTarget]>) => TIdentifier = x =>
			(x as any)['id'],
		selector: (x: TPayload) => TIdentifier = x => x as any
	): TAction {
		return this.finish(
			(data: TPayload, state: TContextTarget) =>
				({
					[target]: removeListItem(
						(state?.[target] as unknown as ArrayType<
							TContextTarget[TTarget]
						>[]) ?? [],
						x => identifier(x) === selector(data)
					),
				} as any)
		);
	}

	/**
	 * Deletes an item - based on the given string - from a list while updating the corresponding sortingKeys
	 * @param target - The key to the list property
	 * @param identifier - The property used for identifying an item
	 * @param sortingParent - The identifier for a subset parent identifier (If any)
	 * @param selector - Used to modify the identifier
	 * @returns {TAction} - A finished action
	 */
	deleteWithIndex<
		TTarget extends KeysOfType<TContextTarget, Sorted[]>,
		TIdentifier = string
	>(
		target: TTarget,
		identifier: (x: ArrayType<TContextTarget[TTarget]> & Sorted) => TIdentifier,
		sortingParent:
			| ((x: ArrayType<TContextTarget[TTarget]> & Sorted) => any)
			| null = null,
		selector: (x: TPayload) => TIdentifier = x => x as any
	): TAction {
		return this.finish(
			(data: TPayload, state: TContextTarget) =>
				({
					[target]: removeListItemWithIndex(
						(state?.[target] as unknown as Array<
							ArrayType<TContextTarget[TTarget]> & Sorted
						>) ?? [],
						x => identifier(x) === selector(data),
						sortingParent
					),
				} as any)
		);
	}

	/**
	 * Moves an item in a list to a new location
	 * @param target - The key to the list property
	 * @param identifier - The property used for identifying an item
	 * @param sortingParent - The identifier for a subset parent identifier (If any)
	 * @returns {TAction} - A finished action
	 */
	move<TTarget extends KeysOfType<TContextTarget, Sorted[]>>(
		target: TTarget,
		identifier: (x: ArrayType<TContextTarget[TTarget]> & Sorted) => string,
		sortingParent:
			| ((x: ArrayType<TContextTarget[TTarget]> & Sorted) => any)
			| null = null
	): TAction {
		return this.finish(
			(payload: TPayload, state: TContextTarget) =>
				({
					[target]: moveListItem(
						(state?.[target] as unknown as Array<
							ArrayType<TContextTarget[TTarget]> & Sorted
						>) ?? [],
						x => identifier(x) === (payload as MoveModel).id,
						(payload as MoveModel).sortingKey,
						sortingParent
					),
				} as any)
		);
	}

	/**
	 * Moves an item in a list to a new parent
	 * @param target - The key to the list property
	 * @param identifier - The property used for identifying an item
	 * @param sortingProp - The identifier for a subset parent identifier (If any)
	 * @returns {TAction} - A finished action
	 */
	relocate<TTarget extends KeysOfType<TContextTarget, Sorted[]>>(
		target: TTarget,
		sortingProp: keyof ArrayType<TContextTarget[TTarget]>,
		identifier: (x: ArrayType<TContextTarget[TTarget]> & Sorted) => string = (
			x: any
		) => x['id']
	): TAction {
		return this.finish(
			(payload: TPayload, state: TContextTarget) =>
				({
					[target]: relocateListItem<
						ArrayType<TContextTarget[TTarget]> & Sorted,
						any
					>(
						(state?.[target] as unknown as Array<
							ArrayType<TContextTarget[TTarget]> & Sorted
						>) ?? [],
						x => identifier(x) === (payload as RelocateModel).id,
						x => ({ [sortingProp]: x[sortingProp] }),
						{ [sortingProp]: (payload as RelocateModel).parentId }
					),
				} as any)
		);
	}

	/**
	 * Moves items in a list to a new parent
	 * @param target - The key to the list property
	 * @param identifier - The property used for identifying an item
	 * @param sortingProp - The identifier for a subset parent identifier (If any)
	 * @returns {TAction} - A finished action
	 */
	relocateBulk<TTarget extends KeysOfType<TContextTarget, Sorted[]>>(
		target: TTarget,
		sortingProp: keyof ArrayType<TContextTarget[TTarget]>,
		identifier: (x: ArrayType<TContextTarget[TTarget]> & Sorted) => string = (
			x: any
		) => x['id']
	): TAction {
		return this.finish(
			(payload: TPayload, state: TContextTarget) =>
				({
					[target]: relocateListItems<
						ArrayType<TContextTarget[TTarget]> & Sorted,
						string,
						any
					>(
						(state?.[target] as unknown as Array<
							ArrayType<TContextTarget[TTarget]> & Sorted
						>) ?? [],
						identifier,
						(payload as BulkRelocateModel).ids,
						x => ({ [sortingProp]: x[sortingProp] }),
						{ [sortingProp]: (payload as BulkRelocateModel).parentId }
					),
				} as any)
		);
	}
}

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export type SimpleAction<TPayload> = CustomAction<any, any> &
	((x: any) => { payload?: TPayload });

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export class ActionContext<
	TState extends BaseState,
	TPayload
> extends AddReducerContext<
	TState,
	TState,
	TPayload,
	void,
	SimpleAction<TPayload>
> {
	/**
	 * @template TState, TPayload
	 */
	constructor(
		action: CustomAction<any, any> & ((x: any) => { payload?: TPayload }),
		reducer: ApiReducer<TState>,
		private sideEffects: SideEffectConfig[]
	) {
		super(action, reducer);
	}

	preProcess(
		reducer: (payload: TPayload, state: TState) => Partial<TState>
	): (payload: TPayload, state: TState) => Partial<TState> {
		return reducer;
	}

	/**
	 * Add a reducer to modify the store
	 * @param reducer
	 * @returns {SimpleAction<TPayload>}
	 */
	withReducer(
		reducer:
			| ((payload: TPayload, state: TState) => Partial<TState>)
			| null = null
	) {
		this.reducer.addReducer(this.action, reducer as any);
		return this.action;
	}

	withSideEffect(
		effect: (action: { payload: TPayload; type: string }) => void
	) {
		this.sideEffects.push({ actionType: this.action.type, effect });
		return this;
	}

	withTarget<TTarget>(
		listKey: KeysOfType<TState, TTarget[]>,
		selector: (x: TTarget) => boolean
	): SimpleSubTargetActionContext<TState, TPayload, TTarget> {
		return new SimpleSubTargetActionContext(
			this.action,
			this.reducer,
			listKey,
			selector
		);
	}
}

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export class SimpleSubTargetActionContext<
	TState extends BaseState,
	TPayload,
	TSubTarget
> extends AddReducerContext<
	TSubTarget,
	TState,
	TPayload,
	void,
	SimpleAction<TPayload>
> {
	/**
	 * @template TState, TPayload
	 */
	constructor(
		action: CustomAction<any, any> & ((x: any) => { payload?: TPayload }),
		reducer: ApiReducer<TState>,
		private listKey: KeysOfType<TState, TSubTarget[]>,
		private selector: (x: TSubTarget) => boolean
	) {
		super(action, reducer);
	}

	preProcess(
		reducer: (payload: TPayload, state: TSubTarget) => Partial<TSubTarget>
	): (payload: TPayload, state: TState) => Partial<TState> {
		return (payload: TPayload, state: TState) => {
			const list = state[this.listKey] as unknown as TSubTarget[];
			if (!list) {
				return state;
			}
			const index = list.findIndex(this.selector);
			if (index < 0) {
				return state;
			}
			const target = list[index];
			list[index] = { ...target, ...reducer(payload, target) };
			return {
				...state,
				[this.listKey]: list,
			};
		};
	}

	/**
	 * Add a reducer to modify the target
	 * @param reducer
	 * @returns {SimpleAction<TPayload>}
	 */
	withReducer(
		reducer:
			| ((payload: TPayload, state: TSubTarget) => Partial<TSubTarget>)
			| null = null
	) {
		this.reducer.addReducer(this.action, this.preProcess(reducer as any));
		return this.action;
	}
}

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use
 *   `@consensus/connect/shared/data-access-websocket` instead.
 */
export class SocketActionContext<
	TState extends BaseState,
	TPayload,
	TSocketMessage
> extends ActionContext<TState, TPayload> {
	#effect?: (data: TPayload) => void;
	#mapper?: (data: TSocketMessage) => TPayload;
	#modification?: (data: TPayload) => TPayload;
	#shouldParseDates = false;
	#utcDates = true;

	/**
	 * @template TState, TPayload
	 */
	constructor(
		action: CustomAction<any, any> & ((x: any) => { payload?: TPayload }),
		reducer: ApiReducer<TState>,
		sideEffects: SideEffectConfig[],
		private socketActions: SocketActionConfig<TPayload, TSocketMessage>[],
		private cmdStr: string
	) {
		super(action, reducer, sideEffects);
	}

	/**
	 * Defines a side-effect for the socket signal
	 * @param effect - The action to perform when the signal is received
	 * @returns {this<TState, TPayload>}
	 */
	hasEffect(effect: (data: TPayload) => void) {
		this.#effect = effect;
		return this;
	}

	/**
	 * Map the received data into a different model
	 * @param mapper - The conversion method
	 * @returns {this<TState, TPayload>}
	 */
	mapFrom(mapper: (data: TSocketMessage) => TPayload) {
		this.#mapper = mapper;
		return this;
	}

	/**
	 * Modify the received data
	 * @param modify - The modification method
	 * @returns {this<TState, TPayload>}
	 */
	modify(modify: (data: TPayload) => TPayload) {
		this.#modification = modify;
		return this;
	}

	/**
	 * Parse all date string into Date objects
	 * @param utc - Is the received date defined in UTC
	 * @returns {this<TState, TPayload>}
	 */
	parseDates(utc = true) {
		this.#shouldParseDates = true;
		this.#utcDates = utc;
		return this;
	}

	/**
	 * Add a reducer to modify the store
	 * @param reducer
	 * @return A finished action
	 */
	override withReducer(
		reducer:
			| ((payload: TPayload, state: TState) => Partial<TState>)
			| null = null
	) {
		const action = super.withReducer(reducer);
		this.postProcess(action);
		return action;
	}

	override postProcess(action: SimpleAction<TPayload>) {
		this.socketActions.push({
			action,
			socketString: this.cmdStr,
			mapper: (data: TSocketMessage) => {
				let payload: TPayload = this.#mapper
					? this.#mapper(data)
					: (data as unknown as TPayload);
				payload = this.#shouldParseDates
					? parseObject(payload, d => parseDate(d, this.#utcDates))
					: payload;
				payload = this.#modification?.(payload) ?? payload;

				if (this.#effect) {
					this.#effect(payload);
				}

				return payload;
			},
		});
	}
}

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export class ApiActionContext<TState extends BaseState, TService> {
	#settings: Partial<ApiActionSettings & ApiActionExtraSettings> = {};

	/**
	 * @template TState, TService, TPayload
	 */
	constructor(
		private stateName: string,
		private model: string,
		private action: string,
		private reducer: ApiReducer<TState>,
		private actions: ActionConfig<TState, any>[],
		private service: Type<TService>
	) {}

	/**
	 * Configure the ApiAction with optional settigns
	 * @param settings - The settigns to apply
	 * @returns {this<TState, TService, TPayload>}
	 */
	configure(
		settings: Partial<
			Exclude<ApiActionSettings, 'eager'> & ApiActionExtraSettings
		>
	) {
		this.#settings = { ...this.#settings, ...settings };
		return this;
	}

	withError(errorMessage = '') {
		return this.configure({ showErrors: true, errorMessage });
	}

	withSuccessMessage(successMessage: string) {
		return this.configure({ successMessage });
	}

	parseDates(isUtc = true) {
		return this.configure({ parseDates: true, utcDates: isUtc });
	}

	isInitial() {
		return this.configure({ initialLoad: true });
	}

	/**
	 * Defines the service method used to interact with the API
	 * @param effect - The API method
	 * @returns {ApiEffectContext<TState, TPayload, TData>}
	 */
	withEffect<TData>(
		effect: (service: TService) => () => Observable<TData> | Promise<TData>
	): ApiEffectContext<TState, void, TData>;
	withEffect<TData, TPayload>(
		effect: (
			service: TService
		) => (data: TPayload) => Observable<TData> | Promise<TData>
	): ApiEffectContext<TState, TPayload, TData>;
	withEffect<TData, TPayload>(
		effect: (
			service: TService
		) => (data: TPayload) => Observable<TData> | Promise<TData>
	): ApiEffectContext<TState, TPayload, TData> {
		const action = new ApiAction<TState, TPayload, TData>(
			this.model,
			this.action,
			this.stateName,
			this.#settings
		);
		this.actions.push({ action, effect, service: this.service });
		return new ApiEffectContext<TState, TPayload, TData>(action, this.reducer);
	}

	/**
	 * Defines the service and the subsequent method used to interact with the API
	 * @param customService - The custom service to use for API access
	 * @param effect - The API method
	 * @returns {ApiEffectContext<TState, TPayload, TData>}
	 */
	withCustomEffect<TCustomService, TData>(
		customService: Type<TCustomService>,
		effect: (
			service: TCustomService
		) => () => Promise<TData> | Observable<TData>
	): ApiEffectContext<TState, void, TData>;
	withCustomEffect<TCustomService, TData, TPayload>(
		customService: Type<TCustomService>,
		effect: (
			service: TCustomService
		) => (data: TPayload) => Promise<TData> | Observable<TData>
	): ApiEffectContext<TState, TPayload, TData>;
	withCustomEffect<TCustomService, TData, TPayload>(
		customService: Type<TCustomService>,
		effect: (
			service: TCustomService
		) => (data: TPayload) => Promise<TData> | Observable<TData>
	): ApiEffectContext<TState, TPayload, TData> {
		const action = new ApiAction<TState, TPayload, TData>(
			this.model,
			this.action,
			this.stateName,
			this.#settings
		);
		this.actions.push({ action, effect, service: customService });
		return new ApiEffectContext<TState, TPayload, TData>(action, this.reducer);
	}

	/**
	 * Defines the service method used to interact with the API. The effect will be evaluated after the reducer for the action is applied.
	 * @param effect - The API method
	 * @returns {ApiEffectContext<TState, TPayload, TPayload>}
	 */
	withDelayedEffect(
		effect: (service: TService) => () => Observable<any> | Promise<any>
	): ApiEffectContext<TState, void, void>;
	withDelayedEffect<TPayload>(
		effect: (
			service: TService
		) => (data: TPayload) => Observable<any> | Promise<any>
	): ApiEffectContext<TState, TPayload, TPayload>;
	withDelayedEffect<TPayload>(
		effect: (
			service: TService
		) => (data: TPayload) => Observable<any> | Promise<any>
	): ApiEffectContext<TState, TPayload, TPayload> {
		this.#settings.eager = true;
		return this.withEffect<TPayload, TPayload>(effect) as ApiEffectContext<
			TState,
			TPayload,
			TPayload
		>;
	}
}

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export class ApiEffectContext<
	TState extends BaseState,
	TPayload,
	TData
> extends AddReducerContext<
	TState,
	TState,
	TData,
	TPayload,
	ApiAction<TState, TPayload, TData>
> {
	/**
	 * @template TState, TPayload, TData
	 */
	// Allow @typescript-eslint/no-useless-constructor: Type parameters are different from the base constructor
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(
		action: ApiAction<TState, TPayload, TData>,
		reducer: ApiReducer<TState>
	) {
		super(action, reducer);
	}

	preProcess(
		reducer: (payload: TData, state: TState, extra?: any) => Partial<TState>
	): (payload: TData, state: TState) => Partial<TState> {
		return reducer as any;
	}

	/**
	 * Add a reducer to modify the store
	 * @param reducer
	 * @returns {ApiAction<TState, TPayload, TData>} - A finished action
	 */
	withReducer(
		reducer:
			| ((data: TData, state: TState, payload: TPayload) => Partial<TState>)
			| null = null
	) {
		this.reducer.addApiReducer(this.action, reducer as any);
		return this.action;
	}

	/**
	 * Return an action with no Reducers
	 * @returns {ApiAction<TState, TPayload, TData>} - A finished action
	 */
	noReducer() {
		return this.action;
	}

	withTarget<
		TKey extends keyof Omit<TState, BaseStateExclude>,
		TTarget extends ArrayType<TState[TKey]>
	>(
		listKey: TKey,
		selector: (x: TTarget, data: TData, payload: TPayload) => boolean
	): ApiSubTargetActionContext<TState, TPayload, TData, TTarget> {
		return new ApiSubTargetActionContext(
			this.action,
			this.reducer,
			listKey,
			selector as any
		);
	}
}

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export class ApiSubTargetActionContext<
	TState extends BaseState,
	TPayload,
	TData,
	TSubTarget
> extends AddReducerContext<
	TSubTarget,
	TState,
	TData,
	TPayload,
	ApiAction<TState, TPayload, TData>
> {
	/**
	 * @template TState, TPayload
	 */
	constructor(
		action: ApiAction<TState, TPayload, TData>,
		reducer: ApiReducer<TState>,
		private listKey: keyof TState,
		private selector: (
			x: TSubTarget,
			data?: TData,
			payload?: TPayload
		) => boolean
	) {
		super(action, reducer);
	}

	preProcess(
		reducer: (
			data: TData,
			state: TSubTarget,
			payload: TPayload
		) => Partial<TSubTarget>
	): (data: TData, state: TState, payload: TPayload) => Partial<TState> {
		return (data: TData, state: TState, payload: TPayload) => {
			const oldList = state[this.listKey] as unknown as TSubTarget[];

			if (!Array.isArray(oldList)) {
				console.error(
					`Target Selector for ${this.action.getName()} does not target a list`
				);
				return oldList;
			}

			const list = [...oldList];

			if (!list) {
				return state;
			}
			const index = list.findIndex(x => this.selector(x, data, payload));
			if (index < 0) {
				return state;
			}
			const target = list[index];
			list[index] = { ...target, ...reducer(data, target, payload) };
			return {
				...state,
				[this.listKey]: list,
			};
		};
	}

	/**
	 * Add a reducer to modify the target
	 * @param reducer
	 * @returns {SimpleAction<TPayload>}
	 */
	withReducer(
		reducer:
			| ((
					data: TData,
					state: TSubTarget,
					payload: TPayload
			  ) => Partial<TSubTarget>)
			| null = null
	) {
		this.reducer.addApiReducer(
			this.action,
			this.preProcess(reducer as any) as any
		);
		return this.action;
	}
}

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export class CustomEffects {
	[key: `apiEffect${number}$`]: unknown;
	[key: `sideEffect${number}$`]: unknown;

	readonly #isProductionMode = !isDevMode();

	actions$: Actions;
	store: Store<any>;

	/**
	 * A class to help instantiate all Effects defined in the Store
	 * @param featureStore
	 */
	constructor(featureStore: FeatureStore<any>) {
		this.actions$ = inject(Actions);
		this.store = inject(Store);

		let i = 0;
		for (const storeConfig of featureStore.stores) {
			const service = inject(storeConfig.service);
			for (const config of storeConfig.actionConfigs) {
				const localService =
					storeConfig.service == config.service
						? service
						: inject(config.service);
				i++;
				if (config.action.settings.initialLoad) {
					this[`apiEffect${i}$`] = this.addInitialLoadEffect(
						config,
						localService,
						storeConfig.baseSelector
					);
				} else {
					this[`apiEffect${i}$`] = this.addEffect(config, localService);
				}
			}

			for (const config of storeConfig.sideEffects) {
				i++;
				this[`sideEffect${i}$`] = this.addSideEffect(config);
			}

			for (const socketConfig of storeConfig.socketActions) {
				addSocketConfig(socketConfig);
			}
		}
	}

	addEffect<TState, TService>(
		config: ActionConfig<TState, TService>,
		service: TService
	) {
		if (config.action.settings.useConcatMap) {
			return createEffect(() =>
				this.actions$.pipe(
					ofType(config.action.start.type),
					map(action => action as IStartAction),
					concatMap(this.getSwitchMap(config, service))
				)
			);
		}

		return createEffect(() =>
			this.actions$.pipe(
				ofType(config.action.start.type),
				map(action => action as IStartAction),
				switchMap(this.getSwitchMap(config, service))
			)
		);
	}

	addSideEffect(config: SideEffectConfig) {
		return createEffect(
			() =>
				this.actions$.pipe(
					ofType(config.actionType),
					tap(payload => config.effect(payload))
				),
			{ dispatch: false }
		);
	}

	addInitialLoadEffect<TState, TService>(
		config: ActionConfig<TState, TService>,
		service: TService,
		selector: BaseSelector<any, any>
	) {
		return createEffect(() =>
			this.actions$.pipe(
				ofType(config.action.start.type),
				withLatestFrom(
					this.store.select(
						selector.actionLoadingState(config.action.getName()).selector
					)
				),
				filter(([actionParam, loadState]: [Action, LoadingState]) => {
					const action: IStartAction = actionParam as IStartAction;
					if (
						loadState !== LoadingState.Loading &&
						(!action.forceLoad || loadState !== LoadingState.Awaiting)
					) {
						if (action.onError) {
							action.onError(`'${action.type}' has already been loaded`, true);
						}
						return false;
					}
					return true;
				}),
				map(([action]) => action as IStartAction),
				switchMap(this.getSwitchMap(config, service))
			)
		);
	}

	getSwitchMap<TState, TService>(
		{ action, effect }: ActionConfig<TState, TService>,
		service: TService
	) {
		return (currentAction: IStartAction) => {
			const start = new Date();
			let apiCall = effect(service);
			apiCall = apiCall.bind(service);
			let data = apiCall(currentAction.payload);
			if (data instanceof Promise) {
				data = from(data);
			}
			return data.pipe(
				map(response => {
					this.logSuccess(currentAction, response, start);

					if (action.settings.parseDates) {
						response = parseObject(response, x =>
							parseDate(x, action.settings.utcDates)
						);
					}

					if (currentAction.onSuccess) {
						currentAction.onSuccess(response);
					}

					return action.successWithData({
						data: response,
						payload: currentAction.payload,
					});
				}),
				catchError(data => {
					const correlationId: string =
						data?.headers?.get instanceof Function &&
						data?.headers?.get('x-correlation-id');
					const payload = data?.error ?? {};
					if (!payload.error) {
						payload.error = 'Something went wrong';
					}
					if (correlationId) {
						payload.correlationId = correlationId;
					}

					this.logError(currentAction, payload.error, start);
					if (currentAction.onError) {
						currentAction.onError(payload.error);
					}
					return of(action.failWithData(payload));
				})
			);
		};
	}

	logSuccess(action: IStartAction, result: any, start: Date) {
		if (this.#isProductionMode) {
			return;
		}

		const time = new Date().getTime() - start.getTime();
		const style = 'font-weight: bold';

		console.groupCollapsed(action.name);
		console.log('%cPayload: ', style, action.payload);
		console.log('%cReturn: ', style, result);
		console.log('%cTime: ', style, time < 1000 ? '< 1s' : `${time / 1000}s`);
		console.groupEnd();
	}

	logError(action: IStartAction, error: string, start: Date) {
		if (this.#isProductionMode) {
			return;
		}

		const time = new Date().getTime() - start.getTime();
		const style = 'font-weight: bold; color: red';

		console.groupCollapsed(`%cERROR: ${action.name}`, 'color:red');
		console.log('%cPayload: ', style, action.payload);
		console.log('%cError: ', style, error);
		console.log('%cTime: ', style, time < 1000 ? '< 1s' : `${time / 1000}s`);
		console.groupEnd();
	}
}
