import { SelectionModel } from '@angular/cdk/collections';
import { Injectable, TemplateRef, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { TableTemplateDirective } from '@shared/directives';
import { Observable, debounceTime, pipe, tap } from 'rxjs';
import { MatTableStore } from './mat-table.store';
import {
	TableAction,
	TableColumn,
	TableConfig,
	TableEntity,
} from './table.component';

interface TableState<TData extends TableEntity> {
	readonly actions: readonly TableAction<TData>[];
	readonly columns: readonly TableColumn<TData>[];
	readonly defaultSortDirection: 'asc' | 'desc';
	readonly hideActionHeader: boolean;
	readonly items: readonly TData[];
	readonly pagination: boolean;
	readonly search: boolean;
	readonly searchQuery: string;
	readonly templates: Record<string, TemplateRef<unknown>>;
}

@Injectable()
export class TableStore<TData extends TableEntity> extends ComponentStore<
	TableState<TData>
> {
	readonly #matTable = inject(MatTableStore<TData>);

	readonly #actions$: Observable<readonly TableAction<TData>[]> = this.select(
		state => state.actions
	);
	readonly #columns$: Observable<readonly TableColumn<TData>[]> = this.select(
		state => state.columns
	);
	readonly #items$: Observable<readonly TData[]> = this.select(
		state => state.items
	);
	/**
	 * @remarks The initial state is stored as a property instead of as a constant
	 *   to support the state type parameter.
	 */
	readonly #initialState: TableState<TData> = {
		actions: [],
		columns: [],
		defaultSortDirection: 'asc',
		hideActionHeader: false,
		items: [],
		pagination: false,
		search: false,
		searchQuery: '',
		templates: {},
	};
	readonly #cdkSelection = new SelectionModel<string>(false, []);
	readonly #searchQuery$: Observable<string> = this.select(
		state => state.searchQuery
	);

	readonly #data$: Observable<readonly TData[]> = this.select(
		this.#columns$,
		this.#items$,
		(columns, items) =>
			items.map(item =>
				columns
					.filter(
						(
							column
						): column is TableColumn<TData> &
							NonNullable<Pick<TableColumn<TData>, 'mapData'>> =>
							column.mapData !== undefined
					)
					.reduce(
						(row, column): TData => ({
							...row,
							[column.id]:
								column.mapData?.(row) ?? row[column.id as keyof TData],
						}),
						item
					)
			)
	);

	readonly actions$: Observable<readonly TableAction<TData>[]> = this.select(
		this.#actions$,
		actions => actions.filter(x => !x.disabled)
	);
	readonly columns$: Observable<readonly TableColumn<TData>[]> = this.#columns$;
	readonly columnIds$: Observable<readonly string[]> = this.select(
		this.#columns$,
		this.actions$,
		(columns, actions) =>
			[...columns.map(column => column.id)].concat(
				actions.length > 0 ? ['actions'] : []
			)
	);
	readonly defaultColumn$: Observable<string | null> = this.select(
		this.#columns$,
		columns => columns.find(column => column.defaultSort)?.id ?? null
	);
	readonly defaultSortDirection$: Observable<'asc' | 'desc'> = this.select(
		state => state.defaultSortDirection
	);
	readonly hideActionHeader$: Observable<boolean> = this.select(
		state => state.hideActionHeader
	);
	readonly pagination$: Observable<boolean> = this.select(
		state => state.pagination
	);
	readonly search$: Observable<boolean> = this.select(state => state.search);
	readonly searchQuery$: Observable<string> = this.#searchQuery$.pipe(
		debounceTime(200)
	);
	readonly selection$: Observable<string | null> = this.select(
		this.#cdkSelection.changed,
		change => change.source.selected[0] ?? null
	);
	readonly selected$: Observable<TData | null> = this.select(
		this.#items$,
		this.selection$,
		(items, selection) => items.find(item => item.id === selection) ?? null
	);
	readonly templates$: Observable<Record<string, TemplateRef<unknown>>> =
		this.select(state => state.templates);

	constructor() {
		super();
		this.setState(this.#initialState);

		this.#matTable.updateData(this.#data$);
		this.#matTable.updateFilter(
			this.select(this.searchQuery$, searchQuery => searchQuery.toLowerCase())
		);
	}

	readonly onToggleRow = this.effect<string>(
		pipe(tap((selection: string) => this.#cdkSelection.toggle(selection)))
	);

	isRowSelected(id: string): boolean {
		return this.#cdkSelection.isSelected(id);
	}

	readonly updateConfig = this.updater<TableConfig<TData>>(
		(
			state,
			{
				actions,
				columns,
				defaultSortDirection,
				hideActionHeader,
				pagination,
				search,
			}
		): TableState<TData> => ({
			...state,
			actions: actions ?? this.#initialState.actions,
			columns,
			defaultSortDirection:
				defaultSortDirection ?? this.#initialState.defaultSortDirection,
			hideActionHeader: hideActionHeader ?? this.#initialState.hideActionHeader,
			pagination,
			search,
		})
	);

	readonly updateItems = this.updater<readonly TData[]>(
		(state, items): TableState<TData> => ({
			...state,
			items,
		})
	);

	readonly updateSearchQuery = this.updater<string>(
		(state, searchQuery): TableState<TData> => ({
			...state,
			searchQuery: searchQuery.trim(),
		})
	);

	readonly updateSelection = this.effect<string | null>(
		pipe(
			tap((selection: string | null) => {
				if (selection === null) {
					this.#cdkSelection.clear();
				} else {
					this.#cdkSelection.select(selection);
				}
			})
		)
	);

	readonly updateTemplates = this.updater<readonly TableTemplateDirective[]>(
		(state, tableTemplateDirectives): TableState<TData> => ({
			...state,
			templates: tableTemplateDirectives.reduce(
				(templates, tableTemplateDirective) => ({
					...templates,
					[tableTemplateDirective.column]: tableTemplateDirective.templateRef,
				}),
				{}
			),
		})
	);
}
