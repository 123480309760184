import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	standalone: true,
	name: 'secondsToTime',
})
export class SecondsToTimePipe implements PipeTransform {
	transform(seconds: number): string {
		if (!seconds && seconds !== 0) {
			return;
		}
		return new Date(seconds * 1000).toISOString().substr(11, 8);
	}
}
