import { inject, Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { environmentToken } from '@environments/environment';
import { fromEvent, Subscription } from 'rxjs';
import {
	findAnchorElement,
	inAppBrowserUse,
	isValidExternalUrl,
	openLinkInCapacitorHandler,
} from './utils';
import { InAppBrowser } from '@capgo/inappbrowser';
import { CAPACITOR_IN_APP_BROWSER_REDIRECT_ROUTE } from './capacitor-in-app-browser-redirect.component';
import { TokenService } from '@consensus/shared/shared/iam/data-access';
import { Browser } from '@capacitor/browser';

@Injectable({ providedIn: 'root' })
export class CapacitorSafeLinksService {
	readonly isNativePlatform = Capacitor.isNativePlatform();
	readonly #webUrl = inject(environmentToken)?.wrappedApp?.webUrl;
	readonly #tokenService = inject(TokenService);
	#safeLinkEventSubscription?: Subscription;

	/** Mutates the HTMLAnchorElement to ensure that target is _self and that links to the web-version of the app are converted to in-app paths */
	#makeLinkElemCapacitorSafe(linkElem: HTMLAnchorElement): HTMLAnchorElement {
		linkElem.setAttribute('target', '_self');
		// If we use link.href, '/event/novo' will be parsed to e.g., http://localhost:4200/event/novo
		const href = linkElem.getAttribute('href');
		if (href && isValidExternalUrl(href)) {
			const url = new URL(href);
			// Replace full links to the application with a relative link to the same path
			if (this.#webUrl && url.href.startsWith(`${this.#webUrl}/`)) {
				linkElem.setAttribute('href', url.pathname);
			}
		}
		return linkElem;
	}

	#handleClick(event: MouseEvent | Event) {
		if (!this.isNativePlatform) {
			return;
		}
		if (!(event.target instanceof Element)) {
			// Narrow type from EventTarget to Element
			return;
		}

		// Find clicked anchor element, if any
		const linkElem = findAnchorElement(event.target);
		if (!linkElem) {
			return;
		}
		this.#makeLinkElemCapacitorSafe(linkElem);

		// If we use targetLinkElem.href, '/event/novo' will be parsed to e.g., http://localhost:4200/event/novo
		const href = linkElem.getAttribute('href');
		const useInAppBrowser = inAppBrowserUse(href);

		if (!href) {
			return;
		}
		if (useInAppBrowser === true) {
			event?.preventDefault(); // Do not open link normally
			this.openRouteWithInAppBrowser(href);
			return;
		} else if (useInAppBrowser === false) {
			return;
		} else {
			// Default behaviour
			openLinkInCapacitorHandler({ href, event });
		}
	}

	openRouteWithInAppBrowser(route: string) {
		if (isValidExternalUrl(route)) {
			// ensure that external URLs are opened in the default in-app browser
			Browser.open({ url: route });
			return;
		}
		// Handle internal routes
		const jwt = this.#tokenService.getLoginToken();
		const url =
			this.#webUrl +
			`/${CAPACITOR_IN_APP_BROWSER_REDIRECT_ROUTE}?jwt=${jwt}&redirectPath=${route}`;
		InAppBrowser.openWebView({
			url,
			title: 'CONNECT',
			isPresentAfterPageLoad: false,
		});
	}

	setupCapacitorSafeLinkEventListener() {
		if (!this.isNativePlatform) {
			console.warn(
				'Tried adding Capacitor safe link event listener in non-Capacitor context'
			);
			return;
		}
		this.#safeLinkEventSubscription?.unsubscribe();
		this.#safeLinkEventSubscription = fromEvent(window, 'click', {
			capture: true,
		}).subscribe(event => this.#handleClick(event));
	}
}
