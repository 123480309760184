import { EventAgendaState } from './agenda.model';
import { ApiAction } from '@lib/redux';
import { LoadUserAgendaModel } from './agenda.model';

export const loadAgendas = new ApiAction<
	EventAgendaState,
	void,
	LoadUserAgendaModel
>('Agenda', 'Load', 'Event Guard', {
	initialLoad: true,
	showErrors: true,
	parseDates: true,
	utcDates: false,
});
