import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { ScopeService } from '@core/services';

export const leaveClientGuard: CanDeactivateFn<unknown> = () => {
	const scopeService = inject(ScopeService);

	// Allow console.debug usage
	// eslint-disable-next-line no-restricted-syntax
	console.debug('Left Client Scope');
	scopeService.leaveClient();
	return true;
};
