import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';

const USE_IN_APP_BROWSER_QUERY_PARAM = 'useInAppBrowser';

export const isTargetAnchorElement = (
	target: Element
): target is HTMLAnchorElement => target.matches('a');

export const isValidExternalUrl = (url: string | null | undefined): boolean => {
	if (!url) {
		return false;
	}
	try {
		new URL(url);
		return true; // We could parse the URL and hence assume that it is a valid, external URL
	} catch {
		return false;
	}
};

export const findAnchorElement = (
	targetElem: Element
): HTMLAnchorElement | null => {
	if (isTargetAnchorElement(targetElem)) {
		return targetElem;
	}
	return targetElem.closest('a');
};

/**
 * Checks if useInAppBrowser is a query param in the URL and if it's value is 'true'
 *
 * The intent is to be able to force Capacitor to either use or not use the in-app browser
 *
 * Use case for forcing use: to navigate for an internal URL instead of navigating in the app directly.
 *
 * Use case for not forcing use: to navigate to e.g., the native SABA app through universal links
 *
 * If 'null' is returned, stay with default behavior.
 */
export const inAppBrowserUse = (href: string | null): boolean | null => {
	if (!href) {
		return null;
	}
	if (!Capacitor.isNativePlatform()) {
		return null;
	}
	try {
		const url = new URL(
			href,
			'https://placeholder.example' // Allows us to parse partial URLs. Permissible as we only care about the search params
		);
		// Replace full links to the application with a relative link to the same path
		const param: string | null = url.searchParams.get(
			USE_IN_APP_BROWSER_QUERY_PARAM
		);
		return param === null ? null : param === 'true';
	} catch {
		return null;
	}
};

export const openLinkInCapacitorHandler = ({
	href,
	event,
	defaultAction,
}: {
	href: string;
	event?: MouseEvent | Event;
	defaultAction?: (href: string) => void;
}): void => {
	const isExternalUrl = isValidExternalUrl(href);
	const isWebUrl =
		isExternalUrl && href
			? ['http:', 'https:'].includes(new URL(href).protocol)
			: false;
	if (isWebUrl && href) {
		event?.preventDefault(); // Do not open link normally
		Browser.open({ url: href }); // Open link using the Capacitor in-app browser
		return;
	}
	console.log(`Opening non-web URL: ${href}`); // This is useful e.g., for `msteams://` URLs
	defaultAction?.(href);
};
