import { Actions, createEffect } from '@ngrx/effects';
import { ApiEffects } from '@lib/redux';
import { inject, Injectable } from '@angular/core';
import { ThemingState } from './theming.model';
import { ThemingClient } from './theming.client';
import {
	loadPageTheming,
	loadTheme,
	loadThemeAnon,
	loadThemeById,
	updatePageTheming,
} from './theming.actions';
import { selectThemingState } from './theming.selectors';

@Injectable()
export class ThemingEffects extends ApiEffects<ThemingState> {
	readonly #client = inject(ThemingClient);
	readonly #actions$ = inject(Actions);
	constructor() {
		super(selectThemingState);
	}

	loadTheme$ = createEffect(() =>
		this.generateApiEffect(loadTheme, () => this.#client.getCurrentTheme())(
			this.#actions$
		)
	);

	loadThemeById$ = createEffect(() =>
		this.generateApiEffect(loadThemeById, action =>
			this.#client.getTheme(action.payload)
		)(this.#actions$)
	);

	loadAnonTheme$ = createEffect(() =>
		this.generateApiEffect(loadThemeAnon, action =>
			this.#client.getTheme(action.payload)
		)(this.#actions$)
	);

	loadPages$ = createEffect(() =>
		this.generateApiEffect(loadPageTheming, () =>
			this.#client.getPageTheming()
		)(this.#actions$)
	);

	updatePage$ = createEffect(() =>
		this.generateApiEffect(updatePageTheming, action =>
			this.#client.updatePageTheming(action.payload)
		)(this.#actions$)
	);
}
