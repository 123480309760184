/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import {
	Component,
	ElementRef,
	EventEmitter,
	inject,
	Input,
	OnDestroy,
	Output,
} from '@angular/core';
import { modalAnimations } from '@consensus/co/ui-component-animations';
import { ThemePalette } from '@angular/material/core';
import { OverlayService, OverlayToken } from '@core/services';
import { NgIf } from '@angular/common';

@Component({
	selector: 'co-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.scss'],
	animations: [modalAnimations],
	standalone: true,
	imports: [NgIf],
})
export class DialogComponent implements OnDestroy {
	readonly #overlayService = inject(OverlayService);
	readonly #element: ElementRef<HTMLElement> = inject(ElementRef);
	token: OverlayToken;

	@Input() title: string;
	@Input() color: ThemePalette;
	@Input() noScroll = false;
	@Input() showClose = false;
	@Input() noFooter = false;
	@Input() disableBackground = false;
	@Input() zIndex = 1000;
	@Input() width: string;
	@Input() headerPosition: string;

	_show: boolean;
	@Input() set show(show: boolean) {
		this._show = show;

		if (!show) {
			if (this.token) {
				this.token.destroy();
				this.token = null;
			}
			return;
		}

		if (!this.token) {
			this.token = this.#overlayService.stackOverlay();
			this.token.handleEscape(() => this.showChange.emit(false));
		}

		setTimeout(
			() =>
				(
					this.#element.nativeElement.querySelector(
						'input[autofocus]'
					) as HTMLElement
				)?.focus(),
			0
		);
	}
	get show() {
		return this._show;
	}

	@Output() showChange = new EventEmitter<boolean>();

	ngOnDestroy() {
		if (this.token) {
			this.token.destroy();
			this.token = null;
		}
	}
}
