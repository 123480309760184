import { Directive, HostBinding, Input } from '@angular/core';
import { RelativePosition } from '@shared/models';
import { clamp } from '@lib/helpers';

@Directive({
	standalone: true,
	selector: '[coRelativePosition]',
})
export class RelativePositionDirective {
	@HostBinding('style.position') position = 'absolute';
	@HostBinding('style.left') left = '0';
	@HostBinding('style.top') top = '0';
	@HostBinding('style.width') width = '0';
	@HostBinding('style.height') height = '0';
	@HostBinding('style.transform') transform: string;
	@HostBinding('class.area') isArea: boolean;

	@Input() forceArea = false;

	@Input('coRelativePosition') set relativePosition(pos: RelativePosition) {
		const x = pos.width < 0 ? (pos?.posX ?? 0) + pos.width : pos.posX;
		const y = pos.height < 0 ? (pos?.posY ?? 0) + pos.height : pos.posY;

		this.left = `${clamp(x, 0, 100)}%`;
		this.top = `${clamp(y, 0, 100)}%`;

		this.width = pos?.width ? `${clamp(Math.abs(pos.width), 0, 100)}%` : null;
		this.height = pos?.height
			? `${clamp(Math.abs(pos.height), 0, 100)}%`
			: null;

		if (pos.forceArea || (this.height && this.width)) {
			this.transform = null;
			this.isArea = true;
			return;
		}

		this.transform = `translate(${this.width ? '0' : '-50%'}, ${
			this.height ? '0' : '-50%'
		})`;
		this.isArea = false;
	}
}
