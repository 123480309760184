export const baseFroalaOptions = {
	key: 'Ja2A4wC2D1E1E2A4D3nDc2YRTYKg1Dc2a1JVVG1VJKKYLMPvA1E1I1B1B8C7A6E1E5F4==',
	attribution: false,
	wordPasteModal: false,
	imageUpload: false,
	imagePaste: false,
	emoticonsUseImage: false,
	wordAllowedStyleProps: [],
	wordDeniedAttrs: ['style', 'id', 'class'],
	wordDeniedTags: [],
	pasteDeniedAttrs: ['style', 'id', 'class'],
	pasteDeniedTags: [],
};
