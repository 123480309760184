import { inject, Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '@store/scope';
import { PermissionEnum } from '@consensus/connect/shared/access-control/domain';

@Pipe({
	standalone: true,
	name: 'hasPermission',
})
export class HasPermissionPipe implements PipeTransform {
	readonly #sessionService = inject(SessionService);

	transform(permission: PermissionEnum): boolean {
		return this.#sessionService.hasPermission(permission);
	}
}
