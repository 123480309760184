import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { SidepanelItem } from '@consensus/connect/shared/sidepanel/domain';
import { Observable } from 'rxjs';
import { environmentToken } from '@environments/environment';

@Injectable({
	providedIn: 'root',
})
export class SidepanelClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #http = inject(HttpClient);

	loadUserSidepanel(): Observable<SidepanelItem[]> {
		return this.#http.get<SidepanelItem[]>(`${this.#apiServer}/api/sidepanel`);
	}
}
