import { initialBaseState } from '@shared/models';
import { SignatureState } from './signature.model';
import { ApiReducer } from '@lib/redux';
import {
	addSignature,
	createUserSignature,
	loadSignatures,
	removeSignature,
	removeUserSignature,
	updateSignature,
} from './signature.actions';
import { removeListItem, updateListItem } from '@lib/redux';

const initialState: SignatureState = {
	...initialBaseState,
	userSignatures: [],
	signatures: [],
};

const reducer = new ApiReducer(initialState, 'Event');

reducer.addApiReducer(loadSignatures);

reducer.addApiReducer(createUserSignature, (signature, { userSignatures }) => ({
	userSignatures: [...userSignatures, signature],
}));

reducer.addReducer(addSignature, (signature, { signatures }) => ({
	signatures: updateListItem(
		signatures,
		s => s.id === signature.id,
		signature,
		true
	),
}));

reducer.addReducer(updateSignature, (signature, { signatures }) => ({
	signatures: updateListItem(
		signatures,
		s => s.id === signature.id,
		signature,
		true
	),
}));

reducer.addReducer(removeSignature, (id, { signatures }) => ({
	signatures: removeListItem(signatures, s => s.id === id),
}));

reducer.addReducer(removeUserSignature, (id, { userSignatures }) => ({
	userSignatures: removeListItem(userSignatures, s => s.id === id),
}));

export const signatureReducer = reducer.getReducer();
