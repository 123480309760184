import { Capacitor } from '@capacitor/core';
import { inject, Injectable } from '@angular/core';
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';

@Injectable({ providedIn: 'root' })
export class DeepLinkingService {
	readonly #router = inject(Router);
	readonly #zone = inject(NgZone);
	readonly #isNative = Capacitor.isNativePlatform();
	readonly #isAppPluginAvailable = Capacitor.isPluginAvailable('App');

	/**
	 * Initiates listiner for appUrlOpen events from @capacitor/app
	 *
	 * Provides the URL slug to the Angular router and navigates to it
	 *
	 * The listener is a noop if the build is not a native Capacitor build
	 */
	initDeepLinkingListener(webUrl: string): void {
		if (!this.#isNative || !this.#isAppPluginAvailable) {
			console.log('Non-native context, so no deep linking support');
			return;
		}
		console.log('init DeepLinking listener');
		App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			console.log(`DeepLinking event: ${JSON.stringify(event)}`);
			this.#zone.run(() => {
				// Example url: https://dev.consensus-connect.com/tabs/tab2
				// slug = /tabs/tab2
				const url = new URL(webUrl);
				const slug = event.url.split(url.host).pop();
				if (slug) {
					console.log(`DeepLinking slug: ${slug}`);
					this.#router.navigateByUrl(slug);
				}
				// If no match, do nothing
			});
		});
	}
}
