import { initialBaseState } from '@shared/models';
import { ApiReducer } from '@lib/redux';
import {
	addEliminationGame,
	advanceEliminationGame,
	answerEliminationGameQuestion,
	loadEliminationGames,
	removeEliminationGame,
	setEliminationGameTeam,
	updateEliminationGame,
} from './ufa-elimination-game.actions';
import { removeListItem, updateListItem } from '@lib/redux';
import { UfaEliminationGameStoreState } from './ufa-elimination-game.state';

const initialState: UfaEliminationGameStoreState = {
	...initialBaseState,
	eliminationGames: [],
};

const reducer = new ApiReducer(initialState, 'Event');

reducer.addApiReducer(loadEliminationGames, eliminationGames => ({
	eliminationGames,
}));

reducer.addApiReducer(
	answerEliminationGameQuestion,
	(answer, { eliminationGames }) => ({
		eliminationGames: updateListItem(
			eliminationGames,
			p => p.items.some(i => i.question && i.question.id === answer.questionId),
			p => ({
				answers: [answer, ...p.answers],
			})
		),
	})
);

reducer.addReducer(
	addEliminationGame,
	(eliminationGame, { eliminationGames }) => ({
		eliminationGames: updateListItem(
			eliminationGames,
			p => p.id === eliminationGame.id,
			eliminationGame,
			true
		),
	})
);

reducer.addReducer(
	removeEliminationGame,
	(eliminationGameId, { eliminationGames }) => ({
		eliminationGames: removeListItem(
			eliminationGames,
			p => p.id === eliminationGameId
		),
	})
);

reducer.addReducer(updateEliminationGame, (state, { eliminationGames }) => ({
	eliminationGames: updateListItem(
		eliminationGames,
		p => p.id === state.eliminationGameId,
		{ state }
	),
}));

reducer.addReducer(setEliminationGameTeam, (team, { eliminationGames }) => ({
	eliminationGames: updateListItem(
		eliminationGames,
		p => p.id === team.eliminationGameId,
		p => ({
			teams: [...p.teams, team],
		})
	),
}));

reducer.addReducer(advanceEliminationGame, (item, { eliminationGames }) => ({
	eliminationGames: updateListItem(
		eliminationGames,
		p => p.id === item.eliminationGameId,
		p => ({
			state: {
				...p.state,
				activeItemId: item.id,
				showOptions: p.skipToOptions,
				showResults: false,
			},
			items: [item, ...p.items],
		})
	),
}));

export const ufaEliminationGameReducer = reducer.getReducer();
