import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
	standalone: true,
	name: 'coBypassSecurityTrustHtml',
})
export class BypassSecurityTrustHtmlPipe implements PipeTransform {
	readonly #sanitized = inject(DomSanitizer);
	transform(value: string | null | undefined): SafeHtml {
		if (!value) {
			return '';
		}
		return this.#sanitized.bypassSecurityTrustHtml(value);
	}
}
