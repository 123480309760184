<ng-container
	*ngrxLet="{ search: search$, searchQuery: searchQuery$ } as searchVm"
>
	<co-form-search-input
		*ngIf="searchVm.search"
		[value]="searchVm.searchQuery"
		(valueChange)="onSearch($event)"
		label="Filter"
	/>
</ng-container>

<table
	*ngrxLet="{
		columns: columns$,
		columnIds: columnIds$,
		dataSource: dataSource$,
		defaultColumn: defaultColumn$,
		defaultSortDirection: defaultSortDirection$,
		templates: templates$,
	} as tableVm"
	mat-table
	[dataSource]="tableVm.dataSource"
	matSort
	[matSortActive]="tableVm.defaultColumn ?? ''"
	[matSortDirection]="tableVm.defaultSortDirection"
>
	<ng-container
		*ngFor="let col of tableVm.columns; trackBy: trackById"
		matColumnDef="{{ col.id }}"
	>
		<th
			mat-header-cell
			*matHeaderCellDef
			mat-sort-header
			[disabled]="!col.sort"
		>
			{{ col.title }}
		</th>

		<ng-container [ngSwitch]="col.dataType">
			<ng-container *ngSwitchCase="columnTypes.Bool">
				<td mat-cell *matCellDef="let element">
					{{ element[col.id] ? "Yes" : "No" }}
				</td>
			</ng-container>

			<ng-container *ngSwitchCase="columnTypes.Icon">
				<td mat-cell *matCellDef="let element">
					<i class="icon-data" [class]="element[col.id]"></i>
				</td>
			</ng-container>

			<ng-container *ngSwitchCase="columnTypes.HTML">
				<td
					mat-cell
					*matCellDef="let element"
					[innerHTML]="element[col.id] | coBypassSecurityTrustHtml"
				></td>
			</ng-container>

			<ng-container *ngSwitchCase="columnTypes.Template">
				<td mat-cell *matCellDef="let element">
					<ng-container
						*ngTemplateOutlet="
							tableVm.templates[col.id];
							context: { data: element[col.id] }
						"
					/>
				</td>
			</ng-container>

			<ng-container *ngSwitchDefault>
				<td mat-cell *matCellDef="let element">
					{{ element[col.id] }}
				</td>
			</ng-container>
		</ng-container>
	</ng-container>

	<ng-container
		*ngrxLet="{
			actions: actions$,
			hideActionHeader: hideActionHeader$
		} as actionsVm"
		matColumnDef="actions"
	>
		<th mat-header-cell *matHeaderCellDef>
			{{ !actionsVm.hideActionHeader ? "Actions" : "" }}
		</th>
		<td mat-cell *matCellDef="let row" coNoClickBubble>
			<button
				mat-icon-button
				class="!tw-text-base"
				*ngFor="
					let action of actionsVm.actions;
					trackBy: trackByActionName
				"
				(click)="action.action(row)"
				[matTooltip]="action.name"
			>
				<i [class]="action.icon"></i>
			</button>
		</td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="tableVm.columnIds"></tr>
	<tr
		mat-row
		*matRowDef="let row; columns: tableVm.columnIds"
		(click)="onClickRow(row)"
		[class.selected]="isRowSelected(row?.id)"
	></tr>
</table>

<ng-container *ngrxLet="pagination$ as pagination">
	<mat-paginator
		*ngIf="pagination"
		[pageSizeOptions]="[20, 50, 100]"
		showFirstLastButtons
	/>
</ng-container>
