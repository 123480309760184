import { initialBaseState } from '@shared/models';

import { loadAgendas } from './agenda.actions';
import { EventAgendaState } from './agenda.model';
import { ApiReducer } from '@lib/redux';

const initialState: EventAgendaState = {
	...initialBaseState,
	agendas: [],
	sessions: [],
};

const reducer = new ApiReducer(initialState, 'Event');

reducer.addApiReducer(loadAgendas);

export const agendaReducer = reducer.getReducer();
