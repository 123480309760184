import {
	APP_BOOTSTRAP_LISTENER,
	ComponentRef,
	FactoryProvider,
	Type,
} from '@angular/core';
import { Angulartics2PostHog } from './angulartics2-posthog.service';

function startAngulartic2PosthogTrackingFactory(
	angulartics2Posthog: Angulartics2PostHog
): (_rootComponent: ComponentRef<Type<unknown>>) => void {
	return () => {
		angulartics2Posthog.startTracking();
	};
}
export const angulartics2PosthogBootstrapListener: FactoryProvider = {
	provide: APP_BOOTSTRAP_LISTENER,
	multi: true,
	deps: [Angulartics2PostHog],
	useFactory: startAngulartic2PosthogTrackingFactory,
};
