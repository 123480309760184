import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { resetAllStores } from '@lib/redux';
import { logOut } from './global.actions';
import { of, switchMap, tap } from 'rxjs';
import { posthogToken } from '@consensus/shared/shared/analytics/data-access';
import { Router } from '@angular/router';

@Injectable()
export class GlobalEffects {
	readonly #posthog = inject(posthogToken);
	readonly #actions$ = inject(Actions);
	readonly #router = inject(Router);

	logout$ = createEffect(() =>
		this.#actions$.pipe(
			ofType(logOut),
			tap(() => {
				const spoofToken = localStorage.getItem('spoofToken');
				if (spoofToken) {
					localStorage.removeItem('spoofToken');
					return;
				}

				const spoofId = localStorage.getItem('spoofUserId');
				if (spoofId) {
					localStorage.removeItem('spoofUserId');
					return;
				}

				localStorage.removeItem('loginToken');
			}),
			switchMap(action => {
				this.#posthog.reset();
				this.#router.navigate(action.customRoute ?? ['/']);
				return of(resetAllStores());
			})
		)
	);
}
