// TODO(@LayZeeDK): [no-restricted-globals] Consider Angularizing this file at
//   some point
/* eslint-disable no-restricted-globals */
const buildingBlockSelector = '.froala-design-blocks';

type JQueryEvent<T> = { originalEvent: T; which: number };

export function buildingBlocksPlugin(froalaEditor) {
	froalaEditor.PLUGINS.buildingBlocks = BuildingBlocksPlugin;
	froalaEditor.POPUP_TEMPLATES['buildingBlocks.edit'] = '[_BUTTONS_]';

	froalaEditor.DefineIcon('removeBuildingBlock', {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		NAME: 'trash',
		template: 'font_awesome_5',
	});
	froalaEditor.RegisterCommand('removeBuildingBlock', {
		title: 'Remove Building Block',
		undo: true,
		focus: true,
		showOnMobile: true,
		refreshAfterCallback: true,
		callback: function () {
			this.popups.hide('buildingBlocks.edit');
			const element = this.buildingBlocks.getTopLevelElementSelection();
			this.undo.saveStep();
			element.remove();
			this.undo.saveStep();
		},
		refresh: function (btn) {
			const element = this.buildingBlocks.getTopLevelElementSelection();
			btn.toggleClass(
				'fr-disabled',
				!element || !element.matches(buildingBlockSelector)
			);
		},
	});

	froalaEditor.DefineIcon('moveBuildingBlockDown', {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		NAME: 'chevron-down',
		template: 'font_awesome_5',
	});
	froalaEditor.RegisterCommand('moveBuildingBlockDown', {
		title: 'Move Building Block Down',
		undo: true,
		focus: true,
		showOnMobile: true,
		refreshAfterCallback: true,
		callback: function () {
			this.popups.hide('buildingBlocks.edit');
			const element = this.buildingBlocks.getTopLevelElementSelection();
			this.undo.saveStep();
			this.$(element).insertAfter(this.$(element.nextElementSibling));
			this.undo.saveStep();
		},
		refresh: function (btn) {
			const element = this.buildingBlocks.getTopLevelElementSelection();
			btn.toggleClass(
				'fr-disabled',
				!element ||
					!element.matches(buildingBlockSelector) ||
					!element.nextElementSibling
			);
		},
	});

	froalaEditor.DefineIcon('moveBuildingBlockUp', {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		NAME: 'chevron-up',
		template: 'font_awesome_5',
	});
	froalaEditor.RegisterCommand('moveBuildingBlockUp', {
		title: 'Move Building Block Up',
		undo: true,
		focus: true,
		showOnMobile: true,
		refreshAfterCallback: true,
		callback: function () {
			this.popups.hide('buildingBlocks.edit');
			const element = this.buildingBlocks.getTopLevelElementSelection();
			this.undo.saveStep();
			this.$(element).insertBefore(this.$(element.previousElementSibling));
			this.undo.saveStep();
		},
		refresh: function (btn) {
			const element = this.buildingBlocks.getTopLevelElementSelection();
			btn.toggleClass(
				'fr-disabled',
				!element ||
					!element.matches(buildingBlockSelector) ||
					!element.previousElementSibling
			);
		},
	});
}

class BuildingBlocksPlugin {
	constructor(private editor: any) {}

	getTopLevelElementSelection(): Element {
		let element = this.editor.selection.ranges(0).commonAncestorContainer;
		while (element && element.parentElement !== this.editor.$el[0]) {
			element = element.parentElement;
		}

		return element;
	}

	getElementAtPoint(event: JQueryEvent<MouseEvent>) {
		return document.elementFromPoint(
			event.originalEvent.clientX,
			event.originalEvent.clientY
		);
	}

	getTopLevelElementAtPoint(event: JQueryEvent<MouseEvent>) {
		let element = this.getElementAtPoint(event);
		while (element && element.parentElement !== this.editor.$el[0]) {
			element = element.parentElement;
		}

		return element;
	}

	getOrCreateRelativeElement(event: JQueryEvent<MouseEvent>) {
		const element = this.getTopLevelElementAtPoint(event);
		if (!element) {
			return null;
		}

		const { y, height } = element.getBoundingClientRect();

		if (event.originalEvent.clientY < y + height / 2) {
			if (!element.previousElementSibling) {
				element.parentElement.prepend(document.createElement('div'));
			}

			return element.previousElementSibling as HTMLElement;
		} else {
			return element as HTMLElement;
		}
	}

	/**
	 * @remarks Intentionally public Froala hook, do not convert to ECMAScript
	 *   private method.
	 */
	_init() {
		if (!this.editor.opts.buildingBlocksEnabled) {
			return;
		}

		this.editor.opts.lineBreakerTags = [
			...this.editor.opts.lineBreakerTags,
			buildingBlockSelector,
		];

		this.editor.popups.create('buildingBlocks.edit', {
			buttons: `<div class="fr-buttons">${this.editor.button.buildList([
				'moveBuildingBlockUp',
				'moveBuildingBlockDown',
				'removeBuildingBlock',
			])}</div>`,
		});

		this.editor.events.on(
			'window.mouseup',
			(event: JQueryEvent<MouseEvent>) => {
				if (this.editor.core.hasFocus()) {
					this.editor.popups.hide('buildingBlocks.edit');
					setTimeout(
						() => {
							if (event.which === 1) {
								const element = this.getElementAtPoint(event);
								if (
									!element ||
									!['DIV', 'SECTION', 'ARTICLE'].includes(element.tagName)
								) {
									return;
								}

								const topLevelElement = this.getTopLevelElementAtPoint(event);
								if (!topLevelElement) {
									return;
								}

								if (topLevelElement.matches(buildingBlockSelector)) {
									const $topLevelElement = this.editor.$(topLevelElement);
									const left =
										$topLevelElement.offset().left +
										$topLevelElement.outerWidth() / 2;
									const top =
										$topLevelElement.offset().top +
										$topLevelElement.outerHeight();
									this.editor.popups.show(
										'buildingBlocks.edit',
										left,
										top,
										$topLevelElement.outerHeight(),
										true
									);
								}
							}
						},
						this.editor.helpers.isIOS() ? 100 : 0
					);
				}
			}
		);

		const $dropIndicator = this.editor
			.$(document.createElement('div'))
			.addClass('fr-building-blocks')
			.css({
				borderTop: '1px solid #0098f7',
				position: 'absolute',
				marginTop: '50px',
				height: '1px',
				display: 'none',
				zIndex: 1,
			});
		this.editor.$box.append($dropIndicator);

		this.editor.events.on('dragover', (event: JQueryEvent<DragEvent>) => {
			const element = this.getOrCreateRelativeElement(event);
			if (!element) {
				$dropIndicator.hide().removeClass('fr-visible');
				return;
			}

			$dropIndicator
				.show()
				.addClass('fr-visible')
				.css({
					top: element.offsetTop + element.offsetHeight,
					left: element.offsetLeft,
					width: element.offsetWidth,
				});
		});

		this.editor.events.on('dragleave', () => {
			$dropIndicator.hide().removeClass('fr-visible');
		});

		this.editor.events.on('drop', (event: JQueryEvent<DragEvent>) => {
			$dropIndicator.hide().removeClass('fr-visible');

			const element = this.getOrCreateRelativeElement(event);
			if (element) {
				this.editor.undo.saveStep();
				const html = event.originalEvent.dataTransfer.getData('html');
				this.editor.$(html).insertAfter(this.editor.$(element));
				this.editor.undo.saveStep();
			}

			// Stop event propagation.
			event.originalEvent.preventDefault();
			event.originalEvent.stopPropagation();

			// Firefox show cursor.
			if (this.editor.core.hasFocus() && this.editor.browser.mozilla) {
				this.editor.events.disableBlur();
				setTimeout(() => {
					this.editor.$el.blur().focus();
					this.editor.events.enableBlur();
				}, 0);
			}
		});
	}
}
