<div class="wrapper" [@harmonica]="show && max > min">
	<label
		class="input-label"
		[matTooltip]="tooltip"
		matTooltipPosition="above"
		*ngIf="label"
	>
		{{ label }}
		<span class="required" *ngIf="required">*</span>
		<i class="fad fa-question-circle" *ngIf="tooltip"></i>
	</label>

	<div class="slider-wrapper">
		<div class="slider" #slider>
			<div class="range" #range></div>

			<div
				class="handle"
				#handle
				cdkDrag
				cdkDragLockAxis="x"
				cdkDragBoundary=".slider-wrapper"
				(cdkDragEnded)="dropHandle($event)"
				(cdkDragMoved)="moveHandle($event)"
				[cdkDragDisabled]="isDisabled || readonly"
			></div>
		</div>
	</div>

	<div class="labels">
		<div
			class="slider-label"
			*ngFor="let label of labels; trackBy: trackByIdentity"
			[style.left]="label.position + '%'"
			[style.width]="label.width + '%'"
		>
			<div class="line"></div>
			<label>{{ label.label }}</label>
		</div>
	</div>
</div>
