import { inject, Injectable } from '@angular/core';
import { LocaleService } from '@consensus/connect/shared/globalization/util';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs';
import {
	loadEvent,
	updateEvent,
	refreshEvent,
	clearEvent,
	uploadArPopulation,
	loadArPopulation,
} from './scope.store';
import { SessionService } from './session.service';
import { rawDispatchAsync, resetStores } from '@lib/redux';
import { Store } from '@ngrx/store';

@Injectable()
export class ScopeEffects {
	readonly #actions$ = inject(Actions);
	readonly #localeService = inject(LocaleService);
	readonly #sessionService = inject(SessionService);
	readonly #store = inject(Store);

	/** Update Locale service with locale and timezone from event  */
	updateEvent$ = createEffect(
		() =>
			this.#actions$.pipe(
				ofType(updateEvent.success),
				tap(action => {
					this.#localeService.eventLocale = action?.data.locale;
					this.#localeService.timezone = action?.data?.timezone;
				})
			),
		{ dispatch: false }
	);

	/** Update Locale service with timezone from event  */
	refreshEvent$ = createEffect(
		() =>
			this.#actions$.pipe(
				ofType(refreshEvent.success),
				tap(action => {
					// Only timezone is provided in the SimpleEvent payload
					this.#localeService.timezone = action?.data?.timezone;
				})
			),
		{ dispatch: false }
	);

	/** Update Locale service with locale and timezone from event  */
	loadEvent$ = createEffect(
		() =>
			this.#actions$.pipe(
				ofType(loadEvent.success),
				tap(action => {
					this.#localeService.eventLocale = action?.data.event?.locale;
					this.#localeService.timezone = action?.data.event?.timezone;
				})
			),
		{ dispatch: false }
	);

	clearEvent$ = createEffect(
		() =>
			this.#actions$.pipe(
				ofType(clearEvent, resetStores), // For resetStores, we don't care if the payload is 'Global', 'Client' or 'Event', all should trigger reset.
				tap(() => {
					this.#sessionService.resetStores$.next();
				})
			),
		{ dispatch: false }
	);

	loadArPopulation$ = createEffect(
		() =>
			this.#actions$.pipe(
				ofType(uploadArPopulation.success),
				switchMap(() => rawDispatchAsync(this.#store, loadArPopulation))
			),
		{
			dispatch: false,
		}
	);
}
