import { CustomStore } from '@lib/redux';
import {
	Group,
	GroupAddUserModel,
	GroupRemoveUserModel,
	GroupsState,
	GroupUpdateModel,
} from './group.model';
import { removeListItem, updateListItem } from '@lib/redux';
import { keyBy } from 'lodash-es';
import { GroupClient } from './group.client';

export const groupsStore = new CustomStore<GroupsState, GroupClient>(
	'Groups',
	{ groups: [] },
	GroupClient,
	'Event'
);

const store = groupsStore;
export const maxCacheInconsistencyIntervalSeconds = 15;

export const loadGroups = store
	.addApiAction('Groups', 'Load')
	.configure({ initialLoad: true })
	.withEffect(s => s.loadGroups)
	.withReducer(groups => ({ groups }));

export const createGroup = store
	.addApiAction('Group', 'Create')
	.withEffect(s => s.createGroup)
	.withReducer((group, { groups }) => ({
		groups: groups.some(g => g.id === group.id) ? groups : [...groups, group],
	}));

export const updateGroup = store
	.addApiAction('Group', 'Update')
	.withEffect(s => s.updateGroup)
	.update('groups');

export const deleteGroup = store
	.addApiAction('Group', 'Delete')
	.withDelayedEffect(s => s.deleteGroup)
	.delete('groups');

export const addUserToGroup = store
	.addApiAction('Group', 'Add user')
	.withDelayedEffect(s => s.addUserToGroup)
	.withReducer(({ groupId, userId }, { groups }) => ({
		groups: updateListItem(
			groups,
			g => g.id == groupId,
			g => ({
				users: updateListItem(g.users, x => x === userId, userId, true),
			})
		),
	}));

export const removeUserFromGroup = store
	.addApiAction('Group', 'Remove user')
	.withDelayedEffect(s => s.removeUserFromGroup)
	.withReducer(({ groupId, userId }, { groups }) => ({
		groups: updateListItem(
			groups,
			g => g.id == groupId,
			g => ({
				users: removeListItem(g.users, x => x === userId),
			})
		),
	}));

store
	.addSocketAction<GroupUpdateModel>('Groups', 'Group', 'Updated')
	.update('groups');

store
	.addSocketAction<string>('Groups', 'Group', 'Removed')
	.delete('groups', x => x.id);

store
	.addSocketAction<Group>('Groups', 'Group', 'Created')
	.withReducer((group, { groups }) => ({
		groups: groups.some(g => g.id === group.id) ? groups : [...groups, group],
	}));

store
	.addSocketAction<GroupAddUserModel>('Groups', 'GroupUser', 'Added')
	.withReducer(({ groupId, userId }, { groups }) => ({
		groups: updateListItem(
			groups,
			g => g.id === groupId,
			g => ({
				users: updateListItem(g.users, x => x === userId, userId, true),
			})
		),
	}));

store
	.addSocketAction<GroupRemoveUserModel>('Groups', 'GroupUser', 'Removed')
	.withReducer(({ groupId, userId }, { groups }) => ({
		groups: updateListItem(
			groups,
			g => g.id == groupId,
			g => ({
				users: removeListItem(g.users, x => x === userId),
			})
		),
	}));

// Selectors

export const getGroups = store.addSelector(s => s.groups);
export const getGroupLookup = getGroups.create(x => keyBy(x, x => x.id));

export const getEventUserGroups = getGroups.createWithParam(
	(groups, userId: string) => groups.filter(g => g.users.includes(userId))
);
