import { BaseState } from '@shared/models';
import { isString } from 'lodash-es';

export interface ThemingState extends BaseState {
	pageTheming: PageTheming[];
	theme: Theme;
}

export interface Theme {
	id: string;
	name: string;
	dark: boolean;
	private: boolean;
	useLoginLogo: boolean;
	useDashboardLogo: boolean;
	useOverviewLogo: boolean;
	useEliminationLogo: boolean;
	useBackground: boolean;
	semaStyling: boolean;
	useSurveyResultsBackground: boolean;
	styles: ThemeStyles;
}

export interface ThemeStyles {
	textColor?: string;
	primary?: string;
	primaryText?: string;
	secondary?: string;
	secondaryText?: string;
	backgroundContrast?: string;
	landingPageColor?: string;
	landingPageContrast?: string;
}

export interface PageTheming {
	id: string;
	pageId: PageIds;
	pageTitle: string;
	footerLine1: string;
	footerLine2: string;
}

// Action Models

export enum PageIds {
	ActionRequired = 'ActionRequired',
	Dashboard = 'Dashboard',
	SubDashboard = 'SubDashboard',
	ExternalSite = 'ExternalSite',
	Academy = 'Academy',
	KeyMeetingInfo = 'KeyMeetingInfo',
	Manage = 'Manage',
	Materials = 'Materials',
	MyAgenda = 'MyAgenda',
	Notes = 'Notes',
	Notifications = 'Notifications',
	QA = 'QA',
	SocialFeed = 'SocialFeed',
	Speakers = 'Speakers',
	Statistics = 'Statistics',
	SupportFAQ = 'SupportFAQ',
	Colearn = 'Colearn',
	Certification = 'Certification',
	UserCms = 'UserCms',
	ModularDashboard = 'ModularDashboard',
	ChatMessages = 'ChatMessages',
	CountdownCalendar = 'CountdownCalendar',
	PageEditor = 'PageEditor',
	PointVouchers = 'PointVouchers',
	Storylines = 'Storylines',
	LevelUpVouchers = 'LevelUpVouchers',
}

export function isPageIds(x: unknown): x is PageIds {
	return isString(x) && Object.values(PageIds).includes(x as PageIds);
}

//Add default titles here
export const defaultPageTitles: { [key in PageIds]: string } = {
	[PageIds.ActionRequired]: 'Action Required',
	[PageIds.Dashboard]: null,
	[PageIds.SubDashboard]: null,
	[PageIds.ExternalSite]: 'External Site',
	[PageIds.KeyMeetingInfo]: 'Key Meeting Info',
	[PageIds.Manage]: 'Manage',
	[PageIds.Materials]: 'Materials',
	[PageIds.MyAgenda]: 'My Agenda',
	[PageIds.Notes]: 'Notes',
	[PageIds.Notifications]: 'Notifications',
	[PageIds.QA]: 'Questions & Feedback',
	[PageIds.SocialFeed]: 'Social Feed',
	[PageIds.Speakers]: 'Speakers',
	[PageIds.Statistics]: 'Statistics',
	[PageIds.SupportFAQ]: 'Support FAQ',
	[PageIds.Colearn]: 'Colearn',
	[PageIds.Certification]: 'Certification',
	[PageIds.UserCms]: 'Manage Content',
	[PageIds.ModularDashboard]: 'Modular Dashboard',
	[PageIds.Storylines]: 'Feeds',
	[PageIds.ChatMessages]: 'Messages',
	[PageIds.Academy]: 'Academy',
	[PageIds.CountdownCalendar]: 'Countdown Calendar',
	[PageIds.PageEditor]: 'Page Editor',
	[PageIds.PointVouchers]: 'Point Vouchers',
	[PageIds.LevelUpVouchers]: 'LevelUp Vouchers',
};
