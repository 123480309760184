import { filter, pipe } from 'rxjs';
import { isNonNullish } from '@consensus/co/util-types';

/**
 * A filter `OperatorFunction` with a type predicate to exclude
 * `null` and `undefined` and narrow the type down to type `T`.
 * @see {@link isNonNullish}
 * @see {@link https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates}
 */
export const filterNonNullish = pipe(filter(isNonNullish));
