import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { CUBIC_IN_OUT_EASING } from './constants';

export function harmonicaAnimation(duration = 400) {
	return trigger('harmonica', [
		state(
			'true',
			style({
				height: '*',
				paddingTop: '*',
				marginTop: '*',
				paddingBottom: '*',
				marginBottom: '*',
				boxShadow: '*',
				minHeight: '*',
			})
		),
		state(
			'false',
			style({
				height: 0,
				paddingTop: 0,
				marginTop: 0,
				paddingBottom: 0,
				marginBottom: 0,
				boxShadow: 'none',
				minHeight: 0,
				overflow: 'hidden',
			})
		),
		transition('true => false', [
			style({ overflow: 'hidden' }),
			animate(`${duration}ms ${CUBIC_IN_OUT_EASING}`),
		]),
		transition('false => true', [
			style({ overflow: 'hidden' }),
			animate(
				`${duration}ms ${CUBIC_IN_OUT_EASING}`,
				style({
					height: '*',
					paddingTop: '*',
					marginTop: '*',
					paddingBottom: '*',
					marginBottom: '*',
					boxShadow: '*',
					minHeight: '*',
					overflow: 'hidden',
				})
			),
			style({ overflow: '*' }),
		]),
	]);
}
