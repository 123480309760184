import {
	ChangeDetectorRef,
	inject,
	NgZone,
	OnDestroy,
	Pipe,
	PipeTransform,
} from '@angular/core';
import { parseDate } from '@lib/helpers';

@Pipe({
	standalone: true,
	name: 'timeLeft',
	pure: false,
})
export class TimeLeftPipe implements PipeTransform, OnDestroy {
	readonly #ngZone = inject(NgZone);
	readonly #changeDetectorRef = inject(ChangeDetectorRef);
	#timer: number;

	#getSecondsUntilUpdate(_seconds: number) {
		// update every hour
		return 3600;
	}

	transform(time: Date | string) {
		const val = typeof time === 'string' ? parseDate(time) : (time as Date);

		if (!val) {
			return '';
		}

		this.#removeTimer();
		const now = new Date();

		const seconds = Math.round((val.getTime() - now.getTime()) / 1000);

		const timeToUpdate = Number.isNaN(seconds)
			? 1000
			: this.#getSecondsUntilUpdate(seconds) * 1000;

		this.#timer = this.#ngZone.runOutsideAngular(() => {
			if (typeof window !== 'undefined') {
				return window.setTimeout(() => {
					this.#ngZone.run(() => this.#changeDetectorRef.markForCheck());
				}, timeToUpdate);
			}
			return null;
		});

		const minutes = Math.round(seconds / 60);
		const hours = Math.round(minutes / 60);
		const days = Math.round(hours / 24);

		if (Number.isNaN(seconds)) {
			return '';
		} else if (days <= -1) {
			return `${Math.abs(days)} ${days == -1 ? ' day ago' : ' days ago'}`;
		} else if (hours <= -24) {
			return 'Yesterday';
		} else if (hours <= 24) {
			return 'Today';
		} else if (hours <= 48) {
			return 'Tomorrow';
		} else {
			return val.toLocaleDateString([], {
				month: 'short',
				day: 'numeric',
				year: 'numeric',
			});
		}
	}

	ngOnDestroy(): void {
		this.#removeTimer();
	}

	#removeTimer() {
		if (this.#timer) {
			window.clearTimeout(this.#timer);
			this.#timer = null;
		}
	}
}
