/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { NgIf } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
	MatDatepickerInputEvent,
	MatDatepickerModule,
	MatSingleDateSelectionModel,
} from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { harmonicaAnimation } from '@consensus/co/ui-component-animations';
import { InputBaseComponent } from '@lib/forms';
import moment, { Moment } from 'moment';
import {
	NgxMatTimepickerComponent,
	NgxMatTimepickerDirective,
} from 'ngx-mat-timepicker';
import { provideNgxMatTimepicker } from '../provide-ngx-mat-timepicker';
import { coToDateOrNull } from '@consensus/co/util-date-times';

@Component({
	standalone: true,
	selector: 'co-form-datetime-input',
	imports: [
		NgIf,
		FormsModule,
		MatButtonModule,
		MatDatepickerModule,
		MatInputModule,
		MatTooltipModule,
		NgxMatTimepickerComponent,
		NgxMatTimepickerDirective,
	],
	templateUrl: './datetime-input.component.html',
	styleUrls: ['./datetime-input.component.scss'],
	animations: [harmonicaAnimation()],
	viewProviders: [provideNgxMatTimepicker()],
})
export class DatetimeInputComponent extends InputBaseComponent<Date> {
	@Input() forceUtc = false; // If string is provided, force parsing of it as UTC
	now = new Date();

	date: string;
	time: string;

	@ViewChild('timepicker') timePicker: NgxMatTimepickerComponent;

	preprocessValue(value): Date | any {
		if (!value) {
			return value;
		}

		if (!(value instanceof Date)) {
			if (this.forceUtc) {
				value = coToDateOrNull(value, 'Etc/UTC');
			}
			value = new Date(value);
		}

		this.date =
			`${value.getUTCFullYear()}` +
			`-${(value.getUTCMonth() + 1).toString().padStart(2, '0')}` +
			`-${value.getUTCDate().toString().padStart(2, '0')}`;
		this.time = `${value.getUTCHours()}:${value.getUTCMinutes()}`;

		return (
			`${value.getUTCFullYear()}` +
			`-${(value.getUTCMonth() + 1).toString().padStart(2, '0')}` +
			`-${value.getUTCDate().toString().padStart(2, '0')}` +
			`T${value.getUTCHours().toString().padStart(2, '0')}` +
			`:${value.getUTCMinutes().toString().padStart(2, '0')}` +
			`:${value.getUTCSeconds().toString().padStart(2, '0')}`
		);
	}

	postprocessValue(value): Date | any {
		if (!value) {
			return value;
		}
		if (value instanceof Date) {
			return value;
		}
		const date = new Date(value);
		date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
		return date;
	}

	setDate(
		event: MatDatepickerInputEvent<Moment, MatSingleDateSelectionModel<Moment>>
	): void {
		if (event.value === null) {
			event.value = moment(this.now);
		}

		const val = new Date(this.modelValue);
		val.setUTCFullYear(
			event.value.year(),
			event.value.month(),
			event.value.date()
		);
		this.inputValue = this.preprocessValue(val);

		this.timePicker.open();
	}

	setTime(value: string): void {
		const time = new Date(`1/1/1970 ${value}`);

		const val = new Date(this.modelValue);
		val.setUTCHours(time.getHours(), time.getMinutes(), time.getSeconds());
		this.inputValue = this.preprocessValue(val);
	}
}
