import { CustomStore } from '@lib/redux';
import { keyBy } from 'lodash-es';
import { AdminUser, BaseState } from '@shared/models';
import { UsersClient } from './users.client';
import { removeListItem, updateList, updateListItem } from '@lib/redux';
import { sortAlphAsc } from '@lib/helpers';

export interface EventCmsUsersState extends BaseState {
	users: AdminUser[];
	online: string[];
}

export const eventUsersKey = 'eventUsers';

export const eventCmsUsersStore = new CustomStore<
	EventCmsUsersState,
	UsersClient
>('Event CMS Users', { users: [], online: [] }, UsersClient, 'Event');

const store = eventCmsUsersStore;

export const loadEventCmsUsers = store
	.addApiAction('Users', 'Load')
	.withError()
	.isInitial()
	.withEffect(s => s.loadEventCmsUsers)
	.withReducer(users => ({ users }));

export const loadOnlineUsers = store
	.addApiAction('Online Users', 'Load')
	.withError()
	.isInitial()
	.withEffect(s => s.loadOnlineUsers)
	.withReducer(online => ({ online }));

export const createEventUser = store
	.addApiAction('User', 'Create')
	.withError()
	.withEffect(s => s.createUser)
	.addition('users');

export const updateEventUser = store
	.addApiAction('User', 'Update')
	.withError()
	.withEffect(s => s.updateUser)
	.update('users');

export const deleteEventUser = store
	.addApiAction('User', 'Delete')
	.withError()
	.withDelayedEffect(s => s.deleteUser)
	.delete('users');

export const addClientUserToEventUsers = store
	.addAction<AdminUser>('Client User', 'Add')
	.addition('users');

export const createUserClaim = store
	.addApiAction('UserClaim', 'Create')
	.withError()
	.withEffect(s => s.createUserClaim)
	.withReducer((item, { users }) => ({
		users: updateListItem(
			users,
			x => x.id === item.userId,
			x => ({
				claims: [...x.claims, item],
			})
		),
	}));

export const updateUserClaim = store
	.addApiAction('UserClaim', 'Update')
	.withError()
	.withEffect(s => s.updateUserClaim)
	.withReducer((item, { users }) => ({
		users: updateListItem(
			users,
			x => x.id === item.userId,
			x => ({
				claims: updateListItem(x.claims, y => y.id === item.id, item),
			})
		),
	}));

export const deleteUserClaim = store
	.addApiAction('UserClaim', 'Delete')
	.withError()
	.withDelayedEffect(s => s.deleteUserClaim)
	.withReducer((id, { users }) => ({
		users: updateList(users, x => ({
			claims: removeListItem(x.claims, y => y.id === id),
		})),
	}));

store
	.addSocketAction<string>('Users', 'Connection', 'Set')
	.withReducer((id, { online }) => ({
		online: [...online.filter(x => x !== id), id],
	}));

store
	.addSocketAction<string>('Users', 'Connection', 'Removed')
	.delete('online', x => x);

// Selectors

export const getEventCmsUsers = store.addSelector(x => x.users);
export const getEventCmsUsersLookup = getEventCmsUsers.create(x =>
	keyBy(x, x => x.id)
);
export const getEventCmsUserClaimTypes = getEventCmsUsers.create(x => {
	const types = x.map(x => x.claims.map(c => c.type));
	const flattenedTypes = ([] as string[]).concat(...types);
	const distinctTypes = [...Array.from(new Set(flattenedTypes))];
	return distinctTypes;
});
export const getEventCmsPresentableUsers = getEventCmsUsers.create(users =>
	users
		.filter(u => !u.observer)
		.filter(u => !u.disabled)
		.sort(sortAlphAsc(u => u.firstName?.toLocaleLowerCase() ?? ''))
);

export const getOnlineUserCount = store.addSelector(x => x.online.length);
export const getOnlineUsers = store.addSelector(x => x.online);
