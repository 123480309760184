import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash-es';
import { defaultTheme } from './theming.reducer';
import { ThemingState } from './theming.model';

export const themingKey = 'theming';

interface GlobalState {
	[themingKey]: ThemingState;
}

const selectGlobalState = createFeatureSelector<GlobalState>('global');

export const selectThemingState = createSelector(
	selectGlobalState,
	s => s.theming
);

export const selectTheme = createSelector(selectThemingState, s =>
	isEmpty(s.theme) ? defaultTheme : s.theme
);
export const selectPageTheming = createSelector(
	selectThemingState,
	s => s.pageTheming
);
export const selectSemaStyling = createSelector(
	selectTheme,
	s => s.semaStyling
);
