<div mat-dialog-title>
	<fa-icon [icon]="infoIcon" class="tw-mr-1" />
	No update available
</div>
<div mat-dialog-content class="tw-flex tw-flex-auto tw-flex-col tw-space-y-2">
	<p>There is no update available for the app at this time.</p>
</div>
<div mat-dialog-actions>
	<div class="tw-grow"></div>
	<button mat-stroked-button cdkFocusInitial mat-dialog-close>Close</button>
</div>
