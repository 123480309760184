export function parseDate(str: string, fromUtc = true, force = false) {
	if (typeof str !== 'string') {
		return null;
	}

	const match = str.match(
		/^((\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.?\d*)Z?$/
	);

	if (!match) {
		if (!force) {
			return null;
		}
		const date = new Date(str);
		if (fromUtc) {
			date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
		}
		return date;
	}

	if (fromUtc) {
		return new Date(match[1] + 'Z');
	}

	return new Date(
		Number(match[2]),
		Number(match[3]) - 1,
		Number(match[4]),
		Number(match[5]),
		Number(match[6]),
		Number(match[7])
	);
}

export function wait(delay: number): Promise<void> {
	return new Promise(resolve => setTimeout(() => resolve(), delay));
}
