import { DOCUMENT } from '@angular/common';
import {
	AfterViewInit,
	Directive,
	ElementRef,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	NgZone,
	OnDestroy,
	OnInit,
	Output,
	inject,
} from '@angular/core';
import { InputBaseComponent } from '@lib/forms';
import { baseFroalaOptions } from '@lib/froala';
import {
	defaultTableColors,
	defaultTextColors,
	FroalaPreset,
	getFroalaButtons,
} from '@lib/froala';
import FroalaEditor from 'froala-editor';
import { FormNode, InputTypes } from '@lib/forms';
import { cloneDeep } from 'lodash-es';
import { GlossarySelectService } from '@consensus/academy/courses/ui';
import { ThemeService } from '@core/services';
import { CoUserAgentService } from '@consensus/co/util-user-agent';

@Directive({
	standalone: true,
	selector: '[coInlineHtml]',
})
export class InlineHTMLDirective
	extends InputBaseComponent<string>
	implements OnInit, OnDestroy, AfterViewInit
{
	readonly #zone = inject(NgZone);
	readonly #userAgentService = inject(CoUserAgentService);
	readonly #themeService = inject(ThemeService);
	readonly #glossaryService = inject(GlossarySelectService);
	readonly #element: ElementRef<HTMLElement> = inject(ElementRef);
	readonly #document = inject(DOCUMENT);

	editor: FroalaEditor;

	@HostBinding('class.editor-active') active = false;

	@Input('coInlineHtml') buttonLayout: FroalaPreset;
	@HostBinding('class.single-line')
	@Input()
	singleLine;
	@Input() hidden = false;
	@Input() enableGlossary = false;

	@Output() activated = new EventEmitter<void>();
	@Output() escape = new EventEmitter<void>();
	@Output() leave = new EventEmitter<void>();
	@Output() deactivate = new EventEmitter<void>();

	@HostListener('keydown.escape', ['$event'])
	onEscape(event: KeyboardEvent) {
		if (!this.escape.observed) {
			return;
		}
		event.stopPropagation();
		this.escape.emit();
	}

	constructor() {
		super();
		this.placeholder = 'Type something...';
	}

	ngOnInit() {
		super.ngOnInit();

		if (this.singleLine == null) {
			if (this.control instanceof FormNode) {
				this.singleLine =
					this.control.type !== InputTypes.HTML &&
					this.control.type !== InputTypes.LongText;
			} else {
				this.singleLine = true;
			}
		}
	}

	postprocessValue(value: string): any {
		if (!this.singleLine) {
			return value;
		}
		return value?.replace(/<br\/?>/g, '');
	}

	startup() {
		if (this.editor) {
			return;
		}

		this.#element.nativeElement.style.cursor = '';
		this.#element.nativeElement.style.height =
			this.#element.nativeElement.offsetHeight + 'px';

		const enableGlossary = !!this.enableGlossary;
		const buttons = cloneDeep(
			getFroalaButtons((!this.singleLine && this.buttonLayout) || 'simple')
		);

		if (enableGlossary) {
			if (buttons.toolbarButtons['moreRich']) {
				buttons.toolbarButtons['moreRich'].buttons = [
					'insertGlossary',
					...buttons.toolbarButtons['moreRich'].buttons,
				];
			} else {
				buttons.toolbarButtons['moreRich'] = { buttons: ['insertGlossary'] };
			}
		}

		/**
		 * We initialize Froala outside Zone.js
		 *
		 * This is to avoid countless needless change detection
		 * cycles as Froala listens to mousemove events
		 */
		this.#zone.runOutsideAngular(() => {
			this.editor = new FroalaEditor(this.#element.nativeElement, {
				quickInsertEnabled: false,
				...baseFroalaOptions,
				...buttons,
				/** All event handlers should be run in zone */
				events: {
					contentChanged: () => {
						this.#zone.run(() => {
							this.inputValue = this.editor.html.get();
						});
					},
					focus: () => {
						this.#zone.run(() => {
							this.#element.nativeElement.classList.add('focused');
						});
					},
					initialized: () => {
						this.#zone.run(() => {
							this.editor.html.set(this.inputValue);
							this.#element.nativeElement.style.height = '';
							this.active = true;
							this.activated.emit();
						});
					},
					blur: () => {
						this.#zone.run(() => {
							/**
							 * The contentChanged event fired inconsistently
							 * in Froala version 4.0.12 and setting inputValue in
							 * the blur event was added as a workaround. While the
							 * underlying issue in Froala appears to have been solved,
							 * we keep the workaround in place as a defensive measure.
							 */
							this.inputValue = this.editor.html.get();

							this.leave.emit();
							this.#element.nativeElement.classList.remove('focused');
							if (!this.hidden) {
								return;
							}
							if (this.#glossaryService.interface) {
								return;
							}
							this.destroy();
						});
					},
				},
				multiLine: !this.singleLine,
				toolbarInline: true,
				toolbarVisibleWithoutSelection:
					this.#userAgentService.isMobileOrTablet(),
				charCounterCount: false,
				enter: this.singleLine ? FroalaEditor.ENTER_BR : FroalaEditor.ENTER_DIV,
				placeholderText: this.placeholder,
				allowGlossaryInsert: enableGlossary,
				colorsText: [
					...this.#themeService.getColorList(),
					...defaultTextColors,
				],
				colorsStep: 8,
				tableColors: [
					...this.#themeService.getColorList(),
					...defaultTableColors,
				],
				tableColorsStep: 8,
				pastePlain: this.singleLine,
				wordPasteKeepFormatting: !this.singleLine,
				fontSizeSelection: true,
				fontSize: [
					'0.5',
					'0.7',
					'0.8',
					'0.9',
					'1',
					'1.1',
					'1.2',
					'1.3',
					'1.5',
					'1.7',
					'2',
					'2.5',
					'3',
					'4',
					'5',
				],
				fontSizeDefaultSelection: '1',
				fontSizeUnit: 'rem',
				linkStyles: {
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'fr-no-highlight-link-color': 'No default highlight link color',
				},
			});
		});
	}

	destroy() {
		this.editor.destroy();
		this.editor = null;
		this.#element.nativeElement.innerHTML =
			this.inputValue ||
			`<span class="input-placeholder">${this.placeholder ?? ''}</span>`;
		this.deactivate.emit();
		this.active = false;

		if (this.hidden) {
			this.setInactiveStyle();
		}
	}

	setInactiveStyle() {
		this.#element.nativeElement.tabIndex = 0;
		this.#element.nativeElement.style.outline = 'none';
		this.#element.nativeElement.style.cursor = 'pointer';
	}

	onWindowBlur = () => this.editor && this.destroy();

	ngAfterViewInit() {
		if (!this.hidden) {
			this.startup();
		} else {
			this.setInactiveStyle();

			this.#element.nativeElement.onfocus = () => this.onActivated();
			this.#element.nativeElement.onclick = () => this.onActivated();
			window.addEventListener('blur', this.onWindowBlur);
		}

		this.applyValue(this.inputValue);

		this.valueSubs.add(this.inputValue$.subscribe(val => this.applyValue(val)));

		super.ngAfterViewInit();
	}

	applyValue(val: string) {
		if (this.editor) {
			this.editor.html?.set(val);
		} else {
			this.#element.nativeElement.innerHTML =
				val ||
				(this.placeholder
					? `<span class="input-placeholder">${this.placeholder}</span>`
					: '');
		}
	}

	onActivated() {
		if (this.isDisabled) {
			return;
		}
		if (this._disabled) {
			return;
		}
		if (this.editor) {
			return;
		}

		this.startup();
		setTimeout(() => {
			this.editor.events.focus();
			setTimeout(() => this.selectEnd());
		});
	}

	selectEnd() {
		const range = this.#document.createRange();
		range.selectNodeContents(
			this.#element.nativeElement.querySelector('.fr-element.fr-view')
		);
		range.collapse(false);

		const selection = window.getSelection();
		selection.removeAllRanges();
		selection.addRange(range);
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.editor?.destroy();
		window.removeEventListener('blur', this.onWindowBlur);
	}

	focus() {
		if (this.hidden && !this.editor) {
			this.onActivated();
			return;
		}

		setTimeout(() => this.editor?.events?.focus(), 0);
	}
}
