import { Directive, ElementRef, inject, Input, OnInit } from '@angular/core';
import { SessionService } from '@store/scope';

@Directive({
	standalone: true,
	selector: '[coNovoAlt]',
})
export class NovoAltDirective implements OnInit {
	readonly #sessionService = inject(SessionService);
	readonly #element: ElementRef<HTMLElement> = inject(ElementRef);

	@Input('coNovoAlt') novoAlt: string;

	ngOnInit() {
		if (!this.#sessionService.isSema) {
			return;
		}
		this.#element.nativeElement.innerText = this.novoAlt;
	}
}
