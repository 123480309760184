import {
	animate,
	group,
	query,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { QUAD_IN_OUT_EASING } from './constants';

export const modalAnimations = [
	trigger('background', [
		transition(':enter', [
			style({ opacity: 0 }),
			animate(`300ms ${QUAD_IN_OUT_EASING}`, style({ opacity: '*' })),
		]),
	]),
	trigger('content', [
		transition(':enter', [
			style({ opacity: 0, transform: 'translateY(30px)' }),
			animate(
				`400ms ${QUAD_IN_OUT_EASING}`,
				style({ opacity: '*', transform: 'translateY(0)' })
			),
		]),
	]),
	trigger('modal', [
		transition(':leave', [
			group([
				query('.ng-trigger-background', [
					style({ opacity: '*' }),
					animate(`200ms ${QUAD_IN_OUT_EASING}`, style({ opacity: 0 })),
				]),
				query('.ng-trigger-content', [
					style({ opacity: '*', transform: 'translateY(0)' }),
					animate(
						`200ms ${QUAD_IN_OUT_EASING}`,
						style({ opacity: 0, transform: 'translateY(30px)' })
					),
				]),
			]),
		]),
	]),
];
