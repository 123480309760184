import { QaTopicData } from './qa.model';
import { sortDateDesc, sortBySortingKeyAsc } from '@lib/helpers';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { keyBy } from 'lodash-es';
import {
	selectEventSessions,
	UserSession,
} from '@consensus/connect/ufa/agenda/data-access';
import { GlobalQaState } from './qa.reducer';

const selectEventState = createFeatureSelector<GlobalQaState>('event');

export const selectQaState = createSelector(
	selectEventState,
	state => state.qa
);

export const selectQaPublicQuestions = createSelector(selectQaState, s =>
	[...s.questions].sort(sortDateDesc(x => x.createdAt))
);
export const selectQaUserQuestions = createSelector(selectQaState, s =>
	[...s.userQuestions].sort(sortDateDesc(x => x.createdAt))
);
export const selectQaTopics = createSelector(selectQaState, s => s.topics);
export const selectQaSettings = createSelector(selectQaState, s => s.settings);

export const selectQaTopicData = createSelector(
	selectQaState,
	selectEventSessions,
	(s, sessions) => {
		const result: QaTopicData[] = [];
		const sesLookup = keyBy(sessions, s => s.mainId);

		s.sessions.forEach(
			sesId =>
				Object.prototype.hasOwnProperty.call(sesLookup, sesId) &&
				result.push({ name: sesLookup[sesId]?.name, sessionId: sesId })
		);
		[...s.topics]
			.sort(sortBySortingKeyAsc)
			.forEach(t => result.push({ name: t.name, topicId: t.id }));

		return result;
	}
);

export const selectQaSessions = createSelector(
	selectQaState,
	selectEventSessions,
	(s, sessions) => {
		const result: UserSession[] = [];
		const sesLookup = keyBy(sessions, s => s.mainId);
		s.sessions.forEach(
			sesId =>
				Object.prototype.hasOwnProperty.call(sesLookup, sesId) &&
				result.push(sesLookup[sesId])
		);
		return result;
	}
);
