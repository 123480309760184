<div class="wrapper" [@harmonica]="show">
	<mat-form-field
		[class.has-no-label]="!hasLabel"
		[appearance]="appearance"
		[hideRequiredMarker]="hideRequired"
		[color]="color"
		[matTooltip]="tooltip"
		matTooltipPosition="above"
	>
		<mat-label>
			{{ label }}
			<i class="fad fa-question-circle" *ngIf="tooltip"></i>
		</mat-label>

		<textarea
			matInput
			[type]="type"
			[placeholder]="placeholder"
			[formControl]="control"
			[required]="required"
			cdkTextareaAutosize
			[autofocus]="autofocus"
			[readonly]="readonly"
			#input
		></textarea>

		<mat-error *ngIf="hasErrors">{{ inputError }}</mat-error>
	</mat-form-field>
</div>
