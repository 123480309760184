import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LearningPackageService {
	exitLearningPackage$ = new BehaviorSubject<boolean>(false);

	set exitLearningPackage(shouldExit: boolean) {
		this.exitLearningPackage$.next(shouldExit);
	}
}
