/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InputBaseComponent } from '@lib/forms';
import { FroalaPreset } from '@lib/froala';
import { InlineHTMLDirective } from '@shared/directives';
import { harmonicaAnimation } from '@consensus/co/ui-component-animations';

@Component({
	standalone: true,
	selector: 'co-form-inline-html-input',
	imports: [
		NgIf,
		FormsModule,
		MatInputModule,
		MatTooltipModule,
		InlineHTMLDirective,
	],
	templateUrl: './inline-html-input.component.html',
	styleUrls: ['./inline-html-input.component.scss'],
	animations: [harmonicaAnimation()],
})
export class InlineHtmlInputComponent extends InputBaseComponent<string> {
	active = false;

	@Input() singleLine;
	@Input() buttonLayout: FroalaPreset = 'normal';
}
