import { InjectionToken } from '@angular/core';
import versionJson from './version.json';

export interface ConnectVersion {
	readonly isPrerelease: boolean;
	readonly version: string;
}

function versionFactory(): ConnectVersion {
	const { version } = versionJson;

	return {
		get isPrerelease(): boolean {
			// This version format is set by the CI pipeline and used in merge requests
			return version.startsWith('0.0.0-pre.');
		},
		version,
	};
}

export const versionToken = new InjectionToken<ConnectVersion>('versionToken', {
	providedIn: 'root',
	factory: versionFactory,
});
