import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
	Agenda,
	Connected,
	Selfie,
	Session,
	SessionItem,
	SessionItemPushouts,
	Vote,
} from '@consensus/connect/shared/agenda/domain';
import { ConnectFlashcardSet } from '@consensus/connect/shared/flashcards/domain';
import { ConnectLinkPushout } from '@consensus/connect/shared/pushout/domain';
import { MoveModel } from '@shared/models';
import { EventSessionItem } from '@consensus/connect/ufa/agenda/domain';
import {
	ConnectedUpdateModel,
	CreateSessionItemModel,
	CreateSessionModel,
	CreateVoteModel,
	DuplicateSessionItemModel,
	DuplicateSessionModel,
	LoadAgendaModel,
	UpdateAgendaModel,
	UpdateAgendaSessionsModel,
	UpdateFlashcardSetModel,
	UpdatePushoutLinkDto,
	UpdateSelfieModel,
	UpdateSessionItemModel,
	UpdateSessionPostModel,
	UpdateVoteModel,
	CreateWordCloudModel,
} from './agenda.model';
import { Observable } from 'rxjs';
import { WordCloudModel } from '@consensus/connect/cms/word-cloud/domain';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class AgendaClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #httpClient = inject(HttpClient);

	loadAgendas() {
		return this.#httpClient.get<LoadAgendaModel>(
			`${this.#apiServer}/api/agenda/all`
		);
	}

	createAgenda(name: string) {
		return this.#httpClient.post<Agenda>(`${this.#apiServer}/api/agenda`, {
			name,
		});
	}

	createSession(data: CreateSessionModel) {
		return this.#httpClient.post<Session>(
			`${this.#apiServer}/api/agenda/session`,
			data
		);
	}

	createSessionItem(data: CreateSessionItemModel) {
		return this.#httpClient.post<SessionItem>(
			`${this.#apiServer}/api/agenda/session-item`,
			data
		);
	}

	deleteAgenda(id: string) {
		return this.#httpClient.delete(`${this.#apiServer}/api/agenda/${id}`);
	}

	updateAgenda({ id, ...data }: UpdateAgendaModel) {
		return this.#httpClient.put<Agenda>(
			`${this.#apiServer}/api/agenda/${id}`,
			data
		);
	}

	updateSession({ id, ...data }: UpdateSessionPostModel) {
		return this.#httpClient.put<Session>(
			`${this.#apiServer}/api/agenda/session/${id}`,
			data
		);
	}

	updateAgendaSessionsQaEnabled({
		agendaId,
		...data
	}: UpdateAgendaSessionsModel) {
		return this.#httpClient.put<Session[]>(
			`${this.#apiServer}/api/agenda/${agendaId}/sessions`,
			data
		);
	}

	updateSessionItem({ id, ...data }: Partial<UpdateSessionItemModel>) {
		return this.#httpClient.put<SessionItem>(
			`${this.#apiServer}/api/agenda/session-item/${id}`,
			data
		);
	}

	activatePushout(id: string) {
		return this.#httpClient.patch<SessionItem>(
			`${this.#apiServer}/api/agenda/session-item/${id}/pushout/activate`,
			{}
		);
	}

	deactivatePushout(id: string) {
		return this.#httpClient.patch<SessionItem>(
			`${this.#apiServer}/api/agenda/session-item/${id}/pushout/deactivate`,
			{}
		);
	}

	activatePushoutResults(id: string) {
		return this.#httpClient.patch<SessionItem>(
			`${
				this.#apiServer
			}/api/agenda/session-item/${id}/pushout/results/activate`,
			{}
		);
	}

	deactivatePushoutResults(id: string) {
		return this.#httpClient.patch<SessionItem>(
			`${
				this.#apiServer
			}/api/agenda/session-item/${id}/pushout/results/deactivate`,
			{}
		);
	}

	duplicateSessionItem({ id, ...data }: DuplicateSessionItemModel) {
		return this.#httpClient.post<SessionItem>(
			`${this.#apiServer}/api/agenda/session-item/${id}/duplicate`,
			data
		);
	}

	deleteSessionItem(id: string) {
		return this.#httpClient.delete(
			`${this.#apiServer}/api/agenda/session-item/${id}`
		);
	}

	deleteSession(id: string) {
		return this.#httpClient.delete(
			`${this.#apiServer}/api/agenda/session/${id}`
		);
	}

	moveSession({ id, sortingKey }: MoveModel) {
		return this.#httpClient.patch(
			`${this.#apiServer}/api/agenda/session/${id}/move`,
			{
				sortingKey,
			}
		);
	}

	moveSessionItem({ id, sortingKey }: MoveModel) {
		return this.#httpClient.patch(
			`${this.#apiServer}/api/agenda/session-item/${id}/move`,
			{
				sortingKey,
			}
		);
	}

	duplicateSession({ id, ...data }: DuplicateSessionModel) {
		return this.#httpClient.post<Session>(
			`${this.#apiServer}/api/agenda/session/${id}/duplicate`,
			data
		);
	}

	loadSessionItemPushouts(id: string) {
		return this.#httpClient.get<SessionItemPushouts>(
			`${this.#apiServer}/api/agenda/session-item/${id}/pushout`
		);
	}

	updateVote({ id, eventId, ...data }: UpdateVoteModel) {
		return this.#httpClient.put<Vote>(
			`${this.#apiServer}/api/events/${eventId}/vote/${id}`,
			data
		);
	}

	createVote({ sessionItemId, eventId, ...data }: CreateVoteModel) {
		return this.#httpClient.post<Vote>(
			`${this.#apiServer}/api/events/${eventId}/vote/session/${sessionItemId}`,
			data
		);
	}

	upsertSelfie({ sessionItemId, ...data }: UpdateSelfieModel) {
		return this.#httpClient.put<Selfie>(
			`${this.#apiServer}/api/selfie/session/${sessionItemId}`,
			data
		);
	}

	updateFlashcardSet({ sessionItemId, ...data }: UpdateFlashcardSetModel) {
		return this.#httpClient.put<ConnectFlashcardSet>(
			`${this.#apiServer}/api/pushout/flashcards/${sessionItemId}`,
			data
		);
	}

	updateConnected({ sessionItemId, ...data }: ConnectedUpdateModel) {
		return this.#httpClient.put<Connected>(
			`${this.#apiServer}/api/pushout/connected/${sessionItemId}`,
			data
		);
	}

	updatePushoutLink({ sessionItemId, ...data }: UpdatePushoutLinkDto) {
		return this.#httpClient.put<ConnectLinkPushout>(
			`${this.#apiServer}/api/pushout/link/${sessionItemId}`,
			data
		);
	}

	createWordCloud(wordCloud: CreateWordCloudModel): Observable<WordCloudModel> {
		return this.#httpClient.post<WordCloudModel>(
			`${this.#apiServer}/api/wordcloud`,
			wordCloud
		);
	}

	updateSessionItemPushout({ id, ...data }: UpdateSessionItemModel) {
		return this.#httpClient.put<SessionItemPushouts>(
			`${this.#apiServer}/api/agenda/session-item/${id}/pushout`,
			data
		);
	}

	moveAgenda({ id, sortingKey }: MoveModel) {
		return this.#httpClient.patch(`${this.#apiServer}/api/agenda/${id}/move`, {
			sortingKey,
		});
	}

	getPushoutBySessionItemId(
		sessionItemId: string
	): Observable<EventSessionItem> {
		return this.#httpClient.get<EventSessionItem>(
			`${this.#apiServer}/api/agenda/session-item/${sessionItemId}/pushout`
		);
	}
}
