import { inject, Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PopupService } from '@consensus/legacy/ui-popup';
import { FormRoot } from './form-root';

@Injectable({ providedIn: 'root' })
export class FormService {
	readonly #popupService = inject(PopupService);

	#forms: AbstractControl[] = [];

	addForm(form: AbstractControl) {
		if (this.#forms.includes(form)) {
			return;
		}
		this.#forms.push(form);
	}

	removeForm(form: AbstractControl) {
		const index = this.#forms.findIndex(x => x === form);
		if (index === -1) {
			return;
		}
		this.#forms.splice(index, 1);
	}

	async canRedirect(currentForm: AbstractControl = null) {
		if (
			!this.#forms.some(
				f =>
					f != currentForm &&
					f.dirty &&
					(f instanceof FormRoot ? f.changed : true)
			)
		) {
			return true;
		}
		return await this.#popupService.delete(
			'You are about to move away from a form with unsaved changes. Do you want to discard your changes?',
			'Discard changes?',
			'Discard'
		);
	}
}
