import {
	Artboard,
	OpenUrlEvent,
	RiveCanvas,
	RiveEvent,
	File as RiveFile,
} from '@rive-app/canvas-advanced';
import {
	CoArtboardContent,
	CoReportedRiveEvent,
	CoRiveEventType,
	CoRiveFileContent,
	CoRiveStateMachineInputContents,
} from '@consensus/co/domain-rive';

export function toInt(value: number | string | undefined | null) {
	const v = typeof value === 'string' ? parseInt(value) : value;
	if (typeof v !== 'number') {
		return;
	}
	return v;
}

interface ClientCoordinates {
	clientX: number;
	clientY: number;
}

export function getClientCoordinates(
	event: MouseEvent | TouchEvent
): ClientCoordinates {
	if (
		['touchstart', 'touchmove'].indexOf(event.type) > -1 &&
		(event as TouchEvent).touches?.length
	) {
		event.preventDefault();
		return {
			clientX: (event as TouchEvent).touches[0].clientX,
			clientY: (event as TouchEvent).touches[0].clientY,
		};
	} else if (
		event.type === 'touchend' &&
		(event as TouchEvent).changedTouches?.length
	) {
		return {
			clientX: (event as TouchEvent).changedTouches[0].clientX,
			clientY: (event as TouchEvent).changedTouches[0].clientY,
		};
	} else {
		return {
			clientX: (event as MouseEvent).clientX,
			clientY: (event as MouseEvent).clientY,
		};
	}
}

/**
 * Returns the contents of a Rive file: the artboards, animations, and state machines
 */
export const getRiveFileContentInfo = (
	file: RiveFile,
	canvas: RiveCanvas
): CoRiveFileContent => {
	const riveContents: CoRiveFileContent = {
		artboards: [],
	};
	const artboardCount = file.artboardCount();
	for (let i = 0; i < artboardCount; i++) {
		const artboard = file.artboardByIndex(i) as Artboard & {
			// TODO: Missing typing in Rive, fix by PR to source [MAB]
			eventCount: () => number;
			eventByIndex: (i: number) => CoReportedRiveEvent;
		};
		const artboardContents: CoArtboardContent = {
			name: artboard.name,
			animations: [],
			stateMachines: [],
			events: [],
		};
		const artboardAnimationCount = artboard.animationCount();
		for (let j = 0; j < artboardAnimationCount; j++) {
			const animation = artboard.animationByIndex(j);
			artboardContents.animations.push(animation.name);
		}
		const artboardEventCount = artboard.eventCount();
		for (let j = 0; j < artboardEventCount; j++) {
			const event = artboard.eventByIndex(j);
			artboardContents.events.push(event);
		}
		for (let j = 0; j < artboardAnimationCount; j++) {
			const animation = artboard.animationByIndex(j);
			artboardContents.animations.push(animation.name);
		}
		const artboardStateMachineCount = artboard.stateMachineCount();
		for (let k = 0; k < artboardStateMachineCount; k++) {
			const stateMachine = artboard.stateMachineByIndex(k);
			const name = stateMachine.name;
			const instance = new canvas.StateMachineInstance(stateMachine, artboard);
			const inputContents: CoRiveStateMachineInputContents[] = [];
			const inputCount = instance.inputCount();
			for (let l = 0; l < inputCount; l++) {
				const input = instance.input(l);
				inputContents.push({ name: input.name, type: input.type });
			}
			artboardContents.stateMachines.push({
				name: name,
				inputs: inputContents,
			});
		}
		riveContents.artboards.push(artboardContents);
	}
	return riveContents;
};

export const isOpenUrlEvent = (
	event: CoReportedRiveEvent | null | undefined
): event is OpenUrlEvent => event?.type === CoRiveEventType.OpenUrl;

export const isGeneralEvent = (
	event: CoReportedRiveEvent | null | undefined
): event is RiveEvent => event?.type === CoRiveEventType.General;
