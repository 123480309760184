import { HTTP_INTERCEPTORS, HttpInterceptor } from '@angular/common/http';
import {
	ClassProvider,
	EnvironmentProviders,
	Type,
	makeEnvironmentProviders,
} from '@angular/core';

/**
 * @deprecated Class-based HTTP interceptors are deprecated. We prefer
 *   functional HTTP interceptors for consistency with functional route guards
 *   and functional route resolvers.
 */
export function provideLegacyHttpInterceptor(
	httpInterceptor: Type<HttpInterceptor>
): EnvironmentProviders {
	const httpInterceptorProvider: ClassProvider = {
		provide: HTTP_INTERCEPTORS,
		multi: true,
		useClass: httpInterceptor,
	};

	return makeEnvironmentProviders([httpInterceptorProvider]);
}
