import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { CoSnackService } from '@consensus/co/ui-snackbars';

@Injectable({ providedIn: 'root' })
export class IdManagementService {
	readonly #clipboard = inject(Clipboard);
	readonly #snacks = inject(CoSnackService);
	idCopyMode$ = new BehaviorSubject<boolean>(false);

	constructor() {
		window.addEventListener('keydown', event => {
			if (this.idCopyMode$.value) {
				if (event.key !== 'Escape') {
					return;
				}
				this.idCopyMode$.next(false);
				return;
			}

			if (!event.altKey || event.key !== 'c') {
				return;
			}
			this.idCopyMode$.next(true);
		});
	}

	copyId(id: string) {
		this.#clipboard.copy(id);
		this.#snacks.success('Id copied to Clipboard');
		this.idCopyMode$.next(false);
	}
}
