export enum FileTypes {
	Other = 'Other',
	Pdf = 'Pdf',
	Image = 'Image',
	Video = 'Video',
	Audio = 'Audio',
	Powerpoint = 'Powerpoint',
	Word = 'Word',
	Excel = 'Excel',
	Zip = 'Zip',
	Rive = 'Rive',
	SplineCode = 'SplineCode',
}
