/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
	MatSlideToggle,
	MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { harmonicaAnimation } from '@consensus/co/ui-component-animations';
import { InputBaseComponent } from '@lib/forms';

@Component({
	standalone: true,
	selector: 'co-form-bool-input',
	imports: [NgIf, ReactiveFormsModule, MatSlideToggleModule, MatTooltipModule],
	templateUrl: './bool-input.component.html',
	styleUrls: ['./bool-input.component.scss'],
	animations: [harmonicaAnimation()],
})
export class BoolInputComponent extends InputBaseComponent<boolean> {
	@Input() labelPosition: MatSlideToggle['labelPosition'];
}
