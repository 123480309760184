import { inject, Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { QaState } from './qa.reducer';
import {
	createQaQuestion,
	deleteQaQuestion,
	loadPublicQuestions,
	loadQa,
	loadUserQuestions,
	updateQaQuestion,
} from './qa.actions';
import { QaClient } from './qa.client';
import { selectQaState } from './qa.selectors';
import { ApiEffects } from '@lib/redux';

@Injectable()
export class QaEffects extends ApiEffects<QaState> {
	readonly #client = inject(QaClient);
	readonly #actions$ = inject(Actions);
	constructor() {
		super(selectQaState);
	}

	load$ = createEffect(() =>
		this.generateApiEffect(loadQa, () => this.#client.loadUserQa())(
			this.#actions$
		)
	);

	loadUserQuestions$ = createEffect(() =>
		this.generateApiEffect(loadUserQuestions, () =>
			this.#client.loadUserQuestions()
		)(this.#actions$)
	);

	loadPublicQuestions$ = createEffect(() =>
		this.generateApiEffect(loadPublicQuestions, () =>
			this.#client.loadPublicQuestions()
		)(this.#actions$)
	);

	createQuestion$ = createEffect(() =>
		this.generateApiEffect(createQaQuestion, action =>
			this.#client.createUserQuestion(action.payload)
		)(this.#actions$)
	);

	updateQuestion$ = createEffect(() =>
		this.generateApiEffect(updateQaQuestion, action =>
			this.#client.updateUserQuestion(action.payload)
		)(this.#actions$)
	);

	deleteQuestion$ = createEffect(() =>
		this.generateApiEffect(deleteQaQuestion, action =>
			this.#client.deleteUserQuestion(action.payload)
		)(this.#actions$)
	);
}
