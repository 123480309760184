/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { harmonicaAnimation } from '@consensus/co/ui-component-animations';
import { InputBaseComponent } from '@lib/forms';
import {
	NgxMatTimepickerComponent,
	NgxMatTimepickerDirective,
	NgxMatTimepickerToggleComponent,
} from 'ngx-mat-timepicker';
import { provideNgxMatTimepicker } from '../provide-ngx-mat-timepicker';

@Component({
	standalone: true,
	selector: 'co-form-time-input',
	imports: [
		NgIf,
		FormsModule,
		MatInputModule,
		MatTooltipModule,
		NgxMatTimepickerComponent,
		NgxMatTimepickerDirective,
		NgxMatTimepickerToggleComponent,
	],
	templateUrl: './time-input.component.html',
	styleUrls: ['./time-input.component.scss'],
	animations: [harmonicaAnimation()],
	viewProviders: [provideNgxMatTimepicker()],
})
export class TimeInputComponent extends InputBaseComponent<Date> {
	preprocessValue(value): any {
		if (value instanceof Date) {
			return `${value.getUTCHours()}:${value.getUTCMinutes()}`;
		}
		return value;
	}

	postprocessValue(value): any {
		if (!value) {
			return null;
		}
		if (value instanceof Date) {
			return value;
		}
		const date = new Date(`1/2/1970 ${value}`);
		date.setUTCHours(date.getHours(), date.getMinutes());
		return date;
	}
}
