import { CustomStore } from '@lib/redux';
import { ScopeClient } from './scope.client';
import {
	EventLoadModel,
	SimpleEvent,
} from '@consensus/shared/shared/event/domain';
import { createTheme } from '@store/theming';
import { BaseState } from '@shared/models';

export interface ScopeState extends BaseState, EventLoadModel {}

export const globalScopeKey = 'scope';

export const scopeStore = new CustomStore<ScopeState, ScopeClient>(
	'Scope',
	{ client: null, event: null, terms: null, disclaimer: null },
	ScopeClient,
	'Global'
);

export const loadClient = scopeStore
	.addApiAction('Client', 'Load')
	.withEffect(s => s.loadClient)
	.withReducer(client => ({ client, event: null }));

export const updateClient = scopeStore
	.addApiAction('Client', 'Update')
	.withError()
	.withEffect(s => s.updateClient)
	.withReducer(client => ({ client }));

export const loadEvent = scopeStore
	.addApiAction('Event', 'Load')
	.withError()
	.withEffect(s => s.loadEvent)
	.withReducer();

export const refreshEvent = scopeStore
	.addApiAction('Event', 'Refresh')
	.withEffect(s => s.refreshEvent)
	.withReducer((data, { event }) => ({ event: { ...event, ...data } }));

export const updateEvent = scopeStore
	.addApiAction('Event', 'Update')
	.withEffect(s => s.updateEvent)
	.withReducer(event => ({ event }));

export const clearEvent = scopeStore
	.addAction('Event', 'Clear')
	.withReducer(() => ({ event: null }));

export const readTerms = scopeStore
	.addApiAction('Terms', 'Read')
	.withEffect(x => x.readEventTerms)
	.withReducer((_, { terms }) => ({ terms: { ...terms, isRead: true } }));

export const readDisclaimer = scopeStore
	.addAction('Disclaimer', 'Read')
	.withReducer((_, { disclaimer }) => ({
		disclaimer: { ...disclaimer, isRead: true },
	}));

export const uploadArPopulation = scopeStore
	.addApiAction('Event AR Population File', 'Update')
	.withEffect(x => x.uploadArPopulation)
	.withReducer((_, state) => state);

export const loadArPopulation = scopeStore
	.addApiAction('Event AR Population File', 'Load')
	.withEffect(x => x.loadArPopulation)
	.withReducer((result, { event }) => ({
		event: { ...event, arPopulationJson: result },
	}));

export const deleteEvent = scopeStore
	.addApiAction('Event', 'Delete')
	.withEffect(x => x.deleteEvent)
	.withReducer(() => ({ event: null }));

scopeStore
	.addSideEffect(createTheme)
	.withReducer((theme, { event, client }, { eventId, clientId }) => ({
		event:
			event && event.id == eventId
				? {
						...event,
						themeId: theme.id,
				  }
				: event,
		client:
			client && client.id == clientId
				? {
						...client,
						themeId: theme.id,
				  }
				: client,
	}));

scopeStore
	.addSocketAction<SimpleEvent>('Event', 'Root', 'Update')
	.withReducer((data, { event }) => ({ event: { ...event, ...data } }));
