import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivateFn,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { LoginService } from '@core/services';
import { SessionService } from '@store/scope';
import { getRouteParams } from '@lib/helpers';

export const loggedInAcademyGuard: CanActivateFn = async (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
) => {
	const loginService = inject(LoginService);
	const router = inject(Router);
	const sessionService = inject(SessionService);

	console.debug('# Logged In Guard');

	if (!sessionService.isLoggedIn()) {
		sessionStorage.setItem('academy-redirect', state.url);

		const params = getRouteParams(route);

		try {
			const eventId = params['eventId'];

			if (eventId) {
				const event = await loginService.loadEventById(eventId);

				console.debug(
					`Not logged in - Redirecting to Academy Event login [${event.event.name}]`
				);
				await router.navigate([`academy`, `e`, event.event.slug]);
				return false;
			}

			const clientId = params['clientId'];

			if (clientId) {
				const client = await loginService.loadClientById(clientId);

				console.debug(
					`Not logged in - Redirecting to Academy Client login [${client.name}]`
				);
				await router.navigate([`academy`, `c`, client.slug]);
				return false;
			}
		} catch (e) {
			console.debug(`Failed to load Event/Client - Redirecting to Root`);
			await router.navigate(['/']);
			return false;
		}

		console.debug(`Not logged in - Redirecting to Root`);
		await router.navigate(['/']);
		return false;
	}

	const redirect = sessionStorage.getItem('academy-redirect');

	if (redirect) {
		sessionStorage.removeItem('academy-redirect');
		console.debug(
			`Logged in with saved redirect - Redirecting to "${redirect}"`
		);
		await router.navigate([redirect]);
		return false;
	}

	console.debug(`Logged in`);
	return true;
};
