import { inject, Injectable } from '@angular/core';
import {
	CoPlainSnackComponent,
	PlainSnackData,
} from './co-plain-snack/co-plain-snack.component';
import {
	CoErrorSnackComponent,
	ErrorSnackData,
} from './co-error-snack/co-error-snack.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Capacitor } from '@capacitor/core';

@Injectable({ providedIn: 'root' })
export class CoSnackService {
	readonly #snackbar = inject(MatSnackBar);
	readonly isNative = Capacitor.isNativePlatform();

	success(
		message: string,
		title: string | null = null,
		isHtml = false,
		icon: string | null = null,
		onClick: (() => void) | null = null
	) {
		this.#snackbar.openFromComponent(CoPlainSnackComponent, {
			duration: 5000,
			data: {
				message,
				title,
				isHtml,
				icon,
				onClick,
				type: 'success',
			} satisfies PlainSnackData,
			horizontalPosition: 'right',
			verticalPosition: 'top',
			panelClass: !this.isNative ? [] : ['!tw-mt-safe'],
		});
	}

	info(
		message: string,
		title: string | null = null,
		isHtml = false,
		icon: string | null = null,
		onClick: (() => void) | null = null
	) {
		this.#snackbar.openFromComponent(CoPlainSnackComponent, {
			duration: 5000,
			data: {
				message,
				title,
				isHtml,
				icon,
				onClick,
				type: 'info',
			} satisfies PlainSnackData,
			horizontalPosition: 'right',
			verticalPosition: 'top',
			panelClass: !this.isNative ? [] : ['!tw-mt-safe'],
		});
	}

	warning(
		message: string,
		title: string | null = null,
		isHtml = false,
		icon: string | null = null,
		onClick: (() => void) | null = null
	) {
		this.#snackbar.openFromComponent(CoPlainSnackComponent, {
			duration: 5000,
			data: {
				message,
				title,
				isHtml,
				icon,
				onClick,
				type: 'warning',
			} satisfies PlainSnackData,
			horizontalPosition: 'right',
			verticalPosition: 'top',
			panelClass: !this.isNative ? [] : ['!tw-mt-safe'],
		});
	}

	error<TPayload>(
		message: string,
		title: string | null = null,
		isHtml = false,
		payload: TPayload | null = null
	) {
		this.#snackbar.openFromComponent(CoErrorSnackComponent, {
			duration: 1000000,
			data: {
				message,
				title,
				isHtml,
				payload,
				type: 'error',
			} satisfies ErrorSnackData<TPayload>,
			horizontalPosition: 'right',
			verticalPosition: 'top',
			panelClass: !this.isNative ? [] : ['!tw-mt-safe'],
		});
	}

	plain(
		message: string,
		title: string | null = null,
		isHtml = false,
		icon: string | null = null,
		onClick: (() => void) | null = null
	) {
		this.#snackbar.openFromComponent(CoPlainSnackComponent, {
			duration: 5000,
			data: {
				message,
				title,
				isHtml,
				icon,
				onClick,
				type: 'plain',
			} satisfies PlainSnackData,
			horizontalPosition: 'right',
			verticalPosition: 'top',
			panelClass: !this.isNative ? [] : ['!tw-mt-safe'],
		});
	}
}
