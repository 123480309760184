/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormControl } from '@angular/forms';
import { harmonicaAnimation } from '@consensus/co/ui-component-animations';
import { FormNode, InputTypes } from '@lib/forms';
import { BoolInputComponent } from '../bool-input/bool-input.component';
import { ColorInputComponent } from '../color-input/color-input.component';
import { DateInputComponent } from '../date-input/date-input.component';
import { DatetimeInputComponent } from '../datetime-input/datetime-input.component';
import { HtmlInputComponent } from '../html-input/html-input.component';
import { LongtextInputComponent } from '../longtext-input/longtext-input.component';
import { PasswordInputComponent } from '../password-input/password-input.component';
import { PhoneInputComponent } from '../phone-input/phone-input.component';
import { PlainInputComponent } from '../plain-input/plain-input.component';
import { SearchInputComponent } from '../search-input/search-input.component';
import { SelectComponent } from '../select/select.component';
import { TimeInputComponent } from '../time-input/time-input.component';

@Component({
	standalone: true,
	selector: 'co-form-input',
	imports: [
		NgSwitch,
		NgSwitchCase,
		NgSwitchDefault,
		BoolInputComponent,
		ColorInputComponent,
		DateInputComponent,
		DatetimeInputComponent,
		HtmlInputComponent,
		LongtextInputComponent,
		PasswordInputComponent,
		PhoneInputComponent,
		PlainInputComponent,
		SearchInputComponent,
		SelectComponent,
		TimeInputComponent,
	],
	templateUrl: './input.component.html',
	animations: [harmonicaAnimation()],
})
export class InputComponent implements OnInit {
	readonly #controlContainer = inject(ControlContainer, {
		optional: true,
		host: true,
		skipSelf: true,
	});
	types = InputTypes;

	@Input() type: InputTypes;
	@Input() controlName: string;
	@Input() control: UntypedFormControl;

	@Input() readonly: boolean;

	ngOnInit(): void {
		if (this.controlName) {
			const control = this.#controlContainer?.control?.get(this.controlName);
			if (control instanceof UntypedFormControl) {
				this.control = control;
			}
		}

		if (this.control instanceof FormNode) {
			this.type = this.control?.type;
		}
	}
}
