<div class="error" *ngIf="player?.errorMessage">
	<p>{{ player.errorMessage }}</p>
</div>
<div class="loading" *ngIf="!player || player.isLoading">
	<co-spinner layout="centered" />
</div>
<div
	*ngIf="player && !player.isPlaying && !player.isLoading"
	class="play-overlay overlay"
	coNoClickBubble
	(click)="player.play()"
>
	<i class="fas fa-play secondary-text tw-cursor-pointer" mat-ripple></i>
</div>
<div class="no-click overlay" coNoClickBubble></div>
