import { createAction } from '@ngrx/store';
import { SidepanelState } from './sidepanel.reducer';

import { SidepanelItem } from '@consensus/connect/shared/sidepanel/domain';
import { MoveModel } from '@shared/models';
import { ApiAction } from '@lib/redux';

export const loadSidepanel = new ApiAction<
	SidepanelState,
	void,
	SidepanelItem[]
>('Sidepanel', 'Load', 'Event', {
	showErrors: true,
});

export const addSidepanelItem = createAction(
	'[Event] Add Sidepanel Item',
	(payload: SidepanelItem) => ({ payload })
);
export const hideSidepanelItem = createAction(
	'[Event] Remove Sidepanel Item',
	(payload: string) => ({ payload })
);
export const hideSidepanelSection = createAction(
	'[Event] Remove Sidepanel Section',
	(payload: string) => ({ payload })
);
export const updateSidepanelItem = createAction(
	'[Event] Update Sidepanel Item',
	(payload: SidepanelItem) => ({ payload })
);
export const moveSidepanelItem = createAction(
	'[Event] Move Sidepanel Item',
	(payload: MoveModel) => ({ payload })
);
export const moveSidepanelItemToSection = createAction(
	'[Event] Move Sidepanel Item To Section',
	(payload: MoveModel & { section: string }) => ({ payload })
);
