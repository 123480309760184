import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UfaEliminationGameStoreState } from './ufa-elimination-game.state';

export const eliminationGameKey = 'eliminationGame';

interface EventFeatureState {
	[eliminationGameKey]: UfaEliminationGameStoreState;
}

const selectEventState = createFeatureSelector<EventFeatureState>('event');

export const selectEliminationGameState = createSelector(
	selectEventState,
	state => state[eliminationGameKey]
);

export const selectEliminationGames = createSelector(
	selectEliminationGameState,
	x => x.eliminationGames
);
