import {
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	DestroyRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	QueryList,
	TemplateRef,
	ViewChild,
	inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
	MatTableDataSource,
	MatTableDataSourcePaginator,
	MatTableModule,
} from '@angular/material/table';
import { Observable, map, merge, of } from 'rxjs';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { TableTemplateDirective } from '@shared/directives';
import { CoTrackByFunction } from '@consensus/co/util-control-flow';
import { BypassSecurityTrustHtmlPipe } from '@shared/pipes';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { NoClickBubbleDirective } from '@shared/directives';
import { SearchInputComponent } from '@shared/forms';
import {
	NgIf,
	NgFor,
	NgSwitch,
	NgSwitchCase,
	NgTemplateOutlet,
	NgSwitchDefault,
} from '@angular/common';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { LetDirective } from '@ngrx/component';
import { MatTableStore } from './mat-table.store';
import { TableStore } from './table.store';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'co-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
	standalone: true,
	imports: [
		NgIf,
		SearchInputComponent,
		MatTableModule,
		MatSortModule,
		NoClickBubbleDirective,
		NgFor,
		NgSwitch,
		NgSwitchCase,
		NgTemplateOutlet,
		NgSwitchDefault,
		MatButtonModule,
		MatTooltipModule,
		MatPaginatorModule,
		LetDirective,
		BypassSecurityTrustHtmlPipe,
	],
	providers: [TableStore, MatTableStore],
})
export class TableComponent<TData extends TableEntity> implements OnInit {
	readonly #destroyRef = inject(DestroyRef);
	readonly #matTableStore = inject(MatTableStore<TData>);
	readonly #store = inject(TableStore<TData>);

	readonly columnTypes = ColumnTypes;
	readonly defaultColumn$: Observable<string | null> =
		this.#store.defaultColumn$;
	readonly defaultSortDirection$: Observable<'asc' | 'desc'> =
		this.#store.defaultSortDirection$;

	readonly templates$: Observable<Record<string, TemplateRef<unknown>>> =
		this.#store.templates$;
	@ContentChildren(TableTemplateDirective)
	set templateData(templateData: QueryList<TableTemplateDirective>) {
		this.#store.updateTemplates(
			merge(
				of(templateData.toArray()),
				templateData.changes.pipe(map(() => templateData?.toArray() ?? []))
			)
		);
	}

	@ViewChild(MatPaginator, { static: false })
	set paginator(paginator: MatPaginator) {
		this.#matTableStore.updatePaginator(paginator);
	}
	@ViewChild(MatSort, { static: false })
	set sort(sort: MatSort) {
		this.#matTableStore.updateSort(sort);
	}

	@Input()
	set config(config: TableConfig<TData>) {
		this.#store.updateConfig(config);
	}

	@Input()
	set items(items: TData[]) {
		this.#store.updateItems(items);
	}

	@Input()
	set selection(selection: string) {
		this.#store.updateSelection(selection);
	}
	@Output()
	readonly selectionChange = new EventEmitter<string | null>();
	@Output()
	readonly selected = new EventEmitter<TData | null>();
	@Output()
	readonly clicked = new EventEmitter<TData | null>();

	readonly actions$: Observable<readonly TableAction<TData>[]> =
		this.#store.actions$;
	readonly columns$: Observable<readonly TableColumn<TData>[]> =
		this.#store.columns$;
	readonly columnIds$: Observable<readonly string[]> = this.#store.columnIds$;
	readonly dataSource$: Observable<
		MatTableDataSource<TData, MatTableDataSourcePaginator>
	> = this.#matTableStore.dataSource$;
	readonly hideActionHeader$: Observable<boolean> =
		this.#store.hideActionHeader$;
	readonly pagination$: Observable<boolean> = this.#store.pagination$;
	readonly search$: Observable<boolean> = this.#store.search$;
	readonly searchQuery$: Observable<string> = this.#store.searchQuery$;
	readonly selection$: Observable<string | null> = this.#store.selection$;

	ngOnInit() {
		this.#store.selection$
			.pipe(takeUntilDestroyed(this.#destroyRef))
			.subscribe(selection => this.selectionChange.emit(selection));
		this.#store.selected$
			.pipe(takeUntilDestroyed(this.#destroyRef))
			.subscribe(selected => this.selected.emit(selected));
	}

	onClickRow(data: TData) {
		this.clicked.emit(data);
		const rowId = data?.id ?? undefined;
		if (!rowId) {
			return;
		}
		this.#store.onToggleRow(rowId);
	}

	onSearch(searchQuery: string) {
		this.#store.updateSearchQuery(searchQuery);
	}

	isRowSelected(id?: string): boolean {
		if (id === undefined) {
			return false;
		}

		return this.#store.isRowSelected(id);
	}

	readonly trackById: CoTrackByFunction<TableColumn<TData>> = (
		index: number,
		item: TableColumn<TData>
	) => {
		if (item?.id) {
			return item.id;
		}
		return index;
	};

	readonly trackByActionName: CoTrackByFunction<TableAction<TData>> = (
		_index: number,
		action: TableAction<TData>
	) => action.name;
}

export interface TableEntity {
	readonly id?: string;
}

export interface TableConfig<TData extends TableEntity> {
	search: boolean;
	pagination: boolean;
	columns: TableColumn<TData>[];
	actions?: TableAction<TData>[];
	hideActionHeader?: boolean;
	defaultSortDirection?: 'asc' | 'desc';
}

export interface TableColumn<TData> {
	id: (keyof TData & string) | string;
	title: string;
	sort: boolean;
	dataType: ColumnTypes;
	mapData?: (data: TData) => any;
	defaultSort?: boolean;
}

export interface TableAction<TData> {
	name: string;
	icon: string;
	action: (row: TData) => void;
	disabled?: boolean;
}

export enum ColumnTypes {
	Number,
	String,
	HTML,
	Icon,
	Decimal,
	Template,
	Bool,
}
