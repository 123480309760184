/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { NgIf } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormNode } from '@lib/forms';
import { ControlContainer } from '@angular/forms';
import { harmonicaAnimation } from '@consensus/co/ui-component-animations';
import { SliderInputComponent } from '../slider-input/slider-input.component';
import { BaseRangeInputComponent } from './base-range-input/base-range-input.component';

@Component({
	standalone: true,
	selector: 'co-form-range-input',
	imports: [NgIf, BaseRangeInputComponent, SliderInputComponent],
	templateUrl: './range-input.component.html',
	styleUrls: ['./range-input.component.scss'],
	animations: [harmonicaAnimation()],
})
export class RangeInputComponent implements OnInit {
	readonly #controlContainer = inject(ControlContainer, {
		optional: true,
		host: true,
		skipSelf: true,
	});
	@Input() startName: string;
	@Input() startControl: FormNode<number>;

	@Input() endName: string;
	@Input() endControl: FormNode<number>;

	@Input() max = 10;
	@Input() min = 0;
	@Input() step = 1;
	@Input() visualStep: number;

	@Input() disabled = false;
	@Input() disableStart = false;
	@Input() disableEnd = false;
	@Input() label: string;

	@Input() readonly: boolean;

	get showLow() {
		return !this.startControl.disabled && !this.disableStart;
	}
	get showHigh() {
		return !this.endControl.disabled && !this.disableEnd;
	}
	get isDisabled() {
		return this.disabled || (!this.showLow && !this.showHigh);
	}

	ngOnInit(): void {
		if (this.startName) {
			const control = this.#controlContainer?.control?.get(this.startName);
			if (control instanceof FormNode) {
				this.startControl = control;
			}
		}

		if (this.endName) {
			const control = this.#controlContainer?.control?.get(this.endName);
			if (control instanceof FormNode) {
				this.endControl = control;
			}
		}

		if (!this.startControl && !this.endControl) {
			console.error('Range Input requires at least one FormNode');
			return;
		}
	}
}
