import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
	ColearnProgressSection,
	ColearnSectionOptions,
	ColearnSettings,
	CreateEventBadgeModel,
	CreateEventEngagementModel,
	CreateScoreSettingsModel,
	EventBadge,
	EventEngagement,
	ScoreSettings,
	UpdateEventBadgeModel,
	UpdateEventEngagementModel,
	UpdateScoreSettingsModel,
} from '@consensus/connect/cms/colearn/domain';
import { MoveModel } from '@shared/models';
import {
	ColearnBadge,
	ColearnUser,
	EngagementAggregate,
	LeaderboardItem,
	LearningActivity,
	ScoreOverviewViewModel,
} from '@consensus/connect/ufa/colearn/domain';
import { Observable, map } from 'rxjs';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class ColearnClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #httpClient = inject(HttpClient);

	loadColearnSections() {
		return this.#httpClient.get<ColearnProgressSection[]>(
			`${this.#apiServer}/api/colearn/sections`
		);
	}

	updateColearnSections(sections: ColearnProgressSection[]) {
		return this.#httpClient.put<ColearnProgressSection[]>(
			`${this.#apiServer}/api/colearn/sections`,
			{ sections }
		);
	}

	loadColearnSettings() {
		return this.#httpClient.get<ColearnSettings>(
			`${this.#apiServer}/api/colearn/settings`
		);
	}

	updateColearnSettings(settings: ColearnSettings) {
		return this.#httpClient.put<ColearnSettings>(
			`${this.#apiServer}/api/colearn/settings`,
			settings
		);
	}

	loadColearnProgressSettings() {
		return this.#httpClient
			.get<ColearnSectionOptions>(
				`${this.#apiServer}/api/colearn/sections/settings`
			)
			.pipe(
				map((s: any) => {
					return Object.keys(s).length === 0
						? s
						: {
								...s,
								engagementSectionShowTotalCount: JSON.parse(
									s.engagementSectionShowTotalCount
								),
								engagementSectionShowCompletedLearningActivities: JSON.parse(
									s.engagementSectionShowCompletedLearningActivities
								),
								badgesSectionShowFrequentEngagement: JSON.parse(
									s.badgesSectionShowFrequentEngagement
								),
								badgesSectionShowLearningActivities: JSON.parse(
									s.badgesSectionShowLearningActivities
								),
						  };
				})
			);
	}

	updateColearnProgressSettings(settings: ColearnSectionOptions) {
		const settingsRequest = {
			badgesSectionTitle: settings.badgesSectionTitle,
			badgesSectionShowFrequentEngagement: String(
				settings.badgesSectionShowFrequentEngagement
			),
			badgesSectionShowLearningActivities: String(
				settings.badgesSectionShowLearningActivities
			),
			descriptionSectionTitle: settings.descriptionSectionTitle,
			descriptionSectionText: settings.descriptionSectionText,
			descriptionSectionColor: settings.descriptionSectionColor,
			descriptionSectionIcon: settings.descriptionSectionIcon,
			engagementSectionShowCompletedLearningActivities: String(
				settings.engagementSectionShowCompletedLearningActivities
			),
			engagementSectionShowTotalCount: String(
				settings.engagementSectionShowTotalCount
			),
		};
		return this.#httpClient
			.put<ColearnSectionOptions>(
				`${this.#apiServer}/api/colearn/sections/settings`,
				settingsRequest
			)
			.pipe(
				map((s: any) => {
					return {
						...s,
						engagementSectionShowTotalCount: JSON.parse(
							s.engagementSectionShowTotalCount
						),
						engagementSectionShowCompletedLearningActivities: JSON.parse(
							s.engagementSectionShowCompletedLearningActivities
						),
						badgesSectionShowFrequentEngagement: JSON.parse(
							s.badgesSectionShowFrequentEngagement
						),
						badgesSectionShowLearningActivities: JSON.parse(
							s.badgesSectionShowLearningActivities
						),
					};
				})
			);
	}

	createBadge(data: CreateEventBadgeModel) {
		return this.#httpClient.post<EventBadge>(
			`${this.#apiServer}/api/colearn/badge`,
			{ ...data }
		);
	}

	loadEventBadges() {
		return this.#httpClient.get<EventBadge[]>(
			`${this.#apiServer}/api/colearn/badge`
		);
	}

	moveEventBadge({ id, sortingKey }: MoveModel) {
		return this.#httpClient.patch(
			`${this.#apiServer}/api/colearn/badge/${id}/move`,
			{
				sortingKey,
			}
		);
	}

	updateEventBadge({ id, ...data }: UpdateEventBadgeModel) {
		return this.#httpClient.put<EventBadge>(
			`${this.#apiServer}/api/colearn/badge/${id}`,
			data
		);
	}

	loadEventEngagements() {
		return this.#httpClient.get<EventEngagement[]>(
			`${this.#apiServer}/api/colearn/engagement`
		);
	}

	createEngagement(data: CreateEventEngagementModel) {
		return this.#httpClient.post<EventEngagement>(
			`${this.#apiServer}/api/colearn/engagement`,
			{
				...data,
			}
		);
	}

	updateEngagement(data: UpdateEventEngagementModel) {
		return this.#httpClient.put<EventEngagement>(
			`${this.#apiServer}/api/colearn/engagement`,
			{
				...data,
			}
		);
	}

	loadEngagement(subManagerUserId: string) {
		if (subManagerUserId) {
			return this.#httpClient.get<EngagementAggregate[]>(
				`${this.#apiServer}/api/colearn/progress/${subManagerUserId}`
			);
		}
		return this.#httpClient.get<EngagementAggregate[]>(
			`${this.#apiServer}/api/colearn/progress`
		);
	}

	moveEventEngagement({ id, sortingKey }: MoveModel) {
		return this.#httpClient.patch(
			`${this.#apiServer}/api/colearn/engagement/${id}/move`,
			{
				sortingKey,
			}
		);
	}

	loadScoreSettings() {
		return this.#httpClient.get<ScoreSettings[]>(
			`${this.#apiServer}/api/colearn/scoresettings`
		);
	}

	createScoreSettings(data: CreateScoreSettingsModel) {
		return this.#httpClient.post<ScoreSettings>(
			`${this.#apiServer}/api/colearn/scoresettings`,
			{
				...data,
			}
		);
	}

	updateScoreSettings(data: UpdateScoreSettingsModel) {
		return this.#httpClient.put<ScoreSettings>(
			`${this.#apiServer}/api/colearn/scoresettings`,
			{
				...data,
			}
		);
	}

	loadTeamScores(competitionId: string, limit = 50) {
		return this.#httpClient.get<LeaderboardItem[]>(
			`${
				this.#apiServer
			}/api/colearn/leaderboard/competition/${competitionId}/limit`,
			{
				params: { limit: limit.toString() },
			}
		);
	}

	loadUserCounts(userId: string) {
		return this.#httpClient.get<ScoreOverviewViewModel[]>(
			`${this.#apiServer}/api/colearn/leaderboard/user/${userId}`,
			{}
		);
	}

	loadUserEngagement(userId: string) {
		return this.#httpClient.get<EngagementAggregate[]>(
			`${this.#apiServer}/api/colearn/user/${userId}/engagement`
		);
	}

	loadUserBadges(userId: string) {
		return this.#httpClient.get<ColearnBadge[]>(
			`${this.#apiServer}/api/colearn/user/${userId}/badges`
		);
	}

	loadUserPoints(userId: string) {
		return this.#httpClient.get<{ points: number }>(
			`${this.#apiServer}/api/colearn/user/${userId}/points`
		);
	}

	loadUserAcademy(userId: string) {
		return this.#httpClient.get<LearningActivity[]>(
			`${this.#apiServer}/api/colearn/user/${userId}/academy`
		);
	}

	loadColearnUser(userId: string) {
		if (userId) {
			return this.#httpClient.get<ColearnUser>(
				`${this.#apiServer}/api/colearn/user/${userId}`
			);
		}
		return this.#httpClient.get<ColearnUser>(
			`${this.#apiServer}/api/colearn/user`
		);
	}

	uploadExcel(excelFile: File): Observable<void> {
		const formData = new FormData();
		formData.append('scoresExcelFile', excelFile);
		return this.#httpClient.post<void>(
			`${this.#apiServer}/api/colearn/import`,
			formData
		);
	}
}
