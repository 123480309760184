import { UfaEliminationGameClient } from './ufa-elimination-game.client';
import { Actions, createEffect } from '@ngrx/effects';
import { ApiEffects } from '@lib/redux';
import { selectEliminationGameState } from './ufa-elimination-game.selectors';
import {
	answerEliminationGameQuestion,
	loadEliminationGames,
} from './ufa-elimination-game.actions';
import { inject, Injectable } from '@angular/core';
import { UfaEliminationGameStoreState } from './ufa-elimination-game.state';

@Injectable()
export class EliminationGameEffects extends ApiEffects<UfaEliminationGameStoreState> {
	readonly #actions$ = inject(Actions);
	readonly #client = inject(UfaEliminationGameClient);
	constructor() {
		super(selectEliminationGameState);
	}

	loadEliminationGames$ = createEffect(() =>
		this.generateApiEffect(loadEliminationGames, () =>
			this.#client.loadUserEliminationGames()
		)(this.#actions$)
	);

	answerQuestion$ = createEffect(() =>
		this.generateApiEffect(answerEliminationGameQuestion, action =>
			this.#client.answerQuestion(action.payload)
		)(this.#actions$)
	);
}
