import {
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	inject,
	Input,
	NgZone,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import {
	Observable,
	BehaviorSubject,
	from,
	distinctUntilChanged,
	filter,
	map,
	shareReplay,
	switchMap,
	tap,
	take,
} from 'rxjs';
import { CoRiveService } from './rive.service';
import {
	Artboard,
	CanvasRenderer,
	RiveCanvas as Rive,
	File as RiveFile,
	AABB,
	StateMachineInstance,
	LinearAnimationInstance,
} from '@rive-app/canvas-advanced';
import { getClientCoordinates, getRiveFileContentInfo, toInt } from './utils';
import {
	CoReportedRiveEvent,
	CoRiveCanvasAlignment,
	CoRiveCanvasFit,
	CoRiveFileContent,
} from '@consensus/co/domain-rive';
import { isNonNullish } from '@consensus/co/util-types';

type RiveOrigin = string | File | Blob | null;

const onVisible = (element: HTMLElement) =>
	new Promise<boolean>((res, _) => {
		// SSR
		if (typeof window === 'undefined') {
			return res(false);
		}
		// Compatibility
		if (!('IntersectionObserver' in window)) {
			return res(true);
		}
		const isVisible = false;
		const observer = new IntersectionObserver(
			entries => {
				entries.forEach(entry => {
					const visible = entry.intersectionRatio !== 0;
					if (visible !== isVisible) {
						res(isVisible);
						observer.disconnect();
					}
				});
			},
			{ threshold: [0] }
		);
		// start observing element visibility
		observer.observe(element);
	});

function isLinearAnimation(
	instance: StateMachineInstance | LinearAnimationInstance
): instance is LinearAnimationInstance {
	return 'didLoop' in instance;
}

// Force event to run inside zones
export function enterZone(zone: NgZone) {
	return <T>(source: Observable<T>) =>
		new Observable<T>(observer =>
			source.subscribe({
				next: x => zone.run(() => observer.next(x)),
				error: err => observer.error(err),
				complete: () => observer.complete(),
			})
		);
}

/**
 * RiveCanvas is the main export object that contains references to different Rive classes to help build the Rive render loop for low-level API usage.
 * In addition, this contains multiple methods that help aid in setup, such
 * as loading in a Rive file, creating the renderer, and starting/finishing the render loop (requestAnimationFrame).
 * The RiveCanvas loads a .riv file into it's canvas tag.
 */
@Directive({
	selector: 'canvas[coRive]',
	standalone: true,
})
export class CoRiveCanvasDirective implements OnInit, OnDestroy {
	readonly #service = inject(CoRiveService);
	readonly #riveOrigin = new BehaviorSubject<RiveOrigin>(null);
	readonly #artboardName = new BehaviorSubject<string | null>(null);
	private _ctx?: CanvasRenderingContext2D | null;
	readonly #loaded: Observable<boolean>;
	#boxes: Record<string, AABB> = {};
	canvas: HTMLCanvasElement;
	rive?: Rive;
	file?: RiveFile;
	artboard?: Artboard;
	renderer?: CanvasRenderer;
	// Keep track of current state machine for event listeners
	stateMachines: Record<string, StateMachineInstance> = {};

	whenVisible: Promise<boolean>;

	@Input({ required: true }) set riv(riveOrigin: RiveOrigin) {
		this.#riveOrigin.next(riveOrigin);
	}

	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('artboard') set name(name: string) {
		this.#artboardName.next(name);
	}

	@Input() viewbox = '0 0 100% 100%';
	@Input() lazy: boolean | '' = false;
	@Input() fit: CoRiveCanvasFit = 'contain';
	@Input() alignment: CoRiveCanvasAlignment = 'center';

	@Input()
	set width(w: number | string) {
		const width = toInt(w) ?? this.canvas.width;
		this.canvas.width = width;
	}
	get width() {
		return this.canvas.width;
	}

	@Input()
	set height(h: number | string) {
		const height = toInt(h) ?? this.canvas.height;
		this.canvas.height = height;
	}
	get height() {
		return this.canvas.height;
	}

	@Output() artboardChange = new EventEmitter<Artboard>();
	@Output() fileContentRead = new EventEmitter<CoRiveFileContent>();
	@Output() reportedEventsChange = new EventEmitter<CoReportedRiveEvent[]>();

	@HostListener('touchmove', ['$event'])
	@HostListener('mouseover', ['$event'])
	@HostListener('mouseout', ['$event'])
	@HostListener('mousemove', ['$event'])
	private pointerMove(event: MouseEvent | TouchEvent) {
		const stateMachines = Object.values(this.stateMachines).filter(
			sm => 'pointerMove' in sm
		);
		if (!stateMachines.length) {
			return;
		}
		const vector = this.#getTransform(event);
		if (!vector) {
			return;
		}
		for (const stateMachine of stateMachines) {
			stateMachine.pointerMove(vector.x, vector.y);
		}
	}

	@HostListener('touchstart', ['$event'])
	@HostListener('mousedown', ['$event'])
	private pointerDown(event: MouseEvent | TouchEvent) {
		const stateMachines = Object.values(this.stateMachines).filter(
			sm => 'pointerDown' in sm
		);
		if (!stateMachines.length) {
			return;
		}
		const vector = this.#getTransform(event);
		if (!vector) {
			return;
		}
		for (const stateMachine of stateMachines) {
			stateMachine.pointerDown(vector.x, vector.y);
		}
	}

	@HostListener('touchend', ['$event'])
	@HostListener('mouseup', ['$event'])
	private pointerUp(event: MouseEvent | TouchEvent) {
		const stateMachines = Object.values(this.stateMachines).filter(
			sm => 'pointerUp' in sm
		);
		if (!stateMachines.length) {
			return;
		}
		const vector = this.#getTransform(event);
		if (!vector) {
			return;
		}
		for (const stateMachine of stateMachines) {
			stateMachine.pointerUp(vector.x, vector.y);
		}
	}

	constructor(element: ElementRef<HTMLCanvasElement>) {
		this.canvas = element.nativeElement;

		this.whenVisible = onVisible(element.nativeElement);

		this.#loaded = this.#riveOrigin.pipe(
			filter(isNonNullish),
			distinctUntilChanged(),
			filter(() => typeof window !== 'undefined' && !!this.ctx), // Make sure it's not ssr
			switchMap(async url => {
				this.file = await this.#service.load(url);
				this.rive = this.#service.rive;
				if (!this.rive) {
					throw new Error('Service could not load rive');
				}
				this.renderer = this.rive.makeRenderer(this.canvas) as CanvasRenderer;
			}),
			switchMap(_ => this.#setArtboard()),
			shareReplay({ bufferSize: 1, refCount: true })
		);
	}

	ngOnInit() {
		this.onReady();

		this.#loaded.pipe(take(1)).subscribe(_loaded => {
			const rive = this.rive;
			const file = this.file;
			if (!rive) {
				throw new Error('Rive not found');
			}
			if (!file) {
				throw new Error('Rive file not found');
			}
			const contents = getRiveFileContentInfo(file, rive);
			this.fileContentRead.emit(contents);
		});
	}

	ngOnDestroy() {
		// Timeout to avoid late request to a deleted artboard
		setTimeout(() => {
			this.renderer?.delete();
			this.artboard?.delete();
			this.file?.delete();
		}, 100);
	}

	get ctx(): CanvasRenderingContext2D {
		if (!this._ctx) {
			this._ctx = this.canvas.getContext('2d');
		}
		return this._ctx as CanvasRenderingContext2D;
	}

	#setArtboard() {
		return this.#artboardName.pipe(
			tap(() => this.artboard?.delete()), // Remove previous artboard if any
			map(name =>
				name ? this.file?.artboardByName(name) : this.file?.defaultArtboard()
			),
			tap(artboard => (this.artboard = artboard)),
			tap(() => this.artboardChange.emit(this.artboard)),
			map(() => true)
		);
	}

	/**
	 * Calculate the box of the canvas based on viewbox, width and height.
	 * It caches the values to avoid recalculation for each frame
	 */
	get box() {
		const w = this.width as number;
		const h = this.height as number;
		const boxId = `${this.viewbox} ${w} ${h}`;
		if (!this.#boxes[boxId]) {
			const bounds = this.viewbox.split(' ');
			if (bounds.length !== 4) {
				throw new Error('View box should look like "0 0 100% 100%"');
			}
			const [minX, minY, maxX, maxY] = bounds.map((v, i) => {
				const size: number = i % 2 === 0 ? w : h;
				const percentage = v.endsWith('%')
					? parseInt(v.slice(0, -1), 10) / 100
					: parseInt(v, 10) / size;
				return i < 2 ? -size * percentage : size / percentage;
			});
			this.#boxes[boxId] = { minX, minY, maxX, maxY };
		}
		return this.#boxes[boxId];
	}

	get isLazy() {
		return this.lazy === true || this.lazy === '';
	}

	get count() {
		return this.artboard?.animationCount();
	}

	onReady() {
		if (this.isLazy) {
			return from(this.whenVisible).pipe(
				filter(isVisible => isVisible),
				switchMap(() => this.#loaded)
			);
		}
		return this.#loaded;
	}

	draw(instance: LinearAnimationInstance, delta: number, mix: number): void;
	draw(instance: StateMachineInstance, delta: number): void;
	draw(
		instance: StateMachineInstance | LinearAnimationInstance,
		delta: number,
		mix?: number
	) {
		if (!this.rive) {
			throw new Error('Could not load rive before registrating instance');
		}
		if (!this.artboard) {
			throw new Error('Could not load artboard before registrating instance');
		}
		if (!this.renderer) {
			throw new Error('Could not load renderer before registrating instance');
		}

		this.renderer.clear();

		// Move frame
		if (isLinearAnimation(instance)) {
			instance.advance(delta);
			instance.apply(mix ?? 1);
		} else {
			// Emit events before advancing the state machine
			this.#emitStateMachineInstanceEvents(instance);
			instance.advance(delta);
		}
		this.artboard.advance(delta);

		// Render frame on canvas
		this.renderer.save();

		// Align renderer if needed
		const fit = this.rive.Fit[this.fit];
		const alignment = this.rive.Alignment[this.alignment];
		const box = this.box;
		const bounds = this.artboard.bounds;
		this.renderer.align(fit, alignment, box, bounds);

		this.artboard.draw(this.renderer);

		this.renderer.restore();
	}

	#emitStateMachineInstanceEvents(stateMachine: StateMachineInstance) {
		const eventsToEmit: CoReportedRiveEvent[] = [];
		const firedEventsCount = stateMachine.reportedEventCount();
		for (let i = 0; i < firedEventsCount; i++) {
			const event = stateMachine.reportedEventAt(i);
			if (event) {
				eventsToEmit.push(event);
			}
		}
		if (eventsToEmit.length > 0) {
			this.reportedEventsChange.emit(eventsToEmit);
		}
	}

	#getTransform(event: MouseEvent | TouchEvent) {
		if (!this.rive) {
			return;
		}
		if (!this.artboard) {
			return;
		}
		const boundingRect = this.canvas.getBoundingClientRect();

		const { clientX, clientY } = getClientCoordinates(event);
		if (!clientX && !clientY) {
			return;
		}
		const canvasX = clientX - boundingRect.left;
		const canvasY = clientY - boundingRect.top;
		const forwardMatrix = this.rive.computeAlignment(
			this.rive.Fit[this.fit],
			this.rive.Alignment[this.alignment],
			{
				minX: 0,
				minY: 0,
				maxX: boundingRect.width,
				maxY: boundingRect.height,
			},
			this.artboard.bounds
		);
		const invertedMatrix = new this.rive.Mat2D();
		forwardMatrix.invert(invertedMatrix);
		const canvasCoordinatesVector = new this.rive.Vec2D(canvasX, canvasY);
		const transformedVector = this.rive.mapXY(
			invertedMatrix,
			canvasCoordinatesVector
		);
		const x = transformedVector.x();
		const y = transformedVector.y();

		transformedVector.delete();
		invertedMatrix.delete();
		canvasCoordinatesVector.delete();
		forwardMatrix.delete();
		return { x, y };
	}
}
