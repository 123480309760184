<div class="loading" *ngIf="loading">Loading PDF...</div>
<ng-container
	*ngrxLet="{
		active: active$,
		isAdmin: isAdmin$,
		autohideSidebar: autohideSidebar$,
	} as vm"
>
	<ng-container *ngIf="!loading && url && vm.active">
		<ngx-extended-pdf-viewer
			class="tw-pt-safe"
			[useBrowserLocale]="true"
			[src]="url"
			(pdfLoaded)="loaded.emit()"
			[showPrintButton]="vm.isAdmin || allowSharing"
			[showDownloadButton]="vm.isAdmin || allowSharing"
			(pdfDownloaded)="capacitorDownloadHandler()"
			[showBookmarkButton]="false"
			[showUnverifiedSignatures]="true"
			(pdfLoadingFailed)="onError($event)"
			[sidebarVisible]="
				initialSidebar && vm.autohideSidebar !== true && !fullscreen
			"
			[showSidebarButton]="true"
			[showOpenFileButton]="false"
			[showTextEditor]="false"
			[showDrawEditor]="false"
			[textLayer]="true"
			[(zoom)]="zoom"
			[showPresentationModeButton]="true"
			(pdfLoaded)="pdfLoaded()"
		/>
	</ng-container>
</ng-container>
