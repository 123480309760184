import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { inject, Injectable } from '@angular/core';

import { posthogToken } from './posthog/posthog.token';
import { environmentToken } from '@environments/environment';
import { CoAnalyticsSnackComponent } from '@consensus/co/ui-snackbars';

@Injectable({ providedIn: 'root' })
export class CookieService {
	readonly #capturePolicy = inject(environmentToken).posthog.capturePolicy;
	readonly #posthog = inject(posthogToken);
	readonly #snackBar = inject(MatSnackBar);

	cookieConsentStatus = 'cookieConsent__status';

	consentCheck(): void {
		const cookieStatus = localStorage.getItem(this.cookieConsentStatus);

		if (cookieStatus === 'consent') {
			this.startAnalytics();
		} else if (cookieStatus !== 'optOut') {
			this.startSnack();
		}
	}

	startSnack(): void {
		if (localStorage.getItem(this.cookieConsentStatus)) {
			localStorage.removeItem(this.cookieConsentStatus);
		}

		const snackBarRef = this.#snackBar.openFromComponent(
			CoAnalyticsSnackComponent,
			{
				horizontalPosition: 'left',
				verticalPosition: 'bottom',
			}
		);

		snackBarRef.onAction().subscribe(() => {
			this.startAnalytics();
		});

		snackBarRef.afterDismissed().subscribe((res: MatSnackBarDismiss) => {
			if (!res.dismissedByAction) {
				localStorage.setItem(this.cookieConsentStatus, 'optOut');
			}
		});
	}

	startAnalytics(): void {
		localStorage.setItem(this.cookieConsentStatus, 'consent');

		/** Posthog handling */
		if (this.#capturePolicy === 'RequireInformedConsent') {
			this.#posthog.clear_opt_in_out_capturing();
			this.#posthog.opt_in_capturing();
		}
	}

	// return type 'null' set to foresee future use of --strictNullChecks
	syncOnServer(): boolean | null {
		const consent = localStorage.getItem(this.cookieConsentStatus);
		return consent === 'consent' ? true : consent === 'optOut' ? false : null;
	}
}
