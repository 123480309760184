import {
	ChangeDetectionStrategy,
	Component,
	inject,
	Input,
} from '@angular/core';
import { FileCacheService } from '@consensus/shared/shared/files/data-access-files';
import { NgForOf, NgIf } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { LetDirective } from '@ngrx/component';
import { CoSpinnerComponent } from '@consensus/co/ui-progress';
import { CoUiRivePlayerComponent } from '@consensus/co/ui-rive-player';

@Component({
	selector: 'co-drive-rive-display',
	standalone: true,
	imports: [
		NgIf,
		LetDirective,
		CoSpinnerComponent,
		NgForOf,
		CoUiRivePlayerComponent,
	],
	templateUrl: './co-drive-rive-display.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoDriveRiveDisplayComponent {
	readonly #fileService = inject(FileCacheService);

	loading = false;
	readonly riveOrigin$ = new BehaviorSubject<Blob | string | null>(null);

	@Input() set driveFileId(id: string) {
		this.#setFileId(id);
	}

	@Input() set fileUrl(url: string) {
		this.riveOrigin$.next(url);
	}

	async #setFileId(id: string) {
		this.loading = true;
		const driveFile = await this.#fileService.getFile(`materials/${id}`);
		if (driveFile?.blob) {
			this.riveOrigin$.next(driveFile.blob);
		} else {
			this.riveOrigin$.next(
				await fetch(driveFile?.url ?? '').then(r => r.blob())
			);
		}
		this.loading = false;
	}
}
