import { animate, style, transition, trigger } from '@angular/animations';

export const listItemAnimation = trigger('list-item', [
	transition(':enter', [
		style({
			height: 0,
			marginTop: 0,
			marginBottom: 0,
			paddingTop: 0,
			paddingBottom: 0,
			overflow: 'hidden',
		}),
		animate(
			'200ms cubic-bezier(0.645, 0.045, 0.355, 1)',
			style({
				height: '*',
				marginTop: '*',
				marginBottom: '*',
				paddingTop: '*',
				paddingBottom: '*',
				overflow: 'hidden',
			})
		),
	]),
	transition(':leave', [
		style({
			height: '*',
			marginTop: '*',
			marginBottom: '*',
			paddingTop: '*',
			paddingBottom: '*',
			overflow: 'hidden',
		}),
		animate(
			'200ms cubic-bezier(0.645, 0.045, 0.355, 1)',
			style({
				height: 0,
				marginTop: 0,
				marginBottom: 0,
				paddingTop: 0,
				paddingBottom: 0,
				overflow: 'hidden',
			})
		),
	]),
]);
