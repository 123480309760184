import { inject, Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { SidepanelClient } from './sidepanel.client';

import { SidepanelState } from './sidepanel.reducer';
import { loadSidepanel } from './sidepanel.actions';
import { selectSidepanelState } from './sidepanel.selectors';
import { ApiEffects } from '@lib/redux';

@Injectable()
export class SidepanelEffects extends ApiEffects<SidepanelState> {
	readonly #client = inject(SidepanelClient);
	readonly #actions$ = inject(Actions);
	constructor() {
		super(selectSidepanelState);
	}

	getSidepanel$ = createEffect(() =>
		this.generateApiEffect(loadSidepanel, () =>
			this.#client.loadUserSidepanel()
		)(this.#actions$)
	);
}
