<div class="hoz-wrapper" *ngIf="search || visibilityToggle">
	<co-form-search-input
		label="Search List"
		[value]="searchQuery$.value"
		(valueChange)="searchQuery$.next($event)"
		[disabled]="parents.length < 1"
		*ngIf="search"
	/>

	<button
		mat-icon-button
		class="!tw-text-base"
		*ngIf="visibilityToggle"
		(click)="toggleHideInvisible()"
		[disabled]="!searching"
	>
		<i class="far" [class]="hideInvisible ? 'fa-low-vision' : 'fa-eye'"></i>
	</button>
</div>

<mat-list
	dense
	cdkDropList
	[cdkDropListDisabled]="!showDragParent()"
	(cdkDropListDropped)="dropItem($event, null)"
	tabindex="0"
	(keydown)="rootKeyDown($event)"
>
	<div
		class="root-item"
		*ngFor="let item of getItems(); trackBy: getId; let l = last"
		cdkDrag
		[cdkDragData]="item"
		[class.is-active]="isSelected(item.id)"
		[class.light]="!!childSelection"
	>
		<mat-list-item class="!tw-cursor-pointer" (click)="select(item.id)">
			<i
				matListItemIcon
				[class]="getIcon(item)"
				*ngIf="bindIcon"
				[class.!tw-text-primary-contrast-500]="isSelected(item.id)"
			></i>

			<p
				matListItemLine
				[class.!tw-text-primary-contrast-500]="isSelected(item.id)"
			>
				{{ getName(item) }}
			</p>

			<div
				matListItemMeta
				[class.!tw-text-primary-contrast-500]="isSelected(item.id)"
			>
				<ng-container *ngIf="bindVisible">
					<i
						class="visible-icon fal"
						*ngIf="!visibleClick.observed"
						[class]="getVisible(item) ? 'fa-eye' : 'fa-eye-slash'"
					>
					</i>

					<mat-slide-toggle
						class="visible-toggle"
						color="primary"
						[ngModel]="getVisible(item)"
						coNoClickBubble
						*ngIf="visibleClick.observed"
						(ngModelChange)="visibleClick.emit(item.id)"
					/>
				</ng-container>

				<span
					class="extra"
					*ngIf="bindExtra"
					[coNoClickBubble]="extraClick.observed"
					(click)="extraClick.emit(item.id)"
				>
					{{ getExtra(item) }}
				</span>

				<i
					class="far chevron"
					[class]="
						expanded[item.id]
							? 'fa-chevron-down'
							: 'fa-chevron-right'
					"
					*ngIf="collapsible"
					(click)="expanded[item.id] = !expanded[item.id]"
					coNoClickBubble
				>
				</i>

				<i
					class="drag-handle fas fa-bars"
					cdkDragHandle
					*ngIf="showDragParent()"
				></i>
			</div>
		</mat-list-item>

		<mat-list
			dense
			cdkDropList
			*ngIf="showChildren(item) && hasChildren(item)"
			[@harmonica]="!collapsible || !!expanded[item.id]"
			(cdkDropListDropped)="dropItem($event, item.id)"
			[cdkDropListDisabled]="!showDragChild()"
			coNoClickBubble
		>
			<ng-container
				*ngFor="
					let child of getChildren(item);
					trackBy: getId;
					let l = last
				"
			>
				<mat-list-item
					(click)="select(child.id, item.id)"
					cdkDrag
					class="!tw-cursor-pointer"
					[cdkDragData]="child"
					[class.is-active]="isSelected(child.id, true)"
				>
					<i
						[class.!tw-text-primary-contrast-500]="
							isSelected(child.id, true)
						"
						matListItemIcon
						[class]="getIcon(child, true)"
						*ngIf="bindIcon || bindChildIcon"
					></i>

					<p
						matListItemLine
						[class.!tw-text-primary-contrast-500]="
							isSelected(child.id, true)
						"
					>
						{{ getName(child, true) }}
					</p>

					<div
						matListItemMeta
						[class.!tw-text-primary-contrast-500]="
							isSelected(child.id, true)
						"
					>
						<ng-container *ngIf="bindVisible || bindChildVisible">
							<i
								class="visible-icon fal"
								*ngIf="!childVisibleClick.observed"
								[class]="
									getVisible(child, true)
										? 'fa-eye'
										: 'fa-eye-slash'
								"
							>
							</i>
							<mat-slide-toggle
								class="visible-toggle"
								color="primary"
								[ngModel]="getVisible(child, true)"
								coNoClickBubble
								*ngIf="childVisibleClick.observed"
								(ngModelChange)="
									childVisibleClick.emit([item.id, child.id])
								"
							/>
						</ng-container>

						<span
							class="extra"
							*ngIf="bindExtra || bindChildExtra"
							[coNoClickBubble]="childExtraClick.observed"
							(click)="childExtraClick.emit([child.id, item.id])"
						>
							{{ getExtra(child, true) }}
						</span>

						<i
							class="drag-handle fas fa-bars"
							cdkDragHandle
							*ngIf="showDragChild()"
						></i>
					</div>
				</mat-list-item>
				<mat-divider *ngIf="!l" />

				<div class="preview" *cdkDragPreview>
					<i
						[class]="getIcon(child, true)"
						*ngIf="bindVisible || bindChildVisible"
					></i>

					<span>{{ getName(child, true) }}</span>

					<i
						class="visible-icon fal"
						*ngIf="bindVisible || bindChildVisible"
						[class]="
							getVisible(child, true) ? 'fa-eye' : 'fa-eye-slash'
						"
					>
					</i>
				</div>
			</ng-container>

			<ng-container *ngIf="showCreateChild()">
				<mat-divider />
				<mat-list-item
					class="create-child !tw-cursor-pointer"
					(click)="toggleNewChild(item.id)"
				>
					<i matListItemIcon class="add fal fa-plus"></i>

					<p matListItemLine *ngIf="creatingChild !== item.id">
						Add {{ childName || "child" }}
					</p>

					<input
						*ngIf="creatingChild === item.id"
						type="text"
						(keydown.enter)="addChild(item.id)"
						class="create_form_{{ item.id }}"
						(keydown)="inputKeyDown($event)"
						[(ngModel)]="creatingChildText"
						(blur)="creatingChild = null"
						coNoClickBubble
						placeholder="Add {{ childName || 'child' }}"
					/>
				</mat-list-item>
			</ng-container>
		</mat-list>

		<mat-divider *ngIf="!l" />

		<div class="preview" *cdkDragPreview>
			<i [class]="getIcon(item)" *ngIf="bindIcon"></i>

			<span>{{ getName(item) }}</span>

			<i
				class="visible-icon fal"
				*ngIf="bindVisible"
				[class]="getVisible(item) ? 'fa-eye' : 'fa-eye-slash'"
			>
			</i>
		</div>
	</div>

	<ng-container *ngIf="showCreateParent()">
		<mat-divider />
		<mat-list-item
			class="create-child !tw-cursor-pointer"
			(click)="toggleNewParent()"
		>
			<i matListItemIcon class="add fal fa-plus"></i>

			<p matListItemLine *ngIf="!creatingParent">
				Add {{ parentName || "parent" }}
			</p>

			<input
				*ngIf="creatingParent"
				type="text"
				(keydown.enter)="addParent()"
				#createParentElement
				(keydown)="inputKeyDown($event)"
				[(ngModel)]="creatingParentText"
				(blur)="creatingParent = false"
				coNoClickBubble
				placeholder="Add {{ parentName || 'parent' }}"
			/>
		</mat-list-item>
	</ng-container>
</mat-list>

<ng-container *ngIf="searching && bindChildren">
	<co-divider [text]="childName || 'Children'" />
	<mat-list dense coNoClickBubble>
		<mat-list-item *ngIf="filteredChildren.length < 1">
			<p matListItemLine><i>No Results</i></p>
		</mat-list-item>

		<ng-container
			*ngFor="let child of filteredChildren; trackBy: getId; let l = last"
		>
			<mat-list-item
				class="!tw-cursor-pointer"
				(click)="select(child.id, child._parentId)"
				[class.is-active]="isSelected(child.id, true)"
			>
				<i
					matListItemIcon
					[class]="getIcon(child, true)"
					*ngIf="bindIcon || bindChildIcon"
				></i>

				<p matListItemLine>{{ getName(child, true) }}</p>

				<div matListItemMeta>
					<ng-container *ngIf="bindVisible || bindChildVisible">
						<i
							class="visible-icon fal"
							*ngIf="!childVisibleClick.observed"
							[class]="
								getVisible(child, true)
									? 'fa-eye'
									: 'fa-eye-slash'
							"
						>
						</i>
						<mat-slide-toggle
							class="visible-toggle"
							color="primary"
							[ngModel]="getVisible(child, true)"
							coNoClickBubble
							*ngIf="childVisibleClick.observed"
							(ngModelChange)="
								childVisibleClick.emit([
									child._parentId,
									child.id
								])
							"
						/>
					</ng-container>

					<span
						class="extra"
						*ngIf="bindExtra || bindChildExtra"
						[coNoClickBubble]="childExtraClick.observed"
						(click)="
							childExtraClick.emit([child.id, child._parentId])
						"
					>
						{{ getExtra(child, true) }}
					</span>
				</div>
			</mat-list-item>
			<mat-divider *ngIf="!l" />
		</ng-container>
	</mat-list>
</ng-container>
