/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InputBaseComponent } from '@lib/forms';
import { NoClickBubbleDirective } from '@shared/directives';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { ColorGithubModule } from 'ngx-color/github';
import { harmonicaAnimation } from '@consensus/co/ui-component-animations';
import { TinyColor } from '@ctrl/tinycolor';
import { getColorStyles, parseColor } from '@lib/helpers';

@Component({
	standalone: true,
	selector: 'co-form-color-input',
	imports: [
		NgIf,
		FormsModule,
		MatButtonModule,
		MatInputModule,
		MatSliderModule,
		MatTooltipModule,
		ColorGithubModule,
		NgClickOutsideDirective,
		NoClickBubbleDirective,
	],
	templateUrl: './color-input.component.html',
	styleUrls: ['./color-input.component.scss'],
	animations: [harmonicaAnimation()],
})
export class ColorInputComponent
	extends InputBaseComponent<string>
	implements OnInit
{
	showSwatch = false;
	opacity = 100;
	blur = 0;

	colorStyle = {};

	@Input() showBlur = false;
	@Input() showOpacity = true;

	ngOnInit() {
		super.ngOnInit();
		this.valueSubs.add(this.inputValue$.subscribe(() => this.updateStyle()));
		this.updateStyle();
	}

	preprocessValue(value): any {
		if (!value) {
			return null;
		}

		if (value.includes('gradient')) {
			return value;
		}
		const data = parseColor(value);

		this.blur = data.blur;
		const color = new TinyColor(data.color);
		this.opacity = color.getAlpha() * 255;

		return this.formatColor(color.toHexString());
	}

	postprocessValue(value: string): any {
		if (!value) {
			return null;
		}
		if (value.includes('gradient')) {
			return value;
		}
		const data = parseColor(this.inputValue);
		const color = new TinyColor(data.color);

		return this.formatColor(color?.toHexString() ?? '#000000');
	}

	formatColor(val: string) {
		if (this.showOpacity && this.opacity < 255) {
			val = val + this.opacity.toString(16).padStart(2, '0');
		}

		if (this.showBlur && this.blur > 0) {
			val = val + `-${this.blur.toString(16).padStart(2, '0')}`;
		}

		return val.toUpperCase();
	}

	setOpacity(opacity: number) {
		this.opacity = opacity;

		const data = parseColor(this.inputValue);
		const color = new TinyColor(data.color);

		this.inputValue = this.formatColor(color.toHexString());
		this.updateStyle();
	}

	setBlur(blur: number) {
		this.blur = blur;

		const data = parseColor(this.inputValue);
		const color = new TinyColor(data.color);

		this.inputValue = this.formatColor(color.toHexString());
		this.updateStyle();
	}

	updateStyle() {
		this.colorStyle = getColorStyles(this.inputValue);
	}
}
