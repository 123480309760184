import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, inject, Input, Renderer2 } from '@angular/core';
import { FileCacheService } from '@consensus/shared/shared/files/data-access-files';
import { FileModel } from '@lib/files';

@Directive({
	standalone: true,
	selector: '[coEncodedBkg]',
})
export class EncodedBackgroundDirective {
	readonly #document = inject(DOCUMENT);
	readonly #renderer = inject(Renderer2);
	readonly #fileService = inject(FileCacheService);
	readonly #element: ElementRef<HTMLElement> = inject(ElementRef);

	_oldVal: string;

	@Input('coEncodedBkg') set encodedBkg(val: string) {
		if (val === this._oldVal) {
			return;
		}
		this._oldVal = val;

		this.#element.nativeElement.classList.remove(
			'encodedBkg__empty',
			'encodedBkg__loading',
			'encodedBkg__loaded',
			'encodedBkg__failed'
		);

		if (!val) {
			this.#element.nativeElement.style.backgroundImage = null;
			this.#element.nativeElement.classList.add('encodedBkg__empty');
			return;
		}

		this.#element.nativeElement.classList.add('encodedBkg__loading');

		this.#fileService.getFile(val).then(
			file => {
				if (val !== this._oldVal) {
					return;
				}
				if (file.fileType.startsWith('video/')) {
					this.addVideoBackground(file);
				} else {
					this.#element.nativeElement.style.backgroundImage = `url(${file.url})`;
					this.#element.nativeElement.classList.remove(
						'encodedBkg__empty',
						'encodedBkg__loading',
						'encodedBkg__failed'
					);
					this.#element.nativeElement.classList.add('encodedBkg__loaded');
				}
			},
			() => {
				if (val !== this._oldVal) {
					return;
				}
				this.#element.nativeElement.style.backgroundImage = null;
				this.#element.nativeElement.classList.remove(
					'encodedBkg__empty',
					'encodedBkg__loading',
					'encodedBkg__loaded'
				);
				this.#element.nativeElement.classList.add('encodedBkg__failed');
			}
		);
	}

	addVideoBackground(file: FileModel) {
		const existingVideoElem = this.#document.querySelector('#videoBackground');
		if (existingVideoElem) {
			this.#element.nativeElement.removeChild(existingVideoElem);
		}
		const videoElem = this.#document.createElement('video');
		videoElem.autoplay = true;
		videoElem.loop = true;
		videoElem.muted = true; // It is important that video is muted, otherwise it'll not autoplay on page reload
		videoElem.setAttribute('id', 'videoBackground');
		videoElem.setAttribute('playsinline', 'true');
		videoElem.src = file.url;
		videoElem.style.position = 'absolute';
		videoElem.style.right = '0';
		videoElem.style.bottom = '0';
		videoElem.style.width = '100vw';
		videoElem.style.height = '100vh';
		videoElem.style.objectFit = 'cover';
		this.#renderer.appendChild(this.#element.nativeElement, videoElem);
		this.#element.nativeElement.classList.add('encodedBkg__loaded');
		this.#element.nativeElement.classList.remove(
			'encodedBkg__empty',
			'encodedBkg__loading',
			'encodedBkg__failed'
		);
	}
}
