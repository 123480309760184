export function parseObject(obj: null, parse: (val: any) => any): null;
export function parseObject<TSource, TTarget>(
	obj: TSource,
	parse: (val: any) => any
): TTarget;
export function parseObject<TSource, TTarget>(
	obj: TSource[],
	parse: (val: any) => any
): TTarget[];
export function parseObject<TSource, TTarget>(
	obj: TSource | TSource[] | null,
	parse: (val: any) => any
): TTarget | TTarget[] | null {
	if (obj == null) {
		return null;
	}

	if (Array.isArray(obj)) {
		const ret: TTarget[] = [];

		obj.forEach(val => {
			ret.push(parseObject(val, parse));
		});

		return ret;
	}

	if (typeof obj === 'object') {
		const ret = {} as TTarget;

		Object.entries(obj).forEach(([key, val]) => {
			ret[key as keyof TTarget] = parseObject(val, parse);
		});

		return ret;
	}

	const temp = parse(obj);
	return temp === null ? obj : temp;
}
