import { Injectable, inject } from '@angular/core';
import { coAlwaysDistinct } from '@consensus/co/util-component-stores';
import { ConnectSharedDataAccessWebsocketService } from '@consensus/connect/shared/data-access-websocket';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, merge, pipe, tap } from 'rxjs';
import { coHttpCacheableEndpointUrlsToken } from '../co-http-cacheable-endpoint-urls.token';

interface UserGroupChange {
	readonly groupId: string;
	readonly userId: string;
}

// Resolve @typescript-eslint/no-empty-interface: The internal state is empty
// for now.
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CoHttpCacheState {}

const initialState: CoHttpCacheState = {};

@Injectable()
export class CoHttpCacheStore extends ComponentStore<CoHttpCacheState> {
	#cacheableEndpointUrls = inject(coHttpCacheableEndpointUrlsToken);
	#webSocket = inject(ConnectSharedDataAccessWebsocketService);

	#userGroupChange$: Observable<void> = this.select(
		merge(
			this.#webSocket.action<UserGroupChange>('UserAddedToGroup'),
			this.#webSocket.action<UserGroupChange>('UserRemovedFromGroup')
		),
		() => undefined,
		{
			equal: coAlwaysDistinct,
		}
	);

	constructor() {
		super(initialState);

		this.#clearCacheableEndpointUrls(this.#userGroupChange$);
	}

	readonly #clearCacheableEndpointUrls = this.effect<void>(
		pipe(tap(() => this.#cacheableEndpointUrls.clear()))
	);
}
