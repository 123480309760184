import {
	BehaviorSubject,
	asyncScheduler,
	distinctUntilChanged,
	tap,
	throttleTime,
} from 'rxjs';
import { isEqual } from 'lodash-es';
import { patchAbstractControl } from './utils';
import { FormLayerTemplate } from './form-types';
import { FormLayer } from './form-layer';
import { Directive } from '@angular/core';

@Directive()
export class FormRoot<TForm extends object = any> extends FormLayer<TForm> {
	_formError: string;
	set formError(formError: string) {
		this._formError = formError;
		this.updateState();
	}
	get formError() {
		return this._formError;
	}

	formWarning: string;

	changed: boolean;

	saving$ = new BehaviorSubject(false);
	set saving(saving: boolean) {
		this.saving$.next(saving);
	}
	get saving() {
		return this.saving$.value;
	}
	set savingPromise(saving: Promise<any>) {
		this.saving = true;
		saving.finally(() => (this.saving = false));
	}

	protected _oldValue: TForm;

	canSubmit$ = new BehaviorSubject(false);
	get canSubmit() {
		return this.canSubmit$.value;
	}

	canCreate$ = new BehaviorSubject(false);
	get canCreate() {
		return this.canCreate$.value;
	}

	static fromTemplate<TForm extends object>(
		template: FormLayerTemplate<TForm>
	) {
		return new FormRoot<TForm>(template);
	}

	constructor(template: FormLayerTemplate<TForm>) {
		super(template);

		this.value$ = this._value$.pipe(
			distinctUntilChanged((x, y) => isEqual(x, y)),
			tap(val => {
				this.changed = !isEqual(val, this._oldValue);
				if (this.changed) {
					if (this.pristine) {
						this.markAsDirty();
					}
					if (this.untouched) {
						this.markAsTouched();
					}
				}
				this.updateState();
			})
		);

		this.statusChanges
			.pipe(throttleTime(200, asyncScheduler, { trailing: true }))
			.subscribe(() => this.updateState());

		const value$ = new BehaviorSubject(this.value);

		this.value$
			.pipe(throttleTime(500, asyncScheduler, { trailing: true }))
			.subscribe(value$);
		this.throttledValue$ = value$.asObservable();
	}

	updateState() {
		this.canSubmit$.next(
			this.valid && this.dirty && this.changed && !this.formError
		);
		this.canCreate$.next(this.valid && !this.formError);
	}

	reset(value: Partial<TForm> = {}): void {
		patchAbstractControl(this, value, this._resetValue);
		this._oldValue = this.value;
		this.changed = false;
		this.markAsPristine();
		this.markAsUntouched();
		this.updateValueAndValidity();
	}

	patchValue(value: Partial<TForm>): void {
		patchAbstractControl(this, value, undefined);
		this._oldValue = this.value;
		this.changed = false;
	}
}
