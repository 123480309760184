import {
	provideHttpClient,
	withInterceptors,
	withInterceptorsFromDi,
} from '@angular/common/http';
import {
	ApplicationConfig,
	LOCALE_ID,
	importProvidersFrom,
	isDevMode,
} from '@angular/core';
import {
	coHttpAddCacheControlHeaderToFirstGetRequest,
	provideCoHttpCacheStore,
} from '@consensus/co/http/data-access-caching';
import {
	MAT_MOMENT_DATE_ADAPTER_OPTIONS,
	MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
	HammerModule,
	provideProtractorTestingSupport,
} from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
	provideRouter,
	withInMemoryScrolling,
	withRouterConfig,
} from '@angular/router';
import {
	provideAngulartics2Posthog,
	provideSentry,
} from '@consensus/shared/shared/analytics/data-access';
import { LocaleService } from '@consensus/connect/shared/globalization/util';
import { CoIncompleteRequestBodyInterceptor } from '@consensus/co/util-incomplete-request-body-interceptor';
import { CoUserAgentService } from '@consensus/co/util-user-agent';
import {
	ErrorInterceptor,
	ServerVersionInterceptor,
	TokenInterceptor,
	provideLegacyHttpInterceptor,
} from '@core/interceptors';
import { provideGlobalInjector } from '@lib/global-injector';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideGlobalRouterStore } from '@ngworker/router-component-store';
import { provideGlobalStore } from '@store/store.module';
import { Angulartics2Module } from 'angulartics2';
import { appRoutes } from './app.routes';
import { RIVE_WASM } from '@consensus/co/ui-rive-player';
import {
	registerForPushNotifications,
	deregisterForPushNotifications,
} from '@consensus/connect/ufa/capacitor/data-access-push-notifications';

const isDevelopmentMode = isDevMode();
const isProductionMode = !isDevelopmentMode;

export const appConfig: ApplicationConfig = {
	providers: [
		provideAnimations(),
		provideHttpClient(
			// Class-based HTTP interceptors
			withInterceptorsFromDi(),
			withInterceptors([
				// Functional HTTP interceptors
				coHttpAddCacheControlHeaderToFirstGetRequest,
			])
		),
		provideLegacyHttpInterceptor(ErrorInterceptor),
		provideLegacyHttpInterceptor(TokenInterceptor),
		provideLegacyHttpInterceptor(ServerVersionInterceptor),
		provideLegacyHttpInterceptor(CoIncompleteRequestBodyInterceptor),
		provideCoHttpCacheStore(),
		provideRouter(
			appRoutes,
			withRouterConfig({
				paramsInheritanceStrategy: 'always',
			}),
			withInMemoryScrolling({
				/**
				 * Explicitly set scrollPositionRestoration to 'disabled', which is
				 * the default value. Note, however, that the behaviour is overridden
				 * for the UFA scope of the application in LocationService in a manner
				 * that simulates 'enabled' behaviour.
				 */
				scrollPositionRestoration: 'disabled',
			})
		),
		{
			provide: LOCALE_ID,
			useFactory: (localeService: LocaleService) =>
				localeService.rawDeviceLocale,
			deps: [LocaleService],
		},
		importProvidersFrom(MatDatepickerModule, MatMomentDateModule),
		{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
		{
			provide: MAT_DATE_LOCALE,
			useFactory: (localeService: LocaleService) =>
				localeService.rawDeviceLocale,
			deps: [LocaleService],
		},
		importProvidersFrom(MatDialogModule),
		importProvidersFrom(MatSnackBarModule),
		importProvidersFrom(MatTooltipModule),
		importProvidersFrom(HammerModule),
		provideSentry(),
		importProvidersFrom(Angulartics2Module.forRoot()),
		provideAngulartics2Posthog(),
		CoUserAgentService,
		provideGlobalRouterStore(),
		{
			provide: RIVE_WASM,
			useValue: 'assets/rive/rive.wasm',
		},

		provideStore(
			{},
			{
				runtimeChecks: {
					strictStateImmutability: true,
					strictActionImmutability: true,
					strictStateSerializability: false,
					strictActionSerializability: false,
					strictActionWithinNgZone: true,
					// Disabled until duplicate action types are removed from
					// `apps/connect/src/lib/redux/action.ts`
					strictActionTypeUniqueness: false,
				},
			}
		),
		provideEffects({
			registerForPushNotifications,
			deregisterForPushNotifications,
		}),
		provideStoreDevtools({ logOnly: isProductionMode, maxAge: 25 }),
		provideGlobalStore(),
		provideGlobalInjector(),
		provideProtractorTestingSupport(), // For flaky implementations covered by Playwright end-to-end tests
	],
};
