import {
	colearnCompetitionRemoved,
	colearnCompetitionUpdated,
	colearnProgressSectionsUpdated,
	colearnProgressSettingsUpdated,
	colearnSettingsUpdated,
	loadColearnLeaderboardCompetitions,
	loadColearnProgressSettings,
	loadColearnSections,
	loadColearnSettings,
} from './colearn.actions';
import {
	ColearnCompetition,
	ColearnProgressSection,
	ColearnSectionOptions,
} from '@consensus/connect/ufa/colearn/domain';
import { BaseState, ColearnSettings, initialBaseState } from '@shared/models';
import { ApiReducer } from '@lib/redux';
import { removeListItem, updateListItem } from '@lib/redux';

export interface ColearnState extends BaseState {
	sections: ColearnProgressSection[];
	settings: ColearnSettings;
	sectionSettings: ColearnSectionOptions;
	competitions: ColearnCompetition[];
}

export const colearnKey = 'colearn';

const initialState: ColearnState = {
	...initialBaseState,
	sections: [],
	settings: null,
	sectionSettings: null,
	competitions: [],
};

const reducer = new ApiReducer(initialState, 'Event');

reducer.addApiReducer(loadColearnSections, sections => ({ sections }));

reducer.addApiReducer(loadColearnSettings, settings => ({ settings }));

reducer.addApiReducer(loadColearnProgressSettings, sectionSettings => ({
	sectionSettings,
}));

reducer.addApiReducer(loadColearnLeaderboardCompetitions, competitions => ({
	competitions,
}));

reducer.addReducer(colearnSettingsUpdated, settings => ({ settings }));

reducer.addReducer(colearnProgressSettingsUpdated, (s: any) => ({
	sectionSettings: {
		...s,
		engagementSectionShowTotalCount: JSON.parse(
			s.engagementSectionShowTotalCount
		),
		engagementSectionShowCompletedLearningActivities: JSON.parse(
			s.engagementSectionShowCompletedLearningActivities
		),
		badgesSectionShowFrequentEngagement: JSON.parse(
			s.badgesSectionShowFrequentEngagement
		),
		badgesSectionShowLearningActivities: JSON.parse(
			s.badgesSectionShowLearningActivities
		),
	},
}));

reducer.addReducer(colearnProgressSectionsUpdated, sections => ({ sections }));

reducer.addReducer(
	colearnCompetitionUpdated,
	(competition, { competitions }) => ({
		competitions: updateListItem(
			competitions,
			s => s.id === competition.id,
			competition,
			true
		),
	})
);

reducer.addReducer(colearnCompetitionRemoved, (id, { competitions }) => ({
	competitions: removeListItem(competitions, x => x.id === id),
}));

export const colearnReducer = reducer.getReducer();
