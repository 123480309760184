import { inject, Injectable } from '@angular/core';
import { SessionService } from '@store/scope';
import { AcademyPermissions, AcademyRole } from '@consensus/academy/iam/domain';
import { AcademyPermissionsClient } from './academy-permissions.client';

@Injectable({ providedIn: 'root' })
export class AcademyPermissionService {
	readonly #client = inject(AcademyPermissionsClient);
	readonly #sessionService = inject(SessionService);
	eventId: string;
	clientId: string;

	roles: AcademyRole[];
	eventPermissions: Set<AcademyPermissions>;
	clientPermissions: Set<AcademyPermissions>;

	async loadEvent(clientId: string, eventId: string) {
		if (eventId === this.eventId) {
			return;
		}

		this.eventId = eventId;
		this.clientId = clientId;

		const result = await this.#client.loadPermissions().toPromise();

		this.eventPermissions = new Set(result.event);
		this.clientPermissions = new Set(result.client);
		this.roles = result.roles;
	}

	clearEvent() {
		this.eventId = null;
		this.eventPermissions = null;
	}

	async loadClient(clientId: string) {
		if (clientId === this.clientId) {
			return;
		}

		this.clientId = clientId;

		const result = await this.#client.loadPermissions().toPromise();

		this.clientPermissions = new Set(result.client);
		this.roles = result.roles;
	}

	clearClient() {
		this.clientId = null;
		this.clientPermissions = null;
	}

	hasClientReadAccess() {
		return (
			this.clientPermissions.has(AcademyPermissions.ReadModuleFolders) ||
			this.clientPermissions.has(AcademyPermissions.ReadQuestionFolders) ||
			this.clientPermissions.has(AcademyPermissions.ReadIcons) ||
			this.clientPermissions.has(AcademyPermissions.AccessModuleStaging) ||
			this.clientPermissions.has(AcademyPermissions.ReadTags)
		);
	}

	hasEventReadAccess() {
		return this.eventPermissions?.has(AcademyPermissions.ReadTracks);
	}

	hasClientCmsAccess() {
		return this.#sessionService.isAdmin() || this.hasClientReadAccess();
	}

	hasEventCmsAccess() {
		return this.#sessionService.isAdmin() || this.hasEventReadAccess();
	}

	hasClientPermission(permission: AcademyPermissions) {
		return (
			this.#sessionService.isAdmin() || this.clientPermissions?.has(permission)
		);
	}

	hasEventPermission(permission: AcademyPermissions) {
		return (
			this.#sessionService.isAdmin() || this.eventPermissions?.has(permission)
		);
	}
}
