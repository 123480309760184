<div class="wrapper" [@harmonica]="show">
	<label *ngIf="label">{{ label }}</label>

	<div class="border" (coFileDrop)="onDrop($event)">
		<button type="button" mat-raised-button (click)="fileInput.click()">
			Choose File
		</button>
		<p *ngIf="!file">Or drag and drop file</p>
		<p *ngIf="file">{{ file.name }}</p>
		<input
			type="file"
			#fileInput
			(change)="onFileChange()"
			[accept]="accepts"
			[disabled]="isDisabled"
		/>
	</div>
</div>
