import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
	faArrowDownToArc,
	faTimer,
	faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { A11yModule } from '@angular/cdk/a11y';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { AppflowLiveUpdateService } from '../appflow-live-update.service';
import { LetDirective } from '@ngrx/component';

@Component({
	standalone: true,
	selector: 'co-appflow-live-update-available-modal',
	templateUrl: './appflow-live-update-available-modal.component.html',
	imports: [
		NgIf,
		NgSwitch,
		NgSwitchCase,
		LetDirective,
		MatDialogModule,
		MatButtonModule,
		MatProgressBarModule,
		A11yModule,
		FontAwesomeModule,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppflowLiveUpdateAvailableModalComponent {
	readonly cs = inject(AppflowLiveUpdateService);
	readonly updateIcon = faArrowDownToArc;
	readonly postponeIcon = faTimer;
	readonly errorIcon = faTriangleExclamation;
}
