import { isValid } from 'date-fns/isValid';
import { toDate } from 'date-fns-tz';

/**
 * @summary Convert the given argument to an instance of {@link Date}.
 *
 * @description
 * Convert the given argument to an instance of Date.
 *
 * If the argument is an instance of {@link Date}, the function returns its clone.
 *
 * If the argument is a `number`, it is treated as a timestamp.
 *
 * If an argument is a `string`, the function tries to parse it.
 * Function accepts complete ISO 8601 formats as well as partial implementations.
 * ISO 8601: https://en.wikipedia.org/wiki/ISO_8601
 * If the function cannot parse the string or the values are invalid, it returns `nulL`.
 *
 * If the argument is none of the above, the function returns `null`.
 *
 * **Note**: *all* Date arguments passed to any *date-fns* function is processed by `toDate`.
 * All *date-fns* functions will throw `RangeError` if `options.additionalDigits` is not `0`, `1`, `2` or `undefined`.
 *
 * @see {@link toDate}
 * @see {@link https://github.com/marnusw/date-fns-tz#todate}
 *
 * @remarks Replacement for `parseDate` from `@lib/helpers`.
 *
 * @param date The date with the relevant UTC time. If no UTC offset is
 *   specified, local time is assumed.
 * @param timeZone The time zone to get local time for, can be an offset or
 *   Internet Assigned Numbers Authority (IANA) time zone. Defaults to the
 *   browser's time zone.
 * @returns `null` if {@link date} is invalid or `null`
 *
 * @example <caption>No UTC offset</caption>
 * coToDateOrNull('2023-09-05T00:00:00', 'Europe/London');
 * // -> new Date('2023-09-05T00:00:00+0100')
 * @example <caption>UTC</caption>
 * coToDateOrNull('2023-09-05T02:00:00+0200', 'Europe/London');
 * // -> new Date('2023-09-05T01:00:00+0100')
 * @example <caption>UTC offset</caption>
 * coToDateOrNull('2023-09-05T00:00:00Z', 'Europe/London');
 * // -> new Date('2023-09-05T01:00:00+0100')
 * @example <caption>null</caption>
 * coToDateOrNull(null, 'Europe/London');
 * // -> null
 * @example <caption>Invalid date</caption>
 * coToDateOrNull('potential-bug', 'Europe/London');
 * // -> null
 */
export function coToDateOrNull(
	date: Date | string | number | null,
	timeZone?: string | null
): Date | null {
	if (date === null) {
		return null;
	}

	timeZone = !timeZone
		? new Intl.DateTimeFormat().resolvedOptions().timeZone
		: timeZone;
	date = toDate(date, { timeZone });

	if (!isValid(date)) {
		return null;
	}

	return date;
}
