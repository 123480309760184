<div class="wrapper" [@harmonica]="show">
	<mat-form-field
		[class.has-no-label]="!hasLabel"
		[appearance]="appearance"
		[hideRequiredMarker]="hideRequired"
		[color]="color"
		[matTooltip]="tooltip"
		matTooltipPosition="above"
	>
		<mat-label>
			{{ label }}
			<i class="fad fa-question-circle" *ngIf="tooltip"></i>
		</mat-label>

		<input
			[type]="type"
			matInput
			[placeholder]="placeholder"
			[required]="required"
			[readonly]="readonly"
			[formControl]="control"
			[autocomplete]="autocomplete"
			[matAutocomplete]="matAutocomplete"
			[matAutocompleteDisabled]="!matAutocomplete"
			[autofocus]="autofocus"
			#input
		/>

		<button
			mat-icon-button
			matIconSuffix
			*ngIf="showCopy"
			[cdkCopyToClipboard]="control.value"
			(cdkCopyToClipboardCopied)="onCopied()"
			matTooltip="Copy to clipboard"
			[matTooltipDisabled]="copied"
		>
			<i *ngIf="!copied" class="fad fa-clipboard"></i>
			<i *ngIf="copied" class="fad fa-check"></i>
		</button>

		<mat-error *ngIf="hasErrors">{{ inputError }}</mat-error>
		<mat-hint *ngIf="hint">{{ hint }}</mat-hint>
	</mat-form-field>
</div>
