import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { globalReducers, rootEffects } from './merge';

export function provideGlobalStore(): EnvironmentProviders {
	return makeEnvironmentProviders([
		provideState('global', globalReducers),
		provideEffects(...rootEffects),
	]);
}
