<ng-container *ngrxLet="riveOrigin$ as riveOrigin; suspenseTpl: loadingTpl">
	<ng-container *ngIf="riveOrigin && !loading; else loadingTpl">
		<co-ui-rive-player
			[riveFile]="riveOrigin"
			class="tw-flex tw-h-full tw-w-full"
		/>
	</ng-container>
</ng-container>

<ng-template #loadingTpl>
	<div class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center">
		<co-spinner />
	</div>
</ng-template>
