export enum LoadingState {
	NotLoaded = 'Not Loaded', // First load has not taken place
	Loading = 'Loading', // Loading has started
	Awaiting = 'Awaiting', // Loading is ongoing and another process has been blocked
	Loaded = 'Loaded', // The data has been loaded
	Error = 'Error', // The data failed to load
}

export interface BaseState {
	loaded: LoadingState;
	loadActions: { [action: string]: LoadingState };
	updating: number;
}

export type BaseStateExclude = 'loaded' | 'loadActions' | 'updating';

export const initialBaseState = {
	loaded: LoadingState.NotLoaded,
	loadActions: {},
	updating: 0,
} as BaseState;
