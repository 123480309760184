import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AcademyPermissionLoadModel } from '@consensus/academy/iam/domain';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class AcademyPermissionsClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #httpClient = inject(HttpClient);

	loadPermissions() {
		return this.#httpClient.get<AcademyPermissionLoadModel>(
			`${this.#apiServer}/api/academy/permissions/user`
		);
	}
}
