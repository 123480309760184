/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { harmonicaAnimation } from '@consensus/co/ui-component-animations';
import { InputBaseComponent } from '@lib/forms';

@Component({
	standalone: true,
	selector: 'co-form-longtext-input',
	imports: [NgIf, ReactiveFormsModule, MatInputModule, MatTooltipModule],
	templateUrl: './longtext-input.component.html',
	styleUrls: ['./longtext-input.component.scss'],
	animations: [harmonicaAnimation()],
})
export class LongtextInputComponent extends InputBaseComponent<string> {}
