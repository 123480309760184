import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SessionService } from '@store/scope';

export const isUserAdminGuard: CanActivateFn = () => {
	const sessionService = inject(SessionService);
	const router = inject(Router);
	const auth = inject(SessionService);

	if (auth.isUserAdmin()) {
		return true;
	}

	router.navigate(['/', 'event', sessionService.eventSlug]);
	return false;
};
