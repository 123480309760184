export interface Distribution {
	id: string;
	name?: string | null;
	includeAll: boolean;
	events: DistributionItem[];
	groups: DistributionItem[];
}

export interface DistributionItem {
	id: string;
	blacklist: boolean;
}

export interface DistributionUpdateModel {
	id?: string;
	name?: string | null;
	includeAll: boolean;
	events: DistributionItem[];
	groups: DistributionItem[];
}

export interface DistributionSummary {
	users: DistributionRoleSummary;
	userAdmins: DistributionRoleSummary;
	moderators: DistributionRoleSummary;
	admins: DistributionRoleSummary;
	superAdmins: DistributionRoleSummary;
	totalCount: number;
}

export interface DistributionRoleSummary {
	roleCount: number;
	observerCount: number;
}

export interface DistributionSummaryLoadModel {
	eventId: string;
	includeObservers: boolean;
	config: {
		blacklist: boolean;
		groups: boolean;
		events: boolean;
	};
	distribution: DistributionUpdateModel;
}

export function getDefaultDist() {
	return {
		events: [],
		groups: [],
		includeAll: true,
	} as DistributionUpdateModel;
}
