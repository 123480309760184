<ng-container *ngrxLet="cs.state$ as state">
	<div mat-dialog-title>
		<fa-icon [icon]="updateIcon" class="tw-mr-1" />
		App update available
	</div>
	<div
		mat-dialog-content
		class="tw-flex tw-flex-auto tw-flex-col tw-space-y-2"
	>
		<ng-container [ngSwitch]="state.updateStage">
			<ng-container *ngSwitchCase="'notInitiated'">
				<p>A new version of the app is available.</p>
				<p>
					Please make sure you are up to date with the lastest
					version.
				</p>
			</ng-container>
			<ng-container *ngSwitchCase="'downloading'">
				<p>Downloading app update...</p>
				<mat-progress-bar
					mode="determinate"
					[value]="state.downloadProgress"
				/>
			</ng-container>
			<ng-container *ngSwitchCase="'extracting'">
				Extracting app update...
				<mat-progress-bar
					mode="determinate"
					[value]="state.extractProgress"
				/>
			</ng-container>
			<ng-container *ngSwitchCase="'readyForReload'">
				Reloading app...
				<mat-progress-bar mode="buffer" />
			</ng-container>
			<ng-container *ngSwitchCase="'failed'">
				<fa-icon [icon]="errorIcon" class="tw-mr-1 tw-text-red-800" />
				{{ state.error }}
			</ng-container>
		</ng-container>
	</div>
	<div mat-dialog-actions>
		<button
			mat-flat-button
			mat-dialog-close
			[disabled]="!['notInitiated', 'failed'].includes(state.updateStage)"
		>
			<fa-icon [icon]="postponeIcon" class="tw-mr-1" />
			Postpone
		</button>
		<div class="tw-grow"></div>
		<button
			mat-stroked-button
			cdkFocusInitial
			color="primary"
			(click)="cs.updateApp()"
			[disabled]="state.updateStage !== 'notInitiated'"
			*ngIf="state.updateStage !== 'failed'"
		>
			{{
				state.updateStage !== "notInitiated"
					? "Updating..."
					: "Update app"
			}}
		</button>
		<button
			mat-stroked-button
			cdkFocusInitial
			color="primary"
			(click)="cs.updateApp()"
			*ngIf="state.updateStage === 'failed'"
		>
			Retry update
		</button>
	</div>
</ng-container>
