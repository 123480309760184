import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ModularDashboardLoadModel } from './modular-dashboard.model';
import { Observable } from 'rxjs';
import { environmentToken } from '@environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ModularDashboardClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #http = inject(HttpClient);

	loadModularDashboards(): Observable<ModularDashboardLoadModel[]> {
		return this.#http.get<ModularDashboardLoadModel[]>(
			`${this.#apiServer}/api/modularDashboard/load`
		);
	}
}
