import {
	createFeatureSelector,
	createSelector,
	MemoizedSelector,
	MemoizedSelectorWithProps,
} from '@ngrx/store';
import { BaseState, LoadingState } from '@shared/models';
import { ApiAction } from './action';

/**
 * A Redux selector used to fetch data from a store, and potentially manipulate it
 *
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export class Selector<TState, TBase, TVal> {
	/**
	 * The actual selector method
	 */
	selector: MemoizedSelector<any, TVal>;

	/**
	 * Creates a Selector from a feature, much like `createFeatureSelector`
	 * @param featureName
	 * @param selector
	 * @constructor
	 */
	static fromFeature<TFeature, TVal extends BaseState>(
		featureName: string,
		selector: (val: TFeature) => TVal
	) {
		return new BaseSelector<TVal, TFeature>(
			createFeatureSelector<TFeature>(featureName),
			selector
		);
	}

	protected constructor(
		baseSelector: Selector<TState, any, TBase>,
		selector: (val: TBase) => TVal
	);
	protected constructor(baseSelector: MemoizedSelector<any, TBase>);
	protected constructor(baseSelector: MemoizedSelector<any, TVal>);
	protected constructor(
		baseSelector:
			| Selector<TState, any, TBase>
			| MemoizedSelector<any, TBase>
			| MemoizedSelector<any, TVal>,
		selector?: (val: TBase) => TVal
	) {
		if (baseSelector instanceof Selector) {
			this.selector = createSelector(
				baseSelector.selector as any,
				selector as any
			);
		} else {
			if (selector) {
				this.selector = createSelector(baseSelector as any, selector);
			} else {
				this.selector = baseSelector as any;
			}
		}
	}

	create<TNewVal>(
		selector: (val: TVal) => TNewVal
	): Selector<TState, TVal, TNewVal> {
		return new Selector(this, selector);
	}

	createWithParam<TNewVal, TParam>(
		selector: (val: TVal, param: TParam) => TNewVal
	): ParameterSelector<TVal, TNewVal, TParam> {
		return new ParameterSelector(this, selector);
	}

	createDual<TNewVal, TVal2>(
		addition: MemoizedSelector<any, TVal2> | Selector<any, any, TVal2>,
		selector: (val: TVal, val2: TVal2) => TNewVal
	): Selector<TState, TVal, TNewVal> {
		if (addition instanceof Selector) {
			return new Selector(
				createSelector(this.selector, addition.selector, selector)
			);
		}
		return new Selector(createSelector(this.selector, addition, selector));
	}

	createDualWithParam<TNewVal, TVal2, TParam>(
		addition: MemoizedSelector<any, TVal2> | Selector<any, any, TVal2>,
		selector: (val: TVal, val2: TVal2, param: TParam) => TNewVal
	): ParameterSelector<TVal, TNewVal, TParam> {
		if (addition instanceof Selector) {
			return new ParameterSelector(
				createSelector(this.selector, addition.selector, selector)
			);
		}
		return new ParameterSelector(
			createSelector(this.selector, addition, selector)
		);
	}

	createTriple<TNewVal, TVal2, TVal3>(
		addition: MemoizedSelector<any, TVal2> | Selector<any, any, TVal2>,
		addition2: MemoizedSelector<any, TVal3> | Selector<any, any, TVal3>,
		selector: (val: TVal, val2: TVal2, val3: TVal3) => TNewVal
	): Selector<TState, TVal, TNewVal> {
		return new Selector(
			createSelector(
				this.selector,
				addition instanceof Selector ? addition.selector : addition,
				addition2 instanceof Selector ? addition2.selector : addition2,
				selector
			)
		);
	}
}

/**
 * A Redux selector used to fetch data from a store. This selector represents the base of a store, and has access to some state variables
 *
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export class BaseSelector<TState extends BaseState, TBase> extends Selector<
	TState,
	TBase,
	TState
> {
	loading: Selector<TState, TState, boolean>;
	initialised: Selector<TState, TState, boolean>;

	#actionLoadSelectors: {
		[action: string]: Selector<TState, TState, LoadingState>;
	} = {};

	constructor(baseSelector: any, selector: (val: TBase) => TState) {
		super(baseSelector, selector);

		this.loading = this.create(s => s.updating > 0);
		this.initialised = this.create(s => s.loaded === LoadingState.Loaded);
	}

	actionLoadingState(action: string | ApiAction<any>) {
		const name = action instanceof ApiAction ? action.getName() : action;
		if (
			!Object.prototype.hasOwnProperty.call(this.#actionLoadSelectors, name)
		) {
			this.#actionLoadSelectors[name] = this.create(s => {
				return s.loadActions[name] ?? LoadingState.NotLoaded;
			});
		}
		return this.#actionLoadSelectors[name];
	}

	actionLoading(action: string | ApiAction<any>) {
		return this.actionLoadingState(action).create(
			x => x === LoadingState.Loading || x === LoadingState.Awaiting
		);
	}

	actionLoaded(action: string | ApiAction<any>) {
		return this.actionLoadingState(action).create(
			x => x === LoadingState.Loaded
		);
	}

	actionFailed(action: string | ApiAction<any>) {
		return this.actionLoadingState(action).create(
			x => x === LoadingState.Error
		);
	}
}

/**
 * @deprecated ❌ `@lib/redux` is deprecated. Use NgRx Store and Effects or
 *   NgRx ComponentStore instead.
 */
export class ParameterSelector<TBase, TVal, TParam> {
	selector: MemoizedSelectorWithProps<any, TParam, TVal>;

	constructor(
		baseSelector: Selector<any, any, TBase>,
		selector: (val: TBase, param: TParam) => TVal
	);
	constructor(
		baseSelector: MemoizedSelector<any, TBase>,
		selector: (val: TBase, param: TParam) => TVal
	);
	constructor(baseSelector: MemoizedSelectorWithProps<any, TParam, TBase>);
	constructor(
		baseSelector: any,
		selector?: (val: TBase, param: TParam) => TVal
	) {
		if (baseSelector instanceof Selector) {
			this.selector = createSelector(
				baseSelector.selector as any,
				selector as any
			);
			return;
		}

		if (selector) {
			this.selector = createSelector(baseSelector, selector);
		} else {
			this.selector = baseSelector;
		}
	}
}
