<div class="wrapper" [@harmonica]="show">
	<mat-form-field
		[appearance]="appearance"
		[hideRequiredMarker]="hideRequired"
		[color]="color"
		[matTooltip]="tooltip"
		matTooltipPosition="above"
		[class.multiline]="!singleLine"
		[floatLabel]="active ? 'always' : 'auto'"
	>
		<mat-label>
			{{ label }}
			<i class="fad fa-question-circle" *ngIf="tooltip"></i>
		</mat-label>

		<textarea
			matInput
			class="!tw-hidden"
			[ngModel]="inputValue"
			[required]="required"
			(focusin)="editor.focus()"
		></textarea>

		<div
			#editor
			class="editor"
			[coInlineHtml]="buttonLayout"
			[readonly]="readonly"
			[disabled]="isDisabled || readonly"
			[control]="control"
			[autofocus]="autofocus"
			[placeholder]="''"
			[hidden]="true"
			(activated)="active = true"
			(deactivate)="active = false"
		></div>

		<mat-error *ngIf="hasErrors">{{ inputError }}</mat-error>
		<mat-hint *ngIf="hint">{{ hint }}</mat-hint>
	</mat-form-field>
</div>
