<div class="wrapper" [@harmonica]="show">
	<label [matTooltip]="tooltip" matTooltipPosition="above" *ngIf="label">
		<span class="tw-font-bold"> {{ label }} </span>
		<span class="required" *ngIf="required">*</span>
		<i class="fad fa-question-circle" *ngIf="tooltip"></i>
	</label>

	<div class="top">
		<i
			class="preview fa-2xl"
			[class]="modelValue"
			*ngIf="modelValue"
			[matTooltip]="modelValue"
			matTooltipPosition="above"
		></i>

		<mat-form-field>
			<mat-select [value]="iconWeight" (valueChange)="setWeight($event)">
				<mat-option value="far"> Regular </mat-option>
				<mat-option value="fal"> Light </mat-option>
				<mat-option value="fas"> Solid </mat-option>
				<mat-option value="fad"> Duotone </mat-option>
				<mat-option value="fab"> Brand </mat-option>
			</mat-select>
		</mat-form-field>

		<co-form-search-input
			[value]="query$.value"
			(valueChange)="query$.next($event)"
			label="Search Icons"
		/>
		<button
			class="clear-selection-button"
			mat-icon-button
			color="warn"
			[disabled]="!inputValue"
			(click)="selectIcon(null)"
		>
			<i class="far fa-circle-xmark"></i>
		</button>
	</div>

	<div class="icon-grid" *ngIf="icons.length">
		<div
			class="icon-tile tw-cursor-pointer"
			*ngFor="let icon of icons; trackBy: trackByIdentity"
			mat-ripple
			(click)="selectIcon(icon)"
			[class.selected]="icon === inputValue"
			[matTooltip]="icon"
		>
			<i class="{{ iconWeight ?? '' }} fa-{{ icon }}"></i>
		</div>
		<div
			class="more"
			*ngIf="moreIcons"
			matTooltip="There are more results. Specify your search further"
		>
			...
		</div>
	</div>
</div>
