import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	inject,
	Input,
	NgZone,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subject, map, combineLatest } from 'rxjs';
import { CoRiveCanvasDirective } from '../canvas.directive';
import { CoRiveStateMachineDirective } from '../state-machine.directive';
import {
	CoReportedRiveEvent,
	CoRiveFileContent,
	CoRiveCanvasAlignment,
	CoRiveCanvasFit,
} from '@consensus/co/domain-rive';
import { CoRiveLinearAnimationDirective } from '../animation.directive';
import { LetDirective, PushPipe } from '@ngrx/component';
import { CoSpinnerComponent } from '@consensus/co/ui-progress';
import { DOCUMENT, NgForOf, NgIf } from '@angular/common';
import { CoTrackByFunction } from '@consensus/co/util-control-flow';
import { CoRiveService } from '../rive.service';
import { CoRiveSMInputDirective } from '../state-machine-input.directive';
import { isOpenUrlEvent } from '../utils';
import { WINDOW } from '@ng-web-apis/common';
import { filterNonNullish } from '@consensus/co/util-rxjs';
import { Artboard } from '@rive-app/canvas-advanced';

@Component({
	selector: 'co-ui-rive-player',
	standalone: true,
	imports: [
		CoRiveCanvasDirective,
		CoRiveStateMachineDirective,
		LetDirective,
		CoSpinnerComponent,
		NgForOf,
		NgIf,
		PushPipe,
		CoRiveLinearAnimationDirective,
		CoRiveSMInputDirective,
	],
	templateUrl: './co-ui-rive-player.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [CoRiveService],
})
export class CoUiRivePlayerComponent implements OnInit, OnDestroy {
	readonly containerHeight$ = new BehaviorSubject<number>(100);
	readonly containerWidth$ = new BehaviorSubject<number>(100);
	readonly zone = inject(NgZone);
	readonly fileContent$ = new Subject<CoRiveFileContent>();
	readonly riveService = inject(CoRiveService);
	readonly #document = inject(DOCUMENT);
	readonly #window = inject(WINDOW);
	readonly scrollPercentage$ = new BehaviorSubject<number>(0);
	readonly currentArtboard$ = new Subject<Artboard>();
	readonly currentArtboardContent$ = combineLatest([
		this.currentArtboard$,
		this.fileContent$,
	]).pipe(
		map(([artboard, fileContent]) => {
			const curArtboardContent = fileContent.artboards.find(
				elem => elem.name === artboard.name
			);
			return curArtboardContent;
		}),
		filterNonNullish
	);
	observer!: ResizeObserver;

	readonly setFileContent = (contents: CoRiveFileContent) => {
		this.fileContent$.next(contents);
	};

	/**
	 * @param riveFile When providing Blob or File, the file is used directly.
	 * When providing a string it should be a file url, which the file will be
	 * fetched from.
	 */
	@Input({ required: true }) riveFile!: string | Blob | File;

	@Input() autoplayAnimation = false;

	/** Should the player handle URL events or leave it to its parent */
	@Input() handleOpenUrlEvents = true;

	/** Fit determines how the Rive content will be fitted to the view */
	@Input() fit: CoRiveCanvasFit = 'scaleDown';

	/** Fit determines how the Rive content will be fitted to the view */
	@Input() alignment: CoRiveCanvasAlignment = 'center';

	/** trackScroll */
	@Input() trackScroll = true;

	// TODO: For now a hardcoded list, will be changed later once a proper CMS for configuring animations is added [MAB]
	@Input() scrollInputs: string[] = ['scroll'];

	@Input() inputValues: { [key: string]: number | boolean } = {};

	@ViewChild('canvasContainer', { static: true })
	canvasContainer!: ElementRef;

	@Output() reportedEventsChange = new EventEmitter<CoReportedRiveEvent[]>();
	@Output() fileContentsRead = this.fileContent$;

	ngOnInit(): void {
		const width = this.canvasContainer.nativeElement.offsetWidth;
		const height = this.canvasContainer.nativeElement.offsetHeight;
		if (height > 0) {
			this.containerHeight$.next(height);
		}
		if (width > 0) {
			this.containerWidth$.next(width);
		}

		this.observer = new ResizeObserver(entries => {
			this.zone.run(() => {
				this.containerHeight$.next(entries[0].contentRect.height);
				this.containerWidth$.next(entries[0].contentRect.width);
			});
		});
		this.observer.observe(this.canvasContainer.nativeElement);

		// Set initial scroll position
		this.handleScroll();
	}

	ngOnDestroy() {
		this.observer.unobserve(this.canvasContainer.nativeElement);
	}

	readonly trackByIdentity: CoTrackByFunction = (
		_index: number,
		value: unknown
	) => value;

	onReportedEventsChange(events: CoReportedRiveEvent[]) {
		console.log('onReportedEventsChange', { events }); // TODO: Remove [MAB]
		this.reportedEventsChange.emit(events);
		/** If enabled, handle open url events directly in the player */
		if (this.handleOpenUrlEvents) {
			const openUrlEvent = events.find(event => isOpenUrlEvent(event)) ?? null;
			if (isOpenUrlEvent(openUrlEvent)) {
				window.open(openUrlEvent.url, openUrlEvent.target);
			}
		}
	}

	@HostListener('window:scroll', ['$event']) handleScroll() {
		if (!this.trackScroll) {
			return;
		}
		const scrollPercentage = Math.round(
			(this.#window.scrollY /
				(this.#document.documentElement.scrollHeight -
					this.#window.innerHeight)) *
				100
		);
		this.scrollPercentage$.next(scrollPercentage);
	}

	onArtboardChange(artboard: Artboard) {
		this.currentArtboard$.next(artboard);
	}
}
