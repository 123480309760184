import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, mergeMap } from 'rxjs';
import { AuthedUser } from '@shared/models';
import { login } from '@store/user';
import { logOut } from '@store/global';
import { PushNotificationRegistrationService } from './push-notifications.service';

export const registerForPushNotifications = createEffect(
	(
		actions$ = inject(Actions),
		service = inject(PushNotificationRegistrationService)
	) =>
		actions$.pipe(
			ofType(login),
			mergeMap(action => {
				if (action.payload) {
					const user: AuthedUser = action.payload;
					return service.register(user.id);
				}
				return EMPTY;
			})
		),
	{ dispatch: false, functional: true }
);

export const deregisterForPushNotifications = createEffect(
	(
		actions$ = inject(Actions),
		service = inject(PushNotificationRegistrationService)
	) =>
		actions$.pipe(
			ofType(logOut),
			mergeMap(_action => service.deregister())
		),
	{ dispatch: false, functional: true }
);
