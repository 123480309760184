import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TokenService {
	getLoginToken() {
		const spoofToken = localStorage.getItem('spoofToken');
		if (spoofToken) {
			return spoofToken;
		}

		return localStorage.getItem('loginToken');
	}

	setLoginToken(jwt: string) {
		localStorage.setItem('loginToken', jwt);
	}
}
