import { TinyColor, mostReadable } from '@ctrl/tinycolor';
import { forOwn } from 'lodash-es';

type ColorName =
	| '50'
	| '100'
	| '200'
	| '300'
	| '400'
	| '500'
	| '600'
	| '700'
	| '800'
	| '900'
	| 'A100'
	| 'A200'
	| 'A400'
	| 'A700'
	| 'contrast'
	| 'contrast-dark'
	| 'contrast-light';

export class ThemeColor {
	colors: { [name: string]: TinyColor } = {};

	static getColorObject(
		color: string,
		contrast: string
	): { [name: string]: TinyColor } {
		return {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'50': new TinyColor(color).lighten(52),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'100': new TinyColor(color).lighten(37),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'200': new TinyColor(color).lighten(26),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'300': new TinyColor(color).lighten(12),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'400': new TinyColor(color).lighten(6),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'500': new TinyColor(color),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'600': new TinyColor(color).darken(6),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'700': new TinyColor(color).darken(12),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'800': new TinyColor(color).darken(18),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'900': new TinyColor(color).darken(24),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			A100: new TinyColor(color).lighten(50).saturate(30),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			A200: new TinyColor(color).lighten(30).saturate(30),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			A400: new TinyColor(color).lighten(10).saturate(15),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			A700: new TinyColor(color).lighten(5).saturate(5),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'contrast-dark': new TinyColor(contrast).darken(24),
			contrast: new TinyColor(contrast),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'contrast-light': new TinyColor(contrast).lighten(24),
		};
	}

	constructor(color: string, contrast: string | null = null) {
		if (!contrast) {
			contrast =
				mostReadable(color, [
					'#FFFFFF',
					'#939498',
					'#202020',
					'#000000',
				])?.toHexString() ?? color;
		}

		this.colors = ThemeColor.getColorObject(color, contrast);
	}

	getHex(name: ColorName) {
		return this.colors[name]?.toHexString();
	}

	storeColors(colorName: string) {
		forOwn(this.colors, (c, k) => {
			const key = `--theme__${colorName}-${k}`;
			const value = c.toHexString();
			// TODO(@LayZeeDK): [no-restricted-globals] Rewrite this method to an
			//  Angular service at some point
			// eslint-disable-next-line no-restricted-globals
			document.documentElement.style.setProperty(key, value);
		});
	}
}
