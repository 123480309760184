import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
	CreateQaQuestionModel,
	QaLoadModel,
	QaQuestion,
	UpdateQaQuestionModel,
} from './qa.model';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class QaClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #httpClient = inject(HttpClient);

	loadUserQa() {
		return this.#httpClient.get<QaLoadModel>(`${this.#apiServer}/api/qa`);
	}

	loadUserQuestions() {
		return this.#httpClient.get<QaQuestion[]>(
			`${this.#apiServer}/api/qa/questions/me`
		);
	}

	loadPublicQuestions() {
		return this.#httpClient.get<QaQuestion[]>(
			`${this.#apiServer}/api/qa/questions/public`
		);
	}

	createUserQuestion(data: CreateQaQuestionModel) {
		return this.#httpClient.post<QaQuestion>(
			`${this.#apiServer}/api/qa/question`,
			data
		);
	}

	updateUserQuestion({ id, ...data }: UpdateQaQuestionModel) {
		return this.#httpClient.put<QaQuestion>(
			`${this.#apiServer}/api/qa/question/${id}`,
			data
		);
	}

	deleteUserQuestion(id: string) {
		return this.#httpClient.delete(`${this.#apiServer}/api/qa/question/${id}`);
	}
}
