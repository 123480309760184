<ng-container *ngIf="src">
	<img
		*ngIf="!loading && hasImage"
		[class.rendering]="rendering"
		(load)="onLoad()"
		(error)="onError($event)"
		[src]="failed ? fallback : (safeUrl | coBypassSecurityTrustResourceUrl)"
		[alt]="alt"
		draggable="false"
	/>

	<div class="placeholder" *ngIf="!failed && isLoading"></div>

	<div class="error" *ngIf="showErrors">
		<i class="fal fa-file-image"></i>
		<p>{{ alt || "Image is missing" }}</p>
	</div>
</ng-container>
