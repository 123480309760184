import { CustomStore } from '@lib/redux';
import { Livestream, LivestreamState } from './livestream.model';
import { LivestreamClient } from './livestream.client';
import { MoveModel } from '@shared/models';
import { sortBySortingKeyAsc } from '@lib/helpers';

export const livestreamKey = 'livestreams';

export const livestreamStore = new CustomStore<
	LivestreamState,
	LivestreamClient
>('Event Livestreams', { livestreams: [] }, LivestreamClient, 'Event');

const store = livestreamStore;

export const loadLivestreams = store
	.addApiAction('Livestreams', 'Load')
	.withError()
	.isInitial()
	.parseDates()
	.withEffect(s => s.loadUserLivestreams)
	.withReducer(livestreams => ({ livestreams }));

store
	.addSocketAction<Livestream>('Livestreams', 'Livestream', 'Set')
	.parseDates()
	.set('livestreams');

store
	.addSocketAction<string>('Livestreams', 'Livestream', 'Remove')
	.deleteWithIndex('livestreams', x => x.id);

store
	.addSocketAction<MoveModel>('Livestreams', 'Livestream', 'Move')
	.move('livestreams', x => x.id);

export const getLivestreams = store.addSelector(x =>
	[...(x?.livestreams ?? [])].sort(sortBySortingKeyAsc)
);
