import { inject, Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { versionToken } from './version.token';
import { BehaviorSubject, filter } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VersionService {
	readonly #version = inject(versionToken);

	localVersion = this.#version.version.trim().replace('v', '');

	serverVersion$ = new BehaviorSubject<string | null>(null);
	get serverVersion() {
		return this.serverVersion$.value;
	}

	hostServer$ = new BehaviorSubject<string | null>(null);
	get hostServer() {
		return this.hostServer$.value;
	}

	shouldReload$ = new BehaviorSubject(false);

	constructor() {
		this.serverVersion$.pipe(filter(v => !!v)).subscribe(() => {
			this.shouldReload$.next(this.needReload());
		});
	}

	needReload(): boolean {
		if (Capacitor.isNativePlatform()) {
			// Allow console.info usage
			// eslint-disable-next-line no-restricted-syntax
			console.info('SKIP Reload step due to iOS', {
				local: this.localVersion,
				server: this.serverVersion,
			});

			/** Don't trigger reload flow for mobile builds */
			return false;
		}

		// https://app.meticulous.ai/docs/how-to/window-meticulous-object
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		if ((window as any).Meticulous?.isRunningAsTest) {
			return false;
		}

		if (this.localVersion === this.serverVersion) {
			return false;
		}

		console.log(
			`Refresh requested, Frontend version is: ${this.localVersion}, Backend version is: ${this.serverVersion}`
		);

		return true;
	}
}
