import { ThemesState } from './themes.model';
import { CustomStore } from '@lib/redux';
import { ThemingClient } from './theming.client';
import { updateListItem } from '@lib/redux';

export const themeRepoStore = new CustomStore<ThemesState, ThemingClient>(
	'Theme Repo',
	{ themes: [] },
	ThemingClient,
	'Global'
);

export const loadThemes = themeRepoStore
	.addApiAction('Load', 'Themes')
	.withError()
	.withEffect(s => s.getThemes)
	.withReducer(themes => ({ themes }));

export const createTheme = themeRepoStore
	.addApiAction('Create', 'Theme')
	.withError()
	.withEffect(s => s.createTheme)
	.withReducer((theme, { themes }) => ({ themes: [...themes, theme] }));

export const updateTheme = themeRepoStore
	.addApiAction('Update', 'Theme')
	.withError()
	.withEffect(s => s.updateTheme)
	.withReducer((theme, { themes }) => ({
		themes: updateListItem(themes, x => x.id == theme.id, theme),
	}));

export const getThemes = themeRepoStore.baseSelector.create(s => s.themes);
