import { ErrorHandler, FactoryProvider, Provider } from '@angular/core';
import { ConnectEnvironment } from '@environments/environment';
import { environmentToken } from '@environments/environment';
import { sentryInitializer } from './sentry.initializer';
import { SentryAngular, sentryToken } from './sentry.token';

function sentryErrorHandlerFactory(
	sentry: SentryAngular,
	environment: ConnectEnvironment
): ErrorHandler {
	return environment.sentry.enabled
		? sentry.createErrorHandler({
				showDialog: environment.sentry.showErrorDialog,
		  })
		: new ErrorHandler();
}

const sentryErrorHandlerProvider: FactoryProvider = {
	provide: ErrorHandler,
	deps: [sentryToken, environmentToken],
	useFactory: sentryErrorHandlerFactory,
};

export function provideSentry(): Provider[] {
	return [sentryInitializer, sentryErrorHandlerProvider];
}
