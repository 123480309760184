import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { SubmitWordCloudDto } from './submit-word-cloud-dto';
import { WordCloudSubmission } from '@consensus/connect/ufa/word-cloud/domain';
import { environmentToken } from '@environments/environment';

@Injectable({
	providedIn: 'root',
})
export class WordCloudClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #http = inject(HttpClient);

	getWordCloudUserSubmissions(
		wordCloudId: string
	): Observable<WordCloudSubmission[]> {
		return this.#http.get<WordCloudSubmission[]>(
			`${this.#apiServer}/api/wordcloud/${wordCloudId}/user-submissions`
		);
	}

	submitWordCloudWord({
		wordCloudId,
		...data
	}: SubmitWordCloudDto): Observable<WordCloudSubmission> {
		return this.#http.post<WordCloudSubmission>(
			`${this.#apiServer}/api/wordcloud/${wordCloudId}/words`,
			data
		);
	}
}
