import { inject, Injectable } from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { environmentToken } from '@environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	readonly #mediaServer = inject(environmentToken).mediaServer;

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		return request.url.startsWith(this.#mediaServer)
			? next.handle(request)
			: next.handle(request).pipe(
					catchError((error: HttpErrorResponse) => {
						throw error;
					})
			  );
	}
}
