import { Injectable } from '@angular/core';
import { EliminationGameState } from '@consensus/connect/shared/elimination-game/domain';
import { Subject } from 'rxjs';
import { CmsEliminationGameResultState } from './cms-elimination-game.model';

@Injectable({ providedIn: 'root' })
export class CmsEliminationGameService {
	eliminationGameStates$ = new Subject<Partial<EliminationGameState>>();
	resultStates$ = new Subject<CmsEliminationGameResultState>();
}
