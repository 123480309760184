import { CmsEliminationGameState } from './cms-elimination-game.model';
import { groupBy } from 'lodash-es';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { sortBySortingKeyAsc } from '@lib/helpers';

export const eliminationGameKey = 'eliminationGame';

interface CmsState {
	[eliminationGameKey]: CmsEliminationGameState;
}

const selectCmsState = createFeatureSelector<CmsState>('manage');

export const selectCmsEliminationGameState = createSelector(
	selectCmsState,
	state => state[eliminationGameKey]
);

export const selectCmsEliminationGames = createSelector(
	selectCmsEliminationGameState,
	state => {
		const sorted = groupBy(
			[...state.eliminationGameItems].sort(sortBySortingKeyAsc),
			i => i.eliminationGameId ?? ''
		);
		return [...state.eliminationGames]
			.sort(sortBySortingKeyAsc)
			.map(x => ({ ...x, items: sorted[x.id] || [] }));
	}
);
