import { Actions, createEffect } from '@ngrx/effects';
import { ApiEffects } from '@lib/redux';
import {
	advanceEliminationGame,
	duplicateEliminationGame,
	createEliminationGame,
	createEliminationGameItem,
	deleteEliminationGame,
	deleteEliminationGameItem,
	loadAllEliminationGames,
	moveEliminationGame,
	moveEliminationGameItem,
	startEliminationGame,
	stopEliminationGame,
	updateEliminationGame,
	updateEliminationGameItem,
	updateEliminationGameState,
} from './cms-elimination-game.actions';
import { CmsEliminationGameClient } from './cms-elimination-game.client';
import { CmsEliminationGameState } from './cms-elimination-game.model';
import { selectCmsEliminationGameState } from './cms-elimination-game.selectors';
import { inject, Injectable } from '@angular/core';

@Injectable()
export class CmsEliminationGameEffects extends ApiEffects<CmsEliminationGameState> {
	readonly #client = inject(CmsEliminationGameClient);
	readonly #actions$ = inject(Actions);
	constructor() {
		super(selectCmsEliminationGameState);
	}

	loadAllEliminationGames$ = createEffect(() =>
		this.generateApiEffect(loadAllEliminationGames, () =>
			this.#client.loadAllEliminationGames()
		)(this.#actions$)
	);

	createEliminationGame$ = createEffect(() =>
		this.generateApiEffect(createEliminationGame, action =>
			this.#client.createEliminationGame(action.payload)
		)(this.#actions$)
	);

	updateEliminationGame$ = createEffect(() =>
		this.generateApiEffect(updateEliminationGame, action =>
			this.#client.updateEliminationGame(action.payload)
		)(this.#actions$)
	);

	duplicateEliminationGame$ = createEffect(() =>
		this.generateApiEffect(duplicateEliminationGame, action =>
			this.#client.duplicateEliminationGame(action.payload)
		)(this.#actions$)
	);

	deleteEliminationGame$ = createEffect(() =>
		this.generateApiEffect(deleteEliminationGame, action =>
			this.#client.deleteEliminationGame(action.payload)
		)(this.#actions$)
	);

	moveEliminationGame$ = createEffect(() =>
		this.generateApiEffect(moveEliminationGame, action =>
			this.#client.moveEliminationGame(action.payload)
		)(this.#actions$)
	);

	createPushoutItem$ = createEffect(() =>
		this.generateApiEffect(createEliminationGameItem, action =>
			this.#client.createEliminationGameItem(action.payload)
		)(this.#actions$)
	);

	updateEliminationGameItem$ = createEffect(() =>
		this.generateApiEffect(updateEliminationGameItem, action =>
			this.#client.updateEliminationGameItem(action.payload)
		)(this.#actions$)
	);

	deleteEliminationGameItem$ = createEffect(() =>
		this.generateApiEffect(deleteEliminationGameItem, action =>
			this.#client.deleteEliminationGameItem(action.payload)
		)(this.#actions$)
	);

	moveEliminationGameItem$ = createEffect(() =>
		this.generateApiEffect(moveEliminationGameItem, action =>
			this.#client.moveEliminationGameItem(action.payload)
		)(this.#actions$)
	);

	startEliminationGame$ = createEffect(() =>
		this.generateApiEffect(startEliminationGame, action =>
			this.#client.startEliminationGame(action.payload)
		)(this.#actions$)
	);

	stopEliminationGame$ = createEffect(() =>
		this.generateApiEffect(stopEliminationGame, action =>
			this.#client.stopEliminationGame(action.payload)
		)(this.#actions$)
	);

	advanceEliminationGame$ = createEffect(() =>
		this.generateApiEffect(advanceEliminationGame, action =>
			this.#client.nextItem(action.payload)
		)(this.#actions$)
	);

	updateEliminationGameState$ = createEffect(() =>
		this.generateApiEffect(updateEliminationGameState, action =>
			this.#client.updateEliminationGameState(action.payload)
		)(this.#actions$)
	);
}
