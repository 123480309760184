import { Provider, importProvidersFrom } from '@angular/core';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';

/**
 * @returns Providers required for Angular Material Timepicker
 */
export function provideNgxMatTimepicker(): Provider[] {
	/**
	 * Unwrap environment providers to allow as component-level providers.
	 *
	 * This is done to prevent overriding `MAT_FAB_DEFAULT_OPTIONS` at the root
	 * level.
	 */
	return (
		/**
		 * `ɵproviders` is an *internal* property used by Angular for environment
		 * providers.
		 */
		(importProvidersFrom(NgxMatTimepickerModule) as any)
			.ɵproviders as unknown as Provider[]
	);
}
