import { Provider, ValueProvider } from '@angular/core';
import { ConnectEnvironment } from './connect-environment';
import { environmentToken } from './environment.token';

export function provideEnvironment(
	environment: ConnectEnvironment
): Provider[] {
	const environmentProvider: ValueProvider = {
		provide: environmentToken,
		useValue: environment,
	};

	return [environmentProvider];
}
