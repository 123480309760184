/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import {
	AfterViewInit,
	Component,
	ElementRef,
	inject,
	Input,
	OnDestroy,
	ViewChild,
} from '@angular/core';
import { InputBaseComponent } from '@lib/forms';
import { Subscription } from 'rxjs';
import { UploadScope } from '@lib/files';
import { validateMimeType } from '@lib/files';
import { CoSnackService } from '@consensus/co/ui-snackbars';
import { harmonicaAnimation } from '@consensus/co/ui-component-animations';
import { MatButtonModule } from '@angular/material/button';
import { FileDropDirective } from '@shared/directives';
import { NgIf } from '@angular/common';

@Component({
	selector: 'co-form-file-input',
	templateUrl: './file-input.component.html',
	styleUrls: ['./file-input.component.scss'],
	animations: [harmonicaAnimation()],
	standalone: true,
	imports: [NgIf, FileDropDirective, MatButtonModule],
})
export class FileInputComponent
	extends InputBaseComponent<File>
	implements AfterViewInit, OnDestroy
{
	readonly #snacks = inject(CoSnackService);
	@ViewChild('fileInput', { static: true })
	fileField: ElementRef<HTMLInputElement>;
	file: File;
	sub = new Subscription();

	@Input() accepts = UploadScope.All;

	ngAfterViewInit() {
		this.sub.add(
			this.control?.valueChanges.subscribe((x: File) => {
				this.fileField.nativeElement.value = '';
				this.file = x;
			})
		);
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.sub.unsubscribe();
	}

	onFileChange() {
		const file = this.fileField.nativeElement.files?.[0] ?? null;
		if (!validateMimeType(this.accepts, file.type)) {
			this.#snacks.error(
				`The filetype '${file.type}' is not supported`,
				'Unsupported file type'
			);
			return;
		}
		this.file = file;
		this.control?.patchValue(this.file);
	}

	onDrop(event: DragEvent) {
		const file = event.dataTransfer.files?.[0] ?? null;
		if (!validateMimeType(this.accepts, file.type)) {
			this.#snacks.error(
				`The filetype '${file.type}' is not supported`,
				'Unsupported file type'
			);
			return;
		}
		this.file = file;
		this.control?.patchValue(this.file);
	}
}
