/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { DOCUMENT, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { first } from 'rxjs';
import { VersionService } from '@environments/environment';
import { fadeAnimation } from '@consensus/co/ui-component-animations';
import { BaseComponent } from '@shared/component-bases';
import { UpdateRequiredComponent } from './update-required/update-required.component';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';

@Component({
	standalone: true,
	selector: 'app-splash-screen',
	imports: [NgIf, RouterOutlet, UpdateRequiredComponent],
	templateUrl: './splash-screen.component.html',
	styleUrls: ['./splash-screen.component.scss'],
	animations: [fadeAnimation],
})
export class SplashScreenComponent
	extends BaseComponent
	implements OnInit, OnDestroy
{
	readonly #versionService = inject(VersionService);
	readonly #document = inject(DOCUMENT);

	updateRequired = false;
	version: string;
	loaded = false;

	onInit() {
		this.addSub(
			this.#versionService.serverVersion$.pipe(first(v => !!v)).subscribe(v => {
				this.version = v;
				this.#document
					.getElementById('splash-screen')
					.setAttribute('hidden', '');
				// If we are in a Capacitor context, hide the Capacitor splash screen
				if (Capacitor.isPluginAvailable('SplashScreen')) {
					setTimeout(() => SplashScreen.hide(), 500);
				}
			})
		);

		this.addSub(
			this.#versionService.shouldReload$.subscribe(
				b => (this.updateRequired = b)
			)
		);
	}
}
