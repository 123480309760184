import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Survey, SurveySection } from '@consensus/connect/shared/survey/domain';

import {
	CmsSurveyEditorSectionFileTypePatch,
	CmsSurveyEditorFormData,
} from '@consensus/connect/cms/survey/domain';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class CmsSurveyClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #http = inject(HttpClient);

	patchSectionFileType({ id, ...data }: CmsSurveyEditorSectionFileTypePatch) {
		return this.#http.patch<SurveySection>(
			`${this.#apiServer}/api/surveys/sections/${id}/filetype`,
			data
		);
	}

	updateSurvey({
		sessionItemId,
		data,
	}: {
		sessionItemId: string;
		data: CmsSurveyEditorFormData;
	}) {
		const survey: CmsSurveyEditorFormData = {
			...data,
			submitOnPageChange: true,
		};
		return this.#http.put<Survey>(
			`${this.#apiServer}/api/surveys/${sessionItemId}`,
			survey
		);
	}
}
