import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Popup, PopupButton } from './popup-types';

/**
 * @deprecated ❌ `@consensus/legacy/ui-popup` is deprecated.
 * Use MatDialog from Angular Material instead.
 */
@Injectable({ providedIn: 'root' })
export class PopupService {
	#queue: Popup[] = [];
	get popup() {
		return this.#queue.length > 0 ? this.#queue[0] : null;
	}

	popup$ = new BehaviorSubject<Popup | null>(null);

	confirm(
		text: string,
		title: string | null = null,
		confirmText: string | null = null,
		cancelText: string | null = null
	): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			this.#queue.push({
				text: text,
				title: title ?? 'Are you sure?',
				buttons: [
					{
						text: cancelText ?? 'Cancel',
						resolve: () => resolve(false),
					},
					{
						text: confirmText ?? 'Confirm',
						color: 'primary',
						resolve: () => resolve(true),
					},
				],
			});
			this.#updated();
		});
	}

	plain(
		text: string,
		title: string | null = null,
		closeText: string | null = null
	): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			this.#queue.push({
				text: text,
				title: title,
				buttons: [
					{
						text: closeText ?? 'Close',
						resolve: () => resolve(true),
					},
				],
			});
			this.#updated();
		});
	}

	delete(
		text: string,
		title: string | null,
		deleteText: string | null = null,
		cancelText: string | null = null
	): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			this.#queue.push({
				text: text,
				title: title,
				buttons: [
					{
						text: cancelText ?? 'Cancel',
						resolve: () => resolve(false),
					},
					{
						text: deleteText ?? 'Delete',
						color: 'warn',
						resolve: () => resolve(true),
					},
				],
			});
			this.#updated();
		});
	}

	clickButton(button: PopupButton) {
		const popupIndex = this.#queue.findIndex(p => p.buttons.includes(button));
		if (popupIndex === -1) {
			return;
		}

		const popup = this.#queue[popupIndex];

		if (popup.loading) {
			return;
		}

		const res = button.resolve();

		if (res instanceof Promise) {
			popup.loading = true;
			res.then(
				() => {
					const i = this.#queue.findIndex(p => p.buttons.includes(button));
					if (i === -1) {
						return;
					}
					this.#queue.splice(i, 1);
					this.#updated();
				},
				() => (popup.loading = false)
			);
		} else if (res === undefined || res) {
			this.#queue.splice(popupIndex, 1);
			this.#updated();
		}
	}

	#updated() {
		if (this.popup !== this.popup$.value) {
			this.popup$.next(this.popup);
		}
	}
}
