import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { isSupportedLocale, SupportedLocale } from './utils';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class LocaleService {
	#eventLocale: SupportedLocale | null = null;
	#defaultLocale: SupportedLocale = SupportedLocale.EnGb;
	#rawDeviceLocale: string = this.#defaultLocale;
	#initializedLocales: string[] = [];
	#timezone: string | null = null;

	set timezone(value: string | null) {
		this.#timezone = value;
	}

	/** Relevant timezone, either from event or from user's device */
	get timezone(): string {
		return this.#timezone
			? this.#timezone
			: new Intl.DateTimeFormat().resolvedOptions().timeZone;
	}

	get timezoneInUtc(): string {
		return `UTC ${moment.tz(this.timezone).format('Z')}`;
	}

	/** The device locale as provided by navigator.language */
	get rawDeviceLocale(): string {
		if (navigator.language !== this.#rawDeviceLocale) {
			// If the locale is new/changed, rerun initialization
			this.initRawDeviceLocale();
		}
		return this.#rawDeviceLocale;
	}

	set eventLocale(value: SupportedLocale | null) {
		this.#eventLocale = value;
	}

	/** The currently active locale, as defined by event locale setting if available, else 'safe' device locale */
	get eventLocale(): SupportedLocale {
		return this.#eventLocale ?? this.safeDeviceLocale;
	}

	/**
	 * The device locale with fallback to the default locale,
	 * if the raw device locale is not on the list of supported locales
	 */
	get safeDeviceLocale(): SupportedLocale {
		const rawDeviceLocale = this.rawDeviceLocale;
		return isSupportedLocale(rawDeviceLocale)
			? rawDeviceLocale
			: this.#defaultLocale;
	}

	/**
	 * Set rawDeviceLocale and import Angular locale if needed
	 *
	 * @returns locale string
	 */
	initRawDeviceLocale(): string {
		const locale = navigator.language || this.#defaultLocale;
		this.#rawDeviceLocale = locale;
		const localeId = locale.substring(0, 2);
		// Before importing, check if locale has already been imported
		if (!this.#initializedLocales.includes(localeId)) {
			this.localeInitializer(localeId);
			this.#initializedLocales.push(localeId);
		}
		return this.#rawDeviceLocale;
	}

	/**
	 * Dynamic import of Angular locale
	 *
	 * @param localeId
	 * @returns void
	 */
	localeInitializer(localeId: string): Promise<any> {
		return import(
			/* webpackMode: "lazy-once" */
			/* webpackChunkName: "i18n-base" */
			`../../../../../../../node_modules/@angular/common/locales/${localeId}.mjs`
		).then(module => registerLocaleData(module.default));
	}
}
