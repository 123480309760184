import { Injectable } from '@angular/core';
import { AcademyGlossaryEntry } from '@consensus/academy/courses/domain';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GlossarySelectService {
	interface: GlossarySelectInterface;
	query$ = new Subject<string>();

	openSelect(
		query: string,
		onSelect: (entry: AcademyGlossaryEntry) => void,
		onClose: () => void
	) {
		if (this.interface) {
			this.interface.close();
		}

		this.interface = new GlossarySelectInterface(onSelect, () => {
			onClose();
			this.interface = null;
		});

		this.query$.next(query);

		return this.interface;
	}
}

export class GlossarySelectInterface {
	constructor(
		private onSelect: (entry: AcademyGlossaryEntry) => void,
		private onClose: () => void
	) {}

	selectEntry(entry: AcademyGlossaryEntry) {
		this.onSelect(entry);
	}

	close() {
		this.onClose();
	}
}
