import {
	Route,
	Routes,
	UrlMatchResult,
	UrlSegment,
	UrlSegmentGroup,
} from '@angular/router';
import {
	CAPACITOR_IN_APP_BROWSER_REDIRECT_ROUTE,
	CapacitorInAppBrowserRedirectComponent,
} from '@consensus/connect/ufa/capacitor/util-safe-links';
import {
	isAdminGuard,
	leaveClientGuard,
	leaveEventGuard,
	loggedInGuard,
	notLoggedInGuard,
	verifyLoginGuard,
} from '@core/guards';
import { loggedInAcademyGuard } from './academy/shared/guards/logged-in-academy.guard';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';

function startsWithMatcher(...strings: string[]) {
	return (segments: UrlSegment[], _group: UrlSegmentGroup, _route: Route) => {
		if (segments.length < 1) {
			return null;
		}
		const segment = segments[0].path.toLowerCase();
		if (!strings.some(x => x.toLowerCase() == segment)) {
			return null;
		}
		return { consumed: [] } as UrlMatchResult;
	};
}

export const appRoutes: Routes = [
	{
		path: 'error',
		loadComponent: () => import('./error-page/error-page.component'),
	},
	{
		path: CAPACITOR_IN_APP_BROWSER_REDIRECT_ROUTE,
		component: CapacitorInAppBrowserRedirectComponent,
	},
	{
		path: '',
		component: SplashScreenComponent,
		canActivateChild: [verifyLoginGuard],
		data: { backLevel: 1 },
		children: [
			{
				path: 'cms/academy',
				loadChildren: () =>
					import('./academy/cms/academy-cms.routes').then(
						m => m.academyCmsRoutes
					),
				canActivate: [loggedInAcademyGuard],
				data: { isCms: true },
			},
			{
				path: 'cms',
				loadChildren: () =>
					import('./cms/cms/cms.routes').then(m => m.cmsRoutes),
				canActivate: [isAdminGuard],
				data: { isCms: true },
			},
			{
				matcher: startsWithMatcher('client', 'event'),
				canDeactivate: [leaveClientGuard],
				canActivate: [loggedInGuard],
				children: [
					{
						path: 'client',
						loadChildren: () =>
							import('./site/client/client.routes').then(m => m.clientRoutes),
					},
					{
						path: '',
						canDeactivate: [leaveEventGuard],
						children: [
							{
								path: 'event',
								loadChildren: () =>
									import('./site/event/event.routes').then(m => m.eventRoutes),
							},
						],
					},
				],
			},
			{
				path: '',
				loadChildren: () =>
					import('@login/login.module').then(m => m.loginRoutes),
				canActivate: [notLoggedInGuard],
			},
		],
	},
];
