import { loadSupport, loadSupportById } from './support.actions';
import { ApiReducer } from '@lib/redux';
import { initialBaseState } from '@shared/models';
import { ConnectSupportState } from '@consensus/connect/shared/support/domain';

const initialState: ConnectSupportState = {
	...initialBaseState,
	support: {
		id: '',
		email: '',
		phoneNumber: '',
		sections: [],
	},
	faqs: [],
	sections: [],
};

const reducer = new ApiReducer(initialState, 'Event');

reducer.addApiReducer(loadSupport);
reducer.addApiReducer(loadSupportById);

export const supportReducer = reducer.getReducer();
