<div class="wrapper" *ngIf="showHigh && showLow" [@harmonica]="!isDisabled">
	<co-form-base-range-input
		[label]="label"
		[startControl]="startControl"
		[endControl]="endControl"
		[max]="max"
		[step]="step"
		[min]="min"
		[visualStep]="visualStep"
		[readonly]="readonly"
	/>
</div>

<co-form-slider-input
	*ngIf="showLow && !showHigh"
	[max]="max"
	[min]="min"
	[step]="step"
	[visualStep]="visualStep"
	[control]="startControl"
	[@harmonica]="!disabled"
	[readonly]="readonly"
/>
<co-form-slider-input
	*ngIf="!showLow && showHigh"
	[max]="max"
	[min]="min"
	[step]="step"
	[visualStep]="visualStep"
	[control]="endControl"
	[@harmonica]="!disabled"
	[readonly]="readonly"
/>
