import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UfaPushoutService {
	closedIds$ = new BehaviorSubject<string[]>([]);
	get closedIds() {
		return this.closedIds$.value;
	}

	submittedIds$ = new BehaviorSubject<string[]>([]);
	get submittedIds() {
		return this.submittedIds$.value;
	}

	closePushout(pushoutId: string) {
		this.closedIds$.next([...this.closedIds, pushoutId]);
	}

	removeClosedPushout(pushoutId: string) {
		const filteredIds = this.closedIds.filter(x => x !== pushoutId);
		this.closedIds$.next(filteredIds);
	}

	deleteAnswers(pushoutId: string) {
		const filteredIds = this.submittedIds.filter(x => x !== pushoutId);
		this.submittedIds$.next(filteredIds);
	}

	submitPushout(pushoutId: string) {
		this.submittedIds$.next([...this.submittedIds, pushoutId]);
	}
}
