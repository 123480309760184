/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InputBaseComponent } from '@lib/forms';
import { harmonicaAnimation } from '@consensus/co/ui-component-animations';

@Component({
	standalone: true,
	selector: 'co-form-password-input',
	imports: [
		NgIf,
		ReactiveFormsModule,
		MatButtonModule,
		MatInputModule,
		MatTooltipModule,
	],
	templateUrl: './password-input.component.html',
	styleUrls: ['./password-input.component.scss'],
	animations: [harmonicaAnimation()],
})
export class PasswordInputComponent extends InputBaseComponent<string> {
	showPassword = false;

	toggleShow(_e: Event) {
		this.showPassword = !this.showPassword;
		this.inputElement?.nativeElement?.focus();
	}
}
