import {
	activatePushout,
	activatePushoutResults,
	createAgenda,
	createSession,
	createSessionItem,
	createVote,
	deactivatePushout,
	deactivatePushoutResults,
	deleteAgenda,
	deleteSession,
	deleteSessionItem,
	duplicateSession,
	duplicateSessionItem,
	loadAgendas,
	loadSessionItemPushouts,
	moveAgenda,
	moveSession,
	moveSessionItem,
	sessionItemActiveChanged,
	toggleSessionItem,
	updateAgenda,
	updateAgendaSessionsQaEnabled,
	updateConnected,
	updateFlashcardSet,
	updatePushoutLink,
	upsertSelfie,
	updateSession,
	updateSessionItem,
	updateSessionItemPushouts,
	updateSurvey,
	updateVote,
	createWordCloud,
	createBrainstorm,
} from './agenda.actions';
import { AgendaState } from './agenda.model';
import { initialBaseState } from '@shared/models';
import { ApiReducer } from '@lib/redux';
import {
	moveListItem,
	removeListItemWithIndex,
	updateListItem,
} from '@lib/redux';
import { SessionItem } from '@consensus/connect/shared/agenda/domain';
import { isNonNullish } from '@consensus/co/util-types';

const initialState: AgendaState = {
	...initialBaseState,
	agendas: [],
	sessions: [],
	sessionItems: [],
};

const reducer = new ApiReducer(initialState, 'Event');

reducer.addApiReducer(loadAgendas);

reducer.addApiReducer(createAgenda, (agenda, { agendas }) => ({
	agendas: [...agendas, agenda],
}));

reducer.addApiReducer(updateAgenda, (agenda, { agendas }) => ({
	agendas: updateListItem(agendas, a => a.id === agenda.id, agenda),
}));

reducer.addApiReducer(deleteAgenda, (id, { agendas }) => ({
	agendas: removeListItemWithIndex(agendas, a => a.id === id),
}));

reducer.addApiReducer(moveAgenda, ({ id, sortingKey }, { agendas }) => ({
	agendas: moveListItem(agendas, a => a.id === id, sortingKey),
}));

reducer.addApiReducer(createSession, (session, { sessions }) => ({
	sessions: [...sessions, session],
}));

reducer.addApiReducer(updateSession, (session, { sessions }) => ({
	sessions: updateListItem(sessions, s => s.id === session.id, session),
}));

reducer.addApiReducer(
	updateAgendaSessionsQaEnabled,
	(updatedSessions, { sessions }, payload): Partial<AgendaState> => ({
		sessions: sessions
			.map(s =>
				s.agendaId === payload?.agendaId
					? updatedSessions.find(us => us.id == s.id)
					: s
			)
			.filter(isNonNullish),
	})
);

reducer.addApiReducer(deleteSession, (id, { sessions }) => ({
	sessions: removeListItemWithIndex(
		sessions,
		s => s.id === id,
		s => s.agendaId
	),
}));

reducer.addApiReducer(moveSession, ({ id, sortingKey }, { sessions }) => ({
	sessions: moveListItem(
		sessions,
		s => s.id === id,
		sortingKey,
		s => s.agendaId
	),
}));

reducer.addApiReducer(duplicateSession, (session, { sessions }) => ({
	sessions: [...sessions, session],
}));

reducer.addApiReducer(createSessionItem, (sessionItem, { sessionItems }) => ({
	sessionItems: [...sessionItems, sessionItem],
}));

reducer.addApiReducer(updateSessionItem, (sessionItem, { sessionItems }) => ({
	sessionItems: updateListItem(
		sessionItems,
		s => s.id === sessionItem.id,
		sessionItem
	),
}));

reducer.addApiReducer(activatePushout, (sessionItem, { sessionItems }) => ({
	sessionItems: updateListItem(
		sessionItems,
		s => s.id === sessionItem.id,
		sessionItem
	),
}));

reducer.addApiReducer(deactivatePushout, (sessionItem, { sessionItems }) => ({
	sessionItems: updateListItem(
		sessionItems,
		s => s.id === sessionItem.id,
		sessionItem
	),
}));

reducer.addApiReducer(
	activatePushoutResults,
	(sessionItem, { sessionItems }) => ({
		sessionItems: updateListItem(
			sessionItems,
			s => s.id === sessionItem.id,
			sessionItem
		),
	})
);

reducer.addApiReducer(
	deactivatePushoutResults,
	(sessionItem, { sessionItems }) => ({
		sessionItems: updateListItem(
			sessionItems,
			s => s.id === sessionItem.id,
			sessionItem
		),
	})
);

reducer.addApiReducer(toggleSessionItem, (sessionItem, { sessionItems }) => ({
	sessionItems: updateListItem(
		sessionItems,
		s => s.id === sessionItem.id,
		sessionItem
	),
}));

reducer.addApiReducer(deleteSessionItem, (id, { sessionItems }) => ({
	sessionItems: removeListItemWithIndex(
		sessionItems,
		s => s.id === id,
		s => s.sessionId
	),
}));

reducer.addApiReducer(
	moveSessionItem,
	({ id, sortingKey }, { sessionItems }) => ({
		sessionItems: moveListItem(
			sessionItems,
			s => s.id === id,
			sortingKey,
			s => s.sessionId
		),
	})
);

reducer.addApiReducer(
	duplicateSessionItem,
	(sessionItem, { sessionItems }) => ({
		sessionItems: [...sessionItems, sessionItem],
	})
);

reducer.addReducer(
	sessionItemActiveChanged,
	(sessionItem, { sessionItems }): Partial<AgendaState> => ({
		sessionItems: updateListItem<SessionItem>(
			sessionItems,
			s => s.id === sessionItem.id,
			sessionItem
		),
	})
);

reducer.addApiReducer(updateSessionItemPushouts, (item, { sessionItems }) => ({
	sessionItems: updateListItem(sessionItems, i => i.id === item.id, item),
}));

reducer.addApiReducer(loadSessionItemPushouts, (item, { sessionItems }) => ({
	sessionItems: updateListItem(sessionItems, i => i.id === item.id, {
		...item,
		pushoutsLoaded: true,
	}),
}));

reducer.addApiReducer(updateVote, (vote, { sessionItems }) => ({
	sessionItems: updateListItem(sessionItems, i => i.id === vote.sessionItemId, {
		vote,
	}),
}));

reducer.addApiReducer(createVote, (vote, { sessionItems }) => ({
	sessionItems: updateListItem(sessionItems, i => i.id === vote.sessionItemId, {
		vote,
	}),
}));

reducer.addApiReducer(upsertSelfie, (selfie, { sessionItems }) => ({
	sessionItems: updateListItem(
		sessionItems,
		i => i.id === selfie.sessionItemId,
		{ selfie }
	),
}));

reducer.addApiReducer(updateSurvey, (survey, { sessionItems }) => ({
	sessionItems: updateListItem(
		sessionItems,
		i => i.id === survey.sessionItemId,
		{ survey }
	),
}));

reducer.addApiReducer(updateFlashcardSet, (flashcardSet, { sessionItems }) => ({
	sessionItems: updateListItem(
		sessionItems,
		i => i.id === flashcardSet.sessionItemId,
		{ flashcardSet }
	),
}));

reducer.addApiReducer(updateConnected, (connected, { sessionItems }) => ({
	sessionItems: updateListItem(
		sessionItems,
		i => i.id === connected.sessionItemId,
		{ connected }
	),
}));

reducer.addApiReducer(updatePushoutLink, (link, { sessionItems }) => ({
	sessionItems: updateListItem(sessionItems, i => i.id === link.sessionItemId, {
		link,
	}),
}));

reducer.addApiReducer(createWordCloud, (wordCloud, { sessionItems }) => ({
	sessionItems: sessionItems.map(x =>
		x.id === wordCloud.sessionItemId
			? { ...x, wordCloud: { id: wordCloud.id } }
			: x
	),
}));

reducer.addReducer(
	createBrainstorm,
	({ sessionItemId, brainstormId }, { sessionItems }) => ({
		sessionItems: sessionItems.map(x =>
			x.id === sessionItemId ? { ...x, brainstorm: { id: brainstormId } } : x
		),
	})
);

export const agendaReducer = reducer.getReducer();
