import { BaseComponent } from '@shared/component-bases';
import {
	Directive,
	DoCheck,
	inject,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { forOwn } from 'lodash-es';
import { formUpdated } from './utils';
import { FormService } from './form.service';
import { debounceTime } from 'rxjs';
import {
	FormControls,
	FormDisable,
	FormLayerControls,
	FormLayerTemplate,
} from './form-types';
import { FormRoot } from './form-root';
import { FormProxy } from './form-proxy';

@Directive()
export class FormComponent<TForm extends object, TData = TForm>
	extends BaseComponent
	implements OnInit, OnDestroy, DoCheck
{
	readonly #formService = inject(FormService);

	form: FormRoot<TForm>;
	/**
	 * @remarks Intentionally public, do not convert to ECMAScript private field.
	 */
	_model: TData;

	get _value(): TForm {
		return this.form.value;
	}
	get formValue(): TForm {
		return this.form.getValue();
	}
	get _value$() {
		return this.form.value$;
	}

	set savePromise(promise: Promise<any>) {
		if (!this.form) {
			return;
		}
		this.form.savingPromise = promise;
	}

	@Input() set proxy(proxy: FormProxy) {
		if (!proxy) {
			this.clearMonitorSwitch('proxy-saving');
			this.clearMonitorSwitch('proxy-can-submit');
			this.clearMonitorSwitch('proxy-can-create');
			this.clearMonitorSwitch('proxy-submit');
		} else if (this.form) {
			this.monitorValueSwitch(
				this.form.saving$,
				x => (proxy.saving = x),
				'proxy-saving'
			);
			this.monitorValueSwitch(
				this.form.canSubmit$,
				x => (proxy.canSubmit = x),
				'proxy-can-submit'
			);
			this.monitorValueSwitch(
				this.form.canCreate$,
				x => (proxy.canCreate = x),
				'proxy-can-create'
			);
			this.monitorValueSwitch(
				proxy.submit$,
				() => this.submitForm(),
				'proxy-submit'
			);
		}
	}

	controls: FormLayerControls<TForm>;
	disables: FormDisable<TForm, TForm>;

	disableMethods: {
		func: (val: TForm) => boolean;
		emit: boolean;
		control: AbstractControl;
	}[] = [];

	constructor(formLayout: FormLayerTemplate<TForm> = null) {
		super();

		this.init(formLayout);
	}

	protected init(formLayout: FormLayerTemplate<TForm>) {
		if (formLayout == null || this.form) {
			return;
		}

		this.form = FormRoot.fromTemplate(formLayout);
		this.controls = this.form.controlMap;

		this.monitorValue(this._value$, state => {
			this.updateDisabledState(state);
		});

		this.monitorValue(this._value$.pipe(debounceTime(500)), state => {
			this.formChanged(state);
			this.form.formError = this.validateForm(state) || null;
			this.form.formWarning = this.generateWarning(state) || null;
		});

		this.disables = this.#getDisables<TForm>(this.form);
		setTimeout(() => this.updateDisabledState(this._value), 0);
	}

	updateDisabledState(value: TForm) {
		let update = false;

		if (this.form.disabled) {
			return;
		}

		this.disableMethods.forEach(({ func, emit, control }) => {
			const disable = func(value);
			if (disable === control.disabled) {
				return;
			}

			if (disable) {
				control.disable({ emitEvent: false });
			} else {
				control.enable({ emitEvent: false });
			}

			if (emit) {
				update = true;
			}
		});

		if (update) {
			this.form.updateValueAndValidity();
		}
	}

	/**
	 * Override and return any potential errors for the model. Null means valid form
	 * @param val
	 */
	protected validateForm(_val: TForm): string | null | void {
		return null;
	}

	/**
	 * Override and return any potential errors for the model. Null means valid form
	 * @param val
	 */
	protected generateWarning(_val: TForm): string | null | void {
		return null;
	}

	/**
	 * Override. Will be called by proxy for saving
	 */
	protected submitForm() {
		// Override in the sub-class to be notified when the form is submitted
	}

	#getDisables<T>(template: UntypedFormGroup) {
		const controls = {};

		forOwn(template.controls, (x, k) => {
			if (x instanceof UntypedFormGroup) {
				controls[k] = this.#getDisables(x);
				return;
			}

			controls[k] = (
				disableFunc: (val: TForm) => boolean,
				emitEvent = true
			) => {
				this.disableMethods.push({
					func: disableFunc,
					emit: emitEvent,
					control: x,
				});
			};
		});

		controls['this'] = (
			disableFunc: (val: TForm) => boolean,
			emitEvent = true
		) => {
			this.disableMethods.push({
				func: disableFunc,
				emit: emitEvent,
				control: template,
			});
		};

		return controls as FormDisable<T, TForm>;
	}

	#getControls<T>(template: UntypedFormGroup): FormControls<T> {
		const controls = {};

		forOwn(template.controls, (x, k) => {
			if (x instanceof UntypedFormGroup) {
				controls[k] = this.#getControls(x);
				return;
			}

			controls[k] = x;
		});

		controls['this'] = template;

		return controls as FormControls<T>;
	}

	ngOnInit(): void {
		super.ngOnInit();
		if (this.#formService) {
			this.#formService.addForm(this.form);
		}
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
		if (this.#formService) {
			this.#formService.removeForm(this.form);
		}
	}

	resetForm() {
		this.form.reset();
	}

	updateForm(data: Partial<TForm>) {
		const updated = formUpdated(this._model, data as TData, this.form);
		const newId = updated && this._model?.['id'] !== data?.['id'];
		this._model = data as TData;

		if (!updated) {
			return;
		}

		this.form.reset(data);
		this.formUpdated(this._value, newId);
	}

	patchForm(obj: Partial<TForm>) {
		this.form.patchValue(obj);
	}

	formUpdated(_value: TForm, _newId: boolean) {
		// Override in the sub-class to be notified when the form value is
		// changed programmatically (*from the model*).
	}

	formChanged(_value: TForm) {
		// Override in the sub-class to be notified when the form value changes
		// *from the view*.
	}
}
