/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { harmonicaAnimation } from '@consensus/co/ui-component-animations';
import { InputBaseComponent } from '@lib/forms';
import moment, { Moment } from 'moment';

@Component({
	standalone: true,
	selector: 'co-form-date-input',
	imports: [
		NgIf,
		FormsModule,
		MatDatepickerModule,
		MatInputModule,
		MatTooltipModule,
	],
	templateUrl: './date-input.component.html',
	styleUrls: ['./date-input.component.scss'],
	animations: [harmonicaAnimation()],
})
export class DateInputComponent extends InputBaseComponent<Date> {
	@Input() forceDatepicker = false;
	now = new Date();

	preprocessValue(value): any {
		if (value instanceof Date) {
			return (
				`${value.getUTCFullYear()}` +
				`-${(value.getUTCMonth() + 1).toString().padStart(2, '0')}` +
				`-${value.getUTCDate().toString().padStart(2, '0')}`
			);
		}
		return value;
	}

	postprocessValue(value): any {
		if (!value) {
			return null;
		}
		if (value instanceof Date) {
			return value;
		}
		if (moment.isMoment(value)) {
			return (value as Moment).toDate();
		}

		const date = new Date(value);
		date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
		return date;
	}
}
