import {
	APP_INITIALIZER,
	EnvironmentProviders,
	FactoryProvider,
	makeEnvironmentProviders,
} from '@angular/core';
import { CoHttpCacheStore } from './co-http-cache.store';

/**
 * Clear cacheable endpoint URLs on user group changes.
 *
 * @see `coAddCacheControlHeaderToFirstRequest` Related
 */
export function provideCoHttpCacheStore(): EnvironmentProviders {
	return makeEnvironmentProviders([
		CoHttpCacheStore,
		initializeCoHttpCacheStore,
	]);
}

const initializeCoHttpCacheStore: FactoryProvider = {
	provide: APP_INITIALIZER,
	multi: true,
	deps: [CoHttpCacheStore],
	useFactory: (_store: CoHttpCacheStore) => () => {
		// Eagerly instantiate the component store to initialize its effects
	},
};
