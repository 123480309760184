/* eslint-disable no-restricted-syntax */
// no-restricted-syntax: Allow console.debug usage
import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivateFn,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { SessionService } from '@store/scope';

export const isAdminGuard: CanActivateFn = (
	_route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
) => {
	const sessionService = inject(SessionService);
	const router = inject(Router);

	console.debug('# Is Admin Guard');

	if (!sessionService.isLoggedIn()) {
		sessionStorage.setItem('admin-redirect', state.url);

		console.debug('Not logged in - Sending to Admin Login');
		router.navigate(['/', 'admin']);
		return false;
	}

	if (
		!sessionService.hasScopePermission('globalCms') &&
		!sessionService.hasScopePermission('clientCms') &&
		!sessionService.hasScopePermission('eventCms')
	) {
		console.debug('Not admin - Sending to Login Root');
		router.navigate(['/']);
		return false;
	}

	const redirect = sessionStorage.getItem('admin-redirect');

	if (redirect) {
		sessionStorage.removeItem('admin-redirect');
		console.debug(
			`Is admin with saved redirect - Redirecting to "${redirect}"`
		);
		router.navigate([redirect]);
		return false;
	}

	console.debug('Is admin');
	return true;
};
