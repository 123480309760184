import { UploadManager } from '@consensus/shared/shared/files/data-access-files';
import { FileTypes } from '@consensus/shared/shared/files/domain';
import '@lib/custom-elements';
import { PageEditorClient } from './page-editor.client';
import { globalInjector } from '@lib/global-injector';

export function imageOverloadPlugin(froalaEditor) {
	froalaEditor.PLUGINS.imageOverload = ImageOverloadPlugin;
}

class ImageOverloadPlugin {
	constructor(private editor: any) {
		const getXHR = editor.core.getXHR();

		editor.core.getXHR = function (url: string, method: string) {
			if (url !== 'UPLOAD') {
				return getXHR.call(this, url, method);
			}

			const request = new (class
				implements
					Partial<
						Omit<XMLHttpRequest, 'upload'> & {
							upload: Partial<XMLHttpRequestUpload>;
						}
					>
			{
				readyState = 1;
				status = 0;
				response = '';
				responseText = '';
				responseXML = null;

				upload = {
					onprogress(_: {
						lengthComputable: boolean;
						total: number;
						loaded: number;
					}) {
						// No-op
					},
				};

				onload() {
					// No-op
				}
				onerror() {
					// No-op
				}

				setRequestHeader() {
					return void 0;
				}
				abort() {
					return void 0;
				}
				send(body: FormData) {
					if (!editor.opts.pageId) {
						console.error(`Missing 'pageId' configuration for Froala`);
						return this.onerror();
					}
					const pageEditorClient = globalInjector.get(PageEditorClient);
					pageEditorClient
						.createPageFile(editor.opts.pageId)
						.toPromise()
						.then(createResponse => {
							const file = body.get('file') as File;
							const data = {
								link: URL.createObjectURL(file),
								// These properties will be applied to the created <img> tag.
								// and are crucial for getting the custom element (WebComponent) to work.
								// eslint-disable-next-line @typescript-eslint/naming-convention
								'data-imageId': createResponse.id,
								is: 'page-editor-file',
							};
							this.status = 200;
							this.response = JSON.stringify(data);
							this.responseText = JSON.stringify(data);
							this.readyState = 4;
							this.onload();

							const uploadManager = UploadManager.basic(
								PageEditorClient,
								c => c.getPageFileToken,
								s => s.uploadPageEditorFile
							);
							return uploadManager.upload(
								{ file, type: FileTypes.Image },
								createResponse.id
							);
						})
						.catch(err => {
							this.status = 500;
							this.response = err;
							this.onerror();
						});
				}
			})();

			return request;
		};
	}

	/**
	 * @remarks Intentionally public Froala hook, do not convert to ECMAScript
	 *   private method.
	 */
	_init() {
		// No-op
	}
}
