import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	OnInit,
	inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from '@consensus/shared/shared/iam/data-access';

export const CAPACITOR_IN_APP_BROWSER_REDIRECT_ROUTE =
	'capacitor-in-app-browser-redirect';

@Component({
	standalone: true,
	selector: 'co-capacitor-in-app-browser-redirect',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CapacitorInAppBrowserRedirectComponent implements OnInit {
	readonly #token = inject(TokenService);
	readonly #router = inject(Router);
	readonly #activeRoute = inject(ActivatedRoute);
	readonly #destroyRef = inject(DestroyRef);

	ngOnInit(): void {
		this.#activeRoute.queryParamMap
			.pipe(takeUntilDestroyed(this.#destroyRef))
			.subscribe(params => {
				const jwt = params.get('jwt');
				const redirectPath = params.get('redirectPath');
				if (!jwt || !redirectPath) {
					this.#router.navigateByUrl('/');
					return;
				}
				this.#token.setLoginToken(jwt);
				this.#router.navigateByUrl(redirectPath);
			});
	}
}
