import { Pipe, PipeTransform } from '@angular/core';
import { UserInformation } from '@shared/models';
import { getUsersName } from '@lib/helpers';

@Pipe({
	standalone: true,
	name: 'displayName',
})
export class DisplayNamePipe implements PipeTransform {
	transform(user: UserInformation | null): string {
		if (!user) {
			return '';
		}
		return getUsersName(user);
	}
}
