import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
	Dashboard,
	CompleteDashboardItemActivityResponseDto,
} from '@consensus/connect/shared/dashboard/domain';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class UfaDashboardClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #httpClient = inject(HttpClient);

	completeDashboardItemActivity(id: string) {
		return this.#httpClient.patch<CompleteDashboardItemActivityResponseDto>(
			`${this.#apiServer}/api/dashboard/item/${id}/complete-activity`,
			{}
		);
	}

	loadDashboards() {
		return this.#httpClient.get<Dashboard[]>(
			`${this.#apiServer}/api/dashboard`
		);
	}

	visitDashboardItem(id: string) {
		return this.#httpClient.patch(
			`${this.#apiServer}/api/dashboard/item/${id}/visit`,
			{}
		);
	}
}
