import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PdfService {
	stack: BehaviorSubject<boolean>[] = [];

	register() {
		const subject = new BehaviorSubject(false);
		this.stack.forEach(x => x.next(false));
		this.stack.push(subject);
		setTimeout(() => subject.next(true));
		return subject as Observable<boolean>;
	}

	deregister(subject: Observable<boolean>) {
		const index = this.stack.findIndex(
			x => x === (subject as BehaviorSubject<boolean>)
		);
		if (index < 0) {
			return;
		}

		this.stack.splice(index, 1);
		if (this.stack.length < 1) {
			return;
		}

		setTimeout(() => {
			this.stack[this.stack.length - 1].next(true);
		}, 200);
	}
}
