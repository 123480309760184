/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { Component, ElementRef, inject, Input } from '@angular/core';

@Component({
	standalone: true,
	selector: 'co-svg',
	imports: [],
	templateUrl: './svg.component.html',
	styleUrls: ['./svg.component.scss'],
})
export class SvgComponent {
	readonly #el: ElementRef<HTMLElement> = inject(ElementRef);
	_src: string;
	@Input() set src(src: string) {
		if (src === this._src) {
			return;
		}
		this._src = src;

		this.destroySvg();

		if (src) {
			this.loadSvg();
		}
	}

	async loadSvg() {
		const html = await fetch(this._src).then(x => x.text());
		this.#el.nativeElement.innerHTML = html;
		this.#el.nativeElement.querySelectorAll('style').forEach(x => x.remove());
	}

	destroySvg() {
		this.#el.nativeElement.innerHTML = '';
	}
}
