import {
	faFile,
	faFileAlt,
	faFileArchive,
	faFileAudio,
	faFilePdf,
	faFilePowerpoint,
	faFileSpreadsheet,
	faPhotoVideo,
	faVideo,
} from '@fortawesome/pro-duotone-svg-icons';
import { FileTypes } from '@consensus/shared/shared/files/domain';

export function getFileIcon(type: FileTypes, faPrefix = 'fad') {
	switch (type) {
		case FileTypes.Pdf:
			return `${faPrefix} fa-file-pdf`;
		case FileTypes.Image:
			return `${faPrefix} fa-photo-video`;
		case FileTypes.Audio:
			return `${faPrefix} fa-file-audio`;
		case FileTypes.Video:
			return `${faPrefix} fa-video`;
		case FileTypes.Word:
			return `${faPrefix} fa-file-alt`;
		case FileTypes.Excel:
			return `${faPrefix} fa-file-spreadsheet`;
		case FileTypes.Powerpoint:
			return `${faPrefix} fa-file-powerpoint`;
		case FileTypes.Zip:
			return `${faPrefix} fa-file-archive`;
		case FileTypes.Other:
			return `${faPrefix} fa-file`;
		default:
			return `${faPrefix} fa-file`;
	}
}

export function getFileDuotoneIconDefinition(type: FileTypes) {
	switch (type) {
		case FileTypes.Pdf:
			return faFilePdf;
		case FileTypes.Image:
			return faPhotoVideo;
		case FileTypes.Audio:
			return faFileAudio;
		case FileTypes.Video:
			return faVideo;
		case FileTypes.Word:
			return faFileAlt;
		case FileTypes.Excel:
			return faFileSpreadsheet;
		case FileTypes.Powerpoint:
			return faFilePowerpoint;
		case FileTypes.Zip:
			return faFileArchive;
		case FileTypes.Other:
			return faFile;
		default:
			return faFile;
	}
}
