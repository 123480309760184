import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SideBarSectionItem } from '@shared/models';
import { sortBySortingKeyAsc } from '@lib/helpers';
import { groupBy } from 'lodash-es';
import { GlobalSidepanelState } from './sidepanel.reducer';

const selectEventState = createFeatureSelector<GlobalSidepanelState>('event');

export const selectSidepanelState = createSelector(
	selectEventState,
	state => state.sidepanel
);

export const selectSidepanelItems = createSelector(
	selectSidepanelState,
	state => [...state.items].sort(sortBySortingKeyAsc)
);

export const selectSidepanelItemsSorted = createSelector(
	selectSidepanelItems,
	items => groupBy(items, x => x.sectionId)
);

export const selectSidepanelData = createSelector(
	selectSidepanelState,
	state => {
		const items = groupBy(state.items || [], x => x.sectionId ?? '');

		const noSec = items[''];

		return !noSec
			? []
			: [...noSec].sort(sortBySortingKeyAsc).map(
					item =>
						({
							id: item.id,
							external: item.external,
							icon: item.iconClass,
							link: item.link,
							text: item.name,
							children: item.isSection
								? items[item.id]
									? [...items[item.id]].sort(sortBySortingKeyAsc).map(
											child =>
												({
													id: child.id,
													external: child.external,
													icon: child.iconClass,
													link: child.link,
													text: child.name,
												} as SideBarSectionItem)
									  )
									: []
								: null,
						} as SideBarSectionItem)
			  );
	}
);
