import {
	Directive,
	HostBinding,
	HostListener,
	inject,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { IdManagementService } from './id-management.service';
import { Subscription } from 'rxjs';

@Directive({
	standalone: true,
	selector: '[coModelId]',
})
export class ModelIdDirective implements OnInit, OnDestroy {
	readonly #service = inject(IdManagementService);

	subscription: Subscription;

	@Input('coModelId') modelId: string;
	@HostBinding('class.can-copy') canCopy = false;

	@HostListener('click', ['$event'])
	onClick(event: MouseEvent) {
		if (!this.canCopy) {
			return;
		}

		event.stopPropagation();
		this.#service.copyId(this.modelId);
		return false;
	}

	ngOnInit() {
		this.subscription = this.#service.idCopyMode$.subscribe(
			x => (this.canCopy = x)
		);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
