import { inject, Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { SignatureState } from './signature.model';
import { createUserSignature, loadSignatures } from './signature.actions';
import { selectSignatureState } from './signature.selectors';
import { ApiEffects } from '@lib/redux';
import { SignatureClient } from './signature.client';

@Injectable()
export class SignatureEffects extends ApiEffects<SignatureState> {
	readonly #client = inject(SignatureClient);
	readonly #actions$ = inject(Actions);
	constructor() {
		super(selectSignatureState);
	}

	load$ = createEffect(() =>
		this.generateApiEffect(loadSignatures, () =>
			this.#client.loadUserSignatures()
		)(this.#actions$)
	);

	createUserSignature$ = createEffect(() =>
		this.generateApiEffect(createUserSignature, action =>
			this.#client.createUserSignature(action.payload)
		)(this.#actions$)
	);
}
