import { inject, Injectable } from '@angular/core';
import {
	BehaviorSubject,
	fromEvent,
	Subject,
	debounceTime,
	lastValueFrom,
} from 'rxjs';
import { Event } from '@consensus/shared/shared/event/domain';
import { Store } from '@ngrx/store';
import { parseDate } from '@lib/helpers';
import moment from 'moment-timezone';
import { getUserInitials, getUsersName } from '@lib/helpers';
import { AuthedUser } from '@shared/models';
import { selectAuthedUser, selectUserRole } from '@store/user';
import { selectTheme, Theme } from '@store/theming';
import { PermissionEnum } from '@consensus/connect/shared/access-control/domain';
import { WhatCanIDo } from '@store/user';
import { selectWhatCanIDo } from '@store/user';
import { dispatchAsync } from '@lib/redux';
import { loadWhatCanIDo } from '@store/user';
import { ScopeClient } from './scope.client';
import { selectClient, selectEvent } from './scope.selectors';

@Injectable({ providedIn: 'root' })
export class SessionService {
	readonly #scopeClient = inject(ScopeClient);
	readonly #store = inject(Store);
	resetStores$ = new Subject<void>();
	disableRedirectInRootLogin = false;

	clientId$ = new BehaviorSubject<string>(null);

	get clientId() {
		return this.clientId$.value;
	}

	clientSlug$ = new BehaviorSubject<string>(null);

	get clientSlug() {
		return this.clientSlug$.value;
	}

	clientName$ = new BehaviorSubject<string>(null);

	get clientName() {
		return this.clientName$.value;
	}

	theme$ = new BehaviorSubject<Theme>(null);

	get theme() {
		return this.theme$.value;
	}

	get isSema() {
		return !!this.theme$.value?.semaStyling;
	}
	event$ = new BehaviorSubject<Event>(null);

	get event() {
		return this.event$.value;
	}

	eventId$ = new BehaviorSubject<string>(null);

	get eventId() {
		return this.eventId$.value;
	}

	eventSlug$ = new BehaviorSubject<string>(null);

	get eventSlug() {
		return this.eventSlug$.value;
	}

	eventName$ = new BehaviorSubject<string>(null);

	get eventName() {
		return this.eventName$.value;
	}

	eventTimezone$ = new BehaviorSubject<string>(null);

	get eventTimezone() {
		return this.eventTimezone$.value;
	}

	user$ = new BehaviorSubject<AuthedUser>(null);

	get user() {
		return this.user$.value;
	}

	userId$ = new BehaviorSubject<string>(null);

	get userId() {
		return this.userId$.value;
	}

	userName$ = new BehaviorSubject<string>(null);

	get userName() {
		return this.userName$.value;
	}

	userInitials$ = new BehaviorSubject<string>(null);

	get userInitials() {
		return this.userInitials$.value;
	}

	username$ = new BehaviorSubject<string>(null);

	get username() {
		return this.username$.value;
	}

	#whatCanIDo: WhatCanIDo;

	isLoggedIn$ = new BehaviorSubject(false);
	isUser$ = new BehaviorSubject(false);
	isUserAdmin$ = new BehaviorSubject(false);
	isAdmin$ = new BehaviorSubject(false);
	isSuperAdmin$ = new BehaviorSubject(false);
	role: string;

	_resize$ = new Subject<void>();
	get resize$() {
		return this._resize$.asObservable();
	}
	isPortrait$ = new BehaviorSubject(window.innerWidth < window.innerHeight);
	get isPortrait() {
		return this.isPortrait$.value;
	}
	screenWidth$ = new BehaviorSubject(window.innerWidth);
	get screenWidth() {
		return this.screenWidth$.value;
	}

	constructor() {
		this.#store.select(selectClient).subscribe(client => {
			this.clientId$.next(client?.id);
			this.clientSlug$.next(client?.slug);
			this.clientName$.next(client?.name);
		});

		this.#store.select(selectEvent).subscribe(event => {
			this.event$.next(event);
			this.eventId$.next(event?.id);
			this.eventSlug$.next(event?.slug);
			this.eventName$.next(event?.name);
			this.eventTimezone$.next(event?.timezone);
		});

		this.#store.select(selectTheme).subscribe(theme => {
			this.theme$.next(theme);
		});

		this.#store.select(selectAuthedUser).subscribe(user => {
			this.user$.next(user);
			this.userId$.next(user.id);
			this.username$.next(user.username);
			this.userName$.next(getUsersName(user));
			this.userInitials$.next(getUserInitials(user));
		});

		this.#store.select(selectWhatCanIDo).subscribe(whatCanIDo => {
			this.#whatCanIDo = whatCanIDo;
		});

		this.#store.select(selectUserRole).subscribe(r => {
			this.role = r;

			this.isLoggedIn$.next(this.isLoggedIn());
			this.isUser$.next(this.isUser());
			this.isUserAdmin$.next(this.isUserAdmin());
			this.isAdmin$.next(this.isAdmin());
			this.isSuperAdmin$.next(this.isSuperAdmin());
		});

		fromEvent(window, 'resize')
			.pipe(debounceTime(200))
			.subscribe(() => {
				this.isPortrait$.next(window.innerWidth < window.innerHeight);
				this.screenWidth$.next(window.innerWidth);
				this._resize$.next();
			});
	}

	isLoggedIn = () => this.role != null;

	isUser = (strict = false) =>
		strict ? this.role === 'User' : this.isLoggedIn();

	isUserAdmin = (strict = false) =>
		strict ? this.role === 'UserAdmin' : this.isUser() && !this.isUser(true);

	/**
	 * This will return true if the user is an old school admin, and is not based
	 * on whether or not the user have any permissions to access to CMS.
	 * This is still the proper way to detect if the main purpose of the user is
	 * being an admin, or if it's being a participant.
	 */
	isAdmin = (strict = false) =>
		strict
			? this.role === 'Admin'
			: this.isUserAdmin() && !this.isUserAdmin(true);

	isSuperAdmin = () => this.role === 'SuperAdmin';

	async loadWhatCanIDo() {
		// This is a nasty way to ensure that we have the proper ID's available,
		// instead of relaying on eg. EventComponent and CmsEventComponent doing a
		// request to load them.
		const checkers: {
			pattern: string;
			handler: (
				...matches: string[]
			) => Promise<{ clientId: string | null; eventId: string | null }>;
		}[] = [
			{
				pattern: '^/cms/academy/client/([^/]+)',
				handler: async (clientId: string) => {
					return { eventId: null, clientId };
				},
			},
			{
				pattern: '^/cms/academy/event/([^/]+)',
				handler: async (id: string) => {
					const { event, client } = await lastValueFrom(
						this.#scopeClient.loadEvent({ id })
					);
					return { eventId: event.id, clientId: client.id };
				},
			},
			{
				pattern: '^/cms/client/([^/]+)',
				handler: async (clientId: string) => {
					return { eventId: null, clientId };
				},
			},
			{
				pattern: '^/cms/project/([^/]+)',
				handler: async (_projectId: string) => {
					console.warn(
						`It's not possible to resolve clientId and eventId from '${location.pathname}'`
					);
					return { eventId: null, clientId: null };
				},
			},
			{
				pattern: '^/cms/event/([^/]+)',
				handler: async (id: string) => {
					const { event, client } = await lastValueFrom(
						this.#scopeClient.loadEvent({ id })
					);
					return { eventId: event.id, clientId: client.id };
				},
			},
			{
				pattern: '^/client/([^/]+)',
				handler: async (slug: string) => {
					const { id: clientId } = await lastValueFrom(
						this.#scopeClient.loadClient({ slug })
					);
					return { eventId: null, clientId };
				},
			},
			{
				/** match a line that starts with '/c/', followed by one or more characters that are not forward slashes, and capture those characters. */
				pattern: '^/c/([^/]+)',
				handler: async (slug: string) => {
					const { id: clientId } = await lastValueFrom(
						this.#scopeClient.loadClient({ slug })
					);
					return { eventId: null, clientId };
				},
			},
			{
				pattern: '^/project/([^/]+)',
				handler: async (_slug: string) => {
					console.warn(
						`It's not possible to resolve clientId and eventId from '${location.pathname}'`
					);
					return { eventId: null, clientId: null };
				},
			},
			{
				pattern: '^/event/([^/]+)',
				handler: async (slug: string) => {
					const { event, client } = await lastValueFrom(
						this.#scopeClient.loadEvent({ slug })
					);
					return { eventId: event.id, clientId: client.id };
				},
			},
		];

		for (const checker of checkers) {
			const re = new RegExp(checker.pattern);
			const match = location.pathname.match(re);
			if (!match) {
				continue;
			}

			const [_, ...args] = match;
			const { clientId, eventId } = await checker.handler(...args);

			this.clientId$.next(clientId);
			this.eventId$.next(eventId);
			break;
		}

		// The actual loading of data
		return await dispatchAsync(loadWhatCanIDo, null, true);
	}
	hasPermission(permission: PermissionEnum) {
		if (this.#whatCanIDo.global.has(permission)) {
			return true;
		}

		if (this.#whatCanIDo.clients[this.clientId]?.has(permission)) {
			return true;
		}

		if (this.#whatCanIDo.events[this.eventId]?.has(permission)) {
			return true;
		}

		return false;
	}

	hasScopePermission(scope: 'globalCms' | 'clientCms' | 'eventCms') {
		switch (scope) {
			// @ts-expect-error Expect the case below to fall through
			case 'eventCms':
				if (
					Array.from(this.#whatCanIDo.events[this.eventId] ?? []).some(
						permission => permission.includes(':cms:')
					)
				) {
					return true;
				}
			// fall through - as having a client permission,
			// is the same as having the permission on all
			// events, it's reasonable to fall through:
			// @ts-expect-error Expect the case below to fall through
			case 'clientCms':
				if (
					Array.from(this.#whatCanIDo.clients[this.clientId] ?? []).some(
						permission => permission.includes(':cms:')
					)
				) {
					return true;
				}
			// fall through - as having a global permission,
			// is the same as having the permission on all
			// client, it's reasonable to fall through:
			case 'globalCms':
				if (
					Array.from(this.#whatCanIDo.global).some(permission =>
						permission.includes(':cms:')
					)
				) {
					return true;
				}
		}

		return false;
	}

	getCurrentTimeOfEventTimeZone() {
		const date = new Date();

		// Remove UTC+2
		date.setMinutes(
			date.getMinutes() - moment.tz(moment.tz.guess(true)).utcOffset()
		);

		// Remove EventTimezone UTC+-nnn
		if (this.eventTimezone) {
			date.setMinutes(
				date.getMinutes() + moment.tz(this.eventTimezone).utcOffset()
			);
		}

		return date;
	}

	parseEventDate(date: string | Date) {
		if (date == null) {
			return null;
		}
		if (date instanceof Date) {
			date = new Date(date.getTime());
		} else {
			date = parseDate(date);
		}
		date.setMinutes(
			date.getMinutes() - moment.tz(this.eventTimezone).utcOffset()
		);
		return date;
	}

	parseEventDateToLocal(date: Date) {
		date = this.parseEventDate(date);
		date = new Date(date.getTime());
		date.setMinutes(
			moment.tz(moment.tz.guess(true)).utcOffset() + date.getMinutes()
		);
		return date;
	}
}
