/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import {
	CdkDrag,
	CdkDragEnd,
	CdkDragMove,
	DragDropModule,
} from '@angular/cdk/drag-drop';
import { NgFor, NgIf } from '@angular/common';
import {
	Component,
	ElementRef,
	HostBinding,
	Input,
	ViewChild,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BaseComponent } from '@shared/component-bases';
import { FormNode } from '@lib/forms';
import { SliderLabel } from '../../slider-input/slider-input.component';
import { clamp } from '@lib/helpers';
import { CoTrackByFunction } from '@consensus/co/util-control-flow';

@Component({
	standalone: true,
	selector: 'co-form-base-range-input',
	imports: [NgFor, NgIf, DragDropModule, MatTooltipModule],
	templateUrl: './base-range-input.component.html',
	styleUrls: ['./base-range-input.component.scss'],
})
export class BaseRangeInputComponent extends BaseComponent {
	@ViewChild('slider', { static: true })
	sliderElement: ElementRef<HTMLDivElement>;
	@ViewChild('range', { static: true })
	rangeElement: ElementRef<HTMLDivElement>;
	@ViewChild('maxHandle', { static: true, read: CdkDrag })
	maxHandleElement: CdkDrag;
	@ViewChild('minHandle', { static: true, read: CdkDrag })
	minHandleElement: CdkDrag;

	@Input() startControl: FormNode<number>;
	@Input() endControl: FormNode<number>;

	@Input() max = 10;
	@Input() min = 0;
	@Input() step = 1;
	@Input() visualStep: number;

	@Input() label: string;
	@HostBinding('class.read-only') @Input() readonly: boolean;

	startVal: number;
	endVal: number;

	labels: SliderLabel[] = [];

	onInit(): void {
		this.endVal = clamp(this.endControl.value, this.min, this.max);
		this.monitorValue(this.endControl.valueChanges, x => {
			x = clamp(x, Math.max(this.min, this.startVal), this.max);
			if (x != this.endVal) {
				this.endVal = x;
				this.renderEnd();
			}
		});

		this.startVal = clamp(
			this.startControl.value,
			this.min,
			Math.min(this.max, this.endVal)
		);
		this.monitorValue(this.startControl.valueChanges, x => {
			x = clamp(x, this.min, Math.min(this.max, this.endVal));
			if (x != this.startVal) {
				this.startVal = x;
				this.renderStart();
			}
		});

		this.dynamicProp(
			() => {
				this.renderStart();
				this.renderEnd();
			},
			'min',
			'max',
			'step'
		);

		this.dynamicProp(() => this.renderLabels(), 'min', 'max', 'visualStep');
	}

	renderStart() {
		const newValue = clamp(this.startVal, this.min, this.max);
		const percentage = ((newValue - this.min) * 100) / (this.max - this.min);

		this.placeStartHandle(percentage);
	}

	renderEnd() {
		const newValue = clamp(this.endVal, this.min, this.max);
		const percentage = ((newValue - this.min) * 100) / (this.max - this.min);

		this.placeEndHandle(percentage);
	}

	renderLabels() {
		const step = +(this.visualStep ?? this.step);
		const stepCount = Math.floor((this.max - this.min) / step);
		const stepPercentage = 100 / stepCount;
		const stepSize = 100 / (stepCount + 1);

		const labels: SliderLabel[] = [];

		for (let i = 0; i <= stepCount; i++) {
			labels.push({
				label: (+this.min + i * step).toString(),
				width: stepSize,
				position: i * stepPercentage,
			});
		}

		this.labels = labels;
	}

	dropStart(event: CdkDragEnd) {
		const sliderWidth = this.sliderElement.nativeElement.offsetWidth;
		const currentPosition =
			event.source.element.nativeElement.offsetLeft +
			event.source.getFreeDragPosition().x;
		const value = (currentPosition * 100) / sliderWidth;

		const stepCount = Math.floor((this.max - this.min) / this.step);
		const stepPercentage = 100 / stepCount;

		const newValue = clamp(
			Math.round(value / stepPercentage) + +this.min,
			this.min,
			Math.min(this.endVal ?? this.max, this.max)
		);
		const percentage = ((newValue - this.min) * 100) / (this.max - this.min);

		this.placeStartHandle(percentage);
		this.startControl.setValue(newValue);
	}

	placeStartHandle(percentage: number) {
		percentage = Math.round(percentage * 1000) / 1000;

		this.rangeElement.nativeElement.style.left = percentage + '%';
		this.minHandleElement.element.nativeElement.style.right =
			100 - percentage + '%';
		this.minHandleElement._dragRef.reset();
		this.maxHandleElement.element.nativeElement.parentElement.style.marginLeft =
			-1 * (100 - percentage - 50) + '%';

		if (percentage <= 50) {
			this.maxHandleElement.element.nativeElement.parentElement.style.paddingLeft =
				100 - percentage - 50 + '%';
			this.maxHandleElement.element.nativeElement.parentElement.style.width =
				'';
		} else {
			this.maxHandleElement.element.nativeElement.parentElement.style.width =
				100 - percentage + '%';
			this.maxHandleElement.element.nativeElement.parentElement.style.paddingLeft =
				'';
		}
	}

	dropEnd(event: CdkDragEnd) {
		const sliderWidth = this.sliderElement.nativeElement.offsetWidth;
		const currentPosition =
			event.source.element.nativeElement.offsetLeft +
			event.source.getFreeDragPosition().x;
		const value = (currentPosition * 100) / sliderWidth;

		const stepCount = Math.floor((this.max - this.min) / this.step);
		const stepPercentage = 100 / stepCount;

		const newValue = clamp(
			Math.round(value / stepPercentage) + +this.min,
			Math.max(this.startVal ?? this.min, this.min),
			this.max
		);
		const percentage = ((newValue - this.min) * 100) / (this.max - this.min);

		this.placeEndHandle(percentage);
		this.endControl.setValue(newValue);
	}

	placeEndHandle(percentage: number) {
		percentage = Math.round(percentage * 1000) / 1000;

		this.rangeElement.nativeElement.style.right = 100 - percentage + '%';
		this.maxHandleElement.element.nativeElement.style.left = percentage + '%';
		this.maxHandleElement._dragRef.reset();
		this.minHandleElement.element.nativeElement.parentElement.style.marginRight =
			-1 * (percentage - 50) + '%';

		if (percentage >= 50) {
			this.minHandleElement.element.nativeElement.parentElement.style.paddingRight =
				percentage - 50 + '%';
			this.minHandleElement.element.nativeElement.parentElement.style.width =
				'';
		} else {
			this.minHandleElement.element.nativeElement.parentElement.style.width =
				percentage + '%';
			this.minHandleElement.element.nativeElement.parentElement.style.paddingRight =
				'';
		}
	}

	moveStart(event: CdkDragMove) {
		this.rangeElement.nativeElement.style.left =
			event.source.element.nativeElement.offsetLeft +
			event.source.getFreeDragPosition().x +
			20 +
			'px';
	}

	moveEnd(event: CdkDragMove) {
		const sliderWidth = this.sliderElement.nativeElement.offsetWidth;
		const element = event.source.element.nativeElement;
		const right =
			sliderWidth - element.offsetLeft - event.source.getFreeDragPosition().x;
		this.rangeElement.nativeElement.style.right = right + 'px';
	}

	readonly trackByIdentity: CoTrackByFunction<unknown> = (
		_index: number,
		value: unknown
	) => value;
}
