import { inject, Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { loadSupport, loadSupportById } from './support.actions';
import { Action } from '@ngrx/store';
import { ConnectSupportState } from '@consensus/connect/shared/support/domain';
import { selectSupportState } from './support.selectors';
import { ApiEffects } from '@lib/redux';
import { SupportClient } from './support.client';

@Injectable()
export class SupportEffects extends ApiEffects<ConnectSupportState> {
	readonly #client = inject(SupportClient);
	readonly #actions$ = inject(Actions);
	constructor() {
		super(selectSupportState);
	}

	loadSupport$: Observable<Action> = createEffect(() =>
		this.generateApiEffect(loadSupport, () => this.#client.getCurrentSupport())(
			this.#actions$
		)
	);

	loadSupportById$: Observable<Action> = createEffect(() =>
		this.generateApiEffect(loadSupportById, action =>
			this.#client.getSupport(action.payload)
		)(this.#actions$)
	);
}
