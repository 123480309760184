// TODO(@LayZeeDK): [no-restricted-globals] Rewrite this function at some point
/* eslint-disable no-restricted-globals */
export function openInNewTab(url: string) {
	return new Promise<void>(resolve => {
		const anchor = document.createElement('a');
		anchor.target = '_blank';
		anchor.href = url;
		document.body.append(anchor);
		setTimeout(() => {
			anchor.click();
			setTimeout(() => {
				anchor.remove();
				resolve();
			});
		});
	});
}
/* eslint-enable no-restricted-globals */
