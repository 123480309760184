import { inject, Injectable } from '@angular/core';
import { RouterStore } from '@ngworker/router-component-store';
import {
	AddEmailModel,
	AddPhoneNumberModel,
	AuthAction,
	AuthResponse,
	ChangePasswordModel,
	TwoFactorModel,
	VerifyPhoneModel,
} from '@store/user';
import { HttpErrorResponse } from '@angular/common/http';
import { SessionService } from '@store/scope';
import { Router } from '@angular/router';
import { login } from '@store/user';
import { CoSnackService } from '@consensus/co/ui-snackbars';
import { AuthClient } from './auth.client';
import { dispatch } from '@lib/redux';
import { AnonLoginModel, LoginModel, PinLoginModel } from '@login/models';
import { environmentToken } from '@environments/environment';
import { CookieService } from '@consensus/shared/shared/analytics/data-access';
import { Capacitor } from '@capacitor/core';
import { InAppBrowser, UrlEvent } from '@capgo/inappbrowser';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
	readonly #cookieService = inject(CookieService);
	readonly #routerStore = inject(RouterStore);
	readonly #authClient = inject(AuthClient);
	readonly #snacks = inject(CoSnackService);
	readonly #router = inject(Router);
	readonly #sessionService = inject(SessionService);
	readonly #server = inject(environmentToken).server;
	readonly #webUrl = inject(environmentToken).wrappedApp?.webUrl;

	authAction: AuthAction;
	tempToken: string;

	loginAs(userId: string) {
		this.#authClient
			.loginAs(userId)
			.toPromise()
			.then(({ token }) => {
				localStorage.setItem('spoofToken', token);
				window.location.href = '/';
			});
	}

	spoofUser(userId: string) {
		localStorage.setItem('spoofUserId', userId);
		window.location.href = `/event/${this.#sessionService.eventSlug}`;
	}

	parseResponse(
		response: AuthResponse,
		spoof = false,
		noErrorRedirect = false
	) {
		// Handle login actions
		if (response.temporary) {
			if (!noErrorRedirect) {
				this.authAction = response.tempAction;
				this.tempToken = response.token;
				this.#router.navigate(['/', 'login', 'info']);
			}
			return false;
		}

		const token = response.token;

		// handle when external token results in redirect
		if (response.callbackUrl) {
			localStorage.removeItem('externalLoginToken');
			localStorage.removeItem('loginToken');
			localStorage.removeItem('spoofToken');

			//equivalent templating is performed backend for SAML login flows at the final response validation step
			location.href = response.callbackUrl.match(/{{token}}/)
				? response.callbackUrl.replace(/{{token}}/, token)
				: response.callbackUrl + `?token=${token}`;
			return false;
		}

		// Clear external token storage on success
		this.#authClient.externalToken = null;
		localStorage.removeItem('externalLoginToken');

		// Clear temp login details on success
		this.authAction = null;
		this.tempToken = null;

		if (spoof) {
			localStorage.setItem('spoofToken', token);
		} else {
			localStorage.setItem('loginToken', token);
		}

		const cookieValue: string | null = localStorage.getItem(
			this.#cookieService.cookieConsentStatus
		);
		if (cookieValue != null) {
			const syncValue = cookieValue === 'consent' ? true : false;
			this.#authClient.updateCookieConsent(syncValue).toPromise();
		}

		dispatch(login, response.user);
		return true;
	}

	async loadWhatCanIDo() {
		await this.#sessionService.loadWhatCanIDo();
	}

	async verifyToken(noErrorRedirect = false) {
		const response = await this.#authClient.verifyToken().toPromise();

		const spoofToken = localStorage.getItem('spoofToken');

		if (response == null) {
			if (spoofToken) {
				localStorage.removeItem('spoofToken');
			} else {
				localStorage.removeItem('loginToken');
			}
			return;
		}

		if (!this.parseResponse(response, !!spoofToken, noErrorRedirect)) {
			return false;
		}
		await this.loadWhatCanIDo();
		return true;
	}

	async ssoLogin(url: string, idpId: string, clientId: string) {
		if (Capacitor.isNativePlatform()) {
			const callBackUrl = `${this.#webUrl}/?auth=`; // TODO: Solve the need for the / [MAB]
			InAppBrowser.openWebView({ url, title: 'Login' });
			InAppBrowser.addListener('urlChangeEvent', (event: UrlEvent) => {
				if (event.url.startsWith(callBackUrl)) {
					const jwt = event.url.replaceAll(callBackUrl, '');
					this.#router.navigateByUrl(`/?auth=${jwt}`);
					InAppBrowser.close();
				}
			});
		} else {
			const token = await firstValueFrom(
				this.#routerStore.selectQueryParam('externalToken')
			);

			if (token) {
				localStorage.setItem('externalLoginToken', token);

				//"external" tokens are used by non-Connect apps within Consensus, such as AR and Moderator.
				//In these cases we want to avoid being redirected to the default url for the Connect web app,
				//which will trigger smartPhone OS to use the "WrappedApp" native app to navigate there.
				//TODO finish essay later
				url = `${
					this.#server
				}/api/auth/sso/saml/${clientId}/${idpId}?externalToken=${token}`;
			}
			location.href = url;
		}
	}

	async userLogin(data: LoginModel, clientId: string) {
		const response = await this.#authClient
			.userLogin(data, clientId)
			.toPromise();
		if (!this.parseResponse(response)) {
			return false;
		}
		await this.loadWhatCanIDo();
		return true;
	}

	async adminLogin(data: LoginModel) {
		const response = await this.#authClient.adminLogin(data).toPromise();
		if (!this.parseResponse(response)) {
			return false;
		}
		await this.loadWhatCanIDo();
		return true;
	}

	async anonLogin(data: AnonLoginModel) {
		const response = await this.#authClient.anonLogin(data).toPromise();
		if (!this.parseResponse(response)) {
			return false;
		}
		await this.loadWhatCanIDo();
		return true;
	}

	async pinLogin(
		data: PinLoginModel,
		settings: { clientLogin: boolean } = { clientLogin: false }
	) {
		const response = await this.#authClient
			.pinLogin(data, settings)
			.toPromise();
		if (!this.parseResponse(response)) {
			return false;
		}
		await this.loadWhatCanIDo();
		return true;
	}

	async resetPassword(data: ChangePasswordModel) {
		const response = await this.#authClient
			.resetPassword(data, this.tempToken)
			.toPromise();
		if (!this.parseResponse(response)) {
			return false;
		}
		await this.loadWhatCanIDo();
		return true;
	}

	async saveEmail(data: AddEmailModel) {
		const response = await this.#authClient
			.saveEmail(data, this.tempToken)
			.toPromise();
		if (!this.parseResponse(response)) {
			return false;
		}
		await this.loadWhatCanIDo();
		return true;
	}

	sendEmail() {
		return this.#authClient.sendEmail(this.tempToken).toPromise();
	}

	async savePhone(data: AddPhoneNumberModel) {
		const response = await this.#authClient
			.savePhone(data, this.tempToken)
			.toPromise();
		if (!this.parseResponse(response)) {
			return false;
		}
		await this.loadWhatCanIDo();
		return true;
	}

	async verifyPhone(data: VerifyPhoneModel) {
		const response = await this.#authClient
			.verifyPhone(data, this.tempToken)
			.toPromise();
		if (!this.parseResponse(response)) {
			return false;
		}
		await this.loadWhatCanIDo();
		return true;
	}

	async verifyEmail() {
		const response = await this.#authClient
			.verifyEmail(this.tempToken)
			.toPromise();
		if (!this.parseResponse(response)) {
			return false;
		}
		await this.loadWhatCanIDo();
		return true;
	}

	async verifyTwoFactor(data: TwoFactorModel) {
		const response = await this.#authClient
			.verifyTwoFactor(data, this.tempToken)
			.toPromise();
		if (!this.parseResponse(response)) {
			return false;
		}
		await this.loadWhatCanIDo();
		return true;
	}

	sendSms() {
		return this.#authClient.sendSms(this.tempToken).toPromise();
	}

	handleError(error: HttpErrorResponse) {
		console.error(error);

		const payload = error?.error ?? {};

		if (!payload.error) {
			payload.error = 'Something went wrong';
		}

		this.#snacks.error(payload.error, null, false, payload);
	}

	adminSsoLogin() {
		const url = `${this.#server}/api/auth/sso/admin/oauth/login`;
		if (Capacitor.isNativePlatform()) {
			const callBackUrl = `${this.#webUrl}/?auth=`; // TODO: Solve the need for the / [MAB]
			InAppBrowser.openWebView({ url, title: 'Login' });
			InAppBrowser.addListener('urlChangeEvent', (event: UrlEvent) => {
				if (event.url.startsWith(callBackUrl)) {
					const jwt = event.url.replaceAll(callBackUrl, '');
					this.#router.navigateByUrl(`/?auth=${jwt}`);
					InAppBrowser.close();
				}
			});
		} else {
			window.location.href = url;
		}
	}
}
