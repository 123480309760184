import { InjectionToken } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

export type SentryAngular = typeof Sentry;

function sentryFactory(): SentryAngular {
	return Sentry;
}
export const sentryToken = new InjectionToken<SentryAngular>('sentryToken', {
	providedIn: 'root',
	factory: sentryFactory,
});
