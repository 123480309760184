import { ApiAction } from '@lib/redux';
import { PageTheming, Theme, ThemingState } from './theming.model';

export const loadTheme = new ApiAction<ThemingState, void, Theme>(
	'Theming',
	'Load',
	'Theming',
	{
		showErrors: true,
	}
);

export const loadThemeById = new ApiAction<ThemingState, string, Theme>(
	'Specific Theme',
	'Load',
	'Theming',
	{
		showErrors: true,
	}
);

export const loadPageTheming = new ApiAction<ThemingState, void, PageTheming[]>(
	'Page Theming',
	'Load',
	'Theming',
	{
		showErrors: true,
	}
);

export const loadThemeAnon = new ApiAction<ThemingState, string, Theme>(
	'Anon Theming',
	'Load',
	'Theming',
	{
		showErrors: true,
	}
);

export const updatePageTheming = new ApiAction<
	ThemingState,
	PageTheming,
	PageTheming
>('Page Theming', 'Update', 'Theming', {
	showErrors: true,
	successMessage: 'Updated Page Theming',
});
