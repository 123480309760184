import { sortBySortingKeyAsc } from '@lib/helpers';
import { Selector } from '@lib/redux';
import { ColearnState, colearnKey } from './colearn.reducer';

interface EventFeatureState {
	[colearnKey]: ColearnState;
}

export const getColearnState = Selector.fromFeature(
	'event',
	(state: EventFeatureState) => state.colearn
);

export const getActiveColearnSections = getColearnState.create(s => s.sections);

export const getColearnSettings = getColearnState.create(s => s.settings);

export const getColearnSectionSettings = getColearnState.create(
	s => s.sectionSettings
);

export const getColearnLeaderboardCompetitions = getColearnState.create(s =>
	[...s.competitions].sort(sortBySortingKeyAsc)
);
