import { Directive, ElementRef, inject, OnInit } from '@angular/core';
import { SessionService } from '@store/scope';
import { novofy } from '@lib/helpers';

@Directive({
	standalone: true,
	selector: '[coNovofy]',
})
export class NovofyDirective implements OnInit {
	readonly #session = inject(SessionService);
	readonly #element: ElementRef<HTMLElement> = inject(ElementRef);

	ngOnInit() {
		this.#element.nativeElement.innerText = novofy(
			this.#session.isSema,
			this.#element.nativeElement.innerText
		);
	}
}
