import { EventSessionItem } from '@consensus/connect/ufa/agenda/domain';
import { BaseState, initialBaseState } from '@shared/models';
import {
	addSessionItem,
	completeSurvey,
	loadSessionItems,
	loadWordCloudUserSubmissions,
	removeSessionItem,
	submitWordCloudWord,
} from './session-item.actions';
import { ApiReducer } from '@lib/redux';
import { removeListItem, updateListItem } from '@lib/redux';

export interface SessionItemState extends BaseState {
	sessionItems: EventSessionItem[];
}

const initialState: SessionItemState = {
	...initialBaseState,
	sessionItems: [],
};

const reducer = new ApiReducer(initialState, 'Event');

reducer.addApiReducer(loadSessionItems, pushouts => ({
	sessionItems: pushouts,
}));

reducer.addReducer(addSessionItem, (pushout, { sessionItems }) => ({
	sessionItems: updateListItem(
		sessionItems,
		p => p.id === pushout.id,
		pushout,
		true
	),
}));

reducer.addReducer(removeSessionItem, (id, { sessionItems }) => ({
	sessionItems: removeListItem(sessionItems, p => p.id === id),
}));

reducer.addReducer(completeSurvey, (id, { sessionItems }) => ({
	sessionItems: updateListItem(
		sessionItems,
		p => p.id === id,
		p => ({
			survey: { ...p.survey, completed: true },
		})
	),
}));

reducer.addApiReducer(
	loadWordCloudUserSubmissions,
	(wordCloudSubmissions, { sessionItems }, wordCloudId) => ({
		sessionItems: updateListItem(
			sessionItems,
			si => si.wordCloud && si.wordCloud.id == wordCloudId,
			si => ({
				wordCloud: { ...si.wordCloud, submissions: wordCloudSubmissions },
			})
		),
	})
);

reducer.addApiReducer(
	submitWordCloudWord,
	(wordSubmission, { sessionItems }, _payload) => ({
		sessionItems: updateListItem(
			sessionItems,
			si => si.wordCloud && si.wordCloud.id === wordSubmission.wordCloudId,
			si => ({
				wordCloud: {
					...si.wordCloud,
					submissions: [...si.wordCloud.submissions, wordSubmission],
				},
			})
		),
	})
);

export const sessionItemReducer = reducer.getReducer();
