<div matSnackBarLabel class="tw-flex tw-flex-row tw-items-center tw-space-x-2">
	<fa-icon [icon]="typeIcon" class="tw-text-red-700" />
	<div class="tw-flex tw-flex-col">
		<div *ngIf="data.title" class="tw-font-bold">
			{{ data.title }}
		</div>
		<div *ngIf="!data.isHtml">
			{{ data.message }}
		</div>
		<div *ngIf="data.isHtml" [innerHTML]="data.message"></div>
		<div
			class="tw-text-xs tw-font-thin tw-text-gray-500"
			*ngIf="data.payload?.['correlationId'] as correlationId"
		>
			ID: {{ correlationId }}
		</div>
	</div>
</div>
<div matSnackBarActions>
	<button
		matSnackBarAction
		mat-button
		(click)="onErrorCopy()"
		*ngIf="!data.isHtml"
	>
		COPY
	</button>
	<button matSnackBarAction mat-button (click)="onErrorDismiss()">
		CLOSE
	</button>
</div>
