import { ApiAction } from '@lib/redux';
import { ColearnSettings } from '@shared/models';
import {
	ColearnCompetition,
	ColearnProgressSection,
	ColearnSectionOptions,
} from '@consensus/connect/ufa/colearn/domain';
import { ColearnState } from './colearn.reducer';
import { createAction } from '@ngrx/store';

export const loadColearnSections = new ApiAction<
	ColearnState,
	void,
	ColearnProgressSection[]
>('Colearn sections', 'Load', 'Event Colearn', {
	showErrors: true,
	initialLoad: true,
});

export const loadColearnSettings = new ApiAction<
	ColearnState,
	void,
	ColearnSettings
>('Settings', 'Load', 'Event Colearn', {
	showErrors: true,
	initialLoad: true,
});

export const loadColearnProgressSettings = new ApiAction<
	ColearnState,
	void,
	ColearnSectionOptions
>('Colearn progress settings', 'Load', 'Event', {
	showErrors: true,
	initialLoad: true,
});

export const loadColearnLeaderboardCompetitions = new ApiAction<
	ColearnState,
	void,
	ColearnCompetition[]
>('Colearn leaderboard competitions', 'Load', 'Event', {
	showErrors: true,
	initialLoad: true,
});

export const colearnSettingsUpdated = createAction(
	'[Colearn socket] Settings: Updated',
	(payload: ColearnSettings) => ({ payload })
);
export const colearnCompetitionUpdated = createAction(
	'[Colearn socket] Competition: Updated',
	(payload: ColearnCompetition) => ({ payload })
);
export const colearnCompetitionRemoved = createAction(
	'[Colearn socket] Competition: Removed',
	(payload: string) => ({ payload })
);
export const colearnProgressSectionsUpdated = createAction(
	'[Colearn socket] Progress Sections: Updated',
	(payload: ColearnProgressSection[]) => ({ payload })
);
export const colearnProgressSettingsUpdated = createAction(
	'[Colearn socket] Progress Settings: Updated',
	payload => ({ payload })
);
