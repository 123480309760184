import { BaseState } from '@shared/models';
import { Distribution, DistributionUpdateModel } from '@store/distributions';

export interface AdminLivestreamState extends BaseState {
	livestreams: AdminLivestream[];
}

export interface AdminLivestream {
	id: string;
	title: string;
	sortingKey: number;
	description: string;
	livestreamUrl: string;
	startTime?: Date;
	optional: boolean;
	sessionId: string;

	active: boolean;

	distribution?: Distribution;
}

// Action Models

export interface LivestreamCreateModel {
	title: string;
	sessionId?: string;
}

export interface LivestreamUpdateModel {
	id: string;
	title: string;
	description: string;
	livestreamUrl: string;
	startTime?: Date;
	optional: boolean;
	sessionId: string;

	distribution?: DistributionUpdateModel;
}

export interface ToggleLivestreamModel {
	id: string;
	active: boolean;
}

export interface LivestreamLogEntryModel {
	id: string;
	livestreamPlayerInstanceName: string;
	muted: boolean;
	livestreamLogEntryType: LivestreamLogEntryType;
}

export enum LivestreamLogEntryType {
	Open = 'Open',
	Close = 'Close',
	Mute = 'Mute',
	Unmute = 'Unmute',
	GoToFullscreen = 'GoToFullscreen',
	GoToPip = 'GoToPip',
}

export interface GetLivestreamAttendanceReportRequestDto {
	eventId: string;
	livestreamIds: string[];
	userIds?: string[];
	start?: Date;
	end?: Date;
}
