import { initialBaseState } from '@shared/models';
import { CmsEliminationGameState } from './cms-elimination-game.model';
import {
	advanceEliminationGame,
	duplicateEliminationGame,
	createEliminationGame,
	createEliminationGameItem,
	deleteEliminationGame,
	deleteEliminationGameItem,
	loadAllEliminationGames,
	moveEliminationGame,
	moveEliminationGameItem,
	startEliminationGame,
	stopEliminationGame,
	updateEliminationGame,
	updateEliminationGameItem,
	updateEliminationGameState,
} from './cms-elimination-game.actions';
import { ApiReducer } from '@lib/redux';
import {
	moveListItem,
	removeListItemWithIndex,
	updateListItem,
} from '@lib/redux';
import { CmsEliminationGame } from '@consensus/connect/cms/elimination-game/domain';

const initialState: CmsEliminationGameState = {
	...initialBaseState,
	eliminationGames: [],
	eliminationGameItems: [],
};

const reducer = new ApiReducer(initialState, 'Event');

reducer.addApiReducer(
	loadAllEliminationGames,
	({ eliminationGames, eliminationGameItems }) => ({
		eliminationGames: eliminationGames,
		eliminationGameItems: eliminationGameItems,
	})
);

reducer.addApiReducer(
	createEliminationGame,
	(eliminationGame, { eliminationGames }) => ({
		eliminationGames: [...eliminationGames, eliminationGame],
	})
);

reducer.addApiReducer(
	updateEliminationGame,
	(
		{ state, ...eliminationGame },
		{ eliminationGames }
	): Partial<CmsEliminationGameState> => ({
		eliminationGames: updateListItem<CmsEliminationGame>(
			eliminationGames,
			x => x.id === eliminationGame.id,
			eliminationGame
		),
	})
);

reducer.addApiReducer(
	duplicateEliminationGame,
	(eliminationGame, { eliminationGames, eliminationGameItems }) => ({
		eliminationGames: [...eliminationGames, eliminationGame],
		eliminationGameItems: [
			...eliminationGameItems,
			...(eliminationGame.items ?? []),
		],
	})
);

reducer.addApiReducer(deleteEliminationGame, (id, { eliminationGames }) => ({
	eliminationGames: removeListItemWithIndex(eliminationGames, x => x.id === id),
}));

reducer.addApiReducer(
	moveEliminationGame,
	({ id, sortingKey }, { eliminationGames }) => ({
		eliminationGames: moveListItem(
			eliminationGames,
			x => x.id === id,
			sortingKey
		),
	})
);

reducer.addApiReducer(
	createEliminationGameItem,
	(item, { eliminationGameItems }) => ({
		eliminationGameItems: [...eliminationGameItems, item],
	})
);

reducer.addApiReducer(
	updateEliminationGameItem,
	(item, { eliminationGameItems }) => ({
		eliminationGameItems: updateListItem(
			eliminationGameItems,
			x => x.id === item.id,
			item
		),
	})
);

reducer.addApiReducer(
	deleteEliminationGameItem,
	(id, { eliminationGameItems }) => ({
		eliminationGameItems: removeListItemWithIndex(
			eliminationGameItems,
			x => x.id === id,
			a => a.eliminationGameId
		),
	})
);

reducer.addApiReducer(
	moveEliminationGameItem,
	({ id, sortingKey }, { eliminationGameItems }) => ({
		eliminationGameItems: moveListItem(
			eliminationGameItems,
			x => x.id === id,
			sortingKey,
			x => x.eliminationGameId
		),
	})
);

reducer.addApiReducer(
	startEliminationGame,
	(state, { eliminationGames }, eliminationGameId) => ({
		eliminationGames: updateListItem(
			eliminationGames,
			x => x.id === eliminationGameId,
			{
				state,
			}
		),
	})
);

reducer.addApiReducer(
	stopEliminationGame,
	(state, { eliminationGames }, eliminationGameId) => ({
		eliminationGames: updateListItem(
			eliminationGames,
			x => x.id === eliminationGameId,
			{
				state,
			}
		),
	})
);

reducer.addApiReducer(
	advanceEliminationGame,
	(state, { eliminationGames }, eliminationGameId) => ({
		eliminationGames: updateListItem(
			eliminationGames,
			x => x.id === eliminationGameId,
			{
				state,
			}
		),
	})
);

reducer.addApiReducer(
	updateEliminationGameState,
	(state, { eliminationGames }, payload) => ({
		eliminationGames: updateListItem(
			eliminationGames,
			x => x.id === payload?.eliminationGameId,
			{
				state,
			}
		),
	})
);

export const cmsEliminationGameReducer = reducer.getReducer();
