import { Action, createAction } from '@ngrx/store';
import { StoreScopes } from './store-scopes';

export function isResetStoresAction(
	action: Action
): action is ReturnType<typeof resetStores> {
	return action.type === resetStores.type;
}

export const resetStores = createAction(
	'[Global] Reset Stores',
	(payload: StoreScopes) => ({ payload })
);

export const resetAllStores = createAction('[Global] Reset All Stores');
