import { BehaviorSubject } from 'rxjs';
import { LivestreamPlayer } from './livestream-player';

export class LivestreamPlayerInstance {
	#active$ = new BehaviorSubject(true);
	name: string | null;

	get active$() {
		return this.#active$.asObservable();
	}

	set active(b: boolean) {
		this.#active$.next(b);
	}

	get active() {
		return this.#active$.value;
	}

	get show() {
		return this.active && this.player.hasContent;
	}

	get player() {
		return this._player;
	}

	constructor(
		private _player: LivestreamPlayer,
		private destroyed: (i: LivestreamPlayerInstance) => void,
		name = null
	) {
		this.name = name;
	}

	destroy() {
		this.active = false;
		this.destroyed(this);
	}
}
