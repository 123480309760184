import { environmentToken } from '@environments/environment';
import { FileModel } from '@lib/files';
import { SessionService } from '@store/scope';
import { TokenService } from '@consensus/shared/shared/iam/data-access';
import { globalInjector } from '@lib/global-injector';

export class PageEditorFile extends HTMLImageElement {
	static get observedAttributes() {
		return ['data-imageid'];
	}

	// This attribute is here to allow the callar to feature detect if the "is" attribute works
	isPageEditorFileApplied = true;

	constructor() {
		super();

		const imageId = this.getAttribute('data-imageid');
		if (imageId) {
			this.updateImage(imageId);
		}
	}

	attributeChangedCallback(name: string, oldValue: string, newValue: string) {
		if (name === 'data-imageid' && oldValue !== newValue) {
			this.updateImage(newValue);
		}
	}

	async updateImage(id: string) {
		const url = await this.loadImageUrl(id);
		this.src = url;
	}

	async loadImageUrl(id: string): Promise<string> {
		return loadImageUrlFromCacheOrRemote(id);
	}
}

customElements.define('page-editor-file', PageEditorFile, { extends: 'img' });

export const loadImageUrlFromCacheOrRemote = async (
	id: string
): Promise<string> => {
	const cachedUrl = urlCache.get(id);
	if (cachedUrl) {
		return cachedUrl;
	}

	const imagePath = `pageeditor/${id}`;
	let promise = promiseCache.get(imagePath);
	if (!promise) {
		promise = loadImageUrl(imagePath);
		promiseCache.add(imagePath, promise);
	}

	const url = await promise;
	promiseCache.remove(imagePath);
	urlCache.add(id, url);
	return url;
};

const urlCache = new (class URLCache {
	#cache: Record<string, string> = {};

	add(id: string, url: string) {
		this.#cache[id] = url;
	}
	get(id: string): string | null {
		const url = this.#cache[id];
		if (!url) {
			return null;
		}

		// If the URL expires in less then five minutes then a the cache is invalid
		const match = url.match(/Expires=(\d+)/);
		if (match && Number(match[1]) * 1000 > Date.now() - 5 * 1000 * 60) {
			delete this.#cache[id];
			return null;
		}

		// Reuse cached url
		return url;
	}
})();

const promiseCache = new (class PromiseCache {
	#cache: Record<string, Promise<string>> = {};

	add(id: string, url: Promise<string>) {
		this.#cache[id] = url;
	}
	get(id: string): Promise<string> | null {
		return this.#cache[id];
	}
	remove(id: string) {
		delete this.#cache[id];
	}
})();

const loadImageUrl = async (imagePath: string) => {
	const tokenService = globalInjector.get(TokenService);
	const token = tokenService.getLoginToken();
	const { mediaServer } = globalInjector.get(environmentToken);
	const sessionService = globalInjector.get(SessionService);

	const authHeaders = {
		clientId: sessionService.clientId,
		eventId: sessionService.eventId,
		authorization: `Bearer ${token}`,
	};

	const { url } = await fetch(`${mediaServer}/${imagePath}`, {
		headers: authHeaders,
	}).then(resp => resp.json() as Promise<FileModel>);
	if (url) {
		return url;
	}

	// We don't support signed urls on development
	const blob = await fetch(
		`${mediaServer}/${imagePath}/read?time=${Date.now()}`,
		{
			headers: authHeaders,
		}
	).then(resp => resp.blob());
	return URL.createObjectURL(blob);
};
