import { Directive, ElementRef, inject, Input } from '@angular/core';
import anime, { AnimeInstance } from 'animejs';

@Directive({
	standalone: true,
	selector: '[coCountTo]',
})
export class CountToDirective {
	readonly #element: ElementRef<HTMLElement> = inject(ElementRef);

	#counter = { value: 0 };
	#animation: AnimeInstance;

	@Input('coCountTo') set countTo(endValue: number) {
		if (!endValue && endValue !== 0) {
			return;
		}
		endValue = Math.trunc(endValue);
		if (this.#animation) {
			this.#animation.pause();
		}

		this.#animation = anime({
			targets: this.#counter,
			value: endValue,
			round: 1,
			easing: 'easeInOutQuad',
			duration: Math.max(
				300,
				Math.min(2000, Math.abs(this.#counter.value - endValue))
			),
			update: () =>
				(this.#element.nativeElement.innerText =
					this.#counter.value.toLocaleString()),
			begin: () =>
				(this.#element.nativeElement.innerText =
					this.#counter.value.toLocaleString()),
			complete: () =>
				(this.#element.nativeElement.innerText = endValue.toLocaleString()),
		});
	}

	constructor() {
		this.#element.nativeElement.innerText = '0';
	}
}
