import { Subject } from 'rxjs';

export class FormProxy {
	canSubmit = false;
	canCreate = false;

	saving = false;

	submit$ = new Subject<void>();

	submit() {
		if (!this.canSubmit) {
			return;
		}
		this.submit$.next();
	}

	create() {
		if (!this.canCreate) {
			return;
		}
		this.submit$.next();
	}
}
