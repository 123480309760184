import { ConnectEnvironment } from './connect-environment';

function stripTailingSlash(url: string) {
	return url.endsWith('/') ? url.slice(0, -1) : url;
}

export async function loadEnvironment(): Promise<ConnectEnvironment> {
	const response = await fetch('/config/config.json');
	const environment: ConnectEnvironment = await response.json();
	const sanitizedEnv = {
		...environment,
		server: stripTailingSlash(environment.server),
		mediaServer: stripTailingSlash(environment.mediaServer),
	};
	if (sanitizedEnv.wrappedApp?.webUrl) {
		sanitizedEnv.wrappedApp = {
			...sanitizedEnv.wrappedApp,
			webUrl: stripTailingSlash(sanitizedEnv.wrappedApp.webUrl),
		};
	}
	return sanitizedEnv;
}
