import { initialBaseState } from '@shared/models';
import { ApiReducer } from '@lib/redux';
import { Theme, ThemingState } from './theming.model';
import {
	loadPageTheming,
	loadTheme,
	loadThemeAnon,
	loadThemeById,
	updatePageTheming,
} from './theming.actions';
import { updateListItem } from '@lib/redux';

export const defaultTheme: Theme = {
	id: 'na',
	name: 'Default',
	private: false,
	dark: false,
	semaStyling: false,
	useBackground: false,
	useDashboardLogo: false,
	useOverviewLogo: false,
	useEliminationLogo: false,
	useLoginLogo: false,
	useSurveyResultsBackground: false,
	styles: {
		primary: '#3f51b5',
		primaryText: '#FFFFFF',
		secondary: '#ff4081',
		secondaryText: '#FFFFFF',
		textColor: '#000000CC',
		backgroundContrast: '#FFFFFF',
		landingPageColor: '#3f51b5',
		landingPageContrast: '#FFFFFF',
	},
};

const initialState: ThemingState = {
	...initialBaseState,
	theme: null,
	pageTheming: [],
};

const reducer = new ApiReducer(initialState, 'Global');

reducer.addApiReducer(loadTheme, theme => ({ theme }));
reducer.addApiReducer(loadThemeById, theme => ({ theme }));
reducer.addApiReducer(loadThemeAnon, theme => ({ theme }));
reducer.addApiReducer(loadPageTheming, pageTheming => ({ pageTheming }));

reducer.addApiReducer(updatePageTheming, (page, { pageTheming }) => ({
	pageTheming: updateListItem(pageTheming, p => p.id === page.id, page, true),
}));

export const themingReducer = reducer.getReducer();
