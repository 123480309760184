import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
	ColearnAdminCompetition,
	ColearnCompetitionLoadModel,
	ColearnTeam,
	ColearnTeamMember,
	ColearnTier,
	CompetitionUpdateModel,
	TeamCreateModel,
	TeamMemberCreateModel,
	TeamMemberUpdateModel,
	TeamUpdateModel,
	TierCreateModel,
	TierUpdateModel,
} from '@consensus/connect/cms/colearn/domain';
import { MoveModel } from '@shared/models';
import { ColearnCompetition } from '@consensus/connect/ufa/colearn/domain';
import { environmentToken } from '@environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ColearnCompetitionClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #httpClient = inject(HttpClient);

	//Load:
	loadColearnCompetitions() {
		return this.#httpClient.get<ColearnCompetitionLoadModel>(
			`${this.#apiServer}/api/colearn/competition/load`
		);
	}

	loadColearnLeaderboardCompetitions() {
		return this.#httpClient.get<ColearnCompetition[]>(
			`${this.#apiServer}/api/colearn/competition/leaderboard`
		);
	}

	//Create:
	createCompetition(title: string) {
		return this.#httpClient.post<ColearnAdminCompetition>(
			`${this.#apiServer}/api/colearn/competition`,
			{ title }
		);
	}

	createTeam({ parentId, name }: TeamCreateModel) {
		return this.#httpClient.post<ColearnTeam>(
			`${this.#apiServer}/api/colearn/competition/${parentId}/teams`,
			{ name }
		);
	}

	createTeamMember(model: TeamMemberCreateModel) {
		return this.#httpClient.post<ColearnTeamMember>(
			`${this.#apiServer}/api/colearn/competition/teams/team-member`,
			model
		);
	}

	createTier({ parentId, name }: TierCreateModel) {
		return this.#httpClient.post<ColearnTier>(
			`${this.#apiServer}/api/colearn/competition/${parentId}/tiers`,
			{ name }
		);
	}

	//Update:
	updateCompetition({ id, ...data }: CompetitionUpdateModel) {
		return this.#httpClient.put<ColearnAdminCompetition>(
			`${this.#apiServer}/api/colearn/competition/${id}`,
			data
		);
	}

	updateTeam({ id, ...model }: TeamUpdateModel) {
		return this.#httpClient.put<ColearnTeam>(
			`${this.#apiServer}/api/colearn/competition/team/${id}`,
			model
		);
	}

	updateTeamMember(model: TeamMemberUpdateModel) {
		return this.#httpClient.put<ColearnTeamMember>(
			`${this.#apiServer}/api/colearn/competition/teams/team-member`,
			model
		);
	}

	updateTier({ id, ...model }: TierUpdateModel) {
		return this.#httpClient.put<ColearnTier>(
			`${this.#apiServer}/api/colearn/competition/tier/${id}`,
			model
		);
	}

	//Move:
	moveCompetition({ id, sortingKey }: MoveModel) {
		return this.#httpClient.patch(
			`${this.#apiServer}/api/colearn/competition/${id}/move`,
			{
				sortingKey,
			}
		);
	}

	moveTier({ id, sortingKey }: MoveModel) {
		return this.#httpClient.patch(
			`${this.#apiServer}/api/colearn/competition/tier/${id}/move`,
			{
				sortingKey,
			}
		);
	}

	//Delete
	deleteCompetition(id: string) {
		return this.#httpClient.delete(
			`${this.#apiServer}/api/colearn/competition/${id}`
		);
	}

	deleteTeam(id: string) {
		return this.#httpClient.delete(
			`${this.#apiServer}/api/colearn/competition/teams/${id}`
		);
	}

	deleteTeamMember(model: { teamId: string; userId: string }) {
		return this.#httpClient.delete(
			`${this.#apiServer}/api/colearn/competition/teams/team-member?teamId=${
				model.teamId
			}&userId=${model.userId}`
		);
	}

	deleteTier(id: string) {
		return this.#httpClient.delete(
			`${this.#apiServer}/api/colearn/competition/tiers/${id}`
		);
	}
}
