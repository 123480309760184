import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { UserInformation } from '@shared/models';
import { Observable } from 'rxjs';
import { environmentToken } from '@environments/environment';

@Injectable({
	providedIn: 'root',
})
export class UsersClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #http = inject(HttpClient);

	loadEventUsers(): Observable<UserInformation[]> {
		return this.#http.get<UserInformation[]>(
			`${this.#apiServer}/api/user/event`
		);
	}
}
