import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OverlayService {
	readonly #document = inject(DOCUMENT);

	baseOverlayZIndex = 500;
	tokenStack: OverlayToken[] = [];

	constructor() {
		window.addEventListener('keydown', event => {
			if (event.code === 'Escape' && this.tokenStack.length) {
				this.tokenStack[this.tokenStack.length - 1].escape$.next();
			}
		});
	}

	queueOverlay(): OverlayToken {
		const token = new OverlayToken(
			(this.tokenStack[0]?.zIndex ?? this.baseOverlayZIndex) - 2,
			x => this.removeToken(x)
		);
		this.tokenStack = [token, ...this.tokenStack];
		this.updateStack();
		return token;
	}

	stackOverlay(): OverlayToken {
		const token = new OverlayToken(
			(this.tokenStack[this.tokenStack.length - 1]?.zIndex ??
				this.baseOverlayZIndex) + 5,
			x => this.removeToken(x)
		);
		this.tokenStack.push(token);
		this.updateStack();
		return token;
	}

	removeToken(token: OverlayToken) {
		const index = this.tokenStack.findIndex(x => x === token);
		if (index < 0) {
			return;
		}
		this.tokenStack.splice(index, 1);
		this.updateStack();
	}

	updateStack() {
		if (this.tokenStack.length) {
			this.tokenStack[this.tokenStack.length - 1].isTop$.next(true);
			this.#document.documentElement.classList.add('no-scroll');
		} else {
			this.#document.documentElement.classList.remove('no-scroll');
		}

		if (this.tokenStack.length > 1) {
			this.tokenStack
				.slice(0, this.tokenStack.length - 1)
				.forEach(t => t.isTop$.next(false));
		}
	}
}

export class OverlayToken {
	protected createdAt = new Date();
	protected escapeMethod: () => void;
	escape$ = new Subject<void>();
	isTop$ = new BehaviorSubject(false);

	constructor(
		readonly zIndex: number,
		private destroyHandler: (x: OverlayToken) => void
	) {
		this.escape$.subscribe(() => this.escapeMethod && this.escapeMethod());
	}

	handleEscape(action: () => void) {
		this.escapeMethod = action;
	}

	destroy() {
		this.destroyHandler(this);
		this.escape$.complete();
		this.isTop$.complete();
	}
}
