import { createReducer, on } from '@ngrx/store';
import { UserInformation } from '@shared/models';
import {
	ufaEventUsersActions,
	ufaEventUsersWebsocketActions,
} from './ufa-event-users.actions';
import { upsertItem } from '@consensus/co/util-arrays';

export interface EventUsersState {
	readonly users: readonly Readonly<UserInformation>[];
}

const initialState: EventUsersState = {
	users: [],
};

export const ufaEventUsersReducer = createReducer(
	initialState,
	on(
		ufaEventUsersActions.loadEventUsersSuccess,
		(state, { users }): EventUsersState => ({
			...state,
			users,
		})
	),
	on(
		ufaEventUsersWebsocketActions.setEventUser,
		(state, { user }): EventUsersState => ({
			...state,
			users: upsertItem(
				(x: UserInformation) => x.id === user.id,
				state.users,
				user
			),
		})
	)
);
