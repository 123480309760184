import { NgIf } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostBinding,
	inject,
	Input,
	OnDestroy,
	Output,
} from '@angular/core';
import { FileCacheService } from '@consensus/shared/shared/files/data-access-files';
import { BypassSecurityTrustResourceUrlPipe } from '@shared/pipes';
import { Subscription } from 'rxjs';

@Component({
	selector: 'co-encoded-img',
	templateUrl: './encoded-img.component.html',
	styleUrls: ['./encoded-img.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgIf, BypassSecurityTrustResourceUrlPipe],
	standalone: true,
})
export class EncodedImgComponent implements OnDestroy {
	readonly #fileService = inject(FileCacheService);
	readonly #changeDetectorRef = inject(ChangeDetectorRef);
	oldSrc: string | null = null;
	safeUrl: string | null = null;
	@HostBinding('class.failed-load') failed = false;
	@HostBinding('class.rendering') rendering = false;
	@HostBinding('class.loading') loading = false;

	retry = 0;

	@Input() alt: string | null = null;
	@Input() fallback: string | null = null;
	@Input() showError = true;
	@Input() thumbnail = false;
	@Output() domLoad = new EventEmitter<void>();
	@Output() failedLoad = new EventEmitter<void>();
	@Output() imgNotFound = new EventEmitter<void>();

	#src = '';
	@Input()
	set src(src: string) {
		if (src === this.#src) {
			return;
		}

		this.#src = src;
		this.retry = 3;
		this.#loadImage();
		this.#changeDetectorRef.markForCheck();
	}
	get src(): string {
		return this.#src;
	}

	#reload: Subscription | null = null;

	@Input()
	set attachment(id: string) {
		this.src = `attachment/${id}`;
	}

	@Input()
	set user(id: string) {
		this.src = `user/${id}`;
	}

	@Input()
	set speaker(id: string) {
		this.src = `speakers/${id}`;
	}

	@Input()
	set driveFileId(id: string) {
		this.src = `materials/${id}`;
	}

	@Input()
	set academyIcon(id: string) {
		this.src = `academy/icon/${id}`;
	}

	@Input()
	set academyTrack(id: string) {
		this.src = `academy/track/${id}`;
	}

	@Input()
	set academyResource([moduleId, resourceId]: [string, string]) {
		this.src = `academy/module/${moduleId}/resource/${resourceId}`;
	}

	@Input()
	set coachingExerciseFolder(id: string) {
		this.src = `academy/coaching/exercise-folder/${id}`;
	}

	@Input()
	set coachingExercise(id: string) {
		this.src = `academy/coaching/exercise/${id}`;
	}

	@Input()
	set coachingInstanceVideo([exerciseId, id]: [string, string]) {
		this.src = `academy/coaching/exercise/${exerciseId}/instance-video/${id}`;
	}

	@Input()
	set coachingFeedback([exerciseId, id]: [string, string]) {
		this.src = `academy/coaching/exercise/${exerciseId}/feedback/${id}`;
	}

	@Input()
	set videoRecording(id: string) {
		this.src = `video-recording/${id}`;
	}

	@Input()
	set pageBuilder(id: string) {
		this.src = `page-builder/${id}`;
	}

	@Input()
	set selfie({
		selfieId,
		userSelfieId,
	}: {
		selfieId: string; // The Session Item's Selfie entity id
		userSelfieId: string; // The ID of the user's Selfie submission
	}) {
		this.src = `selfies/${selfieId}/${userSelfieId}`;
	}

	@Input()
	set signature(id: string) {
		this.src = `signatures/${id}`;
	}

	@Input()
	set dashboard(id: string) {
		this.src = `dashboard/${id}`;
	}

	@Input()
	set surveySection(id: string) {
		this.src = `survey-section/${id}`;
	}

	@Input()
	set surveySectionItem([eventId, surveySectionItemId]: [string, string]) {
		this.src = `events/${eventId}/survey-section-item/${surveySectionItemId}`;
	}

	@Input()
	set notification(id: string) {
		this.src = `notification/${id}`;
	}

	@Input() set brainstormBackgroundImage([eventId, backgroundImageId]: [
		string,
		string
	]) {
		this.src = `events/${eventId}/brainstorms/${backgroundImageId}`;
	}

	get showErrors() {
		return this.failed && !this.fallback && this.showError;
	}

	get isLoading() {
		return this.loading || this.rendering;
	}

	get hasImage() {
		return !!this.safeUrl || (this.failed && !!this.fallback);
	}

	async #loadImage() {
		if (this.#src === this.oldSrc) {
			return;
		}
		this.safeUrl = null;

		if (!this.#src) {
			this.oldSrc = null;
			this.loading = false;
			this.failed = false;
			if (this.#reload) {
				this.#reload.unsubscribe();
			}
			this.#reload = null;
			this.#changeDetectorRef.markForCheck();
			return;
		}

		if (['http', 'blob'].includes(this.#src.substring(0, 4).toLowerCase())) {
			this.safeUrl = this.#src;
			this.rendering = true;
			this.failed = false;
			return;
		}

		this.loading = true;
		const loadingUrl = this.#src + (this.thumbnail ? '?thumbnail=1' : '');

		try {
			this.oldSrc = this.#src;
			this.safeUrl = await this.#fileService.getFileUrl(loadingUrl);
			this.rendering = true;
			this.failed = false;
			this.#changeDetectorRef.markForCheck();
		} catch (e) {
			this.imgNotFound.emit();
			this.safeUrl = null;
			this.failed = true;
			this.oldSrc = null;
			this.#changeDetectorRef.markForCheck();

			if (this.retry > 0) {
				this.retry--;
				setTimeout(() => {
					if (this.safeUrl) {
						return;
					}
					this.#loadImage();
				}, 5000);
			}
		} finally {
			this.loading = false;

			if (this.#reload) {
				this.#reload.unsubscribe();
			}
			this.#reload = this.#fileService.onReload(loadingUrl, () => {
				this.oldSrc = null;
				this.#loadImage();
			});
			this.#changeDetectorRef.markForCheck();
		}
	}

	onLoad() {
		this.rendering = false;
		this.domLoad.emit();
		this.#changeDetectorRef.markForCheck();
	}

	ngOnDestroy(): void {
		this.#reload?.unsubscribe();
	}

	onError(event: ErrorEvent) {
		console.log(event, this.safeUrl);
		this.failed = true;
		this.rendering = false;
		this.oldSrc = null;
		this.safeUrl = null;

		if (this.retry > 0) {
			this.retry--;
			setTimeout(() => {
				if (this.safeUrl) {
					return;
				}
				this.#loadImage();
			}, 2000);
		}

		if (this.failed) {
			this.failedLoad.emit();
		}
		console.error('Failed to render image');
		this.#changeDetectorRef.markForCheck();
	}
}
