import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ScopeState, globalScopeKey } from './scope.store';
import { getThemes } from '@store/theming';

export interface GlobalScopeState {
	[globalScopeKey]: ScopeState;
}

const selectGlobalScopeState = createSelector(
	createFeatureSelector<GlobalScopeState>('global'),
	state => state.scope
);

export const selectClient = createSelector(
	selectGlobalScopeState,
	s => s.client
);
export const selectClientId = createSelector(selectClient, s => s?.id);
export const selectPortalEventId = createSelector(
	selectClient,
	s => s?.portalEventId
);
export const selectPortalEventSlug = createSelector(
	selectClient,
	s => s?.portalEventSlug
);
export const selectPortalButtonText = createSelector(
	selectClient,
	s => s?.portalButtonText
);

export const selectEvent = createSelector(selectGlobalScopeState, s => s.event);
export const selectEventId = createSelector(selectEvent, e => e?.id);
export const selectEventName = createSelector(selectEvent, e => e?.name);

export const selectEventTheme = createSelector(
	getThemes.selector,
	selectEvent,
	(themes, event) => themes.find(t => t.id == event.themeId)
);
export const selectClientTheme = createSelector(
	getThemes.selector,
	selectClient,
	(themes, client) => themes.find(t => t.id == client.themeId)
);

export const selectTerms = createSelector(selectGlobalScopeState, x => x.terms);
export const selectDisclaimer = createSelector(
	selectGlobalScopeState,
	x => x.disclaimer
);
export const selectPendingDisclaimers = createSelector(
	selectTerms,
	selectDisclaimer,
	(terms, disclaimer) =>
		(!!terms && !terms.isRead) || (!!disclaimer && !disclaimer.isRead)
);
