import { Directive, HostListener, Input } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[coNoClickBubble]',
})
export class NoClickBubbleDirective {
	@Input('coNoClickBubble') noClickBubble = true;

	@HostListener('click', ['$event'])
	onClick(event: MouseEvent) {
		if (this.noClickBubble !== false) {
			event.stopPropagation();
		}
	}

	@HostListener('mousedown', ['$event'])
	onMouseDown(event: MouseEvent) {
		if (this.noClickBubble !== false) {
			event.stopPropagation();
		}
	}

	@HostListener('mouseup', ['$event'])
	onMouseUp(event: MouseEvent) {
		if (this.noClickBubble !== false) {
			event.stopPropagation();
		}
	}
}
