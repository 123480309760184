import { inject, Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { AgendaClient } from './agenda.client';
import { loadAgendas } from './agenda.actions';
import { selectAgendaState } from './agenda.selectors';
import { EventAgendaState } from './agenda.model';
import { ApiEffects } from '@lib/redux';

@Injectable()
export class AgendaEffects extends ApiEffects<EventAgendaState> {
	readonly #client = inject(AgendaClient);
	readonly #actions$ = inject(Actions);
	getEventAgendas$ = createEffect(() =>
		this.generateApiEffect(loadAgendas, () => this.#client.loadAgendasUser())(
			this.#actions$
		)
	);

	constructor() {
		super(selectAgendaState);
	}
}
