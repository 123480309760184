<ng-container *ngIf="audioUrl">
	<audio
		preload
		[autoplay]="autoplay"
		[src]="audioUrl | coBypassSecurityTrustResourceUrl"
		[controls]="!proxy && !loop"
		(loadedmetadata)="loaded($event)"
		(ended)="onEnd()"
		(playing)="onStart()"
		#audio
		(waiting)="onBuffer()"
		(pause)="onPause()"
		[muted]="muted"
		[loop]="loop"
	></audio>
</ng-container>

<ng-container *ngIf="!audioUrl">
	<div class="error" *ngIf="failed">
		<i class="fal fa-file-audio"></i>
		<p>{{ alt || "Audio is missing" }}</p>
	</div>

	<ng-container *ngIf="loading && !failed">
		<p>Loading...</p>
	</ng-container>
</ng-container>
