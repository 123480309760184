<co-dialog
	[show]="show"
	(showChange)="close($event)"
	[noScroll]="virtualScroll"
	[title]="title"
	[class.no-select]="!canSelect"
>
	<div class="search-wrapper">
		<co-form-search-input
			*ngIf="enableSearch"
			[value]="query$.value"
			(valueChange)="query$.next($event)"
			#searchInput
		/>
	</div>

	<p class="no-items" *ngIf="itemList.length === 0"><i>No Items</i></p>

	<cdk-virtual-scroll-viewport
		[itemSize]="bindSubText ? 60 : 40"
		*ngIf="show && virtualScroll"
	>
		<mat-list dense>
			<ng-container *cdkVirtualFor="let item of itemList">
				<mat-list-item
					class="!tw-cursor-pointer"
					matRipple
					(click)="select(item)"
					[class.active]="
						selectedItemId && selectedItemId === item.id
					"
				>
					<p matListItemLine *ngIf="!htmlLabel">
						{{ item["_label"] }}
					</p>
					<p
						matListItemLine
						*ngIf="htmlLabel"
						[innerHTML]="item['_label']"
					></p>
					<p matListItemLine *ngIf="!!bindSubText && !htmlLabel">
						{{ item["_subText"] }}
					</p>
					<p
						mat-line
						*ngIf="!!bindSubText && htmlLabel"
						[innerHTML]="item['_subText']"
					></p>
				</mat-list-item>
				<mat-divider />
			</ng-container>
		</mat-list>
	</cdk-virtual-scroll-viewport>

	<mat-list dense *ngIf="!virtualScroll && show">
		<ng-container *ngFor="let item of itemList; trackBy: trackByItemId">
			<mat-list-item
				class="!tw-cursor-pointer"
				matRipple
				(click)="select(item)"
				[class.active]="selectedItemId && selectedItemId === item.id"
			>
				<p matListItemLine *ngIf="!htmlLabel">{{ item["_label"] }}</p>
				<p
					matListItemLine
					*ngIf="htmlLabel"
					[innerHTML]="item['_label']"
				></p>
				<p matListItemLine *ngIf="!!bindSubText && !htmlLabel">
					{{ item["_subText"] }}
				</p>
				<p
					matListItemLine
					*ngIf="!!bindSubText && htmlLabel"
					[innerHTML]="item['_subText']"
				></p>
			</mat-list-item>
			<mat-divider />
		</ng-container>
	</mat-list>

	<footer *ngIf="showChange.observed">
		<button mat-button (click)="close()">Close</button>
	</footer>
</co-dialog>
