import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MoveModel } from '@shared/models';
import {
	AdminLivestream,
	GetLivestreamAttendanceReportRequestDto,
	LivestreamCreateModel,
	LivestreamLogEntryModel,
	LivestreamUpdateModel,
	ToggleLivestreamModel,
} from './livestream.model';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class LivestreamClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #httpClient = inject(HttpClient);

	loadAdminLivestreams() {
		return this.#httpClient.get<AdminLivestream[]>(
			`${this.#apiServer}/api/livestream/all`
		);
	}

	createLivestream(data: LivestreamCreateModel) {
		return this.#httpClient.post<AdminLivestream>(
			`${this.#apiServer}/api/livestream`,
			data
		);
	}

	updateLivestream({ id, ...data }: LivestreamUpdateModel) {
		return this.#httpClient.put<AdminLivestream>(
			`${this.#apiServer}/api/livestream/${id}`,
			data
		);
	}

	moveLivestream({ id, ...data }: MoveModel) {
		return this.#httpClient.patch(
			`${this.#apiServer}/api/livestream/${id}/move`,
			data
		);
	}

	deleteLivestream(id: string) {
		return this.#httpClient.delete(`${this.#apiServer}/api/livestream/${id}`);
	}

	toggleLivestream({ id, ...data }: ToggleLivestreamModel) {
		return this.#httpClient.patch<AdminLivestream>(
			`${this.#apiServer}/api/livestream/${id}/toggle`,
			data
		);
	}

	syncLivestream(id: string) {
		return this.#httpClient.post(
			`${this.#apiServer}/api/livestream/${id}/sync`,
			{}
		);
	}

	createLivestreamLogEntry({ id, ...data }: LivestreamLogEntryModel) {
		return this.#httpClient.post(
			`${this.#apiServer}/api/livestream/${id}/log`,
			data
		);
	}

	getLivestreamAttendanceReport(
		requestDto: GetLivestreamAttendanceReportRequestDto
	) {
		return this.#httpClient.post(
			`${this.#apiServer}/api/livestream/reports/attendance`,
			requestDto,
			{
				responseType: 'blob',
			}
		);
	}
}
