import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SessionItemState } from './session-item.reducer';

export const sessionItemKey = 'sessionItems';

export interface GlobalSessionItemState {
	[sessionItemKey]: SessionItemState;
}

const selectEventState = createFeatureSelector<GlobalSessionItemState>('event');

export const selectSessionItemState = createSelector(
	selectEventState,
	state => state.sessionItems
);

export const selectActiveSessionItems = createSelector(
	selectSessionItemState,
	state => state.sessionItems.filter(p => !p.optional && !p.launchCode)
);

export const selectHiddenSessionItems = createSelector(
	selectSessionItemState,
	state => state.sessionItems.filter(p => p.optional || p.launchCode)
);

export const selectOptionalNonHiddenSessionItems = createSelector(
	selectSessionItemState,
	state => state.sessionItems.filter(p => p.optional && !p.hideFromSidepanel)
);

export const selectAllOptionalSessionItems = createSelector(
	selectSessionItemState,
	state => state.sessionItems.filter(p => p.optional)
);

export const selectLauncherSessionItems = createSelector(
	selectSessionItemState,
	state => state.sessionItems.filter(p => p.launchCode)
);

export const selectSessionItem = (id: string) =>
	createSelector(selectSessionItemState, state =>
		state.sessionItems.find(p => p.id == id)
	);
