<div
	class="popup-wrapper"
	*ngIf="show"
	@modal
	[style.zIndex]="zIndex"
	data-testid="co-dialog"
>
	<div
		class="background"
		@background
		(click)="!disableBackground && showChange.emit(false)"
	></div>
	<div
		class="popup"
		@content
		[class]="color ? 'color-' + color : ''"
		[class.scroll]="!noScroll"
		[style.width]="width"
	>
		<div class="popup-header" *ngIf="title || showClose">
			<p [style.text-align]="headerPosition">
				{{ title }}
			</p>
			<div
				*ngIf="showClose"
				class="icon-wrapper"
				(click)="showChange.emit(false)"
			>
				<i class="fas fa-times close-icon"></i>
			</div>
		</div>
		<div class="popup-content">
			<ng-content />
		</div>
		<div class="popup-footer" *ngIf="!noFooter">
			<ng-content select="footer" />
		</div>
	</div>
</div>
