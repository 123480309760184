import {
	ChangeDetectorRef,
	inject,
	NgZone,
	OnDestroy,
	Pipe,
	PipeTransform,
} from '@angular/core';
import { parseDate } from '@lib/helpers';

@Pipe({
	standalone: true,
	name: 'timeAgo',
	pure: false,
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
	readonly #ngZone = inject(NgZone);
	readonly #changeDetectorRef = inject(ChangeDetectorRef);
	#timer: number;

	#getSecondsUntilUpdate(seconds: number) {
		const min = 60;
		const hr = min * 60;
		const day = hr * 24;
		if (seconds < min) {
			// less than 1 min, update every 2 secs
			return 2;
		} else if (seconds < hr) {
			// less than an hour, update every 30 secs
			return 30;
		} else if (seconds < day) {
			// less then a day, update every 5 mins
			return 300;
		} else {
			// update every hour
			return 3600;
		}
	}

	transform(time: Date | string) {
		const val = typeof time === 'string' ? parseDate(time) : (time as Date);

		if (!val) {
			return '';
		}

		this.#removeTimer();
		const now = new Date();

		const seconds = Math.round(
			Math.max(0, now.getTime() - val.getTime()) / 1000
		);

		const timeToUpdate = Number.isNaN(seconds)
			? 1000
			: this.#getSecondsUntilUpdate(seconds) * 1000;

		this.#timer = this.#ngZone.runOutsideAngular(() => {
			if (typeof window !== 'undefined') {
				return window.setTimeout(() => {
					this.#ngZone.run(() => this.#changeDetectorRef.markForCheck());
				}, timeToUpdate);
			}
			return null;
		});

		const minutes = Math.round(Math.abs(seconds / 60));
		const hours = Math.round(Math.abs(minutes / 60));

		if (Number.isNaN(seconds)) {
			return '';
		} else if (seconds <= 45) {
			return 'Just now';
		} else if (seconds <= 90) {
			return 'a minute ago';
		} else if (minutes <= 55) {
			return minutes + ' minutes ago';
		} else if (minutes <= 90) {
			return '1 hour ago';
		} else if (hours <= 22) {
			return hours + ' hours ago';
		} else if (hours <= 24) {
			return '1 day ago';
		} else if (hours <= 48) {
			return 'Yesterday';
		} else {
			return `${val.getMonth() + 1}/${val.getDate()}/${val.getFullYear()}`;
		}
	}

	ngOnDestroy(): void {
		this.#removeTimer();
	}

	#removeTimer() {
		if (this.#timer) {
			window.clearTimeout(this.#timer);
			this.#timer = null;
		}
	}
}
