import { EngagementType } from '@consensus/connect/ufa/colearn/domain';

export class ColearnHelper {
	static getEngagementName(type: EngagementType) {
		switch (type) {
			case EngagementType.CompletePodcast:
				return 'Podcasts';
			case EngagementType.ReadPdf:
				return 'PDFs';
			case EngagementType.WatchVideo:
				return 'Videos';
			case EngagementType.CompleteKnowledgeCheck:
			case EngagementType.AcademyModuleCompletion:
				return 'Learning Activities';
			case EngagementType.MaterialAudioCompleted:
				return 'Material Audio Completed';
			case EngagementType.MaterialAudioVisited:
				return 'Material Audio Visited';
			case EngagementType.MaterialCompleted:
				return 'Materials Completed';
			case EngagementType.MaterialImageVisited:
				return 'Material Images Visited';
			case EngagementType.MaterialPdfVisited:
				return 'Material PDFs Read';
			case EngagementType.MaterialVideoCompleted:
				return 'Material Videos Seen';
			case EngagementType.MaterialVideoVisited:
				return 'Material Videos Visited';
			case EngagementType.MaterialVisited:
				return 'Materials Visited';
		}
	}

	static getEngagementIcon(type: EngagementType) {
		switch (type) {
			case EngagementType.CompletePodcast:
				return 'fal fa-podcast';
			case EngagementType.ReadPdf:
				return 'fal fa-file-pdf';
			case EngagementType.WatchVideo:
				return 'fal fa-play-circle';
			case EngagementType.CompleteKnowledgeCheck:
			case EngagementType.AcademyModuleCompletion:
				return 'fal fa-ballot-check';
			case EngagementType.MaterialAudioCompleted:
				return 'fal fa-file-audio';
			case EngagementType.MaterialAudioVisited:
				return 'fal fa-file-audio';
			case EngagementType.MaterialCompleted:
				return 'fal fa-file-check';
			case EngagementType.MaterialImageVisited:
				return 'fal fa-file-image';
			case EngagementType.MaterialPdfVisited:
				return 'fal fa-file-pdf';
			case EngagementType.MaterialVideoCompleted:
				return 'fal fa-file-video';
			case EngagementType.MaterialVideoVisited:
				return 'fal fa-file-video';
			case EngagementType.MaterialVisited:
				return 'fal fa-file-check';
		}
	}
}
