import { animate, style, transition, trigger } from '@angular/animations';
import { QUAD_IN_OUT_EASING } from './constants';

export const slideFromTopAnimation = trigger('slideFromTop', [
	transition(':enter', [
		style({
			transform: 'translateY(-100%)',
			opacity: 0.3,
		}),
		animate(
			`500ms ${QUAD_IN_OUT_EASING}`,
			style({
				transform: 'translateY(0)',
				opacity: 1,
			})
		),
	]),
	transition(':leave', [
		style({
			transform: 'translateY(0)',
			opacity: 1,
		}),
		animate(
			`400ms ${QUAD_IN_OUT_EASING}`,
			style({
				transform: 'translateY(-100%)',
				opacity: 0.3,
			})
		),
	]),
]);
