import { globalInjector } from '@lib/global-injector';
import { CustomStore } from '@lib/redux';
import { WhatCanIDo } from './user.model';
import { UserClient } from './user.client';
import {
	posthogToken,
	sentryToken,
} from '@consensus/shared/shared/analytics/data-access';
import { AuthedUser, BaseState } from '@shared/models';

export interface UserState extends BaseState, AuthedUser {
	whatCanIDo: WhatCanIDo;
}

export const globalUserKey = 'user';

export const userStore = new CustomStore<UserState, UserClient>(
	'Auth User',
	{
		id: null,
		firstName: null,
		lastName: null,
		email: null,
		username: null,
		disabled: null,
		role: null,
		anonymous: false,
		permissions: [],
		clientId: null,
		observer: false,
		whatCanIDo: null,
	},
	UserClient,
	'Global'
);

const store = userStore;

export const upgradeAuthUser = store
	.addApiAction('User', 'Upgrade')
	.configure({
		showErrors: true,
		successMessage: 'You have upgraded your user',
	})
	.withEffect(s => s.upgradeUser)
	.withReducer((_, _state, upgradedUser) => ({
		email: upgradedUser.email,
		firstName: upgradedUser.firstName,
		lastName: upgradedUser.lastName,
		anonymous: false,
	}));

export const login = store
	.addAction<AuthedUser>('User', 'Login')
	.withSideEffect(action => {
		const user: AuthedUser = action.payload;
		setSentryUserData(user);
		setPostHogUserData(user);
	})
	.withReducer();

export const loadWhatCanIDo = store
	.addApiAction('What Can I Do', 'Load')
	.withEffect(s => s.loadWhatCanIDo)
	.withReducer(whatCanIDo => {
		return {
			whatCanIDo: {
				events: Object.fromEntries(
					Object.entries(whatCanIDo.events).map(([key, value]) => [
						key,
						new Set(value),
					])
				),
				clients: Object.fromEntries(
					Object.entries(whatCanIDo.clients).map(([key, value]) => [
						key,
						new Set(value),
					])
				),
				global: new Set(whatCanIDo.global),
			},
		};
	});

const setSentryUserData = (user: AuthedUser) => {
	const sentry = globalInjector.get(sentryToken);
	/* Add Sentry context data */
	sentry.setUser({
		email: user.email,
		username: user.username,
		id: user.id,
	});
	sentry.setContext('Consensus:::clientId', {
		clientId: user.clientId,
	});
};

const setPostHogUserData = (user: AuthedUser) => {
	const posthog = globalInjector.get(posthogToken);

	/* PostHog identify user */
	posthog.identify(user.username, {
		email: user.email,
		id: user.id,
		clientId: user.clientId,
		eventIds: user.events,
	});
};

export const setAuthUser = store
	.addAction<AuthedUser>('User', 'Set')
	.withReducer();
export const removeAnonFlag = store
	.addSignalAction('User', 'Remove Anon Flag')
	.withReducer(() => ({ anonymous: false }));

export const addUserGroup = store
	.addAction<string>('User Group', 'Add')
	.addition('groups');
export const removeUserGroup = store
	.addAction<string>('User Group', 'Remove')
	.delete('groups', x => x);
