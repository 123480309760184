import {
	loadColearnLeaderboardCompetitions,
	loadColearnProgressSettings,
	loadColearnSections,
	loadColearnSettings,
} from './colearn.actions';
import { Actions, createEffect } from '@ngrx/effects';
import { inject, Injectable } from '@angular/core';
import { ApiEffects } from '@lib/redux';
import { ColearnState } from './colearn.reducer';
import { getColearnState } from './colearn.selectors';
import { ColearnCompetitionClient } from '@consensus/connect/shared/colearn/data-access';
import { ColearnClient } from '@consensus/connect/shared/colearn/data-access';

@Injectable()
export class ColearnEffects extends ApiEffects<ColearnState> {
	readonly #actions$ = inject(Actions);
	readonly #competitionClient = inject(ColearnCompetitionClient);
	readonly #client = inject(ColearnClient);
	constructor() {
		super(getColearnState);
	}

	loadColearnSections$ = createEffect(() =>
		this.generateApiEffect(loadColearnSections, () =>
			this.#client.loadColearnSections()
		)(this.#actions$)
	);

	loadColearnSettings$ = createEffect(() =>
		this.generateApiEffect(loadColearnSettings, () =>
			this.#client.loadColearnSettings()
		)(this.#actions$)
	);

	loadColearnProgressSettings$ = createEffect(() =>
		this.generateApiEffect(loadColearnProgressSettings, () =>
			this.#client.loadColearnProgressSettings()
		)(this.#actions$)
	);

	loadColearnLeaderboardCompetitions$ = createEffect(() =>
		this.generateApiEffect(loadColearnLeaderboardCompetitions, () =>
			this.#competitionClient.loadColearnLeaderboardCompetitions()
		)(this.#actions$)
	);
}
