/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import {
	CdkDrag,
	CdkDragEnd,
	CdkDragMove,
	DragDropModule,
} from '@angular/cdk/drag-drop';
import { NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InputBaseComponent } from '@lib/forms';
import { clamp } from '@lib/helpers';
import { harmonicaAnimation } from '@consensus/co/ui-component-animations';
import { CoTrackByFunction } from '@consensus/co/util-control-flow';

@Component({
	standalone: true,
	selector: 'co-form-slider-input',
	imports: [NgFor, NgIf, DragDropModule, MatTooltipModule],
	templateUrl: './slider-input.component.html',
	styleUrls: ['./slider-input.component.scss'],
	animations: [harmonicaAnimation()],
})
export class SliderInputComponent extends InputBaseComponent<number> {
	@ViewChild('slider', { static: true })
	sliderElement: ElementRef<HTMLDivElement>;
	@ViewChild('range', { static: true })
	rangeElement: ElementRef<HTMLDivElement>;
	@ViewChild('handle', { static: true, read: CdkDrag }) handleElement: CdkDrag;

	_max = 10;
	@Input() set max(max: number) {
		this._max = +max;
		this.reRender();
	}
	get max() {
		return this._max;
	}

	_min = 0;
	@Input() set min(min: number) {
		this._min = +min;
		this.reRender();
	}
	get min() {
		return this._min;
	}

	_visualStepSet = false;
	_visualStep = 1;
	@Input() set visualStep(visualStep: number) {
		this._visualStepSet = !!visualStep;
		if (this._visualStepSet) {
			this._visualStep = +visualStep;
			this.reRenderLabels();
		}
	}
	get visualStep() {
		return this._visualStep;
	}

	_step = 1;
	@Input() set step(step: number) {
		this._step = +step;
		if (!this._visualStepSet) {
			this._visualStep = +step;
		}
		this.reRender();
	}
	get step() {
		return this._step;
	}

	labels: SliderLabel[] = [];

	preprocessValue(value: number): any {
		value = clamp(value, this.min, this.max);
		const percentage = ((value - this.min) * 100) / (this.max - this.min);
		this.placeHandle(this.handleElement, percentage);
	}

	dropHandle(event: CdkDragEnd) {
		const sliderWidth = this.sliderElement.nativeElement.offsetWidth;
		const currentPosition =
			event.source.element.nativeElement.offsetLeft +
			event.source.getFreeDragPosition().x;
		const value = (currentPosition * 100) / sliderWidth;

		const stepCount = Math.floor((this.max - this.min) / this.step);
		const stepPercentage = 100 / stepCount;

		const newValue = clamp(
			Math.round(value / stepPercentage) + this.min,
			this.min,
			this.max
		);
		const percentage = ((newValue - this.min) * 100) / (this.max - this.min);

		this.placeHandle(event.source, percentage);
		this.inputValue = newValue;
	}

	placeHandle(dragElement: CdkDrag, percentage: number) {
		percentage = Math.round(percentage * 1000) / 1000;

		this.rangeElement.nativeElement.style.width = percentage + '%';
		dragElement.element.nativeElement.style.left = percentage + '%';
		dragElement._dragRef.reset();
	}

	reRender() {
		const newValue = clamp(this.inputValue, this.min, this.max);
		const percentage = ((newValue - this.min) * 100) / (this.max - this.min);

		if (this.handleElement) {
			this.placeHandle(this.handleElement, percentage);
		} else {
			setTimeout(() => this.placeHandle(this.handleElement, percentage));
		}

		this.reRenderLabels();
	}

	reRenderLabels() {
		const stepCount = Math.floor((this.max - this.min) / this.visualStep);
		const stepPercentage = 100 / stepCount;
		const stepSize = 100 / (stepCount + 1);

		const labels: SliderLabel[] = [];

		for (let i = 0; i <= stepCount; i++) {
			labels.push({
				label: (this.min + i * this.visualStep).toString(),
				width: stepSize,
				position: i * stepPercentage,
			});
		}

		this.labels = labels;
	}

	moveHandle(event: CdkDragMove) {
		this.rangeElement.nativeElement.style.width =
			event.source.element.nativeElement.offsetLeft +
			event.source.getFreeDragPosition().x +
			'px';
	}

	readonly trackByIdentity: CoTrackByFunction<unknown> = (
		_index: number,
		value: unknown
	) => value;
}

export interface SliderLabel {
	label: string;
	position: number;
	width: number;
}
