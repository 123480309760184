/**
 * Replace the specified item in the collection that matches the predicate if
 * it exists in the collection, otherwise add it to the collection.
 *
 * @returns An updated collection.
 */
export function upsertItem<T>(
	predicate: (iteratedItem: Readonly<T>) => boolean,
	collection: readonly Readonly<T>[],
	updatedItem: Readonly<T>
): readonly Readonly<T>[] {
	const itemIndex = collection.findIndex(predicate);

	return itemIndex === -1
		? [...collection, updatedItem]
		: [
				...collection.slice(0, itemIndex),
				updatedItem,
				...collection.slice(itemIndex + 1),
		  ];
}
