import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SignatureState } from './signature.model';

export const signatureKey = 'signatures';

interface EventFeatureState {
	[signatureKey]: SignatureState;
}

const selectEventState = createFeatureSelector<EventFeatureState>('event');

export const selectSignatureState = createSelector(
	selectEventState,
	state => state.signatures
);

export const selectSignatures = createSelector(
	selectSignatureState,
	s => s.signatures
);
export const selectUserSignatures = createSelector(
	selectSignatureState,
	s => s.userSignatures
);

export const selectUnsignedSignatures = createSelector(
	selectSignatureState,
	s => {
		const signed = s.userSignatures.map(x => x.signatureId);
		return s.signatures.filter(x => !signed.includes(x.id));
	}
);
