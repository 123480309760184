import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Permission } from '@shared/models';
import { UserState, globalUserKey } from './user.store';

export interface GlobalUserState {
	[globalUserKey]: UserState;
}

const selectGlobalUserState = createSelector(
	createFeatureSelector<GlobalUserState>('global'),
	state => state.user
);

export const selectAuthedUser = createSelector(selectGlobalUserState, x => x);

export const selectUserRole = createSelector(
	selectGlobalUserState,
	state => state.role
);

export const selectUserId = createSelector(
	selectGlobalUserState,
	state => state.id
);

export const selectUserName = createSelector(selectGlobalUserState, user => {
	if (!user) {
		return 'N/A';
	}
	let name = '';
	if (user.firstName) {
		name += user.firstName;
	}
	if (user.lastName) {
		if (name.length > 0) {
			name += ' ';
		}
		name += user.lastName;
	}
	if (name.length < 1) {
		if (!user.username) {
			return 'N/A';
		}
		name = user.username.toUpperCase();
	}
	return name;
});

export const selectSingleSignOn = createSelector(
	selectGlobalUserState,
	state => state.singleSignOn ?? false
);

export const selectUserAnon = createSelector(
	selectGlobalUserState,
	state => !!state.anonymous
);
export const selectUserGroups = createSelector(
	selectGlobalUserState,
	x => x.groups
);
export const selectUserEvents = createSelector(
	selectGlobalUserState,
	x => x.events
);

export const selectWhatCanIDo = createSelector(
	selectGlobalUserState,
	x => x.whatCanIDo
);

export const selectPermissions = createSelector(
	selectGlobalUserState,
	x => x.permissions
);
export const selectHasLegacyPermission = createSelector(
	selectPermissions,
	(x: Permission[], permission: Permission) => x.includes(permission)
);
