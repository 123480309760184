import { inject, Injectable } from '@angular/core';
import {
	ColearnBadge,
	ColearnCache,
	EngagementAggregate,
	EngagementTotalUpdate,
} from '@consensus/connect/ufa/colearn/domain';
import { BehaviorSubject, filter } from 'rxjs';
import { sortBySortingKeyAsc, sortDateDesc } from '@lib/helpers';
import { parseDate } from '@lib/helpers';
import { ColearnClient } from './colearn.client';
import { ColearnHelper } from './colearn.helper';
import { Actions, ofType } from '@ngrx/effects';
import { resetStores } from '@lib/redux';
import { keyBy } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class ColearnService {
	readonly #colearnClient = inject(ColearnClient);
	actions$ = inject(Actions);

	constructor() {
		this.actions$
			.pipe(
				ofType(resetStores),
				filter(x => !x.payload || x.payload === 'Event')
			)
			.subscribe(() => this.engagementCache$.next({}));
	}

	engagementCache$ = new BehaviorSubject<{ [userId: string]: ColearnCache }>(
		{}
	);

	get engagementCache() {
		return this.engagementCache$.value;
	}

	#getUserCache(userId: string) {
		const cache = { ...this.engagementCache };
		if (!Object.prototype.hasOwnProperty.call(cache, userId)) {
			cache[userId] = {};
		}
		return cache[userId];
	}

	async loadUserEngagement(userId: string) {
		let cache = this.#getUserCache(userId);
		cache.barometer = null;
		cache.engagement = null;
		this.engagementCache$.next({ ...this.engagementCache, [userId]: cache });

		try {
			const engagements = await this.#colearnClient
				.loadUserEngagement(userId)
				.toPromise();

			cache = this.#getUserCache(userId);

			cache.engagement = engagements
				.map(x => ({
					...x,
					displayName: ColearnHelper.getEngagementName(x.metricType),
					icon: ColearnHelper.getEngagementIcon(x.metricType),
				}))
				.sort(sortBySortingKeyAsc);

			const sums = engagements.reduce(
				(acc, cur) => {
					acc.count += cur.count;
					acc.total += cur.total;
					return acc;
				},
				{ count: 0, total: 0 }
			);

			cache.barometer = sums.total
				? Math.round((sums.count / sums.total) * 100)
				: 0;

			this.engagementCache$.next({ ...this.engagementCache, [userId]: cache });
			return cache;
		} catch {
			const cache = this.#getUserCache(userId);
			cache.barometer = undefined;
			cache.engagement = undefined;
			this.engagementCache$.next({ ...this.engagementCache, [userId]: cache });
		}
	}

	async loadUserBadges(userId: string) {
		let cache = this.#getUserCache(userId);
		cache.badges = null;
		this.engagementCache$.next({ ...this.engagementCache, [userId]: cache });

		try {
			const badges = await this.#colearnClient
				.loadUserBadges(userId)
				.toPromise();
			cache = this.#getUserCache(userId);
			cache.badges = badges.sort(sortBySortingKeyAsc);

			this.engagementCache$.next({ ...this.engagementCache, [userId]: cache });
			return cache;
		} catch {
			const cache = this.#getUserCache(userId);
			cache.badges = undefined;
			this.engagementCache$.next({ ...this.engagementCache, [userId]: cache });
		}
	}

	async loadUserPoints(userId: string) {
		let cache = this.#getUserCache(userId);
		cache.points = null;
		this.engagementCache$.next({ ...this.engagementCache, [userId]: cache });

		try {
			const points = await this.#colearnClient
				.loadUserPoints(userId)
				.toPromise();
			cache = this.#getUserCache(userId);
			cache.points = points.points;

			this.engagementCache$.next({ ...this.engagementCache, [userId]: cache });
			return cache;
		} catch {
			const cache = this.#getUserCache(userId);
			cache.points = undefined;
			this.engagementCache$.next({ ...this.engagementCache, [userId]: cache });
		}
	}

	async loadUserLearningActivities(userId: string) {
		let cache = this.#getUserCache(userId);
		cache.learningActivities = null;
		this.engagementCache$.next({ ...this.engagementCache, [userId]: cache });

		try {
			const activities = await this.#colearnClient
				.loadUserAcademy(userId)
				.toPromise();

			cache = this.#getUserCache(userId);
			activities.forEach(
				x => (x.completedAt = parseDate(x.completedAt.toString()))
			);
			cache.learningActivities = activities.sort(
				sortDateDesc(x => x.completedAt)
			);
		} catch {
			const cache = this.#getUserCache(userId);
			cache.learningActivities = undefined;
			this.engagementCache$.next({ ...this.engagementCache, [userId]: cache });
		}
	}

	badgeSocketUpdate(userId: string) {
		const cache = this.#getUserCache(userId);
		if (cache.badges) {
			this.loadUserBadges(userId);
		}
	}

	userBadgeSocketUpdate(userId: string, updatedBadges: ColearnBadge[]) {
		const cache = this.#getUserCache(userId);
		if (cache.badges) {
			const updatedBadgesLookup = keyBy(updatedBadges, s => s.id);
			cache.badges = cache.badges.map(e => ({
				...e,
				count: Object.prototype.hasOwnProperty.call(updatedBadgesLookup, e.id)
					? e.count + updatedBadgesLookup[e.id].count
					: e.count,
			}));

			this.engagementCache$.next({ ...this.engagementCache, [userId]: cache });
			return cache;
		}
	}

	engagementSocketUpdate(userId: string) {
		const cache = this.#getUserCache(userId);
		if (cache.engagement) {
			this.loadUserEngagement(userId);
		}
	}

	engagementTotalsSocketUpdate(
		userId: string,
		engagements: EngagementTotalUpdate[]
	) {
		const cache = this.#getUserCache(userId);
		if (cache.engagement) {
			const engagementLookup = keyBy(engagements, s => s.type);
			cache.engagement = cache.engagement.map(e => ({
				...e,
				total: Object.prototype.hasOwnProperty.call(
					engagementLookup,
					e.metricType
				)
					? e.total + engagementLookup[e.metricType].total
					: e.total,
			}));

			const sums = cache.engagement.reduce(
				(acc, cur) => {
					acc.count += cur.count;
					acc.total += cur.total;
					return acc;
				},
				{ count: 0, total: 0 }
			);

			cache.barometer = sums.total
				? Math.round((sums.count / sums.total) * 100)
				: 0;

			this.engagementCache$.next({ ...this.engagementCache, [userId]: cache });
			return cache;
		}
	}

	userEngagementSocketUpdate(
		userId: string,
		updatedEngagement: EngagementAggregate
	) {
		const cache = this.#getUserCache(userId);
		if (cache.engagement) {
			cache.engagement = cache.engagement.map(e => ({
				...e,
				count:
					e.metricType == updatedEngagement.metricType
						? e.count + updatedEngagement.count
						: e.count,
			}));

			const sums = cache.engagement.reduce(
				(acc, cur) => {
					acc.count += cur.count;
					acc.total += cur.total;
					return acc;
				},
				{ count: 0, total: 0 }
			);

			cache.barometer = sums.total
				? Math.round((sums.count / sums.total) * 100)
				: 0;

			this.engagementCache$.next({ ...this.engagementCache, [userId]: cache });
			return cache;
		}
	}

	userScoreSocketUpdate(userId: string, score: number) {
		const cache = this.#getUserCache(userId);
		if (cache.points) {
			cache.points += score;
			this.engagementCache$.next({ ...this.engagementCache, [userId]: cache });
			return cache;
		}
	}
}
