<div class="files" *ngIf="files.length">
	<div class="file" *ngFor="let file of files; trackBy: trackByIdentity">
		<div class="name">
			<b>{{ file.name }}:</b>
			<i>{{ file.size | coSharedFileSize }}</i>
		</div>
		<mat-progress-bar
			[class.success]="file.completed && !file.failed"
			[class.failed]="file.completed && file.failed"
			color="primary"
			[mode]="
				file.processing && !file.completed ? 'buffer' : 'determinate'
			"
			[value]="file.completed ? 100 : file.processing ? 0 : file.progress"
		/>
	</div>
</div>
