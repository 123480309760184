import { ApiAction } from '@lib/redux';
import {
	ConnectSupportLoadDto,
	ConnectSupportState,
} from '@consensus/connect/shared/support/domain';

export const loadSupport = new ApiAction<
	ConnectSupportState,
	void,
	ConnectSupportLoadDto
>('Support', 'Load Support', 'Load', {
	showErrors: true,
	errorMessage: 'Failed to load support data',
});

export const loadSupportById = new ApiAction<
	ConnectSupportState,
	string,
	ConnectSupportLoadDto
>('Support', 'Load Support from Id', 'Load', {
	showErrors: true,
	errorMessage: 'Failed to load support data',
});
