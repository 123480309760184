import {
	CmsEliminationGame,
	CmsEliminationGameItem,
} from '@consensus/connect/cms/elimination-game/domain';
import {
	CreateCmsEliminationGameItemModel,
	CreateCmsEliminationGameModel,
	LoadCmsEliminationGamesModel,
	CmsEliminationGameState,
	UpdateCmsEliminationGameStateModel,
} from './cms-elimination-game.model';
import { EliminationGameState } from '@consensus/connect/shared/elimination-game/domain';
import { ApiAction } from '@lib/redux';
import { MoveModel } from '@shared/models';

export const loadAllEliminationGames = new ApiAction<
	CmsEliminationGameState,
	void,
	LoadCmsEliminationGamesModel
>('Elimination Game', 'Load', 'Elimination Game CMS', {
	showErrors: true,
	initialLoad: true,
});

export const createEliminationGame = new ApiAction<
	CmsEliminationGameState,
	CreateCmsEliminationGameModel,
	CmsEliminationGame
>('Elimination Game', 'Create', 'Elimination Game CMS', {
	showErrors: true,
	successMessage: 'Created Elimination Game',
});

export const updateEliminationGame = new ApiAction<
	CmsEliminationGameState,
	CmsEliminationGame,
	CmsEliminationGame
>('Elimination Game', 'Update', 'Elimination Game CMS', {
	showErrors: true,
	successMessage: 'Updated Elimination Game',
});

export const duplicateEliminationGame = new ApiAction<
	CmsEliminationGameState,
	string,
	CmsEliminationGame
>('Elimination Game', 'Duplicate', 'Elimination Game CMS', {
	showErrors: true,
	successMessage: 'Duplicated Elimination Game',
});

export const deleteEliminationGame = new ApiAction<
	CmsEliminationGameState,
	string,
	string
>('Elimination Game', 'Delete', 'Elimination Game CMS', {
	showErrors: true,
	successMessage: 'Deleted Elimination Game',
	eager: true,
});

export const moveEliminationGame = new ApiAction<
	CmsEliminationGameState,
	MoveModel,
	MoveModel
>('Elimination Game', 'Move', 'Elimination Game CMS', {
	showErrors: true,
	eager: true,
});

export const createEliminationGameItem = new ApiAction<
	CmsEliminationGameState,
	CreateCmsEliminationGameItemModel,
	CmsEliminationGameItem
>('Elimination Game Item', 'Create', 'Elimination Game CMS', {
	showErrors: true,
	successMessage: 'Created Item',
});

export const updateEliminationGameItem = new ApiAction<
	CmsEliminationGameState,
	CmsEliminationGameItem,
	CmsEliminationGameItem
>('Elimination Game Item', 'Update', 'Elimination Game CMS', {
	showErrors: true,
	successMessage: 'Updated Elimination Game',
});

export const deleteEliminationGameItem = new ApiAction<
	CmsEliminationGameState,
	string,
	string
>('Elimination Game Item', 'Delete', 'Elimination Game CMS', {
	showErrors: true,
	successMessage: 'Deleted Elimination Game',
	eager: true,
});

export const moveEliminationGameItem = new ApiAction<
	CmsEliminationGameState,
	MoveModel,
	MoveModel
>('Elimination Game Item', 'Move', 'Elimination Game CMS', {
	showErrors: true,
	eager: true,
});

export const startEliminationGame = new ApiAction<
	CmsEliminationGameState,
	string,
	EliminationGameState
>('Elimination Game', 'Start', 'Elimination Game CMS', {
	showErrors: true,
});

export const stopEliminationGame = new ApiAction<
	CmsEliminationGameState,
	string,
	EliminationGameState
>('Elimination Game', 'Stop', 'Elimination Game CMS', {
	showErrors: true,
});

export const advanceEliminationGame = new ApiAction<
	CmsEliminationGameState,
	string,
	EliminationGameState
>('Elimination Game', 'Advance', 'Elimination Game CMS', {
	showErrors: true,
});

export const updateEliminationGameState = new ApiAction<
	CmsEliminationGameState,
	UpdateCmsEliminationGameStateModel,
	EliminationGameState
>('Elimination Game State', 'Update', 'Elimination Game CMS', {
	showErrors: true,
});
