const wordTranslation = [
	{ from: 'Legal Identifier', to: 'PRB Code' },
	{ from: 'Module', to: 'Asset' },
	{ from: 'Modules', to: 'Assets' },
	{ from: 'Content Owner', to: 'Asset Owner' },
];

/**
 * Make a text Novo Nordisk-compliant, taking Sema into account.
 *
 * @param isSema Whether the session is about Sema.
 * @param str The text to make Novo Nordisk-compliant.
 * @returns A Novo Nordisk-compliant text.
 */
export function novofy(isSema: boolean, str: string) {
	if (isSema) {
		for (const translation of wordTranslation) {
			str = str.replace(translation.from, translation.to);
		}
	}
	return str;
}
