import { inject, Injectable } from '@angular/core';
import {
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { tap } from 'rxjs';
import { VersionService } from '@environments/environment';

@Injectable()
export class ServerVersionInterceptor implements HttpInterceptor {
	readonly #versionService = inject(VersionService);

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		return next.handle(req).pipe(
			tap(request => {
				if (request instanceof HttpResponse) {
					const response = request as HttpResponse<any>;
					const ver = response.headers.get('api-version')?.trim();
					const host = response.headers.get('x-backend-server');

					if (ver && ver !== this.#versionService.serverVersion) {
						this.#versionService.serverVersion$.next(ver);
					}

					if (host !== this.#versionService.hostServer) {
						this.#versionService.hostServer$.next(host);
					}
				}
			})
		);
	}
}
