import {
	ChangeDetectionStrategy,
	Component,
	inject,
	Input,
} from '@angular/core';
import { FileCacheService } from '@consensus/shared/shared/files/data-access-files';
import { LetDirective } from '@ngrx/component';
import { sentryToken } from '@consensus/shared/shared/analytics/data-access';
import { BehaviorSubject } from 'rxjs';
import { NgIf } from '@angular/common';
import { CoSplinePlayerComponent } from '@consensus/co/ui-spline-player';

@Component({
	selector: 'co-drive-spline-viewer',
	standalone: true,
	imports: [LetDirective, NgIf, CoSplinePlayerComponent],
	templateUrl: './co-drive-spline-viewer.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoDriveSplineViewerComponent {
	readonly #sentry = inject(sentryToken);
	readonly #fileService = inject(FileCacheService);
	readonly sceneUrl$ = new BehaviorSubject<string | null>(null);

	@Input({ required: true }) set driveFileId(id: string) {
		this.#fileService.getFileUrl(`materials/${id}`).then(
			sceneUrl => this.sceneUrl$.next(sceneUrl),
			error => {
				this.#sentry.captureException(error);
				console.error(`Failed to get drive spline code url. ${error}`);
			}
		);
	}
	@Input() enableGlobalEvents = false;
	@Input() hideLoadingSpinner = false;
}
