import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
	CreateCmsEliminationGameItemModel,
	CreateCmsEliminationGameModel,
	LoadCmsEliminationGamesModel,
	CmsEliminationGameResultState,
	UpdateCmsEliminationGameStateModel,
} from './cms-elimination-game.model';
import {
	CmsEliminationGame,
	CmsEliminationGameItem,
	CmsEliminationGameItemResult,
	CmsEliminationGameResult,
	CmsEliminationGameTieStatus,
} from '@consensus/connect/cms/elimination-game/domain';
import { EliminationGameState } from '@consensus/connect/shared/elimination-game/domain';
import { map } from 'rxjs';
import { MoveModel } from '@shared/models';
import { SessionService } from '@store/scope';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class CmsEliminationGameClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #http = inject(HttpClient);
	readonly #session = inject(SessionService);

	loadAllEliminationGames() {
		return this.#http.get<LoadCmsEliminationGamesModel>(
			`${this.#apiServer}/api/events/${
				this.#session.eventId
			}/elimination-game/all`
		);
	}

	createEliminationGame(data: CreateCmsEliminationGameModel) {
		return this.#http.post<CmsEliminationGame>(
			`${this.#apiServer}/api/events/${this.#session.eventId}/elimination-game`,
			data
		);
	}

	updateEliminationGame({ id, ...data }: CmsEliminationGame) {
		return this.#http.put<CmsEliminationGame>(
			`${this.#apiServer}/api/events/${
				this.#session.eventId
			}/elimination-game/${id}`,
			data
		);
	}

	duplicateEliminationGame(id: string) {
		return this.#http.post<CmsEliminationGame>(
			`${this.#apiServer}/api/events/${
				this.#session.eventId
			}/elimination-game/${id}/duplicate`,
			{}
		);
	}

	deleteEliminationGame(id: string) {
		return this.#http.delete(
			`${this.#apiServer}/api/events/${
				this.#session.eventId
			}/elimination-game/${id}`
		);
	}

	moveEliminationGame({ id, sortingKey }: MoveModel) {
		return this.#http.patch(
			`${this.#apiServer}/api/events/${
				this.#session.eventId
			}/elimination-game/${id}/move`,
			{ sortingKey }
		);
	}

	createEliminationGameItem({
		eliminationGameId: pushoutId,
		...data
	}: CreateCmsEliminationGameItemModel) {
		return this.#http.post<CmsEliminationGameItem>(
			`${this.#apiServer}/api/events/${
				this.#session.eventId
			}/elimination-game/${pushoutId}/item`,
			data
		);
	}

	updateEliminationGameItem({ id, ...data }: CmsEliminationGameItem) {
		return this.#http.put<CmsEliminationGameItem>(
			`${this.#apiServer}/api/events/${
				this.#session.eventId
			}/elimination-game/item/${id}`,
			data
		);
	}

	deleteEliminationGameItem(id: string) {
		return this.#http.delete(
			`${this.#apiServer}/api/events/${
				this.#session.eventId
			}/elimination-game/item/${id}`
		);
	}

	moveEliminationGameItem({ id, sortingKey }: MoveModel) {
		return this.#http.patch(
			`${this.#apiServer}/api/events/${
				this.#session.eventId
			}/elimination-game/item/${id}/move`,
			{
				sortingKey,
			}
		);
	}

	startEliminationGame(pushoutId: string) {
		return this.#http.patch<EliminationGameState>(
			`${this.#apiServer}/api/events/${
				this.#session.eventId
			}/elimination-game/${pushoutId}/start`,
			{}
		);
	}

	stopEliminationGame(pushoutId: string) {
		return this.#http.patch<EliminationGameState>(
			`${this.#apiServer}/api/events/${
				this.#session.eventId
			}/elimination-game/${pushoutId}/stop`,
			{}
		);
	}

	updateEliminationGameState({
		eliminationGameId: pushoutId,
		...data
	}: UpdateCmsEliminationGameStateModel) {
		return this.#http.put<EliminationGameState>(
			`${this.#apiServer}/api/events/${
				this.#session.eventId
			}/elimination-game/${pushoutId}/state`,
			data
		);
	}

	nextItem(pushoutId: string) {
		return this.#http.patch<EliminationGameState>(
			`${this.#apiServer}/api/events/${
				this.#session.eventId
			}/elimination-game/${pushoutId}/advance`,
			{}
		);
	}

	getAnswerCount(questionId: string) {
		return this.#http
			.get<{ answers: number }>(
				`${this.#apiServer}/api/events/${
					this.#session.eventId
				}/elimination-game/question/${questionId}/answers/count`
			)
			.pipe(map(x => x.answers));
	}

	getUserCount(pushoutId: string) {
		return this.#http
			.get<{ users: number }>(
				`${this.#apiServer}/api/events/${
					this.#session.eventId
				}/elimination-game/${pushoutId}/users/count`
			)
			.pipe(map(x => x.users));
	}

	getEliminationGameState(pushoutId: string) {
		return this.#http.get<EliminationGameState>(
			`${this.#apiServer}/api/events/${
				this.#session.eventId
			}/elimination-game/${pushoutId}/state`
		);
	}

	getEliminationGameResults(
		eliminationGameId: string,
		teamId: string | null = null,
		includeEliminated = false,
		tieBreaker = false,
		winner = false
	) {
		const payload: Record<string, string> = {
			tieBreaker: tieBreaker.toString(),
			includeEliminated: includeEliminated.toString(),
			winner: winner.toString(),
		};
		if (teamId) {
			payload['teamId'] = teamId;
		}
		return this.#http.get<CmsEliminationGameResult[]>(
			`${this.#apiServer}/api/events/${
				this.#session.eventId
			}/elimination-game/${eliminationGameId}/results`,
			{
				params: payload,
			}
		);
	}

	getItemResults(itemId: string) {
		return this.#http.get<CmsEliminationGameItemResult[]>(
			`${this.#apiServer}/api/events/${
				this.#session.eventId
			}/elimination-game/item/${itemId}/results`
		);
	}

	getTieStatus(eliminationGameId: string, teamId: string | null = null) {
		const params = new HttpParams();
		if (teamId) {
			params.set('teamId', teamId);
		}
		return this.#http.get<CmsEliminationGameTieStatus>(
			`${this.#apiServer}/api/events/${
				this.#session.eventId
			}/elimination-game/${eliminationGameId}/ties`,
			{
				params,
			}
		);
	}

	updateEliminationGameResultState(
		eliminationGameId: string,
		state: CmsEliminationGameResultState
	) {
		return this.#http.patch(
			`${this.#apiServer}/api/events/${
				this.#session.eventId
			}/elimination-game/${eliminationGameId}/state/result`,
			state
		);
	}
}
