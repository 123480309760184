import { inject, Injectable } from '@angular/core';
import {
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { TokenService } from '@consensus/shared/shared/iam/data-access';
import { SessionService } from '@store/scope';
import { environmentToken } from '@environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	readonly #sessionService = inject(SessionService);
	readonly #tokenService = inject(TokenService);
	readonly #apiServer = inject(environmentToken).server;
	readonly #mediaServer = inject(environmentToken).mediaServer;

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		const url = req.url;

		if (
			!url.startsWith(this.#apiServer) &&
			!url.startsWith(this.#mediaServer)
		) {
			return next.handle(req);
		}

		const token = this.#tokenService.getLoginToken();
		const spoofUserId = localStorage.getItem('spoofUserId');

		const setHeaders = Object.fromEntries(
			Object.entries({
				eventId:
					req.headers.get('eventId') ?? this.#sessionService.eventId ?? '',
				clientId:
					req.headers.get('clientId') ?? this.#sessionService.clientId ?? '',
				Authorization: token ? `Bearer ${token}` : null,
				spoofUserId: spoofUserId || null,
			})
				// Remove nullable values from the object
				.filter(
					(arg): arg is [string, NonNullable<(typeof arg)[1]>] => arg[1] != null
				)
		);

		req = req.clone({
			setHeaders,
			url,
			withCredentials: true,
		});

		return next.handle(req);
	}
}
