import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
	Group,
	GroupAddUserModel,
	GroupRemoveUserModel,
	GroupUpdateModel,
	ToggleGroup,
} from './group.model';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class GroupClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #httpClient = inject(HttpClient);

	loadGroups() {
		return this.#httpClient.get<Group[]>(`${this.#apiServer}/api/group`);
	}

	loadGroupsForSpecificEvent(eventId: string) {
		return this.#httpClient.get<Group[]>(
			`${this.#apiServer}/api/group/event/${eventId}`
		);
	}

	createGroup(name: string) {
		return this.#httpClient.post<Group>(`${this.#apiServer}/api/group`, {
			name,
		});
	}

	deleteGroup(id: string) {
		return this.#httpClient.delete(`${this.#apiServer}/api/group/${id}`);
	}

	updateGroup({ id, ...data }: GroupUpdateModel) {
		return this.#httpClient.put(`${this.#apiServer}/api/group/${id}`, data);
	}

	addUserToGroup({ groupId, userId }: GroupAddUserModel) {
		return this.#httpClient.post(
			`${this.#apiServer}/api/group/${groupId}/user`,
			{ userId }
		);
	}

	removeUserFromGroup({ groupId, userId }: GroupRemoveUserModel) {
		return this.#httpClient.delete(
			`${this.#apiServer}/api/group/${groupId}/user/${userId}`
		);
	}

	addGroupToSpeaker({ groupId, id }: ToggleGroup) {
		return this.#httpClient.post(
			`${this.#apiServer}/api/speaker/${id}/group/${groupId}`,
			{}
		);
	}

	removeGroupFromSpeaker({ groupId, id }: ToggleGroup) {
		return this.#httpClient.delete(
			`${this.#apiServer}/api/speaker/${id}/group/${groupId}`
		);
	}

	addGroupToSidepanelItem({ groupId, id }: ToggleGroup) {
		return this.#httpClient.post(
			`${this.#apiServer}/api/sidepanel/${id}/group/${groupId}`,
			{}
		);
	}

	removeGroupFromSidepanelItem({ groupId, id }: ToggleGroup) {
		return this.#httpClient.delete(
			`${this.#apiServer}/api/sidepanel/${id}/group/${groupId}`
		);
	}

	addGroupToMaterialsFolder({ groupId, id }: ToggleGroup) {
		return this.#httpClient.post(
			`${this.#apiServer}/api/materials/folder/${id}/group/${groupId}`,
			{}
		);
	}

	removeGroupFromMaterialsFolder({ groupId, id }: ToggleGroup) {
		return this.#httpClient.delete(
			`${this.#apiServer}/api/materials/folder/${id}/group/${groupId}`
		);
	}

	addGroupToMaterialsFile({ groupId, id }: ToggleGroup) {
		return this.#httpClient.post(
			`${this.#apiServer}/api/materials/file/${id}/group/${groupId}`,
			{}
		);
	}

	removeGroupFromMaterialsFile({ groupId, id }: ToggleGroup) {
		return this.#httpClient.delete(
			`${this.#apiServer}/api/materials/file/${id}/group/${groupId}`
		);
	}
}
