import { BaseState, initialBaseState } from '@shared/models';
import { QaLoadModel, QaQuestion } from './qa.model';
import { ApiReducer } from '@lib/redux';
import {
	addUserQaQuestion,
	createQaQuestion,
	deleteQaQuestion,
	loadPublicQuestions,
	loadQa,
	loadUserQuestions,
	removeQaQuestion,
	removeQaSession,
	removeQaSessions,
	removeQaTopic,
	setModQaQuestion,
	setQaSession,
	setQaSessions,
	setQaSettings,
	setQaTopic,
	updateQaQuestion,
} from './qa.actions';
import { removeListItem, updateListItem } from '@lib/redux';

export interface GlobalQaState {
	[qaKey]: QaState;
}

export interface QaState extends BaseState, QaLoadModel {
	questions: QaQuestion[];
	userQuestions: QaQuestion[];
}

export const qaKey = 'qa';

const initialState: QaState = {
	...initialBaseState,
	questions: [],
	userQuestions: [],
	settings: null,
	sessions: [],
	topics: [],
};

const reducer = new ApiReducer(initialState, 'Event');

reducer.addApiReducer(loadQa);

reducer.addApiReducer(loadUserQuestions, userQuestions => ({ userQuestions }));
reducer.addApiReducer(loadPublicQuestions, questions => ({ questions }));

reducer.addApiReducer(
	createQaQuestion,
	(question, { userQuestions }, { isAnonymous }) => ({
		userQuestions: isAnonymous ? userQuestions : [...userQuestions, question],
	})
);

reducer.addApiReducer(updateQaQuestion, (question, { userQuestions }) => ({
	userQuestions: updateListItem(
		userQuestions,
		q => q.id === question.id,
		question
	),
}));

reducer.addApiReducer(deleteQaQuestion, (id, { userQuestions }) => ({
	userQuestions: removeListItem(userQuestions, q => q.id === id),
}));

reducer.addReducer(setQaSettings, (s, { settings }) => ({
	settings: { ...settings, ...s },
}));

reducer.addReducer(setQaSession, (session, { sessions }) => ({
	sessions: updateListItem(sessions, s => s == session, session, true),
}));

reducer.addReducer(removeQaSession, (id, { sessions }) => ({
	sessions: removeListItem(sessions, x => x == id),
}));

reducer.addReducer(setQaSessions, (ids, { sessions }) => ({
	sessions: [...sessions, ...ids],
}));

reducer.addReducer(removeQaSessions, (ids, { sessions }) => ({
	sessions: sessions.filter(s => !ids.some(i => i == s)),
}));

reducer.addReducer(setQaTopic, (topic, { topics }) => ({
	topics: updateListItem(topics, x => x.id === topic.id, topic, true),
}));

reducer.addReducer(removeQaTopic, (id, { topics }) => ({
	topics: removeListItem(topics, x => x.id === id),
}));

reducer.addReducer(addUserQaQuestion, (question, { questions }) => ({
	questions: updateListItem(
		questions,
		x => x.id === question.id,
		question,
		true
	),
}));

reducer.addReducer(setModQaQuestion, (question, { questions }) => ({
	questions: updateListItem(
		questions,
		x => x.id === question.id,
		question,
		true
	),
}));

reducer.addReducer(removeQaQuestion, (id, { questions }) => ({
	questions: removeListItem(questions, x => x.id === id),
}));

export const qaReducer = reducer.getReducer();
